import { useEffect, useMemo, useState } from 'react';
import {
  useSaveSubscriptionsByEmailMutation,
  useSubscriptionsByEmailQuery,
} from '@innovamat/glow-api-client';
import { snack } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

type Newsletter = {
  value: number;
  active: boolean;
  name: string;
  description: string;
};

type UseEmailPreferencesProps = {
  email: string;
};

type useEmailPreferencesValues = {
  updatedSubscriptions: Newsletter[];
  hasSomethingChanged: boolean;
  isEverythingUnsubscribed: boolean;
  isPending: boolean;
  toggleSubscription: (value: number) => void;
  unsubscribeAll: () => void;
  resetValues: () => void;
  handleSave: () => void;
};

const mapValueToSubscription: {
  [key: number]: { name: string; description: string };
} = {
  79038: {
    name: 'settings.emailing.item_3.title',
    description: 'settings.emailing.item_3.subtitle',
  },
  79040: {
    name: 'settings.emailing.item_4.title',
    description: 'settings.emailing.item_4.subtitle',
  },
  79036: {
    name: 'settings.emailing.item_5.title',
    description: 'settings.emailing.item_5.subtitle',
  },
};

const mapSubscriptions = (
  subscribedList: number[],
  unsubscribedList: number[]
): Newsletter[] => {
  const allSubscriptions = [...subscribedList, ...unsubscribedList];

  return allSubscriptions
    .map((value) => ({
      value,
      active: subscribedList.includes(value),
      name: mapValueToSubscription[value]?.name,
      description: mapValueToSubscription[value]?.description,
    }))
    .filter((subscription) => Boolean(subscription.name));
};

export function useEmailPreferences({
  email,
}: UseEmailPreferencesProps): useEmailPreferencesValues {
  const { t } = useTranslation();
  const [updatedSubscriptions, setUpdatedSubscriptions] = useState<
    Newsletter[]
  >([]);
  const [initialSubscriptions, setInitialSubscriptions] = useState<
    Newsletter[]
  >([]);

  const { mutate: saveSubscriptions, isPending } =
    useSaveSubscriptionsByEmailMutation();

  const { data } = useSubscriptionsByEmailQuery({ email });

  const initialSubscriptionsMapped: Newsletter[] = useMemo(() => {
    const subscriptions = data?.subscriptionsByEmail;

    const subscribedList =
      subscriptions?.subscribedList?.filter(
        (value): value is number => value !== null
      ) || [];

    const unsubscribedList =
      subscriptions?.unsubscribedList?.filter(
        (value): value is number => value !== null
      ) || [];

    if (subscribedList.length === 0 && unsubscribedList.length === 0) {
      const allPossibleValues = Object.keys(mapValueToSubscription).map(Number);
      return allPossibleValues.map((value) => ({
        value,
        active: true,
        name: mapValueToSubscription[value]?.name,
        description: mapValueToSubscription[value]?.description,
      }));
    }

    return mapSubscriptions(subscribedList, unsubscribedList);
  }, [data?.subscriptionsByEmail]);

  useEffect(() => {
    if (!initialSubscriptionsMapped) return;
    setInitialSubscriptions(initialSubscriptionsMapped);
    setUpdatedSubscriptions(initialSubscriptionsMapped);
  }, [initialSubscriptionsMapped]);

  const toggleSubscription = (value: number) => {
    const _updatedSubscriptions = updatedSubscriptions.map((subscription) =>
      subscription.value === value
        ? { ...subscription, active: !subscription.active }
        : subscription
    );
    setUpdatedSubscriptions(_updatedSubscriptions);
  };

  const hasSomethingChanged = useMemo(() => {
    return (
      JSON.stringify(initialSubscriptions) !==
      JSON.stringify(updatedSubscriptions)
    );
  }, [initialSubscriptions, updatedSubscriptions]);

  const isEverythingUnsubscribed = useMemo(() => {
    return updatedSubscriptions.every((subscription) => !subscription.active);
  }, [updatedSubscriptions]);

  const unsubscribeAll = () => {
    const updatedSubscriptions = initialSubscriptions.map((subscription) => ({
      ...subscription,
      active: false,
    }));
    setUpdatedSubscriptions(updatedSubscriptions);
  };

  const resetValues = () => {
    setUpdatedSubscriptions(initialSubscriptions);
  };

  const handleSave = () => {
    const subscribedList = updatedSubscriptions
      .filter((subscription) => subscription.active)
      .map((subscription) => subscription.value);

    const unsubscribedList = updatedSubscriptions
      .filter((subscription) => !subscription.active)
      .map((subscription) => subscription.value);

    saveSubscriptions(
      { email, subscribedList, unsubscribedList },
      {
        onError: () => snack.error(t('settings.emailing.not_saved')),
        onSuccess: () => {
          snack.success(t('settings.emailing.saved'));
          setInitialSubscriptions(updatedSubscriptions);
        },
      }
    );
  };

  return {
    updatedSubscriptions,
    toggleSubscription,
    hasSomethingChanged,
    isEverythingUnsubscribed,
    unsubscribeAll,
    resetValues,
    handleSave,
    isPending,
  };
}
