import { isNullOrUndefined } from './common';
import { scoreLevels } from './common';

export const heights = {
  LOW_HEIGHT: 0,
  MEDIUM_HEIGHT: 16,
  MEDIUM_HIGH_HEIGHT: 32,
  HIGH_HEIGHT: 48,
};

export const getScoresHeights = (
  scores: number[],
  numElement: number
): number[] => {
  const scoresPreprocessed: number[] = [];
  if (scores.length >= numElement) scores.splice(0, scores.length - numElement);
  scores.forEach((score, index) => {
    if (isNullOrUndefined(score)) {
      scoresPreprocessed.push(
        index ? scoresPreprocessed[index - 1] : heights.MEDIUM_HEIGHT
      );
    } else {
      const ceilScore = parseFloat(score.toFixed(1));
      if (ceilScore < scoreLevels.LOW_SCORE) {
        scoresPreprocessed.push(heights.LOW_HEIGHT);
      } else if (ceilScore < scoreLevels.MEDIUM_SCORE) {
        scoresPreprocessed.push(heights.MEDIUM_HEIGHT);
      } else if (ceilScore < scoreLevels.MEDIUM_HIGH_SCORE) {
        scoresPreprocessed.push(heights.MEDIUM_HIGH_HEIGHT);
      } else {
        scoresPreprocessed.push(heights.HIGH_HEIGHT);
      }
    }
  });
  return scoresPreprocessed;
};
