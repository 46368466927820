import { GetSpacesBody, MarkSpaceBody } from '@innovamat/glow-api-client';
import { SpacesData } from '@innovamat/smart-guides';

export const updateSpacesInCache = (
  queryClient: any,
  spacesBody: Record<string, GetSpacesBody>,
  body: MarkSpaceBody
) => {
  const variables = ['Spaces', spacesBody];
  const prevSpaces: SpacesData =
    queryClient.getQueryData(variables).spaces || [];

  const updatedSpaces = prevSpaces;
  updatedSpaces[body.spaceId] = {
    ...prevSpaces[body.spaceId],
    is_pinned: body.pinned,
    challenge_id: body.challengeId,
  };
  queryClient.setQueryData(variables, { spaces: updatedSpaces });
  return updatedSpaces;
};

export const updateChallengeFromSpaceInCache = (
  queryClient: any,
  spacesBody: Record<string, GetSpacesBody>,
  spaceId: string,
  challengeId: string
) => {
  const variables = ['Spaces', spacesBody];
  const prevSpaces: SpacesData =
    queryClient.getQueryData(variables).spaces || [];
  if (!spaceId || !challengeId || !prevSpaces) return;
  const updatedSpaces = prevSpaces;

  if (!updatedSpaces[spaceId] || !updatedSpaces[spaceId].challenges)
    return updatedSpaces;

  const { challenges } = updatedSpaces[spaceId];
  challenges[challengeId] =
    challenges[challengeId] === 'completed' ? 'pending' : 'completed';
  updatedSpaces[spaceId] = {
    ...prevSpaces[spaceId],
    challenges,
  };
  queryClient.setQueryData(variables, { spaces: updatedSpaces });
  return updatedSpaces;
};

export const getSpacesInCache = (
  queryClient: any,
  spacesBody: Record<string, GetSpacesBody>
) => {
  const variables = ['Spaces', spacesBody];
  return queryClient.getQueryData(variables).spaces || [];
};

export const updateChallengeInCache = (
  queryClient: any,
  challengesBody: Record<string, GetSpacesBody>,
  challengeId: string,
  done?: boolean
) => {
  const variables = ['Challenges', challengesBody];
  const prevChallenges = queryClient.getQueryData(variables).challenges;
  if (!challengeId || !prevChallenges) return;
  const updatedChallenges = prevChallenges;

  if (!updatedChallenges[challengeId]) return updatedChallenges;

  if (done !== undefined) {
    updatedChallenges[challengeId] =
      updatedChallenges[challengeId] === 'completed' ? 'pending' : 'completed';
  } else {
    updatedChallenges[challengeId] = done ? 'completed' : 'pending';
  }
  queryClient.setQueryData(variables, { challenges: updatedChallenges });
  return updatedChallenges;
};
