import { Cell, Row, TableContentType } from '../types/Table';
import { mapMedia } from './mapMedia';

function mapRows(tableCells: any, colums: number): Row[] {
  const rows: Row[] = [];
  const cells = tableCells.map(mapCell);

  for (let i = 0; i < cells.length; i += colums) {
    const row = cells.slice(i, i + colums);
    rows.push(row);
  }

  return rows;
}

function mapCell(item: any): Cell {
  return {
    isHeader: item.header_cell,
    media: item.image_cell_content
      ? mapMedia([item.image_cell_content], item.ancho_de_imagen)
      : null,
    text: item.text_cell_content,
  };
}

export function mapTable(data: any): TableContentType[] {
  return data?.smartguidetable?.map((item: any, index: number) => {
    const columnPercentage: number[] =
      item.table_configuration.column_percentage
        .split(',')
        .map((column: any) => +column.trim());
    return {
      title: `${data.table_title}-${index}`,
      columnPercentage,
      rows: mapRows(item.table_cells, columnPercentage.length),
    } as TableContentType;
  });
}
