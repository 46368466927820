import React, { useState } from 'react';
import { Wrapper, CountryName } from './FlagAndName.styles';

type FlagAndNameProps = {
  countryFlag: string;
  countryName: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const FlagAndName = ({
  countryFlag,
  countryName,
  onClick,
}: FlagAndNameProps) => {
  return (
    <Wrapper onClick={(e) => onClick?.(e)}>
      <img src={countryFlag} alt={countryName} />
      <CountryName>{countryName}</CountryName>
    </Wrapper>
  );
};

export default FlagAndName;
