import { Member } from '@innovamat/glow-api-client';
import { Dispatch, SetStateAction } from 'react';
import type { StudentRow } from '../classroom-students';

type InviteParentsSuccess = {
  parents: Member[];
  studentId: string;
  setStudents: Dispatch<SetStateAction<StudentRow[]>>;
};

// when we add a parent email to student, that is the email of other student
// and de user confirms, we need to remove the email from the student and set as a parent

function onInviteParentsAction({
  parents,
  studentId,
  setStudents,
}: InviteParentsSuccess): void {
  setStudents((students) => {
    const studentsWithConflictedEmails = students.filter((student) => {
      if (student?.id === studentId) return false;
      return parents.find(
        (parent) => parent.userInfo?.email === student?.email
      );
    });

    const updatedStudents = students?.map((student) => {
      if (student?.id === studentId!) {
        const actualParents = student?.parents || [];
        const newParents = parents.map((parent) => ({
          ...parent,
        }));
        const updatedParents = [...actualParents, ...newParents];

        return { ...student, parents: updatedParents };
      }

      const findedStudent = studentsWithConflictedEmails?.find(
        (s) => s?.id === student?.id
      );

      if (findedStudent) {
        return {
          ...findedStudent,
          email: null,
          individualAccess: false,
        };
      }

      return student;
    });

    return updatedStudents;
  });
}

type UnlinkParentSuccess = {
  parentEmail: string;
  studentId: string;
  setStudents: Dispatch<SetStateAction<StudentRow[]>>;
};

function onUnlinkParentAction({
  parentEmail,
  setStudents,
  studentId,
}: UnlinkParentSuccess): void {
  setStudents((students) => {
    const updatedStudents = students.map((student) => {
      if (student?.id === studentId) {
        const updatedParents = student?.parents?.filter(
          (parent) => parent?.userInfo?.email !== parentEmail
        );
        return {
          ...student,
          parents: updatedParents,
        };
      }
      return student;
    });
    return updatedStudents;
  });
}

export { onInviteParentsAction, onUnlinkParentAction };
