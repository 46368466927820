import { useTranslation } from 'react-i18next';

import {
  Skeleton,
  TableComponent,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import type { Column, Row } from '@innovamat/glimmer-components';
import { Stage } from '@innovamat/radiance-utils';

import {
  renderPrimaryDigitalPracticeSkeleton,
  renderEyDigitalPracticeSkeleton,
  renderDigitalPracticeSubLabel,
  renderHomeAccessSkeleton,
  renderHomeAccessSublabel,
  renderStudentName,
  renderStudentNameSkeleton,
  renderSecondaryDigitalPracticeSkeleton,
} from './utils/render-students-table';
import { tableStyles } from '../../components/common-table-components/table-styles';

function generateRows(count: number): any[] {
  const result: any[] = [];

  for (let i = 0; i < count; i++) {
    result.push({});
  }

  return result;
}

function ClassroomStudentsTableSkeleton({
  classroomHasDigitalPracticeAccess,
  stage,
  isAnonymized,
}: {
  classroomHasDigitalPracticeAccess: boolean;
  stage: string | null;
  isAnonymized?: boolean;
}): JSX.Element {
  const theme = useGlimmerTheme();
  const { t } = useTranslation();
  const rowsNumber = 8;
  const rows = generateRows(rowsNumber);

  const eyColumns = [
    {
      id: 'avatar',
      width: '64px',
      minWidth: '64px',
      align: 'center',
      render: (_: any) => {
        return (
          <div
            style={{
              display: 'flex',
              margin: '0 auto',
              width: '40px',
            }}
          >
            <Skeleton radius="20px" width={'40px'} height={'40px'} />
          </div>
        );
      },
    },
    {
      id: 'firstName',
      label: t('students.table.labels.student-information'),
      width: '25%',
      minWidth: isAnonymized ? '368px' : '268px',
      align: 'left',
      render: (_: any) => renderStudentNameSkeleton(isAnonymized),
      subLabel: () =>
        renderStudentName({
          t,
          selectedStudentId: '',
          selectedInputKey: '',
          handleSelect: () => {},
          handleInputChange: () => {},
          isHeader: true,
          handleBlurInput: () => {},
        }),
    },
    {
      id: 'separator-1',
      width: 9,
      separator: true,
    },
    {
      id: 'digitalPractice',
      label: t('students.table.labels.individualized-practice'),
      width: '50%',
      minWidth: '528px',
      align: 'left',
      render: (_: any) => renderEyDigitalPracticeSkeleton(),
      subLabel: () => renderDigitalPracticeSubLabel({ t, stage }),
    },
    {
      id: 'table-end',
      width: '100%',
    },
  ];

  const primaryColumns: Column<Row>[] = [
    {
      id: 'firstName',
      label: t('students.table.labels.student-information'),
      width: '25%',
      minWidth: isAnonymized ? '368px' : '268px',
      align: 'left',
      render: (_: any) => renderStudentNameSkeleton(isAnonymized),
      subLabel: () =>
        renderStudentName({
          t,
          selectedStudentId: '',
          selectedInputKey: '',
          isAnonymized,
          handleSelect: () => {},
          handleInputChange: () => {},
          isHeader: true,
          handleBlurInput: () => {},
        }),
    },
    {
      id: 'separator-1',
      width: 9,
      separator: true,
    },
    {
      id: 'digitalPractice',
      label: t('students.table.labels.individualized-practice'),
      width: '42%',
      minWidth: '428px',
      align: 'left',
      render: (_: any) => renderPrimaryDigitalPracticeSkeleton(),
      subLabel: () => renderDigitalPracticeSubLabel({ t, stage }),
    },
    {
      id: 'separator-2',
      width: 9,
      separator: true,
    },
    {
      id: 'homeAccess',
      label: '',
      width: '33%',
      minWidth: '332px',
      align: 'left',
      render: (_: any) =>
        renderHomeAccessSkeleton(classroomHasDigitalPracticeAccess),
      subLabel: () =>
        renderHomeAccessSublabel({
          t,
          classroomHasDigitalPracticeAccess,
          handleOpenDigitalPracticeAccessModal: () => {},
        }),
    },
    {
      id: 'table-end',
      width: '100%',
    },
  ];

  const secondaryColumns = [
    {
      id: 'firstName',
      label: t('students.table.labels.student-information'),
      width: '25%',
      minWidth: isAnonymized ? '368px' : '268px',
      align: 'left',
      render: (_: any) => renderStudentNameSkeleton(isAnonymized),
      subLabel: () =>
        renderStudentName({
          t,
          selectedStudentId: '',
          selectedInputKey: '',
          handleSelect: () => {},
          handleInputChange: () => {},
          isHeader: true,
          handleBlurInput: () => {},
        }),
    },
    {
      id: 'separator-1',
      width: 9,
      separator: true,
    },
    {
      id: 'digitalPractice',
      label: t('students.table.labels.individualized-practice'),
      width: '',
      minWidth: '768px',
      align: 'left',
      render: (_: any) => renderSecondaryDigitalPracticeSkeleton(),
      subLabel: () => renderDigitalPracticeSubLabel({ t, stage }),
    },
  ];

  const columnsToUse =
    stage === Stage.INFANTIL
      ? eyColumns
      : stage === Stage.PRIMARIA
      ? primaryColumns
      : secondaryColumns;

  return (
    <TableComponent
      columns={columnsToUse as any}
      id="classroom-teachers-table"
      key="teachers-table-skeleton"
      rows={rows}
      sx={tableStyles(theme)}
    />
  );
}

export { ClassroomStudentsTableSkeleton };
