import { useEffect, useState } from 'react';
import { NavigationWrapper } from './resource-navigation.styled';
import { ListNavigation, NavigationEvents } from '../../types';
import {
  ResourceNavigationProvider,
  useResourceNavigation,
} from '../../providers';
import {
  IconButton,
  Dropdown,
  ThemeProvider,
  Tooltip,
} from '@innovamat/glimmer-components';
import NavigationDropdownContent from './navigation-dropdown-content';

type InnerResourceNavigation = {
  title: string;
  navigationList: ListNavigation[] | null;
  topMenu: string;
  onNavigate?: (navigationEvents: NavigationEvents) => void;
  onCheckListElement?: (id: string, value: boolean) => void;
  isCheckDisabled?: boolean;
  isCloseWhenClickOutsideDisabled?: boolean;
  hasCheckbox?: boolean;
  translations?: {
    previous: string;
    next: string;
    menu: string;
  };
};

type ResourceNavigationProps = {
  undefinedRoute: string;
} & InnerResourceNavigation;

const InnerResourceNavigation = ({
  title,
  navigationList,
  topMenu,
  onNavigate,
  onCheckListElement,
  isCheckDisabled,
  isCloseWhenClickOutsideDisabled,
  hasCheckbox,
  translations,
}: InnerResourceNavigation) => {
  const {
    isFirstEnabledResource,
    isLastEnabledResource,
    setResourcesNavigationList,
    navigateToPreviousResource,
    navigateToNextResource,
  } = useResourceNavigation();

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (value: boolean) => {
    setIsOpen(value);
  };

  useEffect(() => {
    if (!navigationList) return;
    setResourcesNavigationList(navigationList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationList]);

  if (!navigationList)
    return (
      <NavigationWrapper>
        <IconButton
          icon="PreviousIcon"
          size={'L'}
          onClick={() => {}}
          state={'disabled'}
          dataTestId="leftArrowResource"
        />
        <IconButton
          icon="MenuListIcon"
          size={'L'}
          state={'disabled'}
          onClick={() => null}
        />
        <IconButton
          icon="NextIcon"
          size={'L'}
          onClick={() => {}}
          state={'disabled'}
          dataTestId="rightArrowResource"
        />
      </NavigationWrapper>
    );

  return (
    <NavigationWrapper>
      <Tooltip content={translations?.previous}>
        <IconButton
          icon="PreviousIcon"
          size={'L'}
          onClick={() => navigateToPreviousResource(topMenu, onNavigate)}
          state={isFirstEnabledResource() ? 'disabled' : undefined}
          dataTestId="leftArrowResource"
        />
      </Tooltip>
      <Dropdown
        onToggle={handleToggle}
        isCloseWhenClickOutsideDisabled={isCloseWhenClickOutsideDisabled}
      >
        <Dropdown.Toggle dataTestId="buttonScrollResources">
          <Tooltip content={translations?.menu}>
            <IconButton
              icon="MenuListIcon"
              size={'L'}
              state={isOpen ? 'pressed' : undefined}
              onClick={() => null}
            />
          </Tooltip>
        </Dropdown.Toggle>
        <Dropdown.Content
          position="bottomRight"
          offset={-40}
          className="navigation-dropdown-content"
          dataTestId="dropdownScrollResources"
          hasScroll={true}
        >
          <NavigationDropdownContent
            title={title}
            navigationList={navigationList}
            topMenu={topMenu}
            onNavigate={onNavigate}
            onCheckListElement={onCheckListElement}
            isCheckDisabled={isCheckDisabled}
            hasCheckbox={hasCheckbox}
          />
        </Dropdown.Content>
      </Dropdown>
      <Tooltip content={translations?.next}>
        <IconButton
          icon="NextIcon"
          size={'L'}
          onClick={() => navigateToNextResource(topMenu, onNavigate)}
          state={isLastEnabledResource() ? 'disabled' : undefined}
          dataTestId="rightArrowResource"
        />
      </Tooltip>
    </NavigationWrapper>
  );
};

export const ResourceNavigation = ({
  title,
  navigationList,
  topMenu,
  undefinedRoute,
  onNavigate,
  onCheckListElement,
  isCheckDisabled,
  isCloseWhenClickOutsideDisabled,
  hasCheckbox,
  translations,
}: ResourceNavigationProps) => {
  return (
    <ThemeProvider type="light">
      <ResourceNavigationProvider undefinedRoute={undefinedRoute}>
        <InnerResourceNavigation
          title={title}
          navigationList={navigationList}
          topMenu={topMenu}
          onNavigate={onNavigate}
          onCheckListElement={onCheckListElement}
          isCheckDisabled={isCheckDisabled}
          isCloseWhenClickOutsideDisabled={isCloseWhenClickOutsideDisabled}
          hasCheckbox={hasCheckbox}
          translations={translations}
        />
      </ResourceNavigationProvider>
    </ThemeProvider>
  );
};
