import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-domv6';

import {
  NAV_TYPE,
  STATIC_ROUTES,
  useCurrentNavigationValues,
  useSitemapTree,
  useUser,
} from '@innovamat/ga-features';
import { useClassroomQuery, useCourses } from '@innovamat/glow-api-client';

export function useSitemapNavigation() {
  const { user, isStudentViewActive, isRole } = useUser();
  const { classroomId, courseId, menu, submenu } = useParams();
  const { pathname } = useLocation();
  const { navType } = useCurrentNavigationValues();
  const { t } = useTranslation();

  const _courseId = courseId || user?.studentData?.courseId;
  const isRoleStudent = isRole.Student;

  const { data: classroomQuery } = useClassroomQuery(
    {
      id: classroomId!,
      orgId: user?.organizationId!,
    },
    {
      enabled: Boolean(classroomId && user?.organizationId && !isRoleStudent),
    }
  );

  const isRtiClassroom = !!classroomQuery?.classroom?.pilotStatus?.RTI;

  const { getCourseById } = useCourses({
    regionCode: user?.region!,
    organizationId: user?.organizationId!,
  });

  const {
    isPending,
    isError,
    sitemapTree,
    getSitemapByRoute,
    getFirstSitemapRoute,
    getAllSitemapRoutes,
  } = useSitemapTree({
    region: user?.region!,
    courseOrder:
      classroomQuery?.classroom?.courseOrder! ||
      getCourseById(_courseId!)?.order!,
    level: 'MANAGER',
    locale: user?.locale!,
    navType,
    isRtiClassroom,
  });

  const actualCourse = getCourseById(_courseId!);

  const pageName =
    navType === NAV_TYPE.Course
      ? t('course-navigation.title')
      : t('learning-navigation.title');

  const sitemap = useMemo(() => {
    return !submenu
      ? getSitemapByRoute(`/${menu}`)
      : getSitemapByRoute(`/${menu}/${submenu}`);
  }, [pathname, isPending, user?.locale, isStudentViewActive]);

  const isPageIdAsSubmenu = menu === 'page';

  const getAllAvailableRoutes = () => {
    const sitemapRoutes = getAllSitemapRoutes();
    const staticRoutes = sitemapTree.length > 0 ? STATIC_ROUTES : [];

    return [...sitemapRoutes, ...staticRoutes];
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return {
    actualCourse,
    isPageIdAsSubmenu,
    isPending,
    isError,
    pageName,
    sitemap,
    sitemapTree,
    getAllAvailableRoutes,
    getFirstSitemapRoute,
  };
}
