import { Modal } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

type Props = {
  showModal: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

function ConfirmSkipValidationModal({
  showModal,
  onClose,
  onConfirm,
}: Props): JSX.Element | null {
  const { t } = useTranslation();

  if (!showModal) return null;

  return (
    <Modal
      modalWidth={650}
      semantic={{
        type: 'warning',
        text: t('common.modal.important').toUpperCase(),
      }}
      onClose={onClose}
      isOpen={showModal}
      title={t(
        'students.import.modal_skip.title',
        'Tus alumnos pueden verse afectados'
      )}
      buttons={[
        {
          children: t('common.cancel'),
          variant: 'accent',
          onClick: onClose,
        },
        {
          children: t(
            'students.import.modal_skip.confirm',
            'Sí, saltar revisión'
          ),
          variant: 'tertiary',
          onClick: onConfirm,
        },
      ]}
    >
      <>
        {t(
          'students.import.modal_skip.content',
          'Puede que la importación sobreescriba datos de alumnos esxitentes. Recomendamos continuar con la revisión ¿Desaeas saltar la revisión?'
        )}
      </>
    </Modal>
  );
}

export { ConfirmSkipValidationModal };
