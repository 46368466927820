import React, { useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import Icon from '../../Icon';
import Button from '../../Button';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import {
  Footer,
  Title,
  ContentWrapper,
  ModalWrapper,
  OverlayClass,
  CloseButton,
} from './Modal.styles';

type ButtonTypes = 'button' | 'submit' | 'reset' | undefined;

type Props = {
  open: boolean;
  title?: string | JSX.Element;
  clickOutsideClose?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  cancelText?: string;
  submitText?: string;
  showSubmitBtn?: boolean;
  showCancelBtn?: boolean;
  showCloseBtn?: boolean;
  size?: 'auto' | 'normal' | 'small';
  customFooter?: JSX.Element;
  customTitle?: JSX.Element;
  isForm?: boolean;
  disableSubmit?: boolean;
  width?: number | string;
  height?: number | string;
  backgroundColor?: string;
  overlayBgOpacity?: number;
  padding?: string;
  onClose?: () => void;
  onSubmit?: (event: React.FormEvent<HTMLFormElement> | undefined) => void;
  dataInlineManual?: string;
};

function Modal({
  open,
  title,
  clickOutsideClose = false,
  children,
  loading = false,
  cancelText,
  submitText,
  showSubmitBtn = true,
  showCancelBtn = true,
  showCloseBtn = false,
  size,
  customFooter,
  customTitle,
  isForm = false,
  disableSubmit = false,
  width,
  height,
  overlayBgOpacity = 0.5,
  backgroundColor,
  padding,
  onClose,
  onSubmit,
  dataInlineManual,
}: Props) {
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (clickOutsideClose && !loading) {
      onClose!();
    }
  });

  // if the modal is not a form, return a onlick method for submit button
  const handleClickOnSubmit = () => (isForm ? undefined : onSubmit!(undefined));

  const submitButtonType: ButtonTypes = isForm ? 'submit' : 'button';

  const isOneModalOpenAlready = useMemo(
    () => document.getElementById('modal')?.childElementCount! >= 2,
    []
  );

  if (!open) return null;

  const modalRoot = document.getElementById('modal') || document.body;
  const hasTitle = title || customTitle;

  return createPortal(
    <>
      <OverlayClass
        isOneModalOpenAlready={isOneModalOpenAlready}
        opacity={overlayBgOpacity}
      />
      <ModalWrapper
        ref={ref}
        style={{ width, height }}
        data-inlinemanual={dataInlineManual}
        size={size}
        backgroundColor={backgroundColor}
        padding={padding}
      >
        {showCloseBtn && (
          <CloseButton onClick={onClose}>
            <Icon icon="Close" size="md" />
          </CloseButton>
        )}
        {hasTitle &&
          (customTitle || (
            <Title backgroundColor={backgroundColor}>
              <h2>{title}</h2>
            </Title>
          ))}
        {children && <ContentWrapper>{children}</ContentWrapper>}
        {customFooter || (
          <Footer>
            {showCancelBtn && (
              <Button
                size="large"
                color="secondary"
                disabled={loading}
                onClick={onClose}
                data-inlinemanual="cancel"
              >
                {cancelText}
              </Button>
            )}
            {showSubmitBtn && (
              <Button
                size="large"
                type={submitButtonType}
                disabled={disableSubmit}
                loading={loading}
                onClick={handleClickOnSubmit}
                data-inlinemanual="submit"
              >
                {submitText}
              </Button>
            )}
          </Footer>
        )}
      </ModalWrapper>
    </>,
    modalRoot
  );
}

export default Modal;
