import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './InputChips.module.scss';
import AvatarWithInfo from '../AvatarWithInfo';
import SubLabel from '../SubLabel';
import Chip from '../Chip';
import useEventListener from '../../hooks/useEventListener';
import useKeyPress from '../../hooks/useKeyPress';

type SuggestOption = {
  id: string;
  title: string;
  subtitle: string;
  image: string;
};

type Props = {
  error?: Error;
  description?: string;
  label?: string;
  name?: string;
  defaultValue?: string[];
  disabled?: boolean;
  placeholder?: string;
  canPasteEmails?: boolean;
  options?: SuggestOption[];
  loadingOptions?: boolean;
  suggestOptions?: boolean;
  onUpdateChips?: (chips: string[]) => void;
  onInputChange?: (value: string) => void;
};

function InputChips({
  error,
  label,
  name,
  description,
  defaultValue = [],
  placeholder,
  disabled,
  canPasteEmails,
  options,
  loadingOptions,
  suggestOptions = false,
  onUpdateChips,
  onInputChange,
}: Props) {
  const [chips, setChips] = useState<string[]>(defaultValue);
  const [value, setValue] = useState<string>('');
  const [focused, setFocused] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleDelete = (selectedChip: string) => {
    if (disabled) return;
    setChips((prevChips) => prevChips.filter((chip) => chip !== selectedChip));
  };

  const handleInputChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    if (disabled) return;
    setValue(e.currentTarget.value);

    if (!suggestOptions) return;
    onInputChange?.(e.currentTarget.value);
    if (!focused) setFocused(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (disabled) return false;

    if (event.key === 'Enter' && value.trim() !== '') {
      setChips((prevChips) => [...prevChips, value.trim()]);
      setValue('');
      event.preventDefault();
    }
    return false;
  };

  useKeyPress({
    Escape: () => setFocused(false),
  });

  useEffect(() => {
    onUpdateChips?.(chips);
    setFocused(false);
  }, [chips, onUpdateChips]);

  const handlePaste = (e: React.ClipboardEvent) => {
    if (!canPasteEmails) return;
    e.preventDefault();
    const text = e.clipboardData.getData('Text');
    const mails = text.match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
    ) as string[];
    if (Array.isArray(mails)) {
      const newEmails = Array.from(new Set([...chips, ...mails]));
      setChips(newEmails);
    }
  };

  const handleFocus = () => {
    if (!suggestOptions) return;
    setFocused(true);
  };

  const handleBlur = () => {
    if (suggestOptions) return;
    if (value.trim() !== '') {
      setChips((prevChips) => [...prevChips, value.trim()]);
      setValue('');
    }
  };

  const containerClass = cx(styles.inputContainer, {
    [styles.hasError]: error,
  });

  const handleOptionClick = (email: string) => {
    if (chips.some((chip) => chip === email.trim())) return;
    setChips((prevChips) => [...prevChips, email.trim()]);
    inputRef.current?.focus();
  };

  useEventListener('mousedown', (event: any) => {
    if (!suggestOptions) return;
    const isOptionClicked = optionsRef.current
      ? optionsRef.current.contains(event.target as HTMLDivElement)
      : false;
    setValue('');
    setFocused(isOptionClicked);
  });

  const inputHeight = (containerRef.current?.offsetHeight || 0) + 5;

  const removeSelcted = (option: SuggestOption) =>
    !chips.includes(option.subtitle);

  return (
    <div className={containerClass}>
      {error && <SubLabel>{error.message}</SubLabel>}
      {!error && description && <SubLabel>{description}</SubLabel>}
      {focused && (
        <div
          className={styles.options}
          ref={optionsRef}
          style={{ top: inputHeight }}
        >
          {options?.filter(removeSelcted)?.map(({ title, subtitle }, index) => (
            <div
              key={index}
              role="presentation"
              className={styles.item}
              onClick={() => handleOptionClick(subtitle)}
            >
              <AvatarWithInfo title={title} subtitle={subtitle} />
            </div>
          ))}
          {loadingOptions && <div className={styles.noOptions}>Loading</div>}
          {!options?.length && !loadingOptions && (
            <div className={styles.noOptions}>No options</div>
          )}
        </div>
      )}

      <div className={styles.inputChips} ref={containerRef}>
        {chips.map((chip) => (
          <Chip onClick={() => handleDelete(chip)} key={chip}>
            {chip}
          </Chip>
        ))}
        <textarea
          ref={inputRef}
          placeholder={chips.length ? '' : placeholder}
          rows={1}
          name={name}
          className={styles.input}
          value={value}
          onFocus={handleFocus}
          onChange={handleInputChange}
          onPaste={handlePaste}
          onKeyPress={handleKeyPress}
          onBlur={handleBlur}
        />
      </div>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
    </div>
  );
}

export default InputChips;
