import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';

import styled from '@emotion/styled';

import {
  ERROR_TYPE,
  ErrorPage,
  SchoolClassrooms,
  SchoolStudents,
  SchoolTeachers,
  useNavigation,
  useUser,
} from '@innovamat/ga-features';

import { useSitemapNavigation } from '../../hooks/use-sitemap-navigation';

import { Header } from '../layout/header';
import { MainLayout } from '../layout/main-layout';
import { PageWithTableWrapper } from '../layout/page-with-table';

import { ImportsPage } from '../pages/imports-page';
import { OrdersReturnsPage } from '../pages/orders-returns-page';

import { AdminDashboardWrapper } from './admin-dashboard-wrapper';
import { ImportCSVWrapper } from './import-csv-wrapper';
import { Logo } from './logo';
import { NavBreadcrumb } from './nav-breadcrumb';

const PageWrapper = styled.div`
  margin-right: 1.5rem;
`;

const StyledMainLayout = styled(MainLayout)<{ isOverflowVisible: boolean }>`
  ${({ isOverflowVisible }) => {
    if (isOverflowVisible) {
      return `
      .page-frame{
        overflow-y: visible;
        overflow-x: visible;
     ;
      }
    `;
    }
    return ``;
  }};
`;

export function AdminNavigationWrapper(): JSX.Element {
  const { menu, submenu } = useParams();
  const { t } = useTranslation();
  const { goToHome } = useNavigation();
  const { user } = useUser();
  const { sitemapTree } = useSitemapNavigation();

  const getComponentRoute = (): JSX.Element => {
    switch (menu) {
      case 'classrooms':
        return <SchoolClassrooms />;
      case 'imports':
        return <ImportsPage />;
      case 'orders-returns':
        return <OrdersReturnsPage />;
      case 'school-students':
        return (
          <ImportCSVWrapper>
            <SchoolStudents />
          </ImportCSVWrapper>
        );
      case 'deleted-students':
        return (
          <ImportCSVWrapper>
            <SchoolStudents isDeletedStudentsPage />
          </ImportCSVWrapper>
        );
      case 'teachers':
        return <SchoolTeachers />;
      case 'admin-dashboard':
        return (
          <PageWrapper>
            <AdminDashboardWrapper />
          </PageWrapper>
        );

      default:
        return <ErrorPage errorType={ERROR_TYPE.NOT_FOUND} />;
    }
  };

  const isUploadSubmenu = submenu === 'upload';
  const isImportsMenu = menu === 'imports';
  const isImportPageUploadSteps = isImportsMenu && isUploadSubmenu;

  return (
    <>
      <Header
        leftActions={
          isImportPageUploadSteps ? (
            <Logo />
          ) : (
            <NavBreadcrumb
              fromPageName={t('menu.Inicio')}
              pageName={user?.organizationName || ''}
              onGoBack={goToHome}
            />
          )
        }
        hasBackButton={!isImportPageUploadSteps}
        sitemapTree={sitemapTree}
      />
      <StyledMainLayout
        hideLateralMenu={isUploadSubmenu}
        isOverflowVisible={
          menu === 'admin-dashboard' || menu === 'orders-returns'
        }
        sitemapTree={sitemapTree}
      >
        <PageWithTableWrapper>{getComponentRoute()}</PageWithTableWrapper>
      </StyledMainLayout>
    </>
  );
}
