import { Icon } from '@innovamat/innova-components';

import {
  NoMessageSecondaryMessage,
  NoSpacesMainMessage,
  NoSpacesMessageContainer,
  PinWrapper,
} from './EmptyPinnedList.styles';
import useSpaces from '../useSpaces';

export function EmptyPinnedList() {
  const { t } = useSpaces();

  return (
    <NoSpacesMessageContainer>
      <PinWrapper>
        <Icon icon="Pin" />
      </PinWrapper>
      <NoSpacesMainMessage>{t('spaces.emptyState.title')}</NoSpacesMainMessage>
      <NoMessageSecondaryMessage>
        {t('spaces.emptyState.subtitle')}
      </NoMessageSecondaryMessage>
    </NoSpacesMessageContainer>
  );
}
