import {
  JoinToClassroom,
  useNavigation,
  useUser,
} from '@innovamat/ga-features';
import { useSearchParams } from 'react-router-domv6';
import { Header } from '../layout/header';
import { Logo } from '../components/logo';

export function JoinToClassroomPage(): JSX.Element {
  const { user } = useUser();
  const [params] = useSearchParams();

  const { goToHome, goBackOrHome } = useNavigation();

  const isFirstTime = !!params.get('ft');

  return (
    <>
      <Header leftActions={<Logo />} />

      {user && (
        <JoinToClassroom
          orgId={user.organizationId}
          region={user.region}
          email={user.email}
          onGoBack={goBackOrHome}
          onGoHome={goToHome}
          isFirstTime={isFirstTime}
        />
      )}
    </>
  );
}
