import { Column, TableComponent } from '@innovamat/glimmer-components';
import ReportsLoader from '../components/reports-loader';
import {
  TableContainer,
  AdaptedPlansContainer,
  AdaptedPlansTitle,
  PrintArea,
} from '../components/common.styled';
import MessageReports from '../components/message-reports';
import { TFunction } from 'i18next';
import { Course } from '@innovamat/glow-api-client';

type Row = {
  [key: string]: any;
};

export type ReportType = 'weekly' | 'general';

export type ReportData = {
  columns: Column<Row>[];
  rows: Row[];
  id: string;
  averageRow: Row;
  courseOrder?: number | null;
};

type Props = {
  loading: boolean;
  checkReportHasData: (rows: any) => boolean;
  mainReportData: ReportData;
  adaptedReportsData: ReportData[] | undefined;
  isWarningList?: boolean;
  isCurrentWeek?: boolean;
  onExpandColumn: (column: string, tableId: string) => void;
  handleSendEvent: (event: string, parameters?: {}) => void;
  t: TFunction<'translation', undefined, 'translation'>;
  handlePreviousWeek?: () => void;
  showPreviousWeekButton?: boolean;
  courses?: Course[]; // TODO: Change it to mandatory when course in general progress
  isNewManager?: boolean;
  reportType: ReportType;
};

export const renderTables = (props: Props) => {
  const {
    loading,
    checkReportHasData,
    mainReportData,
    adaptedReportsData,
    isWarningList,
    isCurrentWeek,
    onExpandColumn,
    handleSendEvent,
    t,
    handlePreviousWeek,
    showPreviousWeekButton,
    courses,
    isNewManager,
    reportType,
  } = props;

  const { rows, columns, averageRow, id } = mainReportData;

  if (loading) return <ReportsLoader />;

  if (
    !checkReportHasData(rows) &&
    !adaptedReportsData?.some((adaptedReport: any) =>
      checkReportHasData(adaptedReport?.rows)
    )
  )
    return (
      <MessageReports
        isWarningList={!!isWarningList}
        isCurrentWeek={!!isCurrentWeek}
        onPreviousWeek={handlePreviousWeek!}
        showPreviousWeekButton={showPreviousWeekButton!}
        reportType={reportType}
      />
    );
  return (
    <PrintArea id="print-area">
      <TableContainer>
        {rows.length && averageRow && checkReportHasData(rows) && (
          <TableComponent
            rows={rows}
            columns={columns}
            firstRow={averageRow}
            onExpandColumn={onExpandColumn}
            handleSendEvent={handleSendEvent}
            id={id}
            topStickyOffset={isNewManager ? 64 : 72}
            isNewManager={isNewManager}
          />
        )}
      </TableContainer>
      <AdaptedPlansContainer>
        {adaptedReportsData?.map((adaptedReport: any) => {
          const courseName = courses?.find(
            (course) => course.order === adaptedReport.courseOrder
          )?.name;
          return (
            <>
              <AdaptedPlansTitle>
                {`${t('reports.subtitle.adaptedStudents')} ${
                  courseName ? `- ${courseName}` : ''
                }`}
              </AdaptedPlansTitle>
              <TableContainer>
                <TableComponent
                  rows={adaptedReport.rows}
                  columns={adaptedReport.columns}
                  onExpandColumn={onExpandColumn}
                  handleSendEvent={handleSendEvent}
                  id={adaptedReport.id}
                  topStickyOffset={isNewManager ? 64 : 72}
                  isNewManager={isNewManager}
                />
              </TableContainer>
            </>
          );
        })}
      </AdaptedPlansContainer>
    </PrintArea>
  );
};
