import { Material } from '../types/materials';

export type MappedMaterialItem = {
  id: string;
  text: string;
  optional?: boolean;
  additionalText?: string | undefined;
};

export const mapMaterials = (material: Material) => {
  const materialList: MappedMaterialItem[] = [];
  const classroomMaterial: MappedMaterialItem[] = [];
  const digitalResources: MappedMaterialItem[] = [];
  const studentMaterial: MappedMaterialItem[] = [];

  material?.items.forEach((item) => {
    if ('material_list' in item.items) {
      const materialItem = item.items.material_list;
      materialList.push({
        id: item.id!,
        optional: item.items.optional?.value,
        text: materialItem.body,
        additionalText: item.items.additional_text?.body,
      });
    }

    if ('classroom_material' in item.items) {
      classroomMaterial.push({
        id: item.id!,
        text: item.items.classroom_material?.body,
      });
    }

    if ('digital_resource' in item.items) {
      digitalResources.push({
        id: item.id!,
        text: item.items.digital_resource?.body,
      });
    }

    if ('student_material' in item.items) {
      studentMaterial.push({
        id: item.id!,
        text: item.items.student_material?.body,
      });
    }
  });

  return {
    materialList,
    classroomMaterial,
    digitalResources,
    studentMaterial,
  };
};
