import { Grid, Grounder } from '@innovamat/glimmer-components';

import { MyClassesSkeleton } from '../../../components/my-classes';
import { SchoolSectionSkeleton } from '../../../components/my-school';
import { ShortcutsSkeleton } from '../../../../contents';

import { Container, ColWithMargin } from '../styles';

function LandingPageAdvisorSkeleton(): JSX.Element {
  return (
    <Container>
      <Grid.Row justifyContent="center">
        <ColWithMargin md={10} lg={8}>
          <SchoolSectionSkeleton />
        </ColWithMargin>

        <Grid.Col md={10} lg={8}>
          <MyClassesSkeleton />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row justifyContent="center">
        <Grid.Col md={10} lg={8}>
          <ShortcutsSkeleton />
        </Grid.Col>
      </Grid.Row>
      <Grounder />
    </Container>
  );
}

export { LandingPageAdvisorSkeleton };
