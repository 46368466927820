import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from '../../utils/common';
import { Bar, Tooltip, Typography, theme } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import { getReportsTypography } from '../../utils/typography';
import { TimeSchedule } from '../../views/weekly-results/weekly-results';
import { TableType } from '../../utils/render-rows';

type Props = {
  timeSpentHome: number;
  timeSpentSchool: number;
  timeSpentAll: number;
  practicePeriod: TimeSchedule;
  dataTestId?: string;
  tableType: TableType;
  isAverageRow?: boolean;
  isProgressBarHidden?: boolean;
  isTrainingDedicationColumn?: boolean;
  isSecundaria?: boolean;
};

const TimeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1px;
`;

const TimeValue = styled.div`
  ${getReportsTypography()}
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-all;
`;

const DashNoDedication = styled.div`
  color: ${({ theme }) =>
    theme.tokens.color.specific.reports.semantic['no-data'].value};
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getReportsTypography()}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
`;

const NotConnected = styled(Typography.Subtitle2)<{ color?: string }>`
  color: ${({ color }) => color};
  line-height: 1rem;
`;

function Time({
  timeSpentHome,
  timeSpentSchool,
  timeSpentAll,
  practicePeriod,
  dataTestId,
  tableType,
  isAverageRow,
  isProgressBarHidden,
  isTrainingDedicationColumn,
  isSecundaria,
}: Props): JSX.Element {
  const { t } = useTranslation();

  type TimeSchedule = 'home' | 'school' | 'all';

  const getTime = (
    practicePeriod: TimeSchedule,
    typeTable: TableType
  ): JSX.Element | number => {
    let timeSpent;
    switch (practicePeriod) {
      case 'home':
        timeSpent = timeSpentHome;
        break;
      case 'school':
        timeSpent = timeSpentSchool;
        break;
      default:
        timeSpent = timeSpentAll;
        break;
    }

    const renderNoData = () => {
      const noDataComponents = {
        notTrainingDedication: (
          <DashNoDedication>
            -<span>-</span>
          </DashNoDedication>
        ),
        notConnected: (
          <NotConnected
            color={
              theme.tokens.color.specific.reports.semantic['no-data'].value
            }
          >
            {isSecundaria
              ? t('reports.noConnected.time.secondary')
              : t('reports.noConnected.time.primary.earlyyears')}
          </NotConnected>
        ),
      };

      return isTrainingDedicationColumn
        ? noDataComponents.notTrainingDedication
        : noDataComponents.notConnected;
    };

    if (isNullOrUndefined(timeSpent)) {
      return renderNoData();
    }

    const minutes = timeSpent / 60;

    if (typeTable === 'generalProgress') {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      const roundedHours = remainingMinutes >= 30 ? hours + 1 : hours;
      return <TimeValue>{roundedHours}</TimeValue>;
    }

    return <TimeValue>{Math.ceil(timeSpentAll / 60) || 1} </TimeValue>;
  };

  const showMin = (): string => {
    if (isNullOrUndefined(timeSpentAll)) {
      return '';
    }
    if (tableType === 'digitalPractice') {
      return t('reports.dedication.minutesPerStudent');
    }
    return 'min';
  };

  const showHour = (): string => {
    if (isNullOrUndefined(timeSpentAll)) {
      return '';
    }
    if (isAverageRow) {
      return t('reports.dedication.hoursPerStudent');
    }
    return 'h';
  };

  const getSuffixTime = (tableType: TableType): string => {
    const suffixTime =
      tableType === 'weeklyResults' || tableType === 'digitalPractice'
        ? showMin()
        : showHour();
    return `${suffixTime}`;
  };

  const renderTooltip = (): JSX.Element | null => {
    const ceilIfNotNull = (value: number): number => {
      return value === 0 || isNullOrUndefined(value)
        ? 0
        : Math.ceil(value / 60);
    };

    const schoolTooltip = ceilIfNotNull(timeSpentSchool);
    const homeTooltip = ceilIfNotNull(timeSpentHome);

    return (
      <TooltipContainer>
        <span>
          {t('reports.schoolTime.tooltip', { minutes: schoolTooltip })}
        </span>
        <span>{t('reports.homeTime.tooltip', { minutes: homeTooltip })}</span>
      </TooltipContainer>
    );
  };

  const getPercentage = (
    totalTime: number,
    timeHome: number,
    timeSchool: number
  ): { homePercentage: number; schoolPercentage: number } => {
    if (totalTime === 0 || isNullOrUndefined(totalTime)) {
      return { homePercentage: 0, schoolPercentage: 0 };
    }
    totalTime = timeHome + timeSchool;
    totalTime = Math.ceil(totalTime / 60);

    const homePercentage = (timeHome / totalTime) * 100;
    const schoolPercentage = (timeSchool / totalTime) * 100;

    return { homePercentage, schoolPercentage };
  };

  const renderProgressBar = (): JSX.Element => {
    const { homePercentage, schoolPercentage } = getPercentage(
      timeSpentAll,
      timeSpentHome,
      timeSpentSchool
    );

    const baseTokenReport = theme.tokens.color.specific.reports['progress-bar'];

    const barValues = [homePercentage, schoolPercentage];
    const barColors = [
      baseTokenReport.home.value,
      baseTokenReport.school.value,
    ];

    return (
      <Bar
        values={barValues}
        colors={barColors}
        type="percentage"
        width={64}
        height={4}
      />
    );
  };
  const showProgressBar = !isNullOrUndefined(isProgressBarHidden)
    ? !isProgressBarHidden
    : practicePeriod === 'all' && timeSpentAll > 0;

  const shouldCheckTooltip =
    tableType === 'weeklyResults' && practicePeriod === 'all';

  const showTimeTooltip = shouldCheckTooltip && renderTooltip();

  return (
    <Tooltip
      content={showTimeTooltip}
      popperOptions={{ strategy: 'fixed' }}
      disabled={isProgressBarHidden}
    >
      <Container data-testid={dataTestId}>
        <TimeContainer>
          {getTime(practicePeriod, tableType)}
          <Typography.Subtitle2
            style={{ display: 'inline', marginBottom: '-1px' }}
          >
            {getSuffixTime(tableType)}
          </Typography.Subtitle2>
        </TimeContainer>
        {showProgressBar &&
          tableType === 'weeklyResults' &&
          renderProgressBar()}
      </Container>
    </Tooltip>
  );
}

export default Time;
