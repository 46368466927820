import { useParams } from 'react-router-domv6';
import styled from '@emotion/styled';

import {
  ClassNavigator,
  useCurrentNavigationValues,
  useNavigation,
  useUser,
} from '@innovamat/ga-features';

import {
  Breadcrumb,
  IconButton,
  Skeleton,
} from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

export const DesktopAction = styled.div`
  display: block;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const MobileAction = styled.div`
  display: none;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

export function ClassroomSelector(): JSX.Element {
  const { t } = useTranslation();

  const { classroomId } = useParams<{ classroomId: string }>();
  const { user } = useUser();

  const { navigateBetweenClassesOrCourses, goToHome } = useNavigation();
  const { currentClassroom } = useCurrentNavigationValues();

  return (
    <>
      <IconButton icon="BackIcon" onClick={() => goToHome()} />
      <DesktopAction>
        {user && classroomId ? (
          <ClassNavigator
            classroomId={classroomId as string}
            organizationId={user.organizationId}
            onSelectClassroom={(classroomId) =>
              navigateBetweenClassesOrCourses(classroomId)
            }
          />
        ) : (
          <Skeleton width="146px" height="48px" />
        )}
      </DesktopAction>
      <MobileAction>
        <Breadcrumb breadcrumbs={[t('menu.Inicio'), currentClassroom?.name]} />
      </MobileAction>
    </>
  );
}
