import { snack } from '@innovamat/glimmer-components';
import {
  Classroom,
  ClassroomBody,
  useAssignTeacherToClassroomsMutation,
  useClassroomsQuery,
} from '@innovamat/glow-api-client';
import { useQueryClient } from '@tanstack/react-query';
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useManageClassrooms } from '../../hooks/use-manage-classrooms';
import { JoinToClassroomProps } from './join-to-classroom';

type JoinToClassroomContextType = {
  step: number;
  setStep: (step: number) => void;
  selectedClassrooms: (Classroom | ClassroomBody)[];
  setSelectedClassrooms: (classrooms: Classroom[]) => void;
  handleSuccessNewClassroom: (classroom: ClassroomBody) => void;
  isNewClassroom: (classroomId: string) => boolean;
  classrooms: Classroom[];
  alreadyJoinedClassrooms: Classroom[];
  isLoadingClassrooms: boolean;
  onGoBack?: () => void;
  onGoHome?: () => void;
  handleConfirm: () => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  handleClick: (classroom: Classroom | ClassroomBody) => void;
  getClassroomsByCourse: (courseId: string) => (Classroom | ClassroomBody)[];
  getSelectedClassroomsByCourse: (
    courseId: string
  ) => (Classroom | ClassroomBody)[];
  coursesToRender: { id: string; name: string }[];
  selectedClassroomsByCourse: { [key: string]: Classroom[] };
  getCardStatus: (
    id: string,
    type?: 'course' | 'classroom'
  ) => 'default' | 'disabled' | 'selected';
  courses: any[];
  assignMutationIsPending: boolean;
  classroomsQueryError: boolean;
  isFirstTime?: boolean;
  orgId: string;
  region: string;
  handleSelectCourse: (courseId: string) => void;
  selectedCourses: string[];
  editModalData: any;
  openEditModal: (data: Classroom) => void;
  orgHasClassrooms: boolean;
};

const JoinToClassroomContext = createContext<
  JoinToClassroomContextType | undefined
>(undefined);

type JoinToClassroomProviderProps = JoinToClassroomProps & {
  children: ReactNode;
};

export const JoinToClassroomProvider: FunctionComponent<
  JoinToClassroomProviderProps
> = ({ children, onGoBack, onGoHome, email, orgId, region, isFirstTime }) => {
  const [step, setStep] = useState<number>(0);
  const [selectedClassrooms, setSelectedClassrooms] = useState<
    (Classroom | ClassroomBody)[]
  >([]);
  const queryClient = useQueryClient();
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [newClassrooms, setNewClassrooms] = useState<string[]>([]);
  const { editModalData, openEditModal } = useManageClassrooms();
  const { t } = useTranslation();

  const goToNextStep = (): void => {
    setStep(step + 1);
  };

  const goToPreviousStep = (): void => {
    setStep(step - 1);
  };

  const assignTeacherMutation = useAssignTeacherToClassroomsMutation();

  const handleConfirm = (): void => {
    assignTeacherMutation.mutate(
      {
        body: {
          classroomIds: selectedClassrooms.map(({ id }) => id),
          email: email,
        },
      },
      {
        onSuccess: () => {
          goToNextStep();
          queryClient.invalidateQueries({
            queryKey: useClassroomsQuery.getKey({ orgId, mine: true }),
          });
        },
        onError: () => {
          snack.error(t('common.toast.error'));
        },
      }
    );
  };

  const {
    data: allClassrooms,
    isLoading: isLoadingAllClassrooms,
    isError,
  } = useClassroomsQuery(
    {
      orgId: orgId,
      mine: false,
    },
    {
      enabled: !!orgId,
      staleTime: Infinity,
    }
  );

  const classrooms = allClassrooms?.classrooms as Classroom[];

  const { data: myClassrooms, isLoading: isLoadingMyClassrooms } =
    useClassroomsQuery(
      { orgId: orgId, mine: true },
      { enabled: !!orgId, staleTime: Infinity }
    );

  const alreadyJoinedClassrooms =
    myClassrooms?.classrooms !== null
      ? (myClassrooms?.classrooms?.filter(
          (classroom) => !newClassrooms.includes(classroom?.id!)
        ) as Classroom[])
      : [];

  const isLoadingClassrooms = isLoadingAllClassrooms || isLoadingMyClassrooms;

  const courses = useMemo(() => {
    const coursesList =
      classrooms?.map(({ courseId, courseName, courseOrder, region }) => ({
        id: courseId,
        name: courseName,
        order: courseOrder || 0,
        region,
      })) || [];
    return [
      ...new Map(coursesList.map((course) => [course.id, course])).values(),
    ].sort((a, b) => a.order - b.order);
  }, [classrooms]);

  const getClassroomsByCourse = useCallback(
    (courseId: string): Classroom[] =>
      classrooms?.filter((classroom) => classroom.courseId === courseId) || [],
    [classrooms]
  );

  const getSelectedClassroomsByCourse = useCallback(
    (courseId: string): (Classroom | ClassroomBody)[] =>
      selectedClassrooms.filter((classroom) => classroom.courseId === courseId),
    [selectedClassrooms]
  );

  const selectedClassroomsByCourse = selectedClassrooms.reduce(
    (acc, classroom) => {
      (acc[classroom.courseId!] = acc[classroom.courseId!] || []).push(
        classroom
      );
      return acc;
    },
    {}
  );

  const coursesToRender = Object.keys(selectedClassroomsByCourse).map(
    (courseId) => ({
      id: courseId,
      name: selectedClassroomsByCourse[courseId][0].courseName,
    })
  );

  const getCardStatus = (
    id: string,
    type: 'classroom' | 'course' = 'classroom'
  ): 'default' | 'disabled' | 'selected' => {
    if (type === 'course') {
      const isSelected = selectedCourses.includes(id);

      if (isSelected) {
        return 'selected';
      }

      return 'default';
    }

    const isSelected = selectedClassrooms.some(
      (classroom) => classroom.id === id
    );

    if (isSelected) {
      return 'selected';
    }

    const isAlreadyJoined = alreadyJoinedClassrooms.some(
      (classroom) => classroom?.id === id
    );

    if (isAlreadyJoined) {
      return 'disabled';
    }

    return 'default';
  };

  const handleClick = (clickedClassroom: Classroom | ClassroomBody): void => {
    setSelectedClassrooms((prev) =>
      prev.find(({ id }) => id === clickedClassroom.id)
        ? prev.filter(({ id }) => id !== clickedClassroom.id)
        : [...prev, clickedClassroom]
    );
  };

  const handleSelectCourse = (courseName: string): void => {
    setSelectedCourses((prev) =>
      prev.includes(courseName)
        ? prev.filter((course) => course !== courseName)
        : [...prev, courseName]
    );
  };

  const addNewClassroom = (classroomId: Classroom['id']): void => {
    setNewClassrooms((prev) => [...prev, classroomId]);
  };

  const isNewClassroom = (classroomId: Classroom['id']): boolean =>
    newClassrooms.some((id) => id === classroomId);

  const orgHasClassrooms = useRef<boolean | null>(null);

  useEffect(() => {
    if (orgHasClassrooms.current === null && classrooms) {
      orgHasClassrooms.current = classrooms.length > 0;
    }
  }, [classrooms]);

  const handleSuccessNewClassroom = (classroom: ClassroomBody): void => {
    setSelectedClassrooms((prev) => [...prev, classroom]);

    addNewClassroom(classroom.id);

    queryClient.refetchQueries({
      queryKey: ['Classrooms', { mine: false, orgId }],
    });

    setSelectedCourses((prev) => [...prev, classroom?.courseId!]);
  };

  useEffect(() => {
    if (!classrooms) return;

    const updatedSelectedClassrooms = selectedClassrooms.map(
      (selectedClassroom) => {
        const updatedClassroom = classrooms.find(
          (classroom) => classroom.id === selectedClassroom.id
        );
        return updatedClassroom || selectedClassroom;
      }
    );

    setSelectedClassrooms(updatedSelectedClassrooms);
  }, [classrooms]);

  const value = {
    step,
    setStep,
    selectedClassrooms,
    setSelectedClassrooms,
    handleSuccessNewClassroom,
    isNewClassroom,
    classrooms,
    alreadyJoinedClassrooms,
    isLoadingClassrooms,
    onGoBack,
    onGoHome,
    handleConfirm,
    goToNextStep,
    goToPreviousStep,
    handleClick,
    getClassroomsByCourse,
    getSelectedClassroomsByCourse,
    coursesToRender,
    selectedClassroomsByCourse,
    getCardStatus,
    courses,
    assignMutationIsPending: assignTeacherMutation.isPending,
    classroomsQueryError: isError,
    isFirstTime,
    orgId,
    region,
    handleSelectCourse,
    selectedCourses,
    editModalData,
    openEditModal,
    orgHasClassrooms: orgHasClassrooms.current || false,
  };

  return (
    <JoinToClassroomContext.Provider value={value}>
      {children}
    </JoinToClassroomContext.Provider>
  );
};

export const useJoinToClassroom = (): JoinToClassroomContextType => {
  const context = useContext(JoinToClassroomContext);
  if (!context) {
    throw new Error(
      'useJoinToClassroom must be used within a JoinToClassroomProvider'
    );
  }
  return context;
};
