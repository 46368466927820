import { useTranslation } from 'react-i18next';
import {
  Teacher,
  useDeleteInvitationMutation,
  useDeleteTeacherMutation,
} from '@innovamat/glow-api-client';
import { deleteTeacherInCache } from '../cache-actions/delete-teacher';
import { useQueryClient } from '@tanstack/react-query';
import { useConfirmationModal } from './use-confirmation-modal';

const getDisplayName = (student: Teacher) => {
  if (student?.isInvitation || student?.isSchoolInvitation)
    return student?.email;
  return `${student?.firstName} ${student?.lastName}`;
};

type DeleteTeacher = {
  isPlatformEnabled: boolean;
  organizationId: string;
  onSuccess?: () => void;
};

function useDeleteTeacher({
  isPlatformEnabled,
  organizationId,
  onSuccess,
}: DeleteTeacher): {
  onDelete: (teacher: Teacher) => Promise<void>;
  confirmModalData: any;
} {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: deleteInvitation, isPending: isPendingDeleteInvitation } =
    useDeleteInvitationMutation();
  const { mutate: deleteTeacher, isPending: isPendingDeleteTeacher } =
    useDeleteTeacherMutation();

  const {
    requestConfirmation,
    isConfirmationOpen,
    confirmationModalProps,
    handleCloseConfirmationModal,
  } = useConfirmationModal();

  const handleDelete = async (teacher: Teacher) => {
    const isInvitation = !!teacher?.isInvitation;
    const isSchoolInvitation = !!teacher?.isSchoolInvitation;

    if (!teacher) return;

    requestConfirmation({
      title: t('teachers.deleteTeacher'),
      message: t('teachers.deleteTeacher.confirm', {
        name: getDisplayName(teacher),
      }),
      onConfirm: () => {
        if (isInvitation || isSchoolInvitation) {
          deleteInvitation(
            { invitationId: teacher.id, isSchoolInvitation },
            {
              onSuccess() {
                deleteTeacherFromCache(teacher.id, true);
              },
            }
          );
        } else {
          deleteTeacher(
            {
              teacherId: teacher.id,
            },
            {
              onSuccess() {
                deleteTeacherFromCache(teacher.id, false);
              },
            }
          );
        }
        handleCloseConfirmationModal();
        onSuccess?.();
      },
      confirmText: t('confirmModal.action.confirmDelete'),
    });
  };
  const deleteTeacherFromCache = (id: string, isInvitation: boolean): void => {
    deleteTeacherInCache({
      isInvitation,
      isPlatformEnabled,
      organizationId,
      queryClient,
      teacherId: id,
    });
  };

  const confirmModalData = {
    isOpen: isConfirmationOpen,
    isPending: isPendingDeleteInvitation || isPendingDeleteTeacher,
    onClose: handleCloseConfirmationModal,
    ...confirmationModalProps,
  };

  return { onDelete: handleDelete, confirmModalData };
}

export { useDeleteTeacher };
