import { BooleanElement, Item } from './../../../types/prepr';
import { listToMatrix } from '@innovamat/radiance-utils';
import { AssetElement, PrepElement } from '../../../types/prepr';
import {
  MomentContent,
  MomentContentType,
  TableContentType,
} from '../../../types/momentContent';

export type NewMoment = {
  moment: MomentContentType;
  subMoment:
    | (PrepElement | TableContentType | AssetElement | ItemInList)[]
    | undefined;
};

export type ItemInList = TextInList | ImageInList;

export type TextInList = Item<{
  in_a_list?: BooleanElement;
  text?: PrepElement;
  inner_tip?: PrepElement;
}>;

export type ImageInList = Item<{
  content_big_image?: AssetElement;
  content_image?: AssetElement;
}>;

export type PreprTable = {
  tableContent: TableContentType[][];
  configuration: string[];
};

export type SubMoment =
  | PrepElement
  | AssetElement
  | PreprTable
  | ItemInList
  | undefined;

export type DisplayMoment = {
  moment: MomentContentType;
  subMoment: SubMoment[];
};

/**
 
  Para poder renderizar los momentos de manera sencilla tenemos que hacer una serie de pasos.

  - Desde Prepr nos puede llegar un array de objetos de esta manera:
     [ {momento}, {momento}, {text}, {tableConfig}, {tableCell}, {text}, {text}, {moment} ]
    donde todos los objetos que no sean de tipo momento pertenecen al momento que tienen encima

    1. Identificamos todos los momentos
    cuando encontramos un momento lo guardamos en el array, si no es un momento hacemos push
    en el ultimo momento encontrado en la propiedad subMoment.
    
    2. Una vez agrupado por momento con todos sus SubMoments tenemos que agrupar los submomentos
    de tipo Table para poder renderizarla.
    El tipo table siempre empieza un un objeto con el campo "table_columns" que es la que define
    el numero de columnas que tendrá la tabla, a partir de ahí es calcular cuantas filas tendrá y
    montar una matriz con las distintas celdas.

 */

export const mapMoments = (moment_content: MomentContent) => {
  const newMoments = groupByMomentAndSubMoment(moment_content);
  const displayMoments = mapToDisplayMoment(newMoments);
  return displayMoments;
};

const groupByMomentAndSubMoment = (momentContent: MomentContent) => {
  const newMoments: NewMoment[] = [];
  const array = momentContent?.items || [];

  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    const isAContentType1 =
      'items' in element &&
      ('part_name' in element.items || 'type' in element.items);

    const isAContenType2 =
      'items' in element &&
      ('content' in element.items || 'image' in element.items);

    const isMomentContentItem = isAContentType1 || isAContenType2;

    if (isMomentContentItem) {
      newMoments.push({
        moment: element as MomentContentType,
        subMoment: undefined,
      });
    } else {
      // If not, it is a element that goes under the last momentContentItem
      const last = newMoments[newMoments.length - 1];
      newMoments[newMoments.length - 1] = {
        ...last,
        subMoment: [
          ...(last?.subMoment || []),
          //these elements can be a Text or a part of a Table
          element as PrepElement | TableContentType,
        ],
      };
    }
  }

  return newMoments;
};

type TableConfig = (TableContentType | PrepElement | AssetElement)[];

const groupTableByConfig = (
  data: TableContentType,
  subMoment: TableConfig,
  index: number
) => {
  const restOfTable = subMoment
    .slice(index, subMoment.length)
    .filter((item) => {
      // delete all elements that are not type table
      if ('body' in item) return false;
      if (item.label === 'Asset') return false;
      return true;
    }) as TableContentType[];

  const currentHeaderIndex = restOfTable.findIndex(
    (item) => item.id === data.id
  );

  const next =
    restOfTable
      .slice(1, restOfTable.length)
      .findIndex((item) => 'items' in item && 'table_columns' in item.items) ||
    restOfTable.length;

  const nextTableHeader = next < 0 ? restOfTable.length : next + 1;

  const table = restOfTable
    .slice(currentHeaderIndex, nextTableHeader)
    .filter((item) => !item.items.table_columns);

  const columns = data.items.table_columns!.value;
  const matrix = listToMatrix<TableContentType>(table, columns);

  return matrix;
};

const mapToDisplayMoment = (newMoments: NewMoment[]) => {
  const displayMoments: DisplayMoment[] = [];

  for (let index = 0; index < newMoments.length; index++) {
    const { subMoment, moment } = newMoments[index];

    if (subMoment) {
      const newSubMoment: SubMoment[] = [];

      for (let y = 0; y < subMoment.length; y++) {
        const data = subMoment[y];

        // is a image element
        if ('items' in data && data.label === 'Asset') {
          newSubMoment.push(data as AssetElement);
        }

        // is a content image or list_item
        if (
          'items' in data &&
          ('content_big_image' in data.items ||
            'content_image' in data.items ||
            'in_a_list' in data.items)
        ) {
          // const currentItem = data as ItemInList;
          // const lastList = newSubMoment[
          //   newSubMoment.length - 1
          // ] as ItemInList[];
          // const lastItem = lastList?.[lastList.length - 1];

          // if (!lastItem) {
          //   newSubMoment.push([currentItem]);
          // } else {
          //   if (
          //     'in_a_list' in lastItem.items &&
          //     lastItem.items.in_a_list?.value
          //   ) {
          //     newSubMoment[newSubMoment.length - 1] = [
          //       ...lastList,
          //       currentItem,
          //     ];
          //   } else {
          //     newSubMoment.push([currentItem]);
          //   }
          // }

          newSubMoment.push(data as AssetElement);
        }

        if ('body' in data) {
          newSubMoment.push(data);
        }

        // is a table configuration
        if ('items' in data && 'table_columns' in data.items) {
          const dataTable = data as TableContentType;
          const columns = dataTable.items.column_percentage?.body || '';
          const matrix = groupTableByConfig(
            dataTable,
            subMoment as TableConfig,
            y
          );

          newSubMoment.push({
            tableContent: matrix,
            configuration: columns.split(','),
          });
        }
      }

      displayMoments.push({
        moment,
        subMoment: newSubMoment,
      });
    } else {
      displayMoments.push({
        moment,
        subMoment: [],
      });
    }
  }
  return displayMoments;
};

// export const groupMomentListItemByImage = (data: SubMoment[]) => {
//   const newItems: ItemInList[][] = [];

//   for (let i = 0; i < data.length; i++) {
//     const currentItem = data[i] as ItemInList;
//     const lastList: any = newItems[newItems.length - 1];
//     const lastItem = lastList?.[lastList.length - 1];

//     if (!lastItem) {
//       newItems.push([currentItem]);
//     } else {
//       if (lastItem.items.in_a_list?.value) {
//         newItems[newItems.length - 1] = [...lastList, currentItem];
//       } else {
//         newItems.push([currentItem]);
//       }
//     }
//   }

//   return newItems;
// };
