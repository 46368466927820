import { useUser } from '@innovamat/ga-features';
import { initDatadog } from '@innovamat/radiance-utils';
import { useEffect } from 'react';

const sessionSampleRate = 5;
const sessionReplaySampleRate = 5;

export function InitDatadog(): null {
  const { user } = useUser();

  useEffect(() => {
    const sampleRates = {
      sessionSampleRate,
      sessionReplaySampleRate,
    };
    const envVariables = {
      DATADOG_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
      DATADOG_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      DATADOG_ENVIRONMENT: process.env.NEXT_PUBLIC_DATADOG_ENVIRONMENT,
      DATADOG_SERVICE: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
      COMMIT_ID: process.env.NEXT_PUBLIC_COMMIT_ID,
      CONFIG_CAT_KEY: process.env.NEXT_PUBLIC_CONFIG_CAT_KEY,
    };

    if (user?.id && user.organizationId) {
      initDatadog(envVariables, sampleRates, {
        uid: user?.id,
        school: user?.organizationId,
      });
    }
  }, [user]);

  return null;
}
