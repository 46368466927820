import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useImportCSV } from '../providers';
import type { CSVImport, ImportType, Step } from '../types';
import { IMPORT_STATUS, STEPS } from '../types';
import { useUploadCSV } from './use-upload-csv';

function useImportCSVProcess() {
  const { goToImportsPage, organizationId, organizationsApiUrl } =
    useImportCSV();
  const queryClient = useQueryClient();
  const [UIState, setUIState] = useState<Step>(STEPS.SELECT);
  const { onUploadCSV, importCSVStatus } = useUploadCSV({
    organizationsApiUrl,
  });
  const [formData, setFormData] = useState<FormData | null>(null);
  const [filename, setFilename] = useState<string | null>(null);
  const [csvFile, setCsvFile] = useState<File | undefined>();

  const handleSetFile = (file: File): void => {
    const _formData = new FormData();
    _formData.append('csv', file);
    setFormData(_formData);
    setFilename(file.name);
    setCsvFile(file);
  };

  const handleResetForm = (): void => {
    setFormData(null);
    setFilename(null);
    importCSVStatus.reset();
    handleSetStep(STEPS.SELECT);
  };

  const handleUploadFile = (type: ImportType): void => {
    if (!type || !formData) return;

    onUploadCSV(formData, type);
    setUIState(STEPS.IMPORTING);
    setTimeout(() => {
      const key = ['CSVImports', organizationId];
      const imports = queryClient.getQueryData<CSVImport[]>(key);
      if (imports) {
        // find the import process that was just created (more recent createdAt)
        const importProcess = imports.reduce((acc, curr) =>
          acc.createdAt > curr.createdAt ? acc : curr
        );
        const importState = importProcess?.status;
        if (
          filename === importProcess?.originalFilename &&
          importState === IMPORT_STATUS.SUCCESS
        ) {
          goToImportsPage();
        } else {
          setUIState(STEPS.UPLOADED);
        }
      } else {
        setUIState(STEPS.UPLOADED);
      }
    }, 6000);
  };

  const handleSetStep = (step: Step): void => {
    setUIState(step);
  };

  return {
    csvFile,
    filename,
    formData,
    importCSVStatus,
    UIState,
    handleResetForm,
    handleSetFile,
    handleSetStep,
    handleUploadFile,
  };
}

export { useImportCSVProcess };
