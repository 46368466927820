import { useMutation, useQueryClient } from '@tanstack/react-query';
import { authAxiosInstance } from '../../../utils';

interface useUploadCSVMutationProps {
  organizationsApiUrl: string;
}

type uploadCSVProps = {
  formData: FormData;
  type: string;
  organizationsApiUrl: string;
};

type UploadCSVBody = {
  formData: FormData;
  type: string;
};

const uploadCSV = async ({
  formData,
  type,
  organizationsApiUrl,
}: uploadCSVProps) => {
  const response = await authAxiosInstance.post(
    `${organizationsApiUrl}/rostering/${type}`,
    formData
  );
  return response.data;
};

function useUploadCSV({ organizationsApiUrl }: useUploadCSVMutationProps) {
  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess, isError, reset } = useMutation({
    mutationFn: ({ formData, type }: UploadCSVBody) =>
      uploadCSV({ organizationsApiUrl, formData, type }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['Rosterings'] });
    },
  });

  const onUploadCSV = (
    formData: FormData,
    type: string,
    onError?: () => void,
    onSuccess?: () => void
  ): void => {
    mutate({ formData, type }, { onError, onSuccess });
  };

  return {
    onUploadCSV,
    importCSVStatus: {
      isError,
      isLoading: isPending,
      isSuccess,
      reset,
    },
  };
}

export { useUploadCSV };
