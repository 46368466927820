import { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { FullScreenHandle } from 'react-full-screen';

import styles from './PdfToolbar.module.scss';
import { Icon } from '@innovamat/innova-components';

type Props = {
  setZoom: (offset: number) => void;
  setPageNumber: (offset: number) => void;
  zoomScale: number;
  numPages: number;
  currentPageNumber: number;
  fullScreen?: FullScreenHandle;
  doublePage: boolean;
  handleChangeDoublePage: () => void;
  isGuide?: boolean;
};

const PdfToolbar = (props: Props) => {
  const {
    setZoom,
    setPageNumber,
    currentPageNumber,
    numPages,
    zoomScale,
    fullScreen,
    doublePage,
    handleChangeDoublePage,
    isGuide,
  } = props;
  const [zoomPercent, setZoomPercent] = useState(100);
  const [pageSelection, setPageSelection] = useState(
    `${currentPageNumber}/${numPages}`
  );
  const [draggableKey, setDraggableKey] = useState(Date.now());
  const [offSet, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (doublePage) {
      const _currentPageNumber =
        currentPageNumber === numPages ? numPages : currentPageNumber + 1;
      const doubleCurrentPageNumber =
        currentPageNumber % 2 === 0 ? currentPageNumber : _currentPageNumber;
      setPageSelection(`${doubleCurrentPageNumber}/${numPages}`);
    } else {
      setPageSelection(`${currentPageNumber}/${numPages}`);
    }
  }, [currentPageNumber, numPages, doublePage]);

  useEffect(() => {
    setZoomPercent(100);
    setDraggableKey(Date.now());
  }, [fullScreen?.active]);

  useEffect(() => {
    if (doublePage) {
      setZoomPercent(100);
    } else if (isGuide) {
      setZoomPercent(60);
    }
  }, [doublePage]);

  const changeZoom = (offset: number) => {
    if (zoomPercent + offset * 100 > 0) {
      setZoomPercent(zoomPercent + offset * 100);
      setZoom(zoomScale + offset);
    }
  };

  const changePage = (offset: number) => {
    const newPageNumber = currentPageNumber + offset;
    if (newPageNumber <= numPages && newPageNumber > 0) {
      setPageNumber(newPageNumber);
    }
  };

  const reduceZoom = () => {
    changeZoom(-0.2);
  };

  const increaseZoom = () => {
    changeZoom(0.2);
  };

  const previousPage = () => {
    changePage(doublePage ? -2 : -1);
  };

  const nextPage = () => {
    changePage(doublePage ? 2 : 1);
  };

  const handleFullScreen = () => {
    if (fullScreen?.active) {
      fullScreen?.exit();
    } else {
      fullScreen?.enter();
    }
  };

  const getPositionOffset = () => {
    if (window.innerWidth < 1025) {
      setOffset({ x: 138, y: 200 });
    } else {
      setOffset({ x: 320, y: 168 });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', getPositionOffset);
    return () => {
      window.removeEventListener('resize', getPositionOffset);
    };
  }, []);

  useEffect(() => {
    getPositionOffset();
  }, []);

  return (
    <Draggable
      bounds={fullScreen?.active ? false : 'parent'}
      handle="#draggableToolbarButton"
      positionOffset={offSet}
      defaultPosition={{ x: -180, y: -20 }}
      key={draggableKey}
    >
      <div className={styles.toolBar}>
        <div id="draggableToolbarButton" className={styles.dragButton}>
          <Icon icon="Move" />
        </div>

        <div className={styles.separator} />

        <button
          className={styles.toolbarButton}
          onClick={reduceZoom}
          type="button"
        >
          <Icon icon="Zoomout" />
        </button>
        <input className={styles.disabled} value={`${zoomPercent}%`} disabled />
        <button
          className={styles.toolbarButton}
          onClick={increaseZoom}
          type="button"
        >
          <Icon icon={'Zoomin'} />
        </button>

        <div className={styles.separator} />

        <button
          className={styles.toolbarButton}
          onClick={previousPage}
          type="button"
          data-testid="previousPagePDF"
        >
          <Icon icon={'PlayerArrowLeft'} />
        </button>
        <input
          type="text"
          value={pageSelection}
          disabled
          className={styles.disabled}
        />
        <button
          className={styles.toolbarButton}
          onClick={nextPage}
          type="button"
          data-testid="nextPagePDF"
        >
          <Icon icon={'PlayerArrowRight'} />
        </button>

        <div className={styles.separator} />

        <button
          className={styles.toolbarButton}
          onClick={handleChangeDoublePage}
          disabled={numPages === 1}
          type="button"
        >
          <Icon icon={'Download'} />
        </button>

        {fullScreen && (
          <>
            <div className={styles.separator} />

            <button
              className={styles.toolbarButton}
              onClick={handleFullScreen}
              type="button"
              data-testid="fullscreenPDF"
            >
              <Icon icon={fullScreen?.active ? 'Shrink' : 'ExpandPlayer'} />
            </button>
          </>
        )}
      </div>
    </Draggable>
  );
};

export default PdfToolbar;
