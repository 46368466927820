import { useTranslation } from 'react-i18next';

import { Grid, Button } from '@innovamat/glimmer-components';
import { FamilyPayments, useNavigation } from '@innovamat/ga-features';
import { Header } from '../layout/header';

const FamilyPaymentsTeachersPage = (): JSX.Element => {
  const { goToHome } = useNavigation();
  const { t } = useTranslation();

  return (
    <>
      <Header
        leftActions={
          <Button leftIcon="CloseIcon" onClick={goToHome} variant="tertiary">
            {t('common.close')}
          </Button>
        }
      />
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <FamilyPayments />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </>
  );
};

export { FamilyPaymentsTeachersPage };
