import {
  PREPR_LANGUAGES_MAPPER,
  Region,
  getLocalesForPrepr,
} from '@innovamat/localization';

export const getItemsFromLang = (
  items: any,
  locale: string,
  region: Region
) => {
  const fallbacks = getLocalesForPrepr(region, locale);

  let result;

  for (let i = 0; i < fallbacks.length; i++) {
    if (items[fallbacks[i]]) {
      result = items[fallbacks[i]];
      break;
    }
  }

  return result ?? items[PREPR_LANGUAGES_MAPPER.es];
};
