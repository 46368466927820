import styled from '@emotion/styled';
import {
  Tooltip,
  Typography,
  InfoColumnIcon,
} from '@innovamat/glimmer-components';
import {
  AdminSublabel,
  AdminSublabelInfoIcon,
} from '../admin-dashboard.styled';
import CourseElement from '../components/course-element';
import AverageScoreBar from '../components/average-score-bar';
import AdquiredContentBar from '../components/adquired-content-bar';
import AverageInnovamat from '../components/average-innovamat/average-innovamat';
import ConnectedPercentage from '../components/connected-percentage/connected-percentage';
import AverageTime from '../components/average-time/average-time';
import Teachers from '../components/teachers';
import { TeachersProps } from '../components/teachers/teachers';
import ClassroomElement from '../components/classroom-element';
import PlaceholderCell from '../components/placeholder-cell';
import CompletedSessions from '../components/completed-sessions';

export type Sublabel =
  | string
  | {
      text: string;
      tooltip?: string;
      infoTooltip?: string;
      isHidden?: boolean;
      dataTestId?: string;
    };

type MultipleSublabels = {
  values: Sublabel[];
  proportions: string;
};

type MultipleColumn = {
  component: JSX.Element;
  isHidden?: boolean;
};

type MultipleProps = {
  values: MultipleColumn[];
  proportions: string;
};

type CompletedSessionsProps = {
  completed: number;
  total: number;
  lab?: number;
  adv?: number;
  rowIsNull: boolean;
  classroomName: string;
  courseOrder: number;
};

type DigitalPracticeProps = {
  value: {
    avgScore: number;
    studentsPerStrip: number[];
    avgInnovamatScore: number;
    acquiredContent: number;
  };
  proportions: string;
  rowIsNull: boolean;
  classroomName: string;
  courseOrder: number;
};

type StudentsProps = {
  value: {
    connectedPercentage: number;
    timeSpent: number;
  };
  proportions: string;
  classroomName: string;
  rowIsNull: boolean;
  courseOrder: number;
};

type ClassroomElementProps = {
  value: string;
  classroomId: string;
  classroomName: string;
  courseOrder: number;
  goToClassroom?: (classroomId: string) => void;
};

const MultipleContainer = styled.div<{ proportions: string }>`
  display: grid;
  gap: 8px;
  grid-template-columns: ${({ proportions }) => proportions};
  width: 100%;
`;

const Sublabel = styled(Typography.Body3)`
  display: flex;
  align-items: flex-end;
`;

const renderCourseElement = (value: {
  courseName: string;
  courseOrder: number;
}): JSX.Element => {
  const { courseName, courseOrder } = value;
  return (
    <CourseElement
      text={courseName}
      courseName={courseName}
      courseOrder={courseOrder}
    />
  );
};

const renderClassroom = ({
  value,
  classroomId,
  classroomName,
  courseOrder,
  goToClassroom,
}: ClassroomElementProps): JSX.Element => {
  return (
    <ClassroomElement
      text={value}
      classroomName={classroomName}
      classroomId={classroomId}
      courseOrder={courseOrder}
      goToClassroom={goToClassroom}
    />
  );
};

const renderSubLabel = (sublabel: Sublabel): JSX.Element => {
  if (typeof sublabel === 'string') {
    return <AdminSublabel>{sublabel}</AdminSublabel>;
  }

  const { text, tooltip, infoTooltip, dataTestId } = sublabel;
  return (
    <AdminSublabel data-testid={dataTestId}>
      <Tooltip content={tooltip} popperOptions={{ strategy: 'fixed' }}>
        <div>{text}</div>
      </Tooltip>
      {infoTooltip && (
        <AdminSublabelInfoIcon>
          <InfoColumnIcon tooltipText={infoTooltip} />
        </AdminSublabelInfoIcon>
      )}
    </AdminSublabel>
  );
};

const getVisibleProportions = (
  proportions: string,
  visibleValues: Sublabel[] | MultipleColumn[]
): string => {
  return proportions.split(' ').slice(0, visibleValues.length).join(' ');
};

const renderMultipleSublabels = ({
  values,
  proportions,
}: MultipleSublabels): JSX.Element => {
  const visibleValues = values.filter(
    (value) => typeof value === 'string' || !value.isHidden
  );
  return (
    <MultipleContainer
      proportions={getVisibleProportions(proportions, visibleValues)}
    >
      {visibleValues.map((value) => renderSubLabel(value))}
    </MultipleContainer>
  );
};

const renderMultipleColumns = ({
  values,
  proportions,
}: MultipleProps): JSX.Element => {
  const visibleValues = values.filter((value) => !value.isHidden);
  return (
    <MultipleContainer
      proportions={getVisibleProportions(proportions, visibleValues)}
    >
      {visibleValues.map((value) => value.component)}
    </MultipleContainer>
  );
};

const renderDigitalPractice = ({
  value,
  proportions,
  rowIsNull,
  classroomName,
  courseOrder,
}: DigitalPracticeProps): JSX.Element => {
  const { avgInnovamatScore, studentsPerStrip, avgScore, acquiredContent } =
    value;
  return renderMultipleColumns({
    values: [
      {
        component: (
          <AverageScoreBar
            score={avgScore}
            studentsPerStrip={studentsPerStrip}
            rowIsNull={rowIsNull}
            classroomName={classroomName}
            courseOrder={courseOrder}
          />
        ),
      },
      {
        component: <AverageInnovamat avgInnovamatScore={avgInnovamatScore} />,
        isHidden: true,
      },
      {
        component: (
          <AdquiredContentBar value={acquiredContent} rowIsNull={rowIsNull} />
        ),
        isHidden: true,
      },
    ],
    proportions: proportions,
  });
};

const renderStudents = ({
  value,
  proportions,
  classroomName,
  courseOrder,
  rowIsNull,
}: StudentsProps): JSX.Element => {
  const { connectedPercentage, timeSpent } = value;
  return renderMultipleColumns({
    values: [
      {
        component: (
          <ConnectedPercentage
            connectedPercentage={connectedPercentage}
            classroomName={classroomName}
            courseOrder={courseOrder}
            rowIsNull={rowIsNull}
          />
        ),
      },
      {
        component: (
          <AverageTime
            timeSpent={timeSpent}
            rowIsNull={rowIsNull}
            classroomName={classroomName}
            courseOrder={courseOrder}
          />
        ),
      },
    ],
    proportions: proportions,
  });
};

const renderCompletedSessions = ({
  completed,
  total,
  rowIsNull,
  lab,
  adv,
  classroomName,
  courseOrder,
}: CompletedSessionsProps): JSX.Element => {
  return (
    <CompletedSessions
      completed={completed}
      total={total}
      lab={lab}
      adv={adv}
      rowIsNull={rowIsNull}
      classroomName={classroomName}
      courseOrder={courseOrder}
    />
  );
};

const renderTeachers = ({
  firstName,
  lastName,
  lastConnection,
  rowIsNull,
  classroomName,
  courseOrder,
}: TeachersProps & { rowIsNull: boolean }): JSX.Element => {
  if (rowIsNull) return <PlaceholderCell left />;
  return (
    <Teachers
      firstName={firstName}
      lastName={lastName}
      lastConnection={lastConnection}
      classroomName={classroomName}
      courseOrder={courseOrder}
    />
  );
};

export {
  renderCourseElement,
  renderClassroom,
  renderDigitalPractice,
  renderStudents,
  renderMultipleSublabels,
  renderMultipleColumns,
  renderTeachers,
  renderCompletedSessions,
};
