/* eslint-disable react-hooks/rules-of-hooks */
import {
  GlowApiError,
  Student,
  useDeleteInvitationMutation,
  useDeleteStudentMutation,
} from '@innovamat/glow-api-client';

type Props = {
  onSuccess?: () => void;
  onError?: (error: GlowApiError) => void;
};

type DeleteStudent = {
  onDeleteStudent: (selectedStudent: Student) => void;
  isDeletingLoading: boolean;
};

function useDeleteStudent({ onSuccess, onError }: Props): DeleteStudent {
  const { mutate: deleteStudentMutation, isPending: isLoadingStudent } =
    useDeleteStudentMutation<GlowApiError>();
  const { mutate: deleteInvitationMutation, isPending: isLoadingInvitation } =
    useDeleteInvitationMutation<GlowApiError>();

  const onDeleteStudent = (selectedStudent: Student): void => {
    const isInvitation = !!selectedStudent?.isInvitation;

    if (isInvitation) {
      deleteInvitationMutation(
        {
          invitationId: selectedStudent.id!,
        },
        {
          onSuccess: onSuccess,
          onError: onError,
        }
      );
    } else {
      deleteStudentMutation(
        {
          studentId: selectedStudent.id!,
        },
        {
          onSuccess: onSuccess,
          onError: onError,
        }
      );
    }
  };

  return {
    onDeleteStudent,
    isDeletingLoading: isLoadingStudent || isLoadingInvitation,
  };
}

export { useDeleteStudent };
