import { LANGUAGES, Region } from './_generated/locales';
import { createAcceptLanguage } from './utils';

export function getAcceptLanguage(
  region: string | null,
  locale: string
): string {
  if (!region) {
    throw new Error('Region is not defined');
  }

  const locales = LANGUAGES.region[region as Region].locale;

  const { fallback } =
    (locales[locale as keyof typeof locales] as {
      fallback: string[];
    }) || Object.values(locales)[0]; // To avoid blank page when locale is not found

  return createAcceptLanguage(locale, fallback);
}
