/* eslint-disable */
let builds: {} | null = null;

function getStagePrefix(educationLevel: string): string {
  switch (educationLevel) {
    case 'PRIMARIA':
    case 'PRIMARY':
      return 'Primary';
    case 'SECUNDARIA':
    case 'SECONDARY':
      return 'Secondary';
    default:
      return 'Primary';
  }
}

const fetchBuilds = (
  educationalLevel: string,
  unityStandaloneBuildDomain: string
) => {
  if (builds === null) {
    const buildsUrl = `${unityStandaloneBuildDomain}/${getStagePrefix(
      educationalLevel
    )}/BuildRoutes/routes.json`;
    return fetch(buildsUrl).then(
      (res) => {
        const json = res.json();
        builds = json;
        return json;
      },
      (errorMessage) => console.error(errorMessage)
    );
  }
  return new Promise((resolve) => {
    resolve(builds);
  });
};

export const resolveBuildPath = async (
  sceneName: string,
  educationalLevel: string,
  unityBuild: string,
  unityStandaloneBuildDomain: string
) =>
  await fetchBuilds(educationalLevel, unityStandaloneBuildDomain).then(
    (builds: any) => {
      const stagePrefix = getStagePrefix(educationalLevel);
      return sceneName && builds[sceneName]
        ? `${unityStandaloneBuildDomain}/${stagePrefix}${builds[sceneName]}`
        : stagePrefix === 'Primary'
        ? `${unityBuild}/Applets`
        : unityStandaloneBuildDomain;
    }
  );
