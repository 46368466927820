import { IconsFaces } from '@innovamat/glimmer-assets';
import { SelectContainer } from '../onboarding-form.styled';

export function FinishStep(): JSX.Element {
  return (
    <SelectContainer>
      <IconsFaces />
    </SelectContainer>
  );
}
