import { QueryClient } from '@tanstack/react-query';

type UpdateProps = {
  queryClient: QueryClient;
  id: string;
  academicYearId: string;
  rating: number;
};

export function updateSessionReviewInCache({
  queryClient,
  id,
  academicYearId,
  rating,
}: UpdateProps): void {
  const variables = ['SessionReview', { academicYearId, id }];

  queryClient.setQueryData(variables, {
    sessionReview: { rating: rating },
  });
}
