import { TeachersQuery, useTeachersQuery } from '@innovamat/glow-api-client';
import { Roles } from '@innovamat/radiance-utils';
import { QueryClient } from '@tanstack/react-query';

type UpdateProps = {
  queryClient: QueryClient;
  organizationId: string;
  teacherId: string;
  roles: Roles[];
  isPlatformEnabled: boolean;
};

function updateUserRoleInCache({
  queryClient,
  roles,
  organizationId,
  teacherId,
  isPlatformEnabled,
}: UpdateProps): void {
  const key = useTeachersQuery.getKey({
    organizationId,
    isPlatformEnabled,
  });

  const data = queryClient.getQueryData<TeachersQuery>(key);

  const addingOwner = roles.includes(Roles.OWNER);

  if (data) {
    const oldTeachers = data?.teachers?.map((teacher) => {
      if (teacher?.id === teacherId) {
        return {
          ...teacher,
          roles,
        };
      }
      if (teacher?.roles?.includes('ROLE_OWNER') && addingOwner) {
        return {
          ...teacher,
          roles: replaceOwnerByAdmin(teacher.roles),
        };
      }
      return teacher;
    });

    queryClient.setQueryData(key, {
      teachers: oldTeachers,
    });
  }
}

function replaceOwnerByAdmin(roles?: string[]): string[] | undefined {
  const newRoles = roles?.filter((role) => role !== 'ROLE_OWNER');
  if (!newRoles?.includes('ROLE_ADMIN')) newRoles?.push('ROLE_ADMIN');
  return newRoles;
}

export { updateUserRoleInCache };
