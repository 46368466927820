import LayoutSpaces from './LayoutSpaces/LayoutSpaces';
import { PinnedSpacesSection, SpacesSection, Title } from './Spaces.styles';
import { SpaceChallenge, Space, SpaceSession } from './Spaces.types';
import { SpacesProvider, SpacesProviderValue } from './SpacesProvider';
import SpacesGrid from './SpacesGrid/SpacesGrid';
import { ThemeProvider as ThemeProviderInnovaComponents } from '@innovamat/innova-components';
import { TFunction } from 'i18next';

type Params = {
  handleMarkSpace: (space: Space, active: number) => void;
  handleMarkChallengeDone: (space: Space, challenge: SpaceChallenge) => void;
  handleSaveActiveChallenge: (space: Space, active: number) => void;
  classroomId?: string;
  academicYearId?: string;
  setEventData: (key: string, data: any) => void;
  isLoading: boolean;
  goToResource: (
    resource: string,
    id: string,
    params: Record<string, string>,
    isSession?: boolean,
    isChallenge?: boolean
  ) => void;
  onGoBack: () => void;
  session: SpaceSession;
  t: TFunction<'translation', undefined, 'translation'>;
  spaces: Space[];
};

export function Spaces({
  handleMarkSpace,
  handleMarkChallengeDone,
  handleSaveActiveChallenge,
  setEventData,
  classroomId,
  academicYearId,
  isLoading,
  goToResource,
  onGoBack,
  session,
  spaces,
  t,
}: Params) {
  const { title, spaces: spacesFromPrepr } = session;

  const value: SpacesProviderValue = {
    handleMarkSpace,
    handleMarkChallengeDone,
    handleSaveActiveChallenge,
    setEventData,
    classroomId,
    academicYearId,
    goToResource,
    t,
  };

  return (
    <ThemeProviderInnovaComponents>
      <SpacesProvider value={value}>
        <LayoutSpaces title={title} onGoBack={onGoBack}>
          {!!classroomId && (
            <PinnedSpacesSection>
              <Title>{t('spaces.pinnedSpaces.title')}</Title>
              <SpacesGrid
                spaces={spaces.filter((space) => space.pinned)}
                numLoaders={3}
                isLoading={isLoading || !spacesFromPrepr}
                isEmpty={spaces.filter((space) => space.pinned).length === 0}
              />
            </PinnedSpacesSection>
          )}

          <SpacesSection>
            <Title>{t('spaces.unpinnedSpaces.title')}</Title>
            <SpacesGrid
              spaces={spaces.filter((space) => !space.pinned)}
              numLoaders={6}
              isLoading={isLoading || !spacesFromPrepr}
              isEmpty={false}
            />
          </SpacesSection>
        </LayoutSpaces>
      </SpacesProvider>
    </ThemeProviderInnovaComponents>
  );
}

export default Spaces;
