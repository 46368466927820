import { useSwipeable } from 'react-swipeable';
import { isTouchDevice } from '../utils/helpers';
import useResourceNavigation from './useResourceNavigation';
import { getResource } from '../state/selectors';
import usePlayerState from '../providers/PlayerProviders/usePlayerState';

const isTouchable = isTouchDevice();

export default function useSwipe() {
  const { handleNext, handlePrev } = useResourceNavigation();
  const { currentResource, resource } = usePlayerState();
  const subResource = getResource(resource, currentResource);
  const isCurrentResourceGeogebra = subResource?.type === 'geogebra';

  const enableSwipe = isTouchable && !isCurrentResourceGeogebra;

  const config = enableSwipe
    ? {
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
      }
    : {};

  const handlers = useSwipeable({ ...config });

  return handlers;
}
