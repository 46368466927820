import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import SpacesItemCard from './SpacesItemCard';
import { Stepper, Tooltip } from '@innovamat/innova-components';

import { SpacesCardProps } from './SpacesCard.types';

import 'slick-carousel/slick/slick.css';

import {
  Wrapper,
  Title,
  Description,
  ButtonLeftWrapper,
  ButtonRightWrapper,
  ButtonCircleStyle,
  StepperWrapper,
  SpaceHeaderWrapper,
  SpacesPinButton,
  PinIcon,
} from './SpacesCard.styles';

export function SpacesCard({
  title,
  description,
  challenges,
  isPinned,
  onPinItem,
  tooltipPin,
  active,
  handleSetActive,
  showPin,
  enableConfettiAnimation,
  ...rest
}: SpacesCardProps) {
  const [initialSlide, setInitialSlide] = useState(active);
  const sliderRef = useRef<any>(null);

  const handleNextButton = () => {
    const nextIndex = active + 1;
    if (nextIndex < challenges.length) sliderRef.current?.slickGoTo(nextIndex);
  };

  const handlePrevent = (event: any) => {
    event.stopPropagation();
  };

  const handlePrevButton = () => {
    const nextIndex = active - 1;
    if (nextIndex >= 0) sliderRef.current?.slickGoTo(nextIndex);
  };

  const handleStepper = (value: number) => {
    sliderRef.current?.slickGoTo(value);
  };

  const handleBeforeChange = (current: number, next: number) => {
    handleSetActive(next);
  };

  const handlePin = (event: any) => {
    handlePrevent(event);
    onPinItem();
  };

  const checkedArray = challenges.map((c) => c.checked);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    beforeChange: handleBeforeChange,
  };

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      setInitialSlide(active);
    }
  }, [sliderRef.current]);

  if (typeof window === 'undefined')
    return <>The slider only works for client side</>;

  return (
    <Wrapper {...rest}>
      <SpaceHeaderWrapper>
        <div>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </div>
        {showPin && (
          <Tooltip
            content={tooltipPin}
            interactive
            placement="top"
            offset={[0, 15]}
          >
            <SpacesPinButton onClick={handlePin}>
              <PinIcon icon="Pin" isPinned={isPinned} />
            </SpacesPinButton>
          </Tooltip>
        )}
      </SpaceHeaderWrapper>
      <Slider ref={sliderRef} initialSlide={initialSlide} {...settings}>
        {challenges.map((challenge, index) => (
          <SpacesItemCard
            enableConfettiAnimation={enableConfettiAnimation}
            key={index}
            {...challenge}
            showCheckbox={showPin}
          />
        ))}
      </Slider>
      <StepperWrapper onClick={handlePrevent}>
        <Stepper
          steps={challenges.length}
          active={active}
          onActive={handleStepper}
          infinite={false}
          checkedArray={checkedArray}
        />
      </StepperWrapper>
      {challenges.length > 1 && (
        <>
          <ButtonLeftWrapper onClick={handlePrevent}>
            <ButtonCircleStyle
              icon="ArrowLeft"
              onClick={handlePrevButton}
              disabled={active === 0}
              data-testid="button-left-spaces"
            />
          </ButtonLeftWrapper>
          <ButtonRightWrapper onClick={handlePrevent}>
            <ButtonCircleStyle
              icon="ArrowRight"
              onClick={handleNextButton}
              disabled={active === challenges.length - 1}
              data-testid="button-right-spaces"
            />
          </ButtonRightWrapper>
        </>
      )}
    </Wrapper>
  );
}
