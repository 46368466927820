import { StudentRow } from '../classroom-students';

const ALIAS_LENGTH = 5;

export function anonymizeStudent(student: StudentRow) {
  const anonymizedName = valueToAsterisks(student?.firstName!);
  const anonymizedApellido = valueToAsterisks(student?.lastName!);

  return {
    ...student,
    firstName: anonymizedName,
    lastName: anonymizedApellido,
    alias: getAlias(student?.alias!, anonymizedName, anonymizedApellido),
  };
}

export const getAlias = (
  currentAlias: string,
  firstName: string,
  lastName: string
) => {
  if (!currentAlias) {
    const firstNameInitial = firstName.substring(0, 1).toUpperCase();
    const lastNameInitial = lastName.substring(0, 2).toUpperCase();
    const randomTwoDigitsNumber = Math.floor(Math.random() * 100)
      .toString()
      .padStart(2, '0');

    return `${firstNameInitial}${lastNameInitial}${randomTwoDigitsNumber}`;
  }

  if (currentAlias.length < ALIAS_LENGTH) {
    return currentAlias + 'X'.repeat(ALIAS_LENGTH - currentAlias.length);
  }

  return currentAlias;
};

export const valueToAsterisks = (value: string): string => {
  if (value.length < 2) return value;
  return value.substring(0, 2) + '*'.repeat(value.length - 2);
};
