import { PREPR_LANGUAGES_MAPPER } from '@innovamat/localization';

const DEFAULT_LANG = 'es';
const LATAM_LANG = 'es_419';

const languageMapper = (language: string) => {
  const isLatam = language.includes('es_');

  if (isLatam) {
    return PREPR_LANGUAGES_MAPPER[LATAM_LANG];
  }

  if (!PREPR_LANGUAGES_MAPPER[language]) {
    return PREPR_LANGUAGES_MAPPER[DEFAULT_LANG];
  }

  return PREPR_LANGUAGES_MAPPER[language];
};

export { languageMapper };
