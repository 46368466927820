import styled, { css } from 'styled-components';
import {
  Icon,
  InnerHtml,
  Tooltip,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { CardType, StudentFailed } from '@innovamat/glow-api-client';

export const CELL_VARIANT = {
  CELL_NORMAL_SIZE: 'cell-normal-size',
  CELL_SMALL_SIZE: 'cell-small-size',
} as const;

export const STATUS_TYPE = {
  LOCKED: 'locked',
  NOT_SEEN: 'notSeen',
  MOSTLY_FAILED: 'mostlyFailed',
  FAIRLY_FAILED: 'fairlyFailed',
  IN_PROGRESS: 'inProgress',
  MEMORIZED: 'memorized',
} as const;

type CellVariant = (typeof CELL_VARIANT)[keyof typeof CELL_VARIANT];

type Props = {
  status: CardType;
  variant: CellVariant;
  studentsFailed?: StudentFailed[];
};

const StyledCell = styled.div<{
  variant: CellVariant;
  bgColor: string;
}>(({ variant, bgColor }) => {
  const cellSize = {
    [CELL_VARIANT.CELL_NORMAL_SIZE]: 32,
    [CELL_VARIANT.CELL_SMALL_SIZE]: 12,
  };

  const cellBorderRadius = {
    [CELL_VARIANT.CELL_NORMAL_SIZE]: 4,
    [CELL_VARIANT.CELL_SMALL_SIZE]: 2,
  };

  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${cellSize[variant]}px;
    height: ${cellSize[variant]}px;
    border-radius: ${cellBorderRadius[variant]}px;
    background-color: ${bgColor};
  `;
});

const StyledTooltip = styled(Tooltip)`
  text-align: center;
`;

function CellFluency({
  status,
  variant = CELL_VARIANT.CELL_NORMAL_SIZE,
  studentsFailed,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const theme = useGlimmerTheme();

  const baseTokenReport = theme.tokens.color.specific.reports;
  const getBackgroundColor = (status: CardType): string => {
    switch (status) {
      case STATUS_TYPE.LOCKED:
      case STATUS_TYPE.NOT_SEEN:
        return baseTokenReport['progress-bar'].empty.value;
      case STATUS_TYPE.MOSTLY_FAILED:
        return baseTokenReport.semantic['low'].value;
      case STATUS_TYPE.FAIRLY_FAILED:
        return baseTokenReport.semantic['medium'].value;
      case STATUS_TYPE.IN_PROGRESS:
        return baseTokenReport.semantic['high'].value;
      case STATUS_TYPE.MEMORIZED:
        return baseTokenReport.semantic['super-high'].value;
      default:
        return baseTokenReport.semantic['no-data'].value;
    }
  };

  const backgroundColor = getBackgroundColor(status);

  const renderTooltipMessage = (): string => {
    if (status === 'locked') return t('fluency.reports.hover.lockedGroup');
    const students = studentsFailed
      ?.map((student) => `${student.firstName} ${student.lastName}`)
      .join('<br />');

    return students || '';
  };

  return (
    <StyledTooltip
      content={<InnerHtml text={renderTooltipMessage()} />}
      disabled={!studentsFailed || studentsFailed.length === 0}
      maxWidth={250}
    >
      <StyledCell variant={variant} bgColor={backgroundColor}>
        {status === STATUS_TYPE.LOCKED && (
          <Icon
            icon="LockedIcon"
            iconColor={baseTokenReport.semantic['no-data'].value}
          />
        )}
      </StyledCell>
    </StyledTooltip>
  );
}

export default CellFluency;
