import {
  CompletedResource,
  CompletedResourcesQuery,
  MarkSessionBody,
  useCompletedResourcesQuery,
} from '@innovamat/glow-api-client';
import { QueryClient } from '@tanstack/react-query';

type UpdateCompletedResourcesProps = {
  queryClient: QueryClient;
  body: MarkSessionBody;
};

export function updateCompletedResources({
  queryClient,
  body,
}: UpdateCompletedResourcesProps): void {
  const completedResourcesKey = useCompletedResourcesQuery.getKey({
    classroomId: body.classroomId!,
    academicYearId: body.academicYearId,
  });

  const prevCompletedResources =
    queryClient.getQueryData<CompletedResourcesQuery>(completedResourcesKey);

  if (prevCompletedResources?.getCompletedResourcesByClassroomId) {
    queryClient.setQueryData<CompletedResourcesQuery>(completedResourcesKey, {
      getCompletedResourcesByClassroomId: update(
        prevCompletedResources.getCompletedResourcesByClassroomId as CompletedResource[],
        body
      ),
    });
  }
}

const update = (
  completedResources: CompletedResource[],
  markedSession: MarkSessionBody
): CompletedResource[] => {
  const resource = completedResources?.find(
    (c) => c.resourceId === markedSession.resourceId
  );

  // removeit from the list
  if (resource && !markedSession.done) {
    return completedResources?.filter(
      (c) => c.resourceId !== markedSession.resourceId
    );
  }

  // update the element and set the solutionEnabled value
  if (resource) {
    return completedResources?.map((c) =>
      c.resourceId === markedSession.resourceId
        ? {
            ...c,
            solutionsEnabled: !!markedSession.solutionsEnabled,
          }
        : c
    );
  }

  // if no exists add it
  if (!resource) {
    return [
      ...(completedResources || []),
      {
        resourceId: markedSession.resourceId,
        solutionsEnabled: !!markedSession.solutionsEnabled,
      },
    ];
  }

  return completedResources;
};
