import { depthFirstSearchForAssetItemsInDigitalGuides } from '@innovamat/radiance-utils';
import { SpaceSession } from '../../spaces/Spaces.types';
import { DigitalGuide } from '../types';
import { ImageModal } from '../components';

export const getImagesFromPreprResource = (
  preprResource: DigitalGuide | SpaceSession
) => {
  const imagesUnFlattened = [] as any[];
  depthFirstSearchForAssetItemsInDigitalGuides(
    imagesUnFlattened,
    preprResource
  );
  const imagesFlattened = imagesUnFlattened.flatMap((num) => num);
  const images = imagesFlattened.filter(
    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
  );

  const formattedImages: ImageModal[] = images.map((img) => ({
    url: img?.cdn_files?.items?.[0].url || '',
    title: img?.caption || '',
    id: img?.cdn_files?.items?.[0].id,
  }));
  //  If it's infantil I put the last one in first place
  // because usually the last one is the generic one.
  if ('challenges' in preprResource) {
    const lastImage = formattedImages.pop();
    if (lastImage) formattedImages.unshift(lastImage);
  }

  return formattedImages;
};
