import React from 'react';

import Icon from '../Icon';
import {
  CardBody,
  CardItem,
  CheckCardGroupWrapper,
  CheckIconWrapper,
} from './CheckCardGroup.styles';
import TextRow from '../TextRow';

type CheckCardProps = {
  id: string;
  isSelected: boolean;
  onClick?: (id: string) => void;
  rows: string[];
};

type Data = {
  id: string;
  rows: string[];
};

type CheckCardGroupProps = {
  data: Data[];
  onChange: (id: string) => void;
  selectedId?: string;
};

function CheckCard({ id, isSelected, onClick, rows }: CheckCardProps) {
  return (
    <CardItem key={id} isSelected={isSelected}>
      <CardBody
        borderRadius="md"
        className="addressCardBody"
        elevation="00"
        isSelected={isSelected}
        onClick={() => onClick && onClick(id)}
      >
        <CheckIconWrapper>
          {isSelected ? (
            <Icon size="sm" icon="CheckCircle" viewBox="0 0 16 16" />
          ) : (
            <Icon size="sm" icon="UncheckCircle" viewBox="0 0 16 16" />
          )}
        </CheckIconWrapper>
        <div>
          {rows.map((line, index) => (
            <TextRow key={index}>{line}</TextRow>
          ))}
        </div>
      </CardBody>
    </CardItem>
  );
}

function CheckCardGroup({ data, onChange, selectedId }: CheckCardGroupProps) {
  const handleClick = (id: string): void => {
    onChange(id);
  };
  return (
    <CheckCardGroupWrapper>
      {data.map((item) => (
        <CheckCard
          id={item.id}
          isSelected={selectedId === item.id}
          key={item.id}
          onClick={handleClick}
          rows={item.rows}
        />
      ))}
    </CheckCardGroupWrapper>
  );
}

export default CheckCardGroup;
