import { Resource, Session } from '@innovamat/resource-viewer';
import { TFunction } from 'i18next';
import React, { createContext, useEffect, useState } from 'react';
import { ImageModal } from '../../../components/ImagesModal/ImagesModal';
import useBoxes from '../../../hooks/useBoxes';
import { AssetType, ScrollPosition } from '../../../types/prepr';
import { Challenge, ChallengesResponse } from '../types/challenges';
import { EarlyYearDigitalGuide } from '../types/earlyYearDigitalGuide';

type EarlyYearGuideProviderProps = {
  resource: Resource;
  children: React.ReactNode;
  guide: EarlyYearDigitalGuide;
  images?: ImageModal[];
  canMarkAsDone?: boolean;
  onMarkChallengeAsDone?: (challengeId: string, value: boolean) => void;
  scrollPosition?: ScrollPosition;
  challenges?: ChallengesResponse | undefined;
  onEvent: (key: string, data: any) => void;
  t: TFunction<'translation', undefined, 'translation'>;
};

type EarlyYearGuideContextValues = {
  guide: EarlyYearDigitalGuide | undefined;
  defaultImage: ImageModal | null;
  images: ImageModal[];
  onClickAsset: (asset: ImageModal | null) => void;
  id: string | null;
  currentChallenge: ChallengeState | undefined;
  isFirstImage: boolean;
  isLastImage: boolean;
  currentAsset: AssetType | undefined;
  resource: Session;
  canMarkAsDone: boolean;
  scrollPosition: ScrollPosition;
  genericAssetThubmnailTime: number;
  onSelectChallenge: (challenge: Challenge, index: number) => void;
  onNextImage: () => void;
  onPrevImage: () => void;
  getIsOpen: (key: number) => boolean;
  onScrollChallenge: (index: number | undefined) => void;
  onMarkChallengeAsDone?: (challengeId: string, value: boolean) => void;
  isChallengeDone: (challengeId: string) => boolean;
  setCurrentChallenge: (
    value:
      | ChallengeState
      | undefined
      | ((prevVar: ChallengeState | undefined) => ChallengeState | undefined)
  ) => void;
  onEvent: (key: string, data: any) => void;
  t: TFunction<'translation', undefined, 'translation'>;
};

export const EarlyYearGuideContext = createContext<
  EarlyYearGuideContextValues | undefined
>(undefined);

type ChallengeState = Challenge & {
  challengeNumber: number;
};

export default function EarlyYearGuideProvider({
  resource,
  children,
  guide,
  images,
  canMarkAsDone = false,
  challenges,
  onMarkChallengeAsDone,
  scrollPosition,
  onEvent,
  t,
}: EarlyYearGuideProviderProps) {
  const [currentChallenge, setCurrentChallenge] = useState<
    ChallengeState | undefined
  >(undefined);

  const { getIsOpen, onSelectBox } = useBoxes();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [defaultImage, setDefaultImage] = useState<ImageModal | null>(null);
  const id = (resource as Session).guide?.id!;

  const genericAsset = guide?.genericAsset?.items[0];

  const currentAsset = !currentChallenge
    ? genericAsset
    : currentChallenge?.image?.items[currentImageIndex];

  const isFirstImage = currentImageIndex === 0;
  const isLastImage =
    currentImageIndex === (currentChallenge?.image?.items?.length || 0) - 1;

  const onClickAsset = (asset: ImageModal | null) => {
    setDefaultImage(asset);
  };

  const getGenericAssetThubmnailTime = () => {
    if (currentAsset?.id !== genericAsset?.id || !guide.thumbnailVideo) {
      return undefined;
    }

    const [minutes, seconds] = guide.thumbnailVideo.body.split(':');
    const secondsTime = parseInt(minutes) * 60 + parseInt(seconds);

    return secondsTime;
  };

  const genericAssetThubmnailTime = getGenericAssetThubmnailTime();

  useEffect(() => {
    setCurrentImageIndex(0);
  }, [currentChallenge]);

  const onSelectChallenge = (challenge: Challenge, index: number) => {
    const isOpen = getIsOpen(index);
    onSelectBox(index);

    const key = isOpen ? 'close_challenge' : 'open_challenge';
    const isSession = 'resources' in resource;
    const spaceId = (resource as Session).guide?.id!;

    const data = {
      challenge_id: challenge.id,
      challenge_name: challenge.title.body,
      challenge_order: index + 1,
      challenges_total: guide?.challenges.items.length,
      space_name: guide.title.body,
      space_id: spaceId,
      session_name: resource.title,
      [isSession ? 'session_id' : 'resource_id']: resource.id,
    };
    onEvent(key, data);
  };

  const setChallenge = (index: number | undefined) => {
    if (index === undefined) {
      setCurrentChallenge(undefined);
      return;
    }
    const challenge = guide?.challenges.items[index];
    if (challenge) {
      setCurrentChallenge({
        ...challenge,
        challengeNumber: index,
      });
    }
  };

  const onNextImage = () => {
    setCurrentImageIndex(currentImageIndex + 1);
  };

  const onPrevImage = () => {
    setCurrentImageIndex(currentImageIndex - 1);
  };

  const onScrollChallenge = (index: number | undefined) => {
    setChallenge(index);
  };

  const isChallengeDone = (challengeId: string) => {
    return challenges?.[challengeId] === 'completed';
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    guide,
    images,
    defaultImage,
    onClickAsset,
    id,
    currentChallenge,
    isFirstImage,
    isLastImage,
    currentAsset,
    resource,
    canMarkAsDone,
    scrollPosition,
    onSelectChallenge,
    onNextImage,
    onPrevImage,
    getIsOpen,
    onScrollChallenge,
    onMarkChallengeAsDone,
    isChallengeDone,
    setCurrentChallenge,
    genericAssetThubmnailTime,
    onEvent,
    t,
  } as EarlyYearGuideContextValues;

  return (
    <EarlyYearGuideContext.Provider value={value}>
      {children}
    </EarlyYearGuideContext.Provider>
  );
}
