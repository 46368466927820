import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  language: string;
};

export default function Head({ language }: Props) {
  const lang = language ? language.split('_')[0] : 'es';
  return <Helmet htmlAttributes={{ lang }} />;
}
