import BlockWithIcon from '../BlockWithIcon';
import BlockContent from '../BlockContent';
import { PrepElement } from '../../types/prepr';
import { useDigitalGuides } from '../../context';
import { PrintableBody2 } from '../../../printable-styles/printable-typography';

type Props = {
  keyVocabulary: PrepElement | undefined;
};

export default function ChallengesBlockVocabulary({ keyVocabulary }: Props) {
  const { t } = useDigitalGuides();

  if (!keyVocabulary) return null;

  return (
    <BlockWithIcon icon="UserRegister">
      <BlockContent title={t('digitalguides.key_vocabulary')}>
        <PrintableBody2>{keyVocabulary.body}</PrintableBody2>
      </BlockContent>
    </BlockWithIcon>
  );
}
