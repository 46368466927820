import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import {
  FamilyPaymentsDocument,
  useGlowApiClient,
  type FamilyPaymentsQuery,
  type FamilyPaymentsQueryVariables,
} from '@innovamat/glow-api-client';
import { useEventListener } from '@innovamat/hooks';
import type { UserManagementValue } from '../../../../user-management';

import type { FamilyPayment } from '../types/family-payment';

type Props = {
  courseOrder?: number;
  searchedStudentName?: string;
  user: UserManagementValue['user'];
};

export const useInfiniteFamilyPayments = ({
  courseOrder,
  searchedStudentName,
  user,
}: Props) => {
  const { graphqlClient } = useGlowApiClient();

  const {
    data,
    hasNextPage,
    isError,
    isFetchedAfterMount,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ['FamilyPayments', courseOrder, searchedStudentName],
    queryFn: ({ pageParam = 1 }) => {
      const variables: FamilyPaymentsQueryVariables = {
        academicYearId: user?.organization?.academicYearId ?? '',
        courseOrder,
        organizationId: user?.organizationId ?? '',
        page: pageParam,
        pageSize: 20,
        regionId: user?.region ?? '',
        studentName: searchedStudentName,
      };

      return graphqlClient.request(FamilyPaymentsDocument, variables);
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.familyPayments?.items?.length === 0) {
        return undefined;
      }
      return allPages.length + 1;
    },
    initialPageParam: 1,
    enabled: !!user,
  });

  const handleScroll = useCallback(() => {
    const isScrollInTheBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;

    if (isScrollInTheBottom && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, fetchNextPage]);

  useEventListener('scroll', handleScroll);

  const flattenedItems = useMemo(() => {
    const pages = data?.pages as FamilyPaymentsQuery[];
    const payments = (pages?.flatMap((page) => page?.familyPayments?.items) ??
      []) as FamilyPayment[];
    return payments;
  }, [data]);

  return {
    familyPayments: flattenedItems,
    hasNextPage,
    isError,
    isFetchedAfterMount,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
  };
};
