import styled from '@emotion/styled';
import { useReportsFullScreen } from '@innovamat/ga-features';
import { TitleHeader, Typography } from '@innovamat/glimmer-components';
import { Trans } from 'react-i18next';

const Description = styled(Typography.Body2)`
  display: inline-block;
  width: 100%;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  margin: 8px 0px 40px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 720px;
  }
`;

const Link = styled.a`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

type Props = {
  title: string;
  description: string;
  actions?: JSX.Element;
  descriptionTestId: string;
  descriptionLink?: string;
};

const HeaderReports = ({
  title,
  description,
  descriptionTestId,
  descriptionLink,
  actions,
}: Props) => {
  const { isFullScreen, openFullScreen } = useReportsFullScreen();
  return (
    <>
      <TitleHeader
        title={title}
        hasSeparator={false}
        style={{ position: 'sticky', left: 0 }}
        actions={actions}
        hasFullScreenButton={!isFullScreen}
        fullScreenButtonAction={openFullScreen}
      />
      {description && (
        <Description data-testid={descriptionTestId}>
          <Trans
            components={{
              b: <b />,
              a: descriptionLink ? (
                <Link href={descriptionLink} target="_blank" rel="noreferrer" />
              ) : (
                <></>
              ),
            }}
          >
            {description}
          </Trans>
        </Description>
      )}
    </>
  );
};

export default HeaderReports;
