import {
  OnboardingCardSelect,
  SelectContainer,
} from '../onboarding-form.styled';
import { useOnboardingForm } from '../context/onboarding-form-context';
import { useTranslation } from 'react-i18next';

export function FirstYearStep(): JSX.Element {
  const { t } = useTranslation();
  const { isFirstYear, setIsFirstYear } = useOnboardingForm();
  return (
    <SelectContainer>
      <OnboardingCardSelect
        status={isFirstYear ? 'selected' : 'default'}
        onClick={() => setIsFirstYear(true)}
      >
        {t('onboarding-form.first-year-step.first-year')}
      </OnboardingCardSelect>
      <OnboardingCardSelect
        status={isFirstYear === false ? 'selected' : 'default'}
        onClick={() => setIsFirstYear(false)}
      >
        {t('onboarding-form.first-year-step.not-first-year')}
      </OnboardingCardSelect>
    </SelectContainer>
  );
}
