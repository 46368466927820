import { useRef } from 'react';
import { useResponsiveSlide } from './content-list-slider';
import { ResourceCard, Slider } from '@innovamat/glimmer-components';
import { ResourceSlider } from './content-list.styled';
import { useTranslation } from 'react-i18next';
import { ContentResource, ContentListType } from '../../types/page';
import { MarkMessionMethodParams } from '../../../session-done';
import { ListTitle } from './content-list-title';

type ContentListProps = {
  list: ContentListType;
  onResourceClick: (resource: ContentResource) => void;
  onShowMoreClick?: () => void;
  imgOptimizationUrl?: string;
  onMarkSession?: (params: MarkMessionMethodParams) => void;
  onToggleSolutions?: (params: MarkMessionMethodParams) => void;
};

export function ContentList({
  list,
  onResourceClick,
  onShowMoreClick,
  imgOptimizationUrl,
  onMarkSession,
  onToggleSolutions,
}: ContentListProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { name, resources, page_id, description } = list;

  const showMoreId = page_id;

  const { slidesToShow, initialSlide } = useResponsiveSlide({
    resources,
    showMore: showMoreId,
    relatedResources: false,
  });

  return (
    <ResourceSlider
      ref={ref}
      data-testid="sliderWrapperContentList"
      key={list.id}
      role="list"
    >
      <ListTitle
        name={name}
        description={description}
        onShowMoreClick={onShowMoreClick ? () => onShowMoreClick() : undefined}
      />
      <Slider
        gap="1rem"
        initialIndex={initialSlide}
        slidesToShow={slidesToShow}
      >
        {resources.map((resource, index) => {
          return (
            <div
              aria-label="resource card slide"
              className="slide"
              id={resource.id}
              key={resource.id + index}
              role="listitem"
            >
              <ResourceCard
                key={resource.id}
                title={resource.name}
                description={resource.description}
                thumbnail={resource.thumbnail}
                onClick={() => onResourceClick(resource)}
                status={resource.status}
                enumeration={resource.enumeration}
                onToggleSolutions={
                  resource.has_solutions && onToggleSolutions
                    ? (value) =>
                        onToggleSolutions({
                          value,
                          resource,
                        })
                    : undefined
                }
                onMarkSession={
                  resource.is_completable && onMarkSession
                    ? (value) => {
                        onMarkSession({
                          value,
                          resource,
                        });
                      }
                    : undefined
                }
                isDone={resource.is_done}
                areSolutionsEnabled={resource.solutions_enabled}
                type={resource.type}
                videoInfo={resource.video_info}
                t={t}
                imgOptimizationUrl={imgOptimizationUrl}
              />
            </div>
          );
        })}
      </Slider>
    </ResourceSlider>
  );
}
