const token =
  '4f8f549e148777cee764c334b52c76f286933b98f3e644bbeae9f3301e884ea5';

export const getData = async (id: string, fields: string) => {
  const response = await fetch(
    `https://cdn.prepr.io/publications/${id}?fields=${fields}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    }
  );
  return response.json();
};
