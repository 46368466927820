import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useSessionDone } from './session-done-provider';

type Body = {
  username: string;
  name: string;
  lastname: string;
  language: string;
  org_uuid: string;
  org_name: string;
  session_name: string;
  session_id: string;
  course_order: number;
  session_valoration: number;
  comment?: string;
};

type MutationBody = {
  rating: number;
  comment?: string;
};

type Props = {
  sessionId: string;
  sessionName: string;
};

export function useReviewWebhook({
  sessionId,
  sessionName,
}: Props): UseMutationResult<any, Error, MutationBody, unknown> {
  const { email, locale, courseOrder, webhooksInfo } = useSessionDone();

  const {
    name,
    lastName,
    organizationId,
    organizationName,
    INTEGROMAT_REVIEW_WEBHOOK_URL,
  } = webhooksInfo;

  const mutation = useMutation<any, Error, MutationBody, unknown>({
    mutationFn: async (params) => {
      const body: Body = {
        username: email!,
        name: name,
        lastname: lastName,
        language: locale,
        org_uuid: organizationId,
        org_name: organizationName,
        session_name: sessionName,
        session_id: sessionId,
        course_order: courseOrder,
        session_valoration: params.rating,
        comment: params.comment,
      };

      const response = await fetch(INTEGROMAT_REVIEW_WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Failed to send review webhook');
      }

      return response.json();
    },
  });

  return mutation;
}
