import React from 'react';
import styles from './SubLabel.module.scss';

type Props = {
  children?: string
  className?: string
};

function SubLabel({ children, className }: Props) {
  return <span className={`${className} ${styles.subLabel}`}>{children}</span>;
}

export default SubLabel;
