import {
  CompleteAppletBody,
  useCompleteAppletMutation,
} from '@innovamat/glow-api-client';
import { useEffect } from 'react';

type CreateMetricsProps = {
  user: number;
  userToken: string;
  metric: string;
  duration: number;
  activityData: any;
  sessionType: string;
};

type Props = {
  onSuccess: () => void;
  onError: () => void;
};

const createMetricsObject = ({
  user,
  userToken,
  metric,
  duration,
  activityData,
  sessionType,
}: CreateMetricsProps): CompleteAppletBody => {
  const { ...metrics } = activityData;
  return {
    user,
    userToken,
    offlineSession: false,
    metric: {
      ...metrics,
      variation: activityData.variation?.toString(),
      metric,
      duration,
      duration_exceeded: duration > activityData.max_duration, // legacy
      version: '1.0.0', // legacy
      is_invalid: false, // legacy
      coins: 0, // legacy
      gems: 0, // legacy
      hasReachedMaxMultiplier: false, // legacy
      currentMultiplier: 0, // legacy
      activityAttemptData: null, // legacy
    },
    // opinion: null, // legacy
    sessionType, // legacy
  };
};

function useCompleteMetrics({ onSuccess, onError }: Props) {
  const { mutate, isError, isSuccess } = useCompleteAppletMutation();
  useEffect(() => {
    if (isSuccess) onSuccess();
    if (isError) onError();
  }, [isError, isSuccess]);
  return mutate;
}

export { useCompleteMetrics, createMetricsObject };
