import {
  ClassroomQuery,
  ClassroomsQuery,
  OrganizationQuery,
  StudentsQuery,
  useClassroomsQuery,
  useOrganizationQuery,
  useStudentsQuery,
} from '@innovamat/glow-api-client';
import { QueryClient } from '@tanstack/react-query';

type DeleteStudent = {
  queryClient: QueryClient;
  studentId: string;
  organizationId: string;
  classroomId: string;
};

function deleteStudentInCache({
  queryClient,
  studentId,
  organizationId,
  classroomId,
}: DeleteStudent): void {
  const key = useStudentsQuery.getKey({
    classroomId,
    organizationId,
  });

  const studentsQuery = queryClient.getQueryData<StudentsQuery>(key);

  if (studentsQuery) {
    const newData = studentsQuery.students?.filter(
      (student) => student?.id !== studentId
    );

    queryClient.setQueryData(key, {
      students: newData,
    });
  }

  const organizationKey = useOrganizationQuery.getKey({
    id: organizationId,
  });

  const organization =
    queryClient.getQueryData<OrganizationQuery>(organizationKey);

  const numberOfStudents = organization?.organization?.numberOfStudents;
  const numberOfDeletedStudents =
    organization?.organization?.numberOfDeletedStudents;

  if (organization?.organization) {
    queryClient.setQueryData<OrganizationQuery>(organizationKey, {
      organization: {
        ...organization.organization,
        numberOfStudents: numberOfStudents
          ? numberOfStudents - 1
          : numberOfStudents,
        numberOfDeletedStudents: numberOfDeletedStudents
          ? numberOfDeletedStudents + 1
          : numberOfDeletedStudents,
      },
    });
  }

  const classroomKey = useStudentsQuery.getKey({
    classroomId,
    organizationId,
  });

  const classroom = queryClient.getQueryData<ClassroomQuery>(classroomKey);

  if (classroom?.classroom) {
    const numberOfStudents = classroom.classroom?.numberOfStudents;

    queryClient.setQueryData<ClassroomQuery>(classroomKey, {
      classroom: {
        ...classroom.classroom,
        numberOfStudents: numberOfStudents
          ? numberOfStudents - 1
          : numberOfStudents,
      },
    });
  }

  const classroomsKey = useClassroomsQuery.getKey({
    mine: true,
    orgId: organizationId,
  });

  const classrooms = queryClient.getQueryData<ClassroomsQuery>(classroomsKey);

  if (classrooms) {
    const newData = classrooms.classrooms?.map((classroom) => {
      if (classroom?.id === classroomId) {
        return {
          ...classroom,
          numberOfStudents: classroom.numberOfStudents
            ? classroom.numberOfStudents - 1
            : classroom.numberOfStudents,
        };
      }

      return classroom;
    });

    queryClient.setQueryData(classroomsKey, {
      classrooms: newData,
    });
  }
}

export { deleteStudentInCache };
