import { useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { snack } from '@innovamat/glimmer-components';
import type { Teacher } from '@innovamat/glow-api-client';
import { useTeachersQuery } from '@innovamat/glow-api-client';
import { useCopyToClipboard } from '@innovamat/hooks';

import { useUser } from '../../../../../user-management';
import {
  ActionsDropdown,
  type CreateItem,
} from '../../../../components/common-table-components/actions-dropdown';
import { useResendInvitation } from '../../../../hooks/use-resend-invitation';

type ActionsCellProps = {
  classroomId: string;
  isPlatformEnabled: boolean;
  organizationId: string;
  teacher: Teacher;
  handleLeaveClass: (teacher: Teacher) => void;
};

const ActionsCell = ({
  classroomId,
  isPlatformEnabled,
  organizationId,
  teacher,
  handleLeaveClass,
}: ActionsCellProps): JSX.Element => {
  const { t } = useTranslation();

  const [_, copy] = useCopyToClipboard();
  const { isRole } = useUser();

  const queryClient = useQueryClient();
  const onSuccessAddTeachers = (): void => {
    const queryKey = useTeachersQuery.getKey({
      classroomId: classroomId ?? '',
      organizationId,
      isPlatformEnabled,
    });

    queryClient.refetchQueries({ queryKey });
  };

  const { isResendingInvitation, onResendInvitation } = useResendInvitation({
    onSuccessAddTeachers,
  });

  const isExistingTeacher =
    !teacher?.isSchoolInvitation && !teacher?.isInvitation;

  const items: CreateItem[] = useMemo(() => {
    if (isExistingTeacher) {
      return [
        {
          text: t('teachers.unlinkTeacher'),
          icon: 'LeaveClassIcon',
          state: 'active',
          onSelectItem: () => handleLeaveClass(teacher),
        },
      ];
    }

    return [
      {
        text: t('teachers.resendInvitation'),
        icon: 'MailIcon',
        state: isResendingInvitation ? 'disabled' : 'active',
        onSelectItem: () => onResendInvitation(teacher),
      },
    ];
  }, [
    handleLeaveClass,
    isExistingTeacher,
    isResendingInvitation,
    onResendInvitation,
    t,
    teacher,
  ]);

  const copyItem: CreateItem = {
    icon: 'CopyIcon',
    onSelectItem() {
      copy(teacher?.id || '');
      snack.success(t('manager.table.copiedId'));
    },
    state: 'active',
    text: t('manager.table.copyId'),
  };

  const allItems = isRole.Advisor ? [...items, copyItem] : items;

  return <ActionsDropdown dropdownId="class-options-button" items={allItems} />;
};

export { ActionsCell };
