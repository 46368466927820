import { es, enGB, enUS, eu, ca, it, fr, ptBR, sq, be } from 'date-fns/locale';
import i18next from 'i18next';

const languageMapper = () => {
  switch (i18next.language) {
    case 'es':
    case 'es_MX':
    case 'es_EC':
    case 'es_CO':
    case 'es_419':
      return es;
    case 'ca':
    case 'va':
      return ca;
    case 'en':
      return enGB;
    case 'eu':
      return eu;
    case 'it_IT':
      return it;
    case 'fr_FR':
      return fr;
    case 'pt_BR':
      return ptBR;
    case 'en_US':
      return enUS;
    case 'sq':
      return sq;
    case 'be':
        return be;
    default:
      return enGB;
  }
};

export default languageMapper;
