/* eslint-disable */
import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children, top, left, bottom }: any) => {
  const mount = document.getElementById('portal-root');
  const el = useMemo(() => document.createElement('div'), []);

  el.style.position = 'absolute';
  el.style.zIndex = '1000000';
  el.style.top = top;
  el.style.left = left;
  el.style.bottom = bottom;

  useEffect(() => {
    mount?.appendChild(el);
    return () => {
      mount?.removeChild(el);
    };
  }, []);

  return createPortal(children, el);
};

export default Portal;
