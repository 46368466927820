import { LottieAnimation } from '@innovamat/glimmer-components';
import React, { useEffect, useState } from 'react';

type Props = {
  width?: number;
  height?: number;
} & React.InputHTMLAttributes<HTMLInputElement>;

function ModalLoader({ width, height }: Props): JSX.Element {
  const [animationData, setAnimationData] = useState<any>(null);

  useEffect(() => {
    // Dynamically import the animation JSON from the given path
    import('./loading.json')
      .then((data) => {
        setAnimationData(data.default);
      })
      .catch((error) => console.error('Error loading animation data:', error));
  }, []);

  return (
    <LottieAnimation
      animationData={animationData}
      width={width}
      height={height}
      loop
      autoplay
    />
  );
}

export default ModalLoader;
