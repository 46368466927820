import Background1 from '../assets/secundariaBackgrounds/01.jpg';
import Background2 from '../assets/secundariaBackgrounds/02.jpg';
import Background3 from '../assets/secundariaBackgrounds/03.jpg';
import Background4 from '../assets/secundariaBackgrounds/04.jpg';
import Background5 from '../assets/secundariaBackgrounds/05.jpg';
import Background6 from '../assets/secundariaBackgrounds/06.jpg';
import Background7 from '../assets/secundariaBackgrounds/07.jpg';

import Background08 from '../assets/secundariaBackgrounds/08.jpg';
import Background09 from '../assets/secundariaBackgrounds/09.jpg';
import Background10 from '../assets/secundariaBackgrounds/10.jpg';
import Background11 from '../assets/secundariaBackgrounds/11.jpg';
import Background12 from '../assets/secundariaBackgrounds/12.jpg';

import Character01 from '../assets/secundariaCharacter/01.png';
import Character02 from '../assets/secundariaCharacter/02.png';
import Character03 from '../assets/secundariaCharacter/03.png';
import Character04 from '../assets/secundariaCharacter/04.png';
import Character05 from '../assets/secundariaCharacter/05.png';

import Character06 from '../assets/secundariaCharacter/06.png';
import Character07 from '../assets/secundariaCharacter/07.png';
import Character08 from '../assets/secundariaCharacter/08.png';
import Character09 from '../assets/secundariaCharacter/09.png';
import Character10 from '../assets/secundariaCharacter/10.png';

type ImgOptions = {
  [key: number]: any; // TODO type this acording to img type in next
};

const backgrounds: ImgOptions = {
  1: Background1,
  2: Background2,
  3: Background3,
  4: Background4,
  5: Background5,
  6: Background6,
  7: Background7,
};

const character: ImgOptions = {
  1: Character01,
  2: Character02,
  3: Character03,
  4: Character04,
  5: Character05,
};

const character3rdAnd4thSE: ImgOptions = {
  1: Character06,
  2: Character07,
  3: Character08,
  4: Character09,
  5: Character10,
};

const background3rdAnd4thSE: ImgOptions = {
  1: Background08,
  2: Background09,
  3: Background10,
  4: Background11,
  5: Background12,
};

export function getBackground(index: number) {
  return backgrounds[index];
}

export function getCharacter(index: number) {
  return character[index];
}

export function getCharacter3rdOr4thSE(index: number) {
  return character3rdAnd4thSE[index];
}

export function getBackground3rdOr4thSE(index: number) {
  return background3rdAnd4thSE[index];
}
