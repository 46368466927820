import { AvatarStage, Typography } from '@innovamat/glimmer-components';
import styled from 'styled-components';

const CourseWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 134px;
`;

type CourseElementProps = {
  text: string;
  courseName: string;
  courseOrder: number;
};

const CourseElement = ({
  text,
  courseName,
  courseOrder,
}: CourseElementProps): JSX.Element => {
  return (
    <CourseWrapper>
      <AvatarStage size="S" courseName={courseName} courseOrder={courseOrder} />
      <Typography.Subtitle2>{text}</Typography.Subtitle2>
    </CourseWrapper>
  );
};

export default CourseElement;
