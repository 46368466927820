import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { snack } from '@innovamat/glimmer-components';
import { useCopyToClipboard } from '@innovamat/hooks';

import { useUser } from '../../../../../user-management';
import { useClassrooms } from '../../../../hooks/use-classrooms';
import { useSchoolStudents } from '../../providers/school-students-provider';

import type { SchoolStudent } from '../../types/student';

import {
  ActionsDropdown,
  type CreateItem,
} from '../../../../components/common-table-components/actions-dropdown';

type Props = {
  student: SchoolStudent;
};

function SchoolStudentsTableActionsCell({ student }: Props): JSX.Element {
  const {
    onRecoverStudent,
    isDeletedStudentsPage,
    onChangeClassroom,
    onDeleteStudent,
  } = useSchoolStudents();
  const { t } = useTranslation();
  const { isGoogleClassroom } = useClassrooms({ mine: false });

  const [_, copy] = useCopyToClipboard();
  const { isRole } = useUser();
  const isFromGoogle = isGoogleClassroom(student.classroomId);

  const items: CreateItem[] = useMemo(() => {
    if (isDeletedStudentsPage) {
      return [
        {
          text: t('students.table.recover'),
          icon: 'JoinClassIcon',
          state: 'active',
          onSelectItem: () => onRecoverStudent(student),
        } as CreateItem,
      ];
    }

    return [
      {
        text: t('students.button.moveClassroom'),
        icon: 'MoveIcon',
        state: isFromGoogle ? 'disabled' : 'active',
        onSelectItem: () => onChangeClassroom(student),
      },
      {
        text: t('students.button.removeStudent'),
        icon: 'DeleteIcon',
        state: isFromGoogle ? 'disabled' : 'active',
        onSelectItem: () => onDeleteStudent(student),
      },
    ];
  }, [
    isDeletedStudentsPage,
    isFromGoogle,
    onChangeClassroom,
    onDeleteStudent,
    onRecoverStudent,
    student,
    t,
  ]);

  const copyItem: CreateItem = {
    icon: 'CopyIcon',
    onSelectItem() {
      copy(student?.id || '');
      snack.success(t('manager.table.copiedId', '¡Copiado!'));
    },
    state: 'active',
    text: t('manager.table.copyId'),
  };

  const allItems = isRole.Advisor ? [...items, copyItem] : items;

  return (
    <ActionsDropdown dropdownId="school-students-button" items={allItems} />
  );
}

export { SchoolStudentsTableActionsCell };
