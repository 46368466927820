import { useUser } from '@innovamat/ga-features';
import React, { createContext, useContext, useEffect, useState } from 'react';

declare const prepr: any;

type PreprTrackingProps = {
  children: React.ReactNode;
};

const PreprTrackingContext = createContext<{
  setSendViewEventId: (sessionId: string) => void;
} | null>(null);

export const usePreprTracking = () => {
  const context = useContext(PreprTrackingContext);
  if (!context) {
    throw new Error(
      'usePreprTracking must be used within a PreprTrackingProvider'
    );
  }
  return context;
};

export const PreprTrackingProvider: React.FC<PreprTrackingProps> = ({
  children,
}) => {
  const { user, isRole } = useUser();
  const region = user?.region;
  const userId = user?.id;

  const [isCustomerIdSet, setIsCustomerIdSet] = useState(false);
  const [isRegionTagSent, setIsRegionTagSent] = useState(false);
  const [sendViewEventId, setSendViewEventId] = useState('');

  const sendRegionTagEvent = () => {
    if (!region || isRegionTagSent) return;
    if (isRole.Teacher || isRole.Admin || isRole.Owner) {
      if (prepr) {
        prepr('event', 'Tag', [`region:${region}`]);
      }
      setIsRegionTagSent(true);
    }
  };

  const sendViewEvent = (sessionId: string) => {
    if (!isCustomerIdSet) return;
    if (isRole.Teacher || isRole.Admin || isRole.Owner) {
      if (prepr) {
        prepr('event', 'View', { sessionId });
      }
    }
  };

  const setCustomerId = () => {
    if (!userId) return;
    if (isRole.Teacher || isRole.Admin || isRole.Owner) {
      const metaTag = document.getElementById(
        'prepr:customer-id'
      ) as HTMLMetaElement;

      if (metaTag && userId) {
        metaTag.content = userId;
        setIsCustomerIdSet(true);
      }
    }
  };

  useEffect(() => {
    setCustomerId();
  }, [userId]);

  useEffect(() => {
    sendRegionTagEvent();
  }, [region, userId]);

  useEffect(() => {
    if (sendViewEventId) {
      sendViewEvent(sendViewEventId);
    }
  }, [sendViewEventId]);

  const value = {
    setSendViewEventId,
  };

  return (
    <PreprTrackingContext.Provider value={value}>
      {children}
    </PreprTrackingContext.Provider>
  );
};
