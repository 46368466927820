import { PreprModel } from '../types/SmartGuide';

const restEndpoint = 'https://cdn.prepr.io/publications/';
const restToken =
  '4f8f549e148777cee764c334b52c76f286933b98f3e644bbeae9f3301e884ea5';

export const getModel = async (id: string): Promise<PreprModel> => {
  const response = await fetch(`${restEndpoint}${id}?fields=model`, {
    headers: {
      Authorization: `Bearer ${restToken}`,
    },
  });
  const data = await response.json();
  return data.model.body;
};
