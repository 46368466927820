import { Pdf } from '../types/pdf';
import { ResourceType } from '../types/resourceType';
import checkMissingLocale from '../utils/missingLocales';
import { mapPrintable } from './contentsMapper';
import { Params } from './resourceMapper';
import { getPages } from './sessionMapper';

export const pdfMapper = ({
  response,
  isBloqued = false,
  locale,
  isStudent = false,
}: Params): Pdf => {
  const isNotSession = false;
  const printables = mapPrintable(response, isStudent, isNotSession);

  return {
    id: response.uuid,
    isBloqued,
    language: locale,
    type: response.type as ResourceType,
    description: checkMissingLocale(response.description!),
    title: checkMissingLocale(response.name!),
    printables,
    path: response.file?.href!,
    fileId: response.file?.uuid!,
    fileName: response.file?.original_file_name!,
    pages: getPages(response, isStudent),
    tags: response.tags,
  };
};
