import { GridCol } from '@innovamat/glimmer-components';

import Card from '../Card/Card';
import { EmptyPinnedList } from '../EmptyPinnedList/EmptyPinnedList';
import { GridContainerWrapper, GridRowWrapper } from '../Spaces.styles';
import { Space } from '../Spaces.types';
import SpacesPlaceholder from '../SpacesPlaceholder/SpacesPlaceholder';

type SpacesGridParams = {
  spaces: Space[];
  numLoaders: number;
  isLoading: boolean;
  isEmpty: boolean;
};

export default function SpacesGrid({
  spaces,
  isLoading,
  numLoaders,
  isEmpty,
}: SpacesGridParams) {
  if (isLoading) return <SpacesPlaceholder numLoaders={numLoaders} />;

  if (isEmpty) return <EmptyPinnedList />;

  return (
    <GridContainerWrapper>
      <GridRowWrapper>
        {spaces.map((space) => (
          <GridCol key={space.id} sm={6} md={4} lg={4} xl={3}>
            <Card space={space} />
          </GridCol>
        ))}
      </GridRowWrapper>
    </GridContainerWrapper>
  );
}
