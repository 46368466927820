import { DIMENSIONS } from '../templates/EarlyYear/EarlyYear.mappers';
import { Tag } from '../../components/Tag';
import { Tooltip } from '@innovamat/glimmer-components';
import { useDigitalGuides } from '../context';

type Props = {
  type: string | undefined;
  body: string | undefined;
  isDisabled?: boolean;
  tooltip?: string;
  hasTooltip?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export default function DimensionBadge({
  type = '',
  body,
  isDisabled,
  tooltip,
  ...rest
}: Props) {
  const { t } = useDigitalGuides();
  const dimensionText = DIMENSIONS[body as keyof typeof DIMENSIONS];

  return (
    <Tooltip content={tooltip}>
      <Tag isEnabled={!isDisabled} text={t(dimensionText)} dimension={+body!} />
    </Tooltip>
  );
}
