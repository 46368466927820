import type { StudentValidation } from '../types';

function hasRowOverwritingMultipleIds(
  rowToIds: Map<number, Set<string>>
): boolean {
  return Array.from(rowToIds.values()).some((idsSet) => idsSet.size > 1);
}

function hasIdOverwrittenByMultipleRows(
  idToRows: Map<string, Set<number>>
): boolean {
  return Array.from(idToRows.values()).some((rowsSet) => rowsSet.size > 1);
}

function useCheckStudentsValidationsHaveConflict(
  validations: Array<StudentValidation | null | undefined>
): boolean {
  const rowToIds = new Map<number, Set<string>>();
  const idToRows = new Map<string, Set<number>>();

  for (const validation of validations) {
    if (!validation) continue;

    const { rowNumber, overwrites } = validation;
    const id = overwrites?.id;
    if (!id) continue;

    let idsSet = rowToIds.get(rowNumber);
    if (!idsSet) {
      idsSet = new Set<string>();
      rowToIds.set(rowNumber, idsSet);
    }
    idsSet.add(id);

    let rowsSet = idToRows.get(id);
    if (!rowsSet) {
      rowsSet = new Set<number>();
      idToRows.set(id, rowsSet);
    }
    rowsSet.add(rowNumber);
  }

  return (
    hasRowOverwritingMultipleIds(rowToIds) ||
    hasIdOverwrittenByMultipleRows(idToRows)
  );
}

export { useCheckStudentsValidationsHaveConflict };
