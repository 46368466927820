import {
  OrganizationQuery,
  TeachersQuery,
  useOrganizationQuery,
  useTeachersQuery,
} from '@innovamat/glow-api-client';
import { QueryClient } from '@tanstack/react-query';

type DeleteTeacher = {
  queryClient: QueryClient;
  teacherId: string;
  organizationId: string;
  isInvitation: boolean;
  isPlatformEnabled: boolean;
};

function deleteTeacherInCache({
  queryClient,
  teacherId,
  organizationId,
  isInvitation,
  isPlatformEnabled,
}: DeleteTeacher): void {
  const key = useTeachersQuery.getKey({
    organizationId,
    isPlatformEnabled,
  });

  const data = queryClient.getQueryData<TeachersQuery>(key);

  if (data) {
    const newData = data.teachers?.filter(
      (_teacher) => _teacher?.id !== teacherId
    );

    queryClient.setQueryData(key, {
      teachers: newData,
    });
  }

  if (!isInvitation) {
    const organizationKey = useOrganizationQuery.getKey({
      id: organizationId,
    });

    const organization =
      queryClient.getQueryData<OrganizationQuery>(organizationKey);

    if (organization?.organization?.numberOfTeachers) {
      queryClient.setQueryData<OrganizationQuery>(organizationKey, {
        organization: {
          ...organization.organization,
          numberOfTeachers: organization.organization?.numberOfTeachers - 1,
        },
      });
    }
  }
}

export { deleteTeacherInCache };
