import { UseQueryResult, useQuery } from '@tanstack/react-query';
import request from 'graphql-request';
import { useEnvVariables } from '../../env-variables-manager';
import {
  BannerData,
  BannersFilter,
  BannersResponse,
  FormatBanner,
} from '../types';
import { GET_BANNERS_DOCUMENT } from './document';

type Props = {
  filters: BannersFilter | undefined;
  hasDemo: boolean;
};

function useGetBanners({
  filters,
  hasDemo,
}: Props): UseQueryResult<FormatBanner[], Error> {
  const { PREPR_GRAPHQL } = useEnvVariables();

  const query = useQuery<FormatBanner[], Error>({
    queryKey: ['banners', filters?.course, filters?.locale],
    queryFn: async () => {
      if (!filters) return [];

      const { locale, course, region, roles } = filters;
      const hasIntervention = filters.permissions?.intervention;
      const hasAssessment = filters.permissions?.assessment;

      const variables = {
        where: {
          active: true,
        },
        locale,
      };

      if (course) {
        variables.where[`course_${course}`] = true;
      }

      if (region) {
        variables.where[region.toLocaleLowerCase().replace('-', '_')] = true;
      }

      const banners: BannersResponse = await request(
        PREPR_GRAPHQL,
        GET_BANNERS_DOCUMENT,
        variables
      );

      const filterByRole = (banner: BannerData): boolean => {
        const bannerRoles = banner.roles.map((role) => role.roles);

        return !!roles?.some((role) =>
          bannerRoles.includes(role.toLocaleLowerCase())
        );
      };

      const filterByPermission = (banner: BannerData): boolean => {
        if (
          !banner.demo_users &&
          !banner.only_for_intervention &&
          !banner.only_for_assessment
        )
          return true;

        const demoFilter = banner.demo_users ? hasDemo : true;
        const interventionFilter = banner.only_for_intervention
          ? hasIntervention
          : true;
        const assessmentFilter = banner.only_for_assessment
          ? hasAssessment
          : true;
        return Boolean(demoFilter && interventionFilter && assessmentFilter);
      };

      const filteredBanners: FormatBanner[] =
        banners?.CM20Banners?.items
          ?.filter(filterByRole)
          .filter(filterByPermission)
          .map((banner) => {
            return {
              id: banner._id,
              colour: banner.colour,
              cta: Boolean(banner.cta),
              ctaLink: banner.cta_link,
              ctaText: banner.cta_text,
              icon: {
                height: banner.icon?.height!,
                name: banner.icon?.name!,
                url: banner.icon?.url!,
                width: banner.icon?.width!,
              },
              importance: +banner.importance,
              text: banner.text,
              title: banner.title,
              ctaExternal: banner.cta_external,
              canBeClosed: banner.can_be_closed,
              inlineTopicId: banner.inline_topic_id,
              visibleAt: banner.visible_at,
            } satisfies FormatBanner;
          }) || [];

      return filteredBanners;
    },

    enabled: Boolean(filters && filters.locale),
  });

  return query;
}

export { useGetBanners };
