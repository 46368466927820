import { useEffect, useState } from 'react';
import { useOnboardingForm } from '../context/onboarding-form-context';
import styled from '@emotion/styled';
import { Typography } from '@innovamat/glimmer-components';
import { ONBOARDING_STEP } from '../onboarding-form.types';
import { useTranslation } from 'react-i18next';
import { LoadingManagerAnimation } from '../components/loading-manager-animation';

export function LoadingManagerStep(): JSX.Element {
  const { t } = useTranslation();
  const { onClose, currentStep } = useOnboardingForm();
  const [isClosing, setIsClosing] = useState(false);

  const FAKE_LOADING_TIME = 5000;

  const Container = styled.div`
    margin-top: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    height: 100%;
  `;

  const AnimationWrapper = styled(Typography.Subtitle1)`
    height: 96px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
  `;

  useEffect(() => {
    if (currentStep === ONBOARDING_STEP.LoadingManager) {
      const timeout = setTimeout(() => {
        onClose();
        setIsClosing(true);
      }, FAKE_LOADING_TIME);

      return () => {
        clearTimeout(timeout);
      };
    }
    return;
  }, [currentStep, onClose]);

  return (
    <Container>
      <AnimationWrapper>
        {!isClosing && <LoadingManagerAnimation duration={FAKE_LOADING_TIME} />}
      </AnimationWrapper>
      <Typography.Subtitle1>
        {t('onboarding-form.loading-manager')}
      </Typography.Subtitle1>
    </Container>
  );
}
