import React from 'react';
import cx from 'classnames';
import styles from './Avatar.module.scss';
import { getInitialsFromName } from '../../utils/common';
import Icon from '../Icon';
import IconItem from '../../types/icons';
import DefaultAvatar from '../../assets/svg/defaultAvatar.svg';
import { Icons } from '../..';

const defaultSize = 40;

type Props = {
  alt?: string;
  badge?: keyof typeof Icons;
  border?: 'Info';
  icon?: IconItem;
  iconClassname?: string;
  iconContainerClassname?: string;
  iconOnHover?: boolean;
  img?: string;
  name?: string;
  selectable?: boolean;
  selected?: boolean;
  shadow?: boolean;
  size?: number;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

function Avatar({
  alt,
  badge,
  border,
  className,
  disabled = false,
  icon,
  iconClassname,
  iconContainerClassname,
  iconOnHover,
  img,
  name = '',
  selectable,
  selected,
  shadow = false,
  size = defaultSize,
  ...rest
}: Props) {
  const clearName = name.trim();
  const initials = getInitialsFromName(clearName);
  const isEmpty = clearName === '' && !img;
  const bgImage = isEmpty ? DefaultAvatar : img;

  const avatarClass = cx(styles.avatar, {
    [styles.shadow]: shadow,
    [styles.withIcon]: !!icon,
    [className as string]: !!className,
    [styles.bg]: !bgImage,
    [styles.selected]: selected,
    [styles.selectable]: selectable,
    [styles.clickable]: !!rest.onClick,
  });

  const iconClass = cx(styles.avatarIcon, {
    [iconClassname as string]: !!iconClassname,
    [styles.iconOnHover]: iconOnHover,
  });

  const iconContainerClass = cx({
    [iconContainerClassname as string]: !!iconContainerClassname,
    [styles.iconContainerOnHover]: iconOnHover,
  });

  const imgClass = cx({
    [styles.imgOnHover]: iconOnHover,
    [styles.imgDisabled]: disabled,
  });

  const buttonSize = border ? size - 6 : size;

  const borderColor = border && `${styles.border} ${styles['border' + border]}`;

  const borderClass = cx(styles.border, {
    [borderColor as string]: borderColor,
  });

  const badgePosition = border && styles.badgeWithBorder;

  const badgeClass = cx(styles.badge, {
    [badgePosition as string]: badgePosition,
  });

  return (
    <div
      className={border && borderClass}
      style={{ height: size, width: size }}
    >
      <button
        {...rest}
        className={avatarClass}
        disabled={disabled}
        style={{ height: buttonSize, width: buttonSize, fontSize: size * 0.4 }}
      >
        {bgImage && (!icon || iconOnHover) && (
          <img
            alt={isEmpty ? 'default avatar' : alt}
            src={bgImage}
            className={imgClass}
          />
        )}
        {icon && (
          <Icon
            className={iconClass}
            containerClassName={iconContainerClass}
            icon={icon}
          />
        )}

        {!img && !icon && initials}

        {badge && (
          <span className={badgeClass}>
            <Icon icon={badge} size={size > defaultSize ? 'md' : 'sm'} />
          </span>
        )}
      </button>
    </div>
  );
}

export default Avatar;
