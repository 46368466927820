import { ClassroomBody, Classroom } from '@innovamat/glow-api-client';
import { QueryClient } from '@tanstack/react-query';

type Props = {
  queryClient: QueryClient;
  orgId: string;
  classroom: ClassroomBody;
  mine?: boolean;
};

type ClassroomsQueryData = { classrooms: Classroom[] };

function addClassroomToCache({
  queryClient,
  orgId,
  classroom,
  mine = true,
}: Props): void {
  const mineVariables = ['Classrooms', { mine: true, orgId }];
  const notMineVariables = ['Classrooms', { mine: false, orgId }];

  const currentMineClassrooms =
    queryClient.getQueryData<ClassroomsQueryData>(mineVariables)?.classrooms;

  const currentNotMineClassrooms =
    queryClient.getQueryData<ClassroomsQueryData>(notMineVariables)?.classrooms;

  if (currentMineClassrooms) {
    queryClient.setQueryData(mineVariables, {
      classrooms: [...currentMineClassrooms, classroom],
    });
  }

  if (currentNotMineClassrooms) {
    queryClient.setQueryData(notMineVariables, {
      classrooms: [...currentNotMineClassrooms, classroom],
    });
  }
}

export { addClassroomToCache };
