import { SemanticType } from '@innovamat/glimmer-components';

const statusToSemantic = (status: string) => {
  const statusToSemanticMap: Record<string, SemanticType | undefined> = {
    queued: undefined,
    processing: 'info',
    success: 'success',
    failed: 'error',
  };

  return statusToSemanticMap[status as keyof typeof statusToSemanticMap];
};

export { statusToSemantic };
