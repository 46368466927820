import { getAvatar } from '@innovamat/glimmer-assets';
import { TeachersQuery } from '@innovamat/glow-api-client';
import { Roles, hasOwnerRole } from '@innovamat/radiance-utils';

type AvatarProps = {
  img?: string | null;
  name?: string | null;
};

const mapAvatar = (teacher: Teacher | null) =>
  teacher?.classrooms?.map((c) => ({
    img: c?.icon ? getAvatar(c?.icon, c.courseOrder!) : '',
    name: c?.name,
  }));

type Teacher = NonNullable<TeachersQuery['teachers']>[number];

const sortByOwner = (aRow: Teacher, bRow: Teacher) => {
  const a = hasOwnerRole(aRow?.roles as Roles[]);
  const b = hasOwnerRole(bRow?.roles as Roles[]);
  return a === b ? 0 : a ? -1 : 1;
};

export { mapAvatar, sortByOwner };
export type { AvatarProps };
