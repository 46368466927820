import { Trans, useTranslation } from 'react-i18next';
import { ErrorDetail } from './types';
import {
  isOnlyStudentJrError,
  isStudentAlreadyExistsError,
} from './validations';

type ConfirmationOption = {
  title: string;
  message: string | JSX.Element;
  cancelText: string;
  confirmText: string;
};

type CreateConfirmationOptions = {
  onGetConfirmationOptions: (error: ErrorDetail) => ConfirmationOption;
};

function useCreateConfirmationOptions(): CreateConfirmationOptions {
  const { t } = useTranslation();

  const STUDENT_ALREADY_EXISTS: ConfirmationOption = {
    title: t('students.existUserModal.title'),
    message: t('parents.existStudentToParent.message'),
    cancelText: t('confirmModal.action.cancel'),
    confirmText: t('confirmModal.action.assign'),
  };

  const STUDENT_JR_ALREADY_EXISTS: ConfirmationOption = {
    title: t('students.existUserModal.title'),
    message: (
      <>
        {/* <ExistUserModalMessage> TODO */}
        <Trans
          i18nKey="students.existUserIndividualModal.message"
          components={{ b: <b /> }}
        />
        {/* </ExistUserModalMessage> */}
        {/* <ExistUserModalQuestion> */}
        {t('students.existUserModal.question')}
        {/* </ExistUserModalQuestion> */}
      </>
    ),
    cancelText: t('confirmModal.action.cancel'),
    confirmText: t('confirmModal.existUserModal.continue'),
  };

  const handleGetConfirmationOptions = (error: ErrorDetail) => {
    const errorTypesObjects = error.extensions?.['types'];

    const errorTypes = errorTypesObjects?.map(
      (typeObj: { type: string; email: string }) => typeObj.type
    );

    if (isOnlyStudentJrError(errorTypes)) {
      return STUDENT_JR_ALREADY_EXISTS;
    } else if (isStudentAlreadyExistsError(errorTypes)) {
      return STUDENT_ALREADY_EXISTS;
    } else {
      return STUDENT_JR_ALREADY_EXISTS;
    }
  };

  return { onGetConfirmationOptions: handleGetConfirmationOptions };
}

export { useCreateConfirmationOptions };
