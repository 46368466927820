import { useMemo } from 'react';
import { sortBy } from '@innovamat/radiance-utils';

import {
  useOrganizationQuery,
  useEducationalPlansQuery,
} from '@innovamat/glow-api-client';

type Props = {
  filterOrderCourse?: number | undefined | null;
  organizationId: string;
};

type Option = {
  value: string;
  label: string;
};

function useEducationalPlans({ filterOrderCourse, organizationId }: Props) {
  const { data: { organization } = {} } = useOrganizationQuery(
    { id: organizationId },
    { staleTime: Infinity }
  );

  const { data, isLoading } = useEducationalPlansQuery(
    {
      regionCode: organization?.operativeRegion!,
      courseOrder: filterOrderCourse || null,
    },
    {
      enabled: !!organization,
    }
  );

  const educationPlans = useMemo(
    () => data?.educationalPlans?.concat().sort(sortBy('courseOrder')) || [],
    [data]
  );

  const educationOptions: Option[] = useMemo(() => {
    // Workaround (chapuza) to filter only 1º and 2º in Brasil
    // TO DO: Backend should return only 1º and 2º if EY doesn't exist
    const filteredPlans =
      organization?.operativeRegion === 'BR'
        ? educationPlans.filter((plan) => plan?.courseOrder! > 5)
        : educationPlans;

    return filteredPlans.map((plan) => ({
      label: plan?.educationalPlanName!,
      value: plan?.id!,
    }));
  }, [educationPlans, organization]);

  const getEducationalPlanByCourse = (courseId: string) => {
    return (
      educationPlans.find((ed) => ed?.courseId === courseId) ||
      educationPlans[0]
    );
  };

  return {
    educationOptions,
    educationPlans,
    loading: isLoading,
    getEducationalPlanByCourse,
  };
}

export { useEducationalPlans };
