import { useEnvVariables } from './../../env-variables-manager/env-variables-provider';
import { useUser } from '../../user-management';
import { usePlatformApiUrlQuery } from '@innovamat/glow-api-client';

export function usePlatformUrl(): {
  platformUrl: string;
} {
  const { user } = useUser();
  const { API_PLATFORM } = useEnvVariables();

  const query = usePlatformApiUrlQuery(
    {},
    {
      enabled: Boolean(user?.id),
      staleTime: Infinity,
    }
  );

  return {
    platformUrl: query.data?.platformApiUrl || API_PLATFORM,
  };
}
