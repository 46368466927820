import { useCallback, useEffect, useState } from 'react';

/*

  This hook allow us to implement an infinite scroll with lazy load of elements
  The props needed are:
    handleLoadMore: function which is called when the bottom of the scroll is reached and will manage the fetch of new data.
                    this is also called on the first render to fetch the first set of data. It has as a prop the number of
                    next pagination to fetch data.
    startPage:      first element of pagination that will be sent to the handleLoadMore function
    loadMore:       boolean to specify when the infinite scroll is over and stop fetching more data

  This hook returns the handleRef function, necessary to make it work. This handleRef must be assigned to the ref of the element
  that will be placed as bottom of the scroll (ex: an empty div)

  The management of loading state and the data fetch must be made in the component where this hook is called.
  
*/

type Props = {
  handleLoadMore: (nextPage: number) => void;
  startPage: number;
  loadMore: boolean;
};

const delay = 1000;

export function useLazyLoad({ handleLoadMore, startPage, loadMore }: Props) {
  const [nextPage, setNextPage] = useState(startPage);
  const [endRef, setEndRef] = useState<HTMLDivElement>();

  const handleRef = useCallback((ref: HTMLDivElement) => {
    setEndRef(ref);
  }, []);

  const onLoadMore = () => {
    handleLoadMore(nextPage);
    setNextPage((prevPage) => prevPage + 1);
  }

  const handleObserver = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting && loadMore) {
      setTimeout(onLoadMore, delay);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);

    if (endRef) {
      observer.observe(endRef);
    }

    return () => {
      if (endRef) observer.unobserve(endRef);
    };
  }, [endRef, nextPage, loadMore]);

  useEffect(() => {
    onLoadMore();
  }, []);

  return { handleRef };
}
