import { Modal } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

type Props = {
  showModal: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

function ConfirmValidationModal({
  showModal,
  onClose,
  onConfirm,
}: Props): JSX.Element | null {
  const { t } = useTranslation();

  if (!showModal) return null;

  return (
    <Modal
      modalWidth={700}
      semantic={{
        type: 'warning',
        text: t('common.modal.important').toUpperCase(),
      }}
      onClose={onClose}
      isOpen={showModal}
      title={t(
        'students.import.modal.title',
        'Los cambios afectarán a alumnos existentes'
      )}
      buttons={[
        {
          children: t('common.yes-overwrite'),
          variant: 'tertiary',
          onClick: onConfirm,
        },
        {
          children: t('common.goback-and-review'),
          variant: 'accent',
          onClick: onClose,
        },
      ]}
    >
      <>{t('students.import.modal.confirmation-text')}</>
    </Modal>
  );
}

export { ConfirmValidationModal };
