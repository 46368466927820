const sortPerCourseOrder = (data: any): any => {
  const clonedData = JSON.parse(JSON.stringify(data));
  clonedData.sort((a: any, b: any) => {
    if (a.course.courseOrder - b.course.courseOrder === 0) {
      return a.classroom > b.classroom ? 1 : -1;
    }
    return a.course.courseOrder - b.course.courseOrder;
  });
  return clonedData;
};

export const getRowSpan = (data: any): any => {
  const dataSorted = sortPerCourseOrder(data);
  const groupedData = {};

  dataSorted.forEach((item: any) => {
    const courseOrder = item.course.courseOrder;
    if (!groupedData[courseOrder]) {
      groupedData[courseOrder] = [];
    }
    groupedData[courseOrder].push(item);
  });

  for (const courseOrder in groupedData) {
    const group = groupedData[courseOrder];
    const rowCount = group.length;

    group.forEach((item: any, index: number) => {
      if (index === 0) {
        item.course.rowSpan = rowCount;
      } else {
        item.course.rowSpan = 0;
      }
    });
  }

  return dataSorted;
};
