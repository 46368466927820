import { useUser } from '@innovamat/ga-features';
import { storage } from '@innovamat/radiance-utils';
import { useEffect } from 'react';
import i18n from '../../config/i18n';

export function LocaleManager(): JSX.Element | null {
  const { user } = useUser();
  const locale = user?.locale;

  useEffect(() => {
    if (user?.locale) {
      i18n.changeLanguage(user.locale);
      storage.language.set(user.locale);
    }
  }, [user?.locale]);

  return null;
}
