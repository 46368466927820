import { Trans, useTranslation } from 'react-i18next';
import {
  Column,
  TableComponent,
  Typography,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import NotesOfTheWeekTitle from './notes-of-the-week.title';
import NotesOfTheWeekSubtitle from './notes-of-the-week.subtitle';
import {
  ObservationsNotes,
  StudentsWithDifficulties,
} from '../../views/weekly-results/weekly-results.types';
import { CoreActivitiesByBubble } from '@innovamat/glow-api-client';
import { useEffect, useMemo } from 'react';
import { getAppletReadableName } from '../../utils/common';
import LessonsPanel from '../lessons-panel';
import styled from '@emotion/styled';
import {
  renderAppletsWorkedOn,
  renderCompletedTotal,
  renderScore,
  renderTime,
} from '../../utils/render-rows';
import { TimeSchedule } from '../../views/weekly-results/weekly-results';

type Props = {
  observationsNotes: ObservationsNotes[];
  disconnectedStudents: string[];
  studentsWithDifficulties: StudentsWithDifficulties[];
  coreActivities?: CoreActivitiesByBubble[];
  onClose: () => void;
  lessonsDone?: string[];
  isSecundaria: boolean;
  isEarlyYears: boolean;
  averageRow: any;
  totalStudents: number;
  connectedStudents: number;
  practicePeriod: TimeSchedule;
};

function NotesOfTheWeek({
  observationsNotes,
  disconnectedStudents,
  studentsWithDifficulties,
  coreActivities,
  onClose,
  lessonsDone,
  isSecundaria,
  isEarlyYears,
  averageRow,
  totalStudents,
  connectedStudents,
  practicePeriod,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const baseKey = 'reports.notesOfTheWeek.observations.contentDescription';

  const theme = useGlimmerTheme();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  const getObservationNotesDescription = (percentage: number): string => {
    if (percentage > 50) return t(`${baseKey}.moreThanHalfStudents`);
    if (percentage > 25) return t(`${baseKey}.almostHalfStudents`);
    return t(`${baseKey}.someStudents`);
  };

  const getObservationsNotes = (): JSX.Element[] => {
    return observationsNotes.map((observation) => {
      const { subtitle, percentage } = observation;
      const description = getObservationNotesDescription(percentage);
      return (
        <NotesOfTheWeekSubtitle subtitle={subtitle} description={description} />
      );
    });
  };

  const getStudentsWithDifficulties = (): JSX.Element[] => {
    return studentsWithDifficulties.map((content) => {
      const { subtitle, students, id } = content;
      const coreActivity = coreActivities?.find(
        (coreActivity) => coreActivity.readableName === id
      );
      const scenes = coreActivity?.coreActivities?.map((c) =>
        getAppletReadableName(c?.data?.name, c?.sceneName!, c?.pack!)
      );
      return (
        <NotesOfTheWeekSubtitle
          subtitle={subtitle}
          students={students}
          scenes={scenes}
          isStudentsWithDifficulties
        />
      );
    });
  };

  const getDisconnectedStudents = (): JSX.Element => {
    return <NotesOfTheWeekSubtitle students={disconnectedStudents} />;
  };

  const showObservationsNotes = observationsNotes.length > 0;
  const showDisconnectedStudents = disconnectedStudents.length > 0;
  const showStudentsWithDifficulties = studentsWithDifficulties.some(
    (s) => s.students.length > 0
  );

  const validLessons = (Array.isArray(lessonsDone) ? lessonsDone : []).filter(
    (lesson) => lesson.trim() !== ''
  );

  const titleLessonsPanel = useMemo(() => {
    if (validLessons.length > 1) {
      return t('reports.weeklyNotes.sessionsDone', {
        num: validLessons.length,
      });
    }

    if (validLessons.length === 1) {
      return t('reports.weeklyNotes.oneSessionDone', { num: 1 });
    }

    return t('reports.weeklyNotes.noSessionsDone');
  }, [validLessons]);

  const noSessionsDoneReminder =
    validLessons.length <= 1
      ? t('reports.weeklyNotes.noSessionsDone.reminder')
      : '';

  const showLessonsPanel = !isEarlyYears && lessonsDone;
  const stage = isSecundaria ? 'secondary' : 'primary';
  const digitalPracticeColumns: Column<any>[] = [
    {
      id: 'connectedStudents',
      label: t('reports.notesOfTheWeek.table.connectedStudents'),
      width: 100,
      align: 'left',
      render: () => renderCompletedTotal(totalStudents, connectedStudents),
    },
    {
      id: 'separator-1',
      width: 9,
      separator: true,
    },
    {
      id: 'statementsCompleted',
      label: t(`reports.notesOfTheWeek.table.primary.statementsCompleted`),
      width: 100,
      align: 'left',
      render: (value, row) => renderAppletsWorkedOn(value, row),
    },
    {
      id: 'timeSpent',
      label: t('reports.notesOfTheWeek.table.averageTime'),
      width: 100,
      align: 'left',
      render: (_, row) =>
        renderTime(row, 'school', 'digitalPractice', isSecundaria),
    },
    {
      id: 'separator-2',
      width: 9,
      separator: true,
    },
    {
      id: 'averageScore',
      label: t('reports.notesOfTheWeek.table.averageScore'),
      width: 100,
      align: 'left',
      render: (value) => renderScore(value),
    },
  ];

  const getDigitalPracticeTitle = (): string => {
    const titleDigitalPractice = t(
      'reports.notesOfTheWeek.title.digitalPractice'
    );
    const titleSchoolTime = t('reports.toogle.button.schoolTime');
    const titleOutSchoolTime = isSecundaria
      ? t('reports.secondary.filter.button.outSchoolTime')
      : t('reports.primary.filter.button.outSchoolTime');

    switch (practicePeriod) {
      case 'school':
        return `${titleDigitalPractice} - ${titleSchoolTime}`;
      case 'home':
        return `${titleDigitalPractice} - ${titleOutSchoolTime}`;
      default:
        return titleDigitalPractice;
    }
  };

  return (
    <>
      {showLessonsPanel && (
        <LessonsPanel
          title={
            <Trans i18nKey={titleLessonsPanel} components={{ b: <strong /> }} />
          }
          reminderText={noSessionsDoneReminder}
          type={lessonsDone!.length > 1 ? 'success' : 'warning'}
          lessonsDone={validLessons}
        />
      )}
      <div>
        <NotesOfTheWeekTitle title={getDigitalPracticeTitle()} />
        <TableContainer>
          <TableComponent
            columns={digitalPracticeColumns}
            rows={[averageRow]}
            id="notesOfTheWeek-digitalPractice"
            backgroundColor={
              theme.tokens.color.specific.reports.tables['background-highlight']
                .value
            }
          />
        </TableContainer>
      </div>
      {showObservationsNotes && (
        <div>
          <NotesOfTheWeekTitle
            title={t('reports.notesOfTheWeek.title.observations')}
            iconType="infoObservations"
            explanation={t(
              'reports.notesOfTheWeek.title.explanation.observation'
            )}
          />
          {getObservationsNotes()}
        </div>
      )}
      <div>
        {showStudentsWithDifficulties && (
          <div>
            <NotesOfTheWeekTitle
              title={t('reports.notesOfTheWeek.title.studentsWithDifficulty')}
              iconType="info"
              explanation={t(
                'reports.notesOfTheWeek.title.explanation.studentsWithDificulty'
              )}
            />
            {getStudentsWithDifficulties()}
          </div>
        )}
        {/* TODO: Not to be implemented until product specifies. */}
        {/* <NotesOfTheWeekTitle
          title={t('reports.notesOfTheWeek.title.studentsWithNewContent')}
          iconType="check"
        /> */}

        <div>
          <NotesOfTheWeekTitle
            title={t('reports.notesOfTheWeek.title.disconnectedStudents')}
            iconType="cross"
          />
          {showDisconnectedStudents ? (
            getDisconnectedStudents()
          ) : (
            <Typography.Body2
              color={theme.tokens.color.alias.cm.text['text-subtle'].value}
              style={{ marginTop: '8px' }}
            >
              {t('reports.notesOfTheWeek.allStudentsAreConnected')}
            </Typography.Body2>
          )}
        </div>
      </div>
    </>
  );
}

export default NotesOfTheWeek;

const TableContainer = styled.div`
  background-color: ${({ theme }) =>
    theme.tokens.color.specific.reports.tables['background-highlight'].value};
  border-radius: 8px;
  padding: 0 16px 16px 16px;
  margin: 24px 0;
`;
