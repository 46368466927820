import { getAdminColumns } from '../admin-dashboard/utils/get-admin-columns';
import { useTranslation } from 'react-i18next';
import { getRowSpan } from '../admin-dashboard/utils/preprocess';
import { useAdminDashboardQuery, Stages } from '@innovamat/glow-api-client';
import ReportsLoader from '../../components/reports-loader';
import { TableComponent } from '@innovamat/glimmer-components';
import { ERROR_TYPE, ErrorPage } from '../../../navigation';

type Props = {
  organizationId?: string;
  tabsAreLoaded?: boolean;
  isNewManager?: boolean;
  goToClassroom?: (classroomId: string) => void;
};

export const EarlyYearsAdminDashboard = ({
  organizationId,
  tabsAreLoaded,
  isNewManager,
  goToClassroom,
}: Props): JSX.Element => {
  const {
    data: dataEY,
    isLoading: loadingEY,
    isError,
  } = useAdminDashboardQuery(
    {
      organizationId: organizationId!,
      stage: 'EY' as Stages,
    },
    { enabled: !!organizationId }
  );
  const { t } = useTranslation();
  const columns = getAdminColumns({ t, goToClassroom });

  if (isError) {
    return <ErrorPage errorType={ERROR_TYPE.NOT_AVAILABLE} />;
  }

  return (
    <>
      {loadingEY || !tabsAreLoaded ? (
        <ReportsLoader />
      ) : (
        <TableComponent
          id={'ADRE_EARYYEARS'}
          columns={columns}
          rows={dataEY?.adminDashboard ? getRowSpan(dataEY.adminDashboard) : []}
          topStickyOffset={isNewManager ? 64 : 72}
          isNewManager={isNewManager}
        />
      )}
    </>
  );
};
