import { useFlag } from '@innovamat/flags';
import { useSessionDone } from './session-done-provider';
import { useReviewWebhook } from './use-review-webhook';
import { useReportWebhook } from './use-report-webhook';
import {
  useSaveSessionReviewMutation,
  useSessionReviewQuery,
} from '@innovamat/glow-api-client';
import { useEffect, useState } from 'react';
import { snack } from '@innovamat/glimmer-components';
import { updateSessionReviewInCache } from '../cache-actions/update-session-review';
import { useQueryClient } from '@tanstack/react-query';

export function useSessionFeedback() {
  const {
    email,
    currentSession,
    academicYearId,
    t,
    onCloseModal,
    isModalOpen,
    setEventData,
  } = useSessionDone();
  const queryClient = useQueryClient();
  const { value } = useFlag('session-review-ab', false, {
    custom: {},
    identifier: email || '',
  });

  const sessionId = currentSession?.id!;

  const { data } = useSessionReviewQuery(
    { id: sessionId, academicYearId },
    {
      enabled: Boolean(currentSession?.code && academicYearId),
    }
  );
  const { mutate: saveSessionReview, isPending } =
    useSaveSessionReviewMutation();

  const [ratingValue, setRatingValue] = useState<number | null>(null);
  const [review, setReview] = useState<string>('');
  const [errorReview, setErrorReview] = useState<string>('');

  const params = {
    sessionId: sessionId!,
    sessionName: currentSession?.name!,
  };

  const currentRating = data?.sessionReview?.rating;

  const reviewMutation = useReviewWebhook(params);
  const reportMutation = useReportWebhook(params);

  const handleResetRating = (): void => {
    setRatingValue(currentRating || null);
  };

  useEffect(() => {
    if (currentRating) {
      setRatingValue(currentRating);
    }
  }, [currentRating, isModalOpen]);

  useEffect(() => {
    if (!isModalOpen) {
      setRatingValue(null);
      setReview('');
      setErrorReview('');
    }
  }, [isModalOpen]);

  const typeToRender = !value ? 'A' : 'B';

  const onSendReview = (): void => {
    saveSessionReview(
      {
        body: {
          id: sessionId,
          academicYearId,
          rating: ratingValue!,
        },
      },
      {
        onSuccess: (__, { body }) => {
          snack.success(t('session.sesion-review.modal.send-success'));

          updateSessionReviewInCache({
            queryClient,
            id: sessionId,
            academicYearId,
            rating: body.rating,
          });

          if (body.rating <= 3 || (body.rating >= 4 && review)) {
            reviewMutation.mutate({ rating: body.rating, comment: review });
          }

          if (errorReview) {
            reportMutation.mutate({
              report: errorReview,
            });
          }

          setEventData('session_feedback', {
            session_id: sessionId,
            session_name: currentSession?.name,
            variant: typeToRender,
            score: body.rating,
            comments: Boolean(review),
            issue_reported: Boolean(errorReview),
            academic_event_id: academicYearId,
            comment_content: review,
          });

          onCloseModal();
        },
        onError: () => {
          snack.error(t('session.sesion-review.modal.send-error'));
        },
      }
    );
  };

  const onChangeRating = (rating: number | null) => {
    if (rating !== null) {
      setRatingValue(rating);
    }
  };

  const onChangeReview = (review: string) => {
    setReview(review);
  };

  const onErrorReview = (errorReview: string) => {
    setErrorReview(errorReview);
  };

  return {
    typeToRender,
    onSendReview,
    ratingValue,
    onChangeRating,
    hasAlreadyValue: Boolean(currentRating),
    onChangeReview,
    onErrorReview,
    isSendReviewPending: isPending,
    handleResetRating,
    review,
    errorReview,
  };
}
