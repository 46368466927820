// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a0j727PGSp4IJp3m{align-items:center;display:flex;justify-content:center;position:relative}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/Icon/Icon.module.scss"],"names":[],"mappings":"AASA,kBACE,kBAAA,CACA,YAAA,CACA,sBAAA,CACA,iBAAA","sourcesContent":["@import \"../../assets/scss/variables\";\n\n// no funciona be amb totes les icones\n// .icon {\n//   path, circle {\n//     stroke: $black;\n//   }\n// }\n\n.iconContainer {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": "a0j727PGSp4IJp3m"
};
export default ___CSS_LOADER_EXPORT___;
