import React from 'react';
import styles from './Logo.module.scss';
import LogoImage from '../../assets/innovamat.svg';

function Logo() {
  return (
    <div className={styles.logo}>
      <img src={LogoImage} alt="innovamat-logo" />
    </div>
  );
}

export default Logo;
