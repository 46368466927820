import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-domv6';
import { useTranslation } from 'react-i18next';
import {
  EmailPreferences,
  RegionLanguageSelector,
} from '@innovamat/ga-features';
import { decodeBase64 } from '@innovamat/radiance-utils';
import { publicAvailableLanguages } from '@innovamat/localization';
import { useQueryParams } from '@innovamat/hooks';
import { Header } from '../layout/header';
import { Logo } from './logo';

type PublicAvailableLanguage =
  (typeof publicAvailableLanguages)[number]['value'];

export function EmailPreferencesPage(): JSX.Element {
  const { encodedMail } = useParams();
  const navigate = useNavigate();
  const params = useQueryParams();
  const { i18n } = useTranslation();

  const languagesAvailables = publicAvailableLanguages.map((l) => l.value);

  const defaultLang = languagesAvailables[0];

  const paramsLang = params.get('lang') as string;

  const lang =
    paramsLang &&
    languagesAvailables.includes(paramsLang as PublicAvailableLanguage)
      ? paramsLang
      : defaultLang;
  const email = decodeBase64(encodedMail || '') || '';

  useEffect(() => {
    if (encodedMail) {
      i18n.changeLanguage(lang);
      navigate('.', { replace: true });
    }
  }, [encodedMail, navigate]);

  if (!email) {
    return <></>;
  }

  return (
    <>
      <Header
        noProfileButton
        leftActions={<Logo onClickGoHome={false} />}
        rightActions={<RegionLanguageSelector lang={lang} />}
      />
      <EmailPreferences email={email} />
    </>
  );
}
