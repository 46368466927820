import { useTranslation } from 'react-i18next';
import { Column } from '@innovamat/glimmer-components';
import type { FamilyPayment } from '../types/family-payment';

const useFamilyPaymentsColumnsConfiguration = (): Column<FamilyPayment>[] => {
  const { t } = useTranslation();

  return [
    {
      id: 'student.name',
      label: t('familyPayments.tableColumn.studentName'),
      width: 200,
      minWidth: 200,
    },
    {
      id: 'student',
      width: 1,
      separator: true,
    },
    {
      id: 'product.name',
      label: t('common.product'),
      width: 150,
      minWidth: 150,
    },
    {
      id: 'product.courseName',
      label: t('common.course'),
      width: 80,
      minWidth: 80,
    },
    {
      id: 'product.language',
      label: t('common.language'),
      width: 100,
      minWidth: 100,
    },
    {
      id: 'product.quantity',
      label: t('common.quantity'),
      width: 50,
      minWidth: 50,
    },
    {
      id: 'product',
      width: 1,
      separator: true,
    },
    {
      id: 'order.readableExternalId',
      label: t('common.order'),
      width: 80,
      minWidth: 80,
    },
    {
      id: 'order.payoutDate',
      label: t('familyPayments.tableColumn.date'),
      isLargeLabel: true,
      width: 100,
      minWidth: 100,
    },
    {
      id: 'order.financialStatus',
      label: t('common.status', 'Estado'),
      width: 100,
      minWidth: 100,
    },
  ];
};

export { useFamilyPaymentsColumnsConfiguration };
