import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCourses, useSitemapTreeQuery } from '@innovamat/glow-api-client';
import { stages } from '@innovamat/radiance-utils';
import { useFlag } from '@innovamat/flags';

import { useUser } from '../../user-management';
import { usePermissionCourses } from '../../classrooms-management/hooks/use-permission-courses';
import { NAV_TYPE, type NavType } from '../utils';

import { processSitemapTree } from './process-sitemap-tree';
import { useGetAdminSitemap } from './use-get-admin-sitemap';
import { flattenSitemaps } from '../utils/process-helpers';
import type { FlattenedSitemapItem, ProcessedSectionItem } from '../types';
import { useOrganization } from '../../classrooms-management';

type Props = {
  region: string | undefined;
  courseOrder: number | undefined;
  level: 'MANAGER';
  locale: string | undefined;
  navType?: NavType;
  isRtiClassroom?: boolean;
};

export function useSitemapTree({
  region,
  courseOrder,
  level,
  locale,
  navType = NAV_TYPE.Classroom,
  isRtiClassroom,
}: Props) {
  const { t } = useTranslation();
  const { user, isStudentViewActive, isRole } = useUser();
  const { organization } = useOrganization();

  const viewAs = useMemo(() => {
    return isStudentViewActive ? 'student' : undefined;
  }, [isStudentViewActive]);

  // Obtención del árbol de sitemap
  const variables = {
    region: region!,
    courseOrder: courseOrder!,
    level,
    viewAs,
  };
  const { data, isPending, isError } = useSitemapTreeQuery(variables, {
    queryKey: ['SitemapTree', { ...variables, locale }],
    enabled: !!courseOrder && !!region,
    staleTime: Infinity,
  });

  // Obtención de permisos para el curso actual y otras condiciones
  const { getCourseByCourseOrder } = useCourses({
    regionCode: region!,
    organizationId: user?.organizationId!,
  });
  const currentCourse = getCourseByCourseOrder(courseOrder!);
  const { permissionsByCurrentCourse } = usePermissionCourses({
    courseId: currentCourse?.id,
  });

  const showAdminDashboard = organization?.platformEnabled || isRole.Advisor;
  const showIntervention = permissionsByCurrentCourse.earlyIntervention;
  const showAssessment = permissionsByCurrentCourse.assessment;

  const { value: catchUpDashbordEnabled } = useFlag(
    'catchupdashbordenabled',
    false,
    {
      identifier: '',
      custom: {
        schoolId: user?.organizationId!,
        region: region!,
      },
    }
  );

  const isPrimaryClassroom = stages.isPrimaria(courseOrder);
  const showEarlyInterventionMenuItem = Boolean(
    catchUpDashbordEnabled && isRtiClassroom && isPrimaryClassroom
  );
  const isInStudentView = viewAs === 'student';

  const adminSitemap = useGetAdminSitemap({ showAdminDashboard });

  const sitemapTree = useMemo(() => {
    if (navType === NAV_TYPE.Admin) {
      return adminSitemap;
    }

    return data?.sitemapTree
      ? processSitemapTree({
          tree: data.sitemapTree,
          navType,
          t,
          isInStudentView,
          permissions: {
            assessmentAndIntervention: showAssessment || showIntervention,
            earlyIntervention: showEarlyInterventionMenuItem,
          },
        })
      : [];
  }, [
    adminSitemap,
    data?.sitemapTree,
    isInStudentView,
    navType,
    t,
    showAssessment,
    showIntervention,
    showEarlyInterventionMenuItem,
  ]);

  const flattenedSitemaps = useMemo(
    () => flattenSitemaps(sitemapTree),
    [sitemapTree]
  );

  const getSitemapByRoute = (
    route: string
  ): Exclude<ProcessedSectionItem, string> => {
    return flattenedSitemaps?.find(
      (sitemap: FlattenedSitemapItem) =>
        typeof sitemap !== 'string' && route === sitemap?.route
    ) as Exclude<ProcessedSectionItem, string>;
  };

  const getAllSitemapRoutes = (): (string | undefined)[] => {
    if (!flattenedSitemaps) {
      return [];
    }
    return flattenedSitemaps?.map((sitemap: FlattenedSitemapItem) =>
      typeof sitemap !== 'string' ? sitemap?.route : undefined
    );
  };

  const getFirstSitemapRoute = (): string => {
    if (!flattenedSitemaps) {
      return '';
    }
    return flattenedSitemaps[0]?.route || '';
  };

  const getRouteByPageId = (pageId: string) => {
    return (
      flattenedSitemaps?.find(
        (sitemap: FlattenedSitemapItem) =>
          sitemap &&
          typeof sitemap !== 'string' &&
          'fullPageId' in sitemap &&
          'demoPageId' in sitemap &&
          (sitemap?.fullPageId === pageId || sitemap?.demoPageId === pageId)
      )?.route || ''
    );
  };

  return {
    isPending,
    isError,
    sitemapTree,
    getSitemapByRoute,
    getAllSitemapRoutes,
    getRouteByPageId,
    getFirstSitemapRoute,
  };
}
