import { useEffect, useRef } from 'react';
import {
  Typography,
  ListElement,
  theme,
  InnerHtml,
} from '@innovamat/glimmer-components';
import {
  ListNavigation,
  NavigationEvents,
  ResourceNavigationType,
} from '../../types';
import {
  Divider,
  NavigationListWrapper,
  StickyTitle,
} from './resource-navigation.styled';
import { useResourceNavigation } from '../../providers';

type NavigationDropdownContentProps = {
  title: string;
  navigationList: ListNavigation[];
  topMenu: string;
  onNavigate?: (navigationEvents: NavigationEvents) => void;
  onCheckListElement?: (id: string, value: boolean) => void;
  isCheckDisabled?: boolean;
  hasCheckbox?: boolean;
};

const NavigationDropdownContent = ({
  title,
  navigationList,
  topMenu,
  onNavigate,
  onCheckListElement,
  isCheckDisabled,
  hasCheckbox,
}: NavigationDropdownContentProps) => {
  const { resources, currentResourceIndex, navigateToResource } =
    useResourceNavigation();
  const activeItemRef = useRef<HTMLDivElement>(null);
  const activeItemId = resources?.[currentResourceIndex]?.id;

  const getState = (resource: ResourceNavigationType) => {
    if (resources && resource.id === resources[currentResourceIndex]?.id) {
      return 'selected';
    }
    if (resource.enabled) return 'active';
    return 'disabled';
  };

  useEffect(() => {
    if (activeItemId && activeItemRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, [activeItemId]);

  return (
    <>
      <StickyTitle>
        <Typography.H4>
          <InnerHtml text={title} />
        </Typography.H4>
        <Divider />
      </StickyTitle>
      {navigationList.map((item) => (
        <NavigationListWrapper key={item.id}>
          <Typography.SuperTitle
            color={theme.tokens.color.alias.cm.text['text-subtle'].value}
            className="supertitle"
          >
            <InnerHtml text={item.title} />
          </Typography.SuperTitle>
          {item.resources &&
            item.resources.map((resource) => (
              <div
                key={resource.id}
                ref={resource.id === activeItemId ? activeItemRef : null}
              >
                <ListElement
                  state={getState(resource)}
                  isChecked={resource.checked}
                  hasCheckbox={hasCheckbox}
                  onCheck={(value: boolean) =>
                    onCheckListElement?.(resource.id, value)
                  }
                  onClick={() =>
                    navigateToResource(
                      topMenu,
                      resource.id,
                      'selection',
                      resource.type,
                      resource.isDigitalGuide,
                      onNavigate
                    )
                  }
                  isCheckDisabled={
                    resource.isChechedDisabled || isCheckDisabled
                  }
                  solutions={
                    resource.solutionsEnabled || !resource.hasSolutions
                  }
                >
                  <InnerHtml text={resource.title} className="overflow" />
                </ListElement>
              </div>
            ))}
        </NavigationListWrapper>
      ))}
    </>
  );
};

export default NavigationDropdownContent;
