import React from 'react';
import cx from 'classnames';
import Avatar from '../Avatar';
import styles from './AvatarGroup.module.scss';

type AvatarProps = {
  name?: string | null;
  img?: string | null;
};

type Props = {
  avatars: AvatarProps[];
  isDisabled?: boolean;
  isOverlapEnabled?: boolean;
  maxElements?: number;
  onClick?: () => void;
};

function AvatarGroup({
  avatars,
  isOverlapEnabled = false,
  isDisabled = false,
  maxElements = 3,
  onClick,
}: Props) {
  const classes = cx(styles.avatarGroup, {
    [styles.clickable]: !!onClick && !isDisabled,
    [styles.disabled]: isDisabled,
    [styles.overlap]: isOverlapEnabled,
  });

  return (
    <div
      className={classes}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      {avatars.slice(0, maxElements).map((avatar, i) => (
        <Avatar
          alt={`avatar-${i}-${avatar.name}`}
          className={styles.avatar}
          disabled={isDisabled}
          img={avatar.img as string}
          key={i}
          name={avatar.name as string}
        />
      ))}
    </div>
  );
}

export default AvatarGroup;
