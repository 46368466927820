import { Grid, Grounder } from '@innovamat/glimmer-components';

import { useUser } from '../../../../user-management';

import { Shortcuts, ShortcutsSkeleton } from '../../../../contents';
import { MyClasses } from '../../../components/my-classes';
import { MySchool } from '../../../components/my-school';

import { LandingPageAdminSkeleton } from './landing-page-admin-skeleton';

import { ColWithMargin, Container } from '../styles';

function LandingPageAdmin(): JSX.Element {
  const { user } = useUser();

  if (!user) {
    return <LandingPageAdminSkeleton />;
  }

  return (
    <Container>
      <Grid.Row justifyContent="center">
        <ColWithMargin md={10} lg={8}>
          <MySchool organizationId={user.organizationId} />
        </ColWithMargin>

        <Grid.Col md={10} lg={8}>
          <MyClasses />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row justifyContent="center">
        <Grid.Col md={10} lg={8}>
          {user ? (
            <Shortcuts
              organizationId={user.organizationId}
              roles={user.roles}
            />
          ) : (
            <ShortcutsSkeleton />
          )}
        </Grid.Col>
      </Grid.Row>
      <Grounder />
    </Container>
  );
}

export { LandingPageAdmin };
