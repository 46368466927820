import React from 'react';

import { Container } from './MaterialsListHeader.styles';

type MaterialsListHeaderProps = {
  children: React.ReactNode;
};

function MaterialsListHeader({ children }: MaterialsListHeaderProps) {
  return <Container>{children}</Container>;
}

export default MaterialsListHeader;
