import React from 'react';

import { Container } from './MaterialsList.styles';

type MaterialsListProps = {
  children: React.ReactNode | React.ReactNode[];
};

function MaterialsList({ children }: MaterialsListProps) {
  return <Container>{children}</Container>;
}

export default MaterialsList;
