import { Geogebra } from '../types/geogebra';
import { ResourceResponse } from '../types/resourceResponse';
import { ResourceType } from '../types/resourceType';
import checkMissingLocale from '../utils/missingLocales';
import { mapPrintable } from './contentsMapper';

export const geogebraMapper = (
  response: ResourceResponse,
  locale: string,
  isBloqued: boolean,
  isStudent: boolean
): Geogebra => {
  const isNotSession = false;
  const printables = mapPrintable(response, isStudent, isNotSession);
  return {
    id: response.uuid,
    file: response.file?.href || '',
    isBloqued,
    language: locale,
    script: response.script,
    settings: response.settings,
    type: response.type as ResourceType,
    description: checkMissingLocale(response.description),
    title: checkMissingLocale(response.name),
    printables,
    tags: response.tags,
  };
};
