import {
  FlexContainer,
  MinorText,
  ReportsTypography,
} from '../../admin-dashboard.styled';
import PlaceholderCell from '../placeholder-cell';

type Props = {
  timeSpent: number;
  rowIsNull: boolean;
  classroomName: string;
  courseOrder: number;
};

function AverageTime({
  timeSpent,
  rowIsNull,
  courseOrder,
  classroomName,
}: Props): JSX.Element {
  if (rowIsNull) return <PlaceholderCell left leftMargin={16} />;
  return (
    <FlexContainer>
      <ReportsTypography
        data-testid={`${courseOrder}_${classroomName}_STUDENTSTIME`}
      >
        {Math.ceil(timeSpent / 60)}
        <MinorText>min</MinorText>
      </ReportsTypography>
    </FlexContainer>
  );
}

export default AverageTime;
