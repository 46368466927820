import { Modal } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string | JSX.Element;
  confirmText: string;
  cancelText?: string;
  onConfirm: () => void;
  isPending?: boolean;
};

export function ConfirmationModal({
  isOpen,
  onClose,
  title,
  message,
  confirmText,
  cancelText,
  onConfirm,
  isPending,
}: ConfirmationModalProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Modal
      modalWidth={500}
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      buttons={[
        {
          children: confirmText,
          variant: 'accent',
          loading: isPending,
          onClick: onConfirm,
        },
        {
          children: cancelText || t('common.cancel'),
          variant: 'tertiary',
          onClick: onClose,
        },
      ]}
    >
      <>{message}</>
    </Modal>
  );
}
