import { useEnvVariables, useUser } from '@innovamat/ga-features';
import { useEffect } from 'react';
import { useLocation } from 'react-router-domv6';

const PUBLIC_URLS = ['/preferences'];

function ReferralRedirect() {
  const { isLoggedIn } = useUser();
  const { pathname } = useLocation();
  const { GA } = useEnvVariables();

  useEffect(() => {
    const url = window.location.href;

    if (PUBLIC_URLS.some((path) => url.includes(path))) return;

    if (!isLoggedIn) {
      const url =
        pathname !== '/' ? `${GA}?referral=${window.location.href}` : `${GA}`;

      window.location.href = url;
    }
  }, [isLoggedIn]);

  return null;
}

export { ReferralRedirect };
