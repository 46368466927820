import { AccordionContent } from '../Accordion/Accordion.styles';
import MomentBlock from './MomentBlock';
import MomentBlockVideoChapter from './MomentsBlock.VideoChapter';
import { Moment, MomentTypes } from '../../types/moments';
import SimpleBlock from '../SimpleBlock';
import MaterialsBlock from '../MaterialsBlock';
import { mapMoments } from './helpers';
import { ImageModal } from '../ImagesModal/ImagesModal';
import { useMemo } from 'react';
import { MaterialList } from '../../types/materials';

type Props = {
  moment: Moment;
  isOpen: boolean;
  materialList?: MaterialList;
  onClickImage: (image: ImageModal | null) => void;
  momentSummaryKey?: string;
};

export default function MomentsBlockItems({
  moment,
  isOpen,
  materialList,
  onClickImage,
  momentSummaryKey,
}: Props) {
  const momentContent = moment.moment_content;

  const newMoments = useMemo(() => {
    const moments = momentContent ? mapMoments(momentContent) : undefined;
    return moments;
  }, [momentContent]);

  const renderItems = () => {
    const items = [];
    const hasFeatured = momentContent?.items.some(
      (i) => 'items' in i && 'featured' in i.items && i.items?.featured?.value
    );

    if (moment.summary) {
      items.push(
        <SimpleBlock
          key="summary"
          text={moment.summary}
          title={momentSummaryKey}
        />
      );
    }
    if (moment.recap) {
      items.push(<SimpleBlock key="recap" text={moment.recap} />);
    }

    if (moment.material && (isOpen || hasFeatured) && materialList) {
      items.push(
        <MaterialsBlock
          key="materials-block"
          material={moment.material}
          items={materialList}
        />
      );
    }

    if (moment.video_chapter_content && isOpen) {
      items.push(
        <MomentBlockVideoChapter
          key="video"
          videoChapter={moment.video_chapter_content}
        />
      );
    }

    if (newMoments) {
      newMoments.forEach((item, index) => {
        if (item.moment.items.featured?.value || isOpen) {
          items.push(
            <MomentBlock
              onClickImage={onClickImage}
              key={`item-${index}-${moment.id}`}
              momentContent={item.moment}
              momentType={moment.moment?.body || MomentTypes.Video}
              subMoment={item.subMoment}
            />
          );
        }
      });
    }
    return items;
  };

  const items = renderItems();

  if (items.length === 0) return null;

  return <AccordionContent>{items}</AccordionContent>;
}
