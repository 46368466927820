import { useEnvVariables } from './../../env-variables-manager/env-variables-provider';
import { useSolverApiUrlQuery } from '@innovamat/glow-api-client';

export function useSolverUrl(): {
  solverUrl: string;
} {
  const { API_SOLVER_URL } = useEnvVariables();
  const query = useSolverApiUrlQuery({}, { staleTime: Infinity });

  return {
    solverUrl: query.data?.solverApiUrl || API_SOLVER_URL,
  };
}
