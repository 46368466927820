import { Skeleton } from '@innovamat/glimmer-components';
import { ClassroomsPerCourse, CourseBlocks } from '../join-to-classroom.styled';

const CourseTitleSkeleton = (): JSX.Element => (
  <Skeleton width="25%" height="24" />
);
const ClassCardSkeleton = (): JSX.Element => (
  <Skeleton width="100%" height="64" />
);

export function SelectStepSkeleton(): JSX.Element {
  const renderClassCardSkeletons = (count: number): JSX.Element[] =>
    Array.from({ length: count }, (_, index) => (
      <ClassCardSkeleton key={index} />
    ));

  return (
    <>
      <CourseBlocks>
        <CourseTitleSkeleton />
        <ClassroomsPerCourse>{renderClassCardSkeletons(6)}</ClassroomsPerCourse>
      </CourseBlocks>
      <CourseBlocks>
        <CourseTitleSkeleton />
        <ClassroomsPerCourse>{renderClassCardSkeletons(6)}</ClassroomsPerCourse>
      </CourseBlocks>
    </>
  );
}
