import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleImport } from '../../../google-classroom/';

import { Button, ButtonVariant, Dropdown } from '@innovamat/glimmer-components';
import type { ClassroomBody, GlowApiError } from '@innovamat/glow-api-client';

import { useFlag } from '@innovamat/flags';
import { addClassroomToCache } from '../../cache-actions';
import { AddClassroomModal } from '../add-classroom-modal';

type AddClassroomDropdownOption = 'add' | 'import' | 'join';

type AddClassroomDropdownProps = {
  disabled?: boolean;
  variant?: ButtonVariant;
  onJoinClassroom?: () => void;
  organizationId: string;
  region: string;
  options: AddClassroomDropdownOption[];
  position?: 'bottomRight' | 'bottomLeft' | 'topRight' | 'topLeft';
  size?: 'S' | 'M';
  onSuccess?: (classroom: ClassroomBody) => void;
  onError?: (error: GlowApiError) => void;
  setEvent?: (event: AssignEventType) => void;
};

export type AssignEventType = 'new' | 'import' | 'existing';

export function AddClassroomDropdown({
  disabled,
  options,
  organizationId,
  position = 'bottomRight',
  region,
  size = 'S',
  variant = 'secondary',
  onJoinClassroom,
  onSuccess,
  onError,
  setEvent,
}: AddClassroomDropdownProps): JSX.Element {
  const queryClient = useQueryClient();
  const [showCreateClassroom, setShowCreateClassroom] = useState(false);
  const { handleOpenModal } = useGoogleImport();
  const { value: showGoogleClassroomImport } = useFlag(
    'showgoogleclassroomimport',
    false,
    {
      identifier: '',
      custom: {
        schoolId: organizationId || '',
      },
    }
  );

  const { t } = useTranslation();

  const handleOnSuccess = (classroom: ClassroomBody): void => {
    setShowCreateClassroom(false);
    addClassroomToCache({ queryClient, orgId: organizationId, classroom });
    onSuccess?.(classroom);
  };

  const handleSelectItemEvent = (type: AssignEventType): void => {
    setEvent?.(type);
  };

  const handleAdd = (): void => {
    setShowCreateClassroom(true);
    handleSelectItemEvent('new');
  };

  const handleImport = () => {
    handleOpenModal();
    handleSelectItemEvent('import');
  };

  const handleJoin = (): void => {
    onJoinClassroom?.();
    handleSelectItemEvent('existing');
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle>
          <Button
            size={size}
            variant={variant}
            rightIcon="ExpandMoreIcon"
            disabled={disabled}
            oneLine
          >
            {t('classrooms.addClassroom')}
          </Button>
        </Dropdown.Toggle>
        <Dropdown.Content position={position}>
          {options.includes('join') && onJoinClassroom && (
            <Dropdown.Item
              state={'active'}
              icon="JoinClassIcon"
              onSelectItem={handleJoin}
            >
              {t('classrooms.join-to-classroom')}
            </Dropdown.Item>
          )}
          {options.includes('add') && (
            <Dropdown.Item
              state={'active'}
              icon="AddUpIcon"
              onSelectItem={handleAdd}
            >
              {t('classrooms.dropdown.createNewClassroom')}
            </Dropdown.Item>
          )}
          {showGoogleClassroomImport && options.includes('import') && (
            <Dropdown.Item
              state={'active'}
              icon="GoogleClassroomIcon"
              onSelectItem={handleImport}
            >
              {t('classrooms.importGoogle')}
            </Dropdown.Item>
          )}
        </Dropdown.Content>
      </Dropdown>

      <AddClassroomModal
        show={showCreateClassroom}
        onClose={() => setShowCreateClassroom(false)}
        organizationId={organizationId}
        region={region}
        onSuccess={handleOnSuccess}
        onError={onError}
      />
    </>
  );
}
