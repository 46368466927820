import { cookies } from '@innovamat/radiance-utils';

type HookReturnType = {
  shownMaxReturnableItemsDialog: boolean;
  setTermsDialogToViewed: () => void;
};

const COOKIE_VALUES = {
  NOT_SEEN: 'NOT_SEEN',
  SEEN: 'SEEN',
  COOKIE_MONTH_LIFE: 60 * 60 * 24 * 30,
} as const;

const useTermsModalVisibility = (): HookReturnType => {
  const { CookieKeys, getCookie, setCookie } = cookies;

  const setTermsDialogToViewed = (): void => {
    setCookie({
      key: CookieKeys.shownMaxReturnableItemsDialog,
      value: COOKIE_VALUES.SEEN,
      maxAge: COOKIE_VALUES.COOKIE_MONTH_LIFE,
    });
  };
  const shownMaxReturnableItemsDialogCookieValue = getCookie(
    CookieKeys.shownMaxReturnableItemsDialog
  );

  return {
    shownMaxReturnableItemsDialog:
      !shownMaxReturnableItemsDialogCookieValue ||
      shownMaxReturnableItemsDialogCookieValue === COOKIE_VALUES.NOT_SEEN,
    setTermsDialogToViewed,
  };
};

export { useTermsModalVisibility };
