import {
  Classroom,
  GlowApiError,
  Student,
  useChangeStudentOrganizationMutation,
  useUpdateStudentClassroomMutation,
} from '@innovamat/glow-api-client';
import { useEducationalPlans } from './use-education-plans';

type Props = {
  currentStudent: Student | undefined;
  onError?: (error: GlowApiError) => void;
  onSuccess?: (student: Student, oldStudent: Student) => void;
};

type MoveStudent = {
  onMoveStudent: (newClassroom: Classroom, newOrgId?: string) => void;
  isLoading: boolean;
};

function useMoveStudent({
  currentStudent,
  onError,
  onSuccess,
}: Props): MoveStudent {
  const {
    mutate: updateStudentClassroomMutation,
    isPending: updateStudentClassroomMutationIsPending,
  } = useUpdateStudentClassroomMutation<GlowApiError>();

  const {
    mutate: changeStudentOrganizationMutation,
    isPending: changeStudentOrganizationIsPending,
  } = useChangeStudentOrganizationMutation<GlowApiError>();

  const { getEducationalPlanByCourse } = useEducationalPlans({});

  const onMoveStudent = (newClassroom: Classroom, newOrgId?: string): void => {
    const educationalPlanId = getEducationalPlanByCourse(
      newClassroom?.courseId!
    )?.id;

    if (!newClassroom) return;

    const newStudent: Student = {
      ...currentStudent,
      educationalPlanId,
      classroomId: newClassroom.id,
      classroomName: newClassroom.name,
      courseName: newClassroom.courseName,
      courseOrder: newClassroom.courseOrder,
    };

    if (newOrgId) {
      changeStudentOrganizationMutation(
        {
          body: {
            studentId: newStudent.id!,
            classroomId: newClassroom.id,
            schoolId: newOrgId,
          },
        },
        {
          onError: (error) => {
            onError?.(error);
          },
          onSuccess: () => {
            onSuccess?.(newStudent, currentStudent!);
          },
        }
      );
    } else {
      updateStudentClassroomMutation(
        {
          body: {
            studentId: newStudent.id!,
            classroomId: newClassroom.id,
            pinCode: newStudent?.pinCode,
          },
        },
        {
          onError: (error) => {
            onError?.(error);
          },
          onSuccess: () => {
            onSuccess?.(newStudent, currentStudent!);
          },
        }
      );
    }
  };

  return {
    onMoveStudent,
    isLoading:
      updateStudentClassroomMutationIsPending ||
      changeStudentOrganizationIsPending,
  };
}

export { useMoveStudent };
