import { openSignInWindow, sendMessageToParent } from '../windowComunication';
import { useQueryParams } from '@innovamat/hooks';
import { useCheckGoogleClassroomConnectionMutation } from '@innovamat/glow-api-client';

type Response = {
  onSuccess: () => void;
};

type Connect = (data: Response) => void;

type Props = {
  organizationsApiUrl: string;
};

export function useConnect({ organizationsApiUrl }: Props) {
  const onCheckMutation = useCheckGoogleClassroomConnectionMutation();
  const query = useQueryParams();
  const code = query.get('code');
  sendMessageToParent(code);

  const onConnect: Connect = ({ onSuccess }) => {
    const redirectTo = window.location.origin;

    const redirectUri = encodeURIComponent(redirectTo);

    const url = `${organizationsApiUrl}/rostering/connect/google?redirect_uri=${redirectUri}`;

    openSignInWindow(url, 'ModalPopUp', (event) => {
      const { data } = event;
      const urlParams = new URLSearchParams(data);
      const codeParam = urlParams.get('code');

      if (codeParam) {
        onCheckMutation.mutate(
          {
            body: {
              code: codeParam,
              redirectTo,
            },
          },
          {
            onSuccess: onSuccess,
          }
        );
      }
    });
  };

  return { onConnect };
}
