import { ContentBlockTag, StandardTag, Summary } from '../../types/SmartGuide';
import { ContentBlockTags } from '../ContentBlockTags';
import { StandardTags } from '../StandardTags';

type Props = {
  summary: Summary;
};

function NewStandardContent({ summary }: Props) {
  const { newStandardContent } = summary;

  if (!newStandardContent) {
    return null;
  }

  if ('tooltipKey' in newStandardContent[0]) {
    const tags = newStandardContent as StandardTag[];

    return <StandardTags tags={tags} />;
  }

  const contentBlocks = newStandardContent as ContentBlockTag[];

  return (
    <ContentBlockTags
      tags={contentBlocks}
      hasAssessables={summary.assessables.length > 0}
    />
  );
}

export { NewStandardContent };
