import React from 'react';
import { ArrowIcon, Wrapper } from './OrderReturnTag.styles';

function OrderReturnTag({ isOrder = false, tag }: any) {
  return (
    <Wrapper isOrder={isOrder}>
      <span>{tag}</span>
      <ArrowIcon icon={isOrder ? 'ArrowRight3' : 'ArrowLeft3'} />
    </Wrapper>
  );
}

export default OrderReturnTag;
