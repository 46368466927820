import { useEffect, useRef } from 'react';

type Props = {
  itemsLength: number;
  getIsOpen: (index: number) => boolean;
};

export default function useScroll({ itemsLength }: Props) {
  const refs = useRef<Array<HTMLDivElement | null>>([]);

  const setAccordionRef = (index: number, al: any) => {
    refs.current[index] = al;
  };

  useEffect(() => {
    refs.current = refs.current.slice(0, itemsLength);
  }, [itemsLength]);

  const waitForBoxToOpen = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const onOpenAccordion = async (index: number, isExpanded: boolean) => {
    const ref = refs.current[index];
    if (ref) {
      if (!isExpanded) {
        await waitForBoxToOpen(100);
        ref.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return {
    setAccordionRef,
    onOpenAccordion,
  };
}
