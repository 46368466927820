import { useRef, useState } from 'react';
import { Icon } from '@innovamat/innova-components';
import { useKeyPress } from '@innovamat/hooks';
import {
  Arrow,
  Arrows,
  BlockImage,
  Footer,
  Wrapper,
} from './ImagesModal.styles';
import { ModalContent, CloseButton } from '../../templates/EarlyYear/EarlyYear';

export type ImageModal = {
  url: string;
  title: string;
  id: string;
};

type ImagesModalProps = {
  listOfImages: ImageModal[];
  defaultImage: ImageModal | null;
  onClickImage: (image: ImageModal | null) => void;
};

export default function ImagesModal({
  listOfImages,
  defaultImage,
  onClickImage,
}: ImagesModalProps) {
  const imageRef = useRef<HTMLImageElement>(null);
  const [activeImage, setActiveImage] = useState(defaultImage);
  const activeImageIndex =
    listOfImages.findIndex((im) => im.id === activeImage?.id) || 0;

  const isFirstImage = activeImageIndex === 0;
  const isLastImage = activeImageIndex === listOfImages.length - 1;

  const onPrevImage = () => {
    if (!(isFirstImage && isLastImage) && !isFirstImage) {
      setActiveImage(listOfImages[activeImageIndex - 1]);
    }
  };

  const onNextImage = () => {
    if (!(isFirstImage && isLastImage) && !isLastImage) {
      setActiveImage(listOfImages[activeImageIndex + 1]);
    }
  };
  useKeyPress({
    ArrowRight: onNextImage,
    ArrowLeft: onPrevImage,
  });

  if (!activeImage) return null;

  return (
    <ModalContent>
      <CloseButton
        onClick={() => {
          onClickImage(null);
        }}
      >
        <Icon icon="Close" size="md" color="white" />
      </CloseButton>
      <Wrapper>
        <BlockImage
          ref={imageRef}
          src={activeImage.url}
          alt={activeImage.title}
        />
        <Footer>
          <span>{activeImage.title}</span>
          {!(isFirstImage && isLastImage) && (
            <Arrows>
              <Arrow isDisabled={isFirstImage} onClick={onPrevImage}>
                <Icon icon="LargeLeftArrow" />
              </Arrow>
              <Arrow isDisabled={isLastImage} onClick={onNextImage}>
                <Icon icon="LargeRightArrow" />
              </Arrow>
            </Arrows>
          )}
        </Footer>
      </Wrapper>
    </ModalContent>
  );
}
