import { useEventLogging } from '@innovamat/event-logging';
import {
  DigitalPractice,
  useEnvVariables,
  useRegionParameters,
  useUser,
} from '@innovamat/ga-features';
import { storage } from '@innovamat/radiance-utils';
import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';

function DigitalPracticeContent() {
  const { user, isStudentViewActive } = useUser();
  const { setEventData } = useEventLogging();
  const { regionParameters } = useRegionParameters();
  const { apolloServer } = useRegionalConfigVariables();
  const tokens = storage.tokenInfo.get();

  const tokenInfo = {
    accessToken: tokens?.access_token || '',
    refreshToken: tokens?.refresh_token || '',
    idToken: tokens?.id_token || '',
  };

  if (!user) return <></>;

  return (
    <DigitalPractice
      setEventData={setEventData}
      user={user}
      tokenInfo={tokenInfo}
      regionParameters={regionParameters}
      isStudentViewActive={isStudentViewActive}
      apolloServer={apolloServer}
    />
  );
}

export { DigitalPracticeContent };
