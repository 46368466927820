import { useWebviewBridge } from '@innovamat/webview-bridge';
import {
  Applet,
  CatchUpZoneDocument,
  CatchUpZoneQuery,
  CatchUpZoneQueryVariables,
  WeeklyPractice,
  useCatchUpZoneQuery,
  useFetch,
  useGlowApiClient,
} from '@innovamat/glow-api-client';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useEventListener } from '@innovamat/hooks';
import { getActivitiesWithoutDuplicates } from '../utils/utils';

type Props = {
  locale: string;
};

export function useCatchUpZone({ locale }: Props) {
  const { isInWebview, buildVersion } = useWebviewBridge();
  const { graphqlClient } = useGlowApiClient();
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [useCatchUpZoneQuery.getKey()],
      queryFn: ({ pageParam = 1 }) => {
        return graphqlClient.request(CatchUpZoneDocument, { locale, offset: pageParam, limit: 1 }, { 'build-version': isInWebview ? buildVersion : 'web' });
      },
      getNextPageParam: (lastPage, allPages) => {
        if(lastPage.catchUpZone?.noMoreData) return undefined;
        return allPages.length + 1;
      },
      initialPageParam: 0,
    });

  const catchUpZone = useMemo(() => {
    const pages = data?.pages as CatchUpZoneQuery[];
    const flatMap = pages?.flatMap((page) => page?.catchUpZone);
    if (!flatMap) return null;
    const object: WeeklyPractice = {
      active: flatMap[0]?.active,
      unityPayload: flatMap[0]?.unityPayload,
      noMoreData: false,
      activities: [],
    };
    flatMap.forEach((session) => {
      if (session?.activities?.length)
        object.activities = object.activities?.concat(session.activities);
      if (session?.noMoreData) object.noMoreData = true;
    });
    if (object.activities)
      object.activities = getActivitiesWithoutDuplicates(
        object.activities as Applet[]
      );
    return object;
  }, [data]);

  const handleScroll = useCallback(() => {
    const isScrollInTheBottom =
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;

    if (isScrollInTheBottom && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, fetchNextPage]);

  useEventListener('scroll', handleScroll);

  const handleFetchNextPage = (): void => {
    fetchNextPage();
  };

  return {
    catchUpZone,
    loadingCatchUpZone: isLoading || isFetchingNextPage,
    fetchNextCatchUpPage: handleFetchNextPage,
    catchUpZoneHasMoreData: hasNextPage,
  };
}
