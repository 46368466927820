import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { snack } from '@innovamat/glimmer-components';
import {
  useEditPreferencesMutation,
  useEditSettingsMutation,
} from '@innovamat/glow-api-client';

import { useRefreshToken } from './use-refresh-token';
import type { EditSettingsValues } from '../types';

type UseSettingsHook = {
  setSettings: (values: EditSettingsValues) => void;
  isSuccessSettings: boolean;
  isSavingSettings: boolean;
};

type Props = {
  onSuccess: () => void;
};

export function useSetSettings({ onSuccess }: Props): UseSettingsHook {
  const [isRefreshTokenPending, setIsRefreshTokenPending] = useState(false);
  const { t } = useTranslation();

  const {
    mutate: editSettings,
    isSuccess: settingsIsSuccess,
    isError: settingsIsError,
    isPending: settingsIsPending,
  } = useEditSettingsMutation();

  const {
    mutate: editPreferences,
    isSuccess: preferencesIsSuccess,
    isError: preferencesIsError,
    isPending: preferencesIsPending,
  } = useEditPreferencesMutation();

  const { onRefreshToken } = useRefreshToken();

  const handleRefreshToken = (): void => {
    onRefreshToken(() => {
      snack.success(t("Settings.Els canvis s'han desat correctament."));
      onSuccess();
      setIsRefreshTokenPending(false);
    });
  };

  const setSettings = (values: EditSettingsValues): void => {
    editSettings({ body: values.settings });
    editPreferences({ key: 'language', value: values.language });
    setIsRefreshTokenPending(true);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (settingsIsSuccess && preferencesIsSuccess) {
      timeout = setTimeout(() => {
        // TODO: Remove setTimeout when backend fixes refresh token returning user not updated
        handleRefreshToken();
      }, 1000);
    }

    if (settingsIsError || preferencesIsError) {
      setIsRefreshTokenPending(false);
      snack.error(
        t(
          "Settings.Els canvis no s'han desat a causa d'un error. Torneu a intentar-ho."
        )
      );
    }

    return () => clearTimeout(timeout);
  }, [
    settingsIsSuccess,
    preferencesIsSuccess,
    settingsIsError,
    preferencesIsError,
  ]);

  return {
    setSettings: setSettings,
    isSuccessSettings:
      settingsIsSuccess && preferencesIsSuccess && !isRefreshTokenPending,
    isSavingSettings:
      settingsIsPending || preferencesIsPending || isRefreshTokenPending,
  };
}
