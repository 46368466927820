import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useSessionDone } from './session-done-provider';

type Body = {
  username: string;
  name: string;
  org_uuid: string;
  org_name: string;
  session_id: string;
  session_name: string;
  course_name: string;
  course_order: number;
  comment: string;
  language: string;
  region: string;
  device: string;
  browser: string;
  SO: string;
};

type MutationBody = {
  report: string;
};

type Props = {
  sessionId: string;
  sessionName: string;
};

export function useReportWebhook({
  sessionId,
  sessionName,
}: Props): UseMutationResult<any, Error, MutationBody, unknown> {
  const { email, region, locale, courseOrder, webhooksInfo } = useSessionDone();

  const {
    INTEGROMAT_ERROR_WEBHOOK_URL,
    name,
    organizationId,
    organizationName,
    courseName,
  } = webhooksInfo;

  const mutation = useMutation<any, Error, MutationBody, unknown>({
    mutationFn: async (params) => {
      const body: Body = {
        username: email!,
        name: name,
        language: locale,
        org_uuid: organizationId,
        org_name: organizationName,
        session_name: sessionName,
        session_id: sessionId,
        course_order: courseOrder,
        course_name: courseName!,
        comment: params.report,
        region: region!,
        browser: navigator.userAgent,
        device: navigator.platform,
        SO: navigator.appVersion,
      };

      const response = await fetch(INTEGROMAT_ERROR_WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Failed to send error webhook');
      }

      return response.json();
    },
  });

  return mutation;
}
