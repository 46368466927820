import { useContext } from 'react';
import { WebviewBridgeContext } from './WebviewBridgeProvider';

export function useWebviewBridge() {
  const context = useContext(WebviewBridgeContext);

  if (context === undefined) {
    throw new Error(
      'useWebviewBridge must be used within a WebviewBridgeProvider'
    );
  }

  return context;
}
