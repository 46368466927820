import { PartType } from '../types/SmartGuide';
import { useGlimmerTheme } from '@innovamat/glimmer-components';

export function usePartColors() {
  const theme = useGlimmerTheme();
  const guideTokens = theme.tokens.color.specific['smart-guides'];

  const getBackground = (part?: PartType) => {
    const processedPart = part === 4 ? 0 : part;
    if (part === undefined) return undefined;
    const key = `part${processedPart}-background` as keyof typeof guideTokens;
    return guideTokens[key].value;
  };

  const getElement = (part?: PartType) => {
    const processedPart = part === 4 ? 0 : part;
    if (part === undefined)
      return theme.tokens.color.alias.cm.text['text-subtle'].value;
    const key = `part${processedPart}-element` as keyof typeof guideTokens;
    return guideTokens[key].value;
  };

  const getMedium = (part?: PartType) => {
    const processedPart = part === 4 ? 0 : part;
    if (part === undefined) return 'rgba(0,0,0,0.04)';
    const key = `part${processedPart}-medium` as keyof typeof guideTokens;
    return guideTokens[key].value;
  };

  return {
    getBackground,
    getElement,
    getMedium,
  };
}
