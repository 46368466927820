import { useWebviewBridge } from '@innovamat/webview-bridge';
import {
  WeeklyPracticeDocument,
  WeeklyPracticeQuery,
  WeeklyPracticeQueryVariables,
  useFetch,
  useWeeklyPracticeQuery
} from '@innovamat/glow-api-client';
import { useQuery } from '@tanstack/react-query';

type Props = {
  locale: string;
};

export function useWeeklyPractice({ locale }: Props) {
  const { isInWebview, buildVersion } = useWebviewBridge();

  const { data, refetch, isPending } = useQuery<WeeklyPracticeQuery>({
    queryKey: useWeeklyPracticeQuery.getKey(),
    queryFn: useFetch<WeeklyPracticeQuery, WeeklyPracticeQueryVariables>(
      WeeklyPracticeDocument,
      { 'build-version': isInWebview ? buildVersion : 'web' }
    ).bind(null, { locale }),
    enabled: !!locale,
  });

  return {
    weeklyPractice: data?.weeklyPractice,
    refetchWeeklyPractice: refetch,
    loadingWeeklyPractice: isPending,
  };
}
