import { useState } from 'react';

type ConfirmationModalProps = {
  title: string;
  message: string | JSX.Element;
  onConfirm: () => void;
  confirmText: string;
  cancelText?: string;
};

type ConfirmationModalReturn = {
  isConfirmationOpen: boolean;
  confirmationModalProps: ConfirmationModalProps;
  requestConfirmation: (props: ConfirmationModalProps) => void;
  handleConfirm: () => void;
  handleCloseConfirmationModal: () => void;
};

export const useConfirmationModal = (): ConfirmationModalReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ConfirmationModalProps>({
    title: '',
    message: '',
    onConfirm: () => {},
    confirmText: '',
  });

  const requestConfirmation = ({
    title,
    message,
    onConfirm,
    confirmText,
  }: ConfirmationModalProps): void => {
    setModalProps({ title, message, onConfirm, confirmText });
    setIsOpen(true);
  };

  const handleConfirm = (): void => {
    modalProps.onConfirm();
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  return {
    isConfirmationOpen: isOpen,
    confirmationModalProps: modalProps,
    requestConfirmation,
    handleConfirm,
    handleCloseConfirmationModal: handleClose,
  };
};
