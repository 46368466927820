import { useContext } from 'react';

import { PlayerStateContext } from './PlayerProvider';

export default function usePlayerState() {
  const context = useContext(PlayerStateContext);

  if (context === undefined) {
    throw new Error('usePlayerState must be used within a PlayerProvider');
  }

  return context;
}
