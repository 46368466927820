import { Typography } from '@innovamat/glimmer-components';
import styled from '@emotion/styled';
import CellFluency from '../cell-fluency';
import { MultiplicationTable } from '@innovamat/glow-api-client';
import { CELL_VARIANT, STATUS_TYPE } from '../cell-fluency/cell-fluency';

type Props = {
  operator: string;
  tableFluencyData?: MultiplicationTable[];
};

const TableWrapper = styled.div``;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 8px;
`;

const HeaderCell = styled(Typography.Subtitle1)<{ isLocked?: boolean }>`
  text-align: center;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.sizes.normal};
  color: ${({ isLocked, theme }) =>
    isLocked
      ? `${theme.tokens.color.alias.cm.text['text-disabled'].value}`
      : ''};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  gap: 8px;
`;

const LabelCell = styled(Typography.Subtitle1)`
  text-align: center;
  font-weight: bold;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function TableFluency({ operator, tableFluencyData }: Props): JSX.Element {
  const rowCount = tableFluencyData?.[0].cards.length || 0;

  return (
    <TableWrapper>
      <HeaderRow>
        <HeaderCell>{operator}</HeaderCell>
        {tableFluencyData?.map((column, columnIndex) => {
          const card = tableFluencyData?.[columnIndex]?.cards?.[0];
          const columnLocked = card?.status === STATUS_TYPE.LOCKED;
          return (
            <HeaderCell key={columnIndex} isLocked={columnLocked}>
              {column.operand2}
            </HeaderCell>
          );
        })}
      </HeaderRow>
      {Array.from({ length: rowCount }).map((_, rowIndex) => {
        const operand2 = tableFluencyData?.[rowIndex]?.operand2;
        return (
          <Row key={`row-${rowIndex}`}>
            <LabelCell>{operand2}</LabelCell>
            {tableFluencyData?.map((column, columnIndex) => {
              const { status, studentsFailed } = column.cards[rowIndex];
              return (
                <CellFluency
                  key={`cell-${rowIndex}-${columnIndex}`}
                  status={status}
                  variant={CELL_VARIANT.CELL_NORMAL_SIZE}
                  studentsFailed={studentsFailed}
                />
              );
            })}
          </Row>
        );
      })}
    </TableWrapper>
  );
}

export default TableFluency;
