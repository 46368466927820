import { useFeatureFlag } from 'configcat-react';
import { User } from 'configcat-common';
import { Flags } from './flags';

export function useFlag(
  key: Flags,
  defaultValue?: boolean,
  user?: User | undefined
): {
  value: boolean;
  loading: boolean;
} {
  return useFeatureFlag(key, (defaultValue = false), user);
}
