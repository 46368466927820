import { Region } from '@innovamat/localization';
import { useSessionDone } from './session-done-provider';
import {
  AppletsBySessionCodeQuery,
  useAppletsBySessionCodeQuery,
} from '@innovamat/glow-api-client';
import { ContentResource } from '../../contents/types/page';

type useGetAppletsReturn = {
  data: AppletsBySessionCodeQuery['appletsBySessionCode'] | undefined;
  isError: boolean;
  isPending: boolean;
};

const availableRegions: Region[] = ['ES', 'IT', 'MX', 'CO-B', 'EC-SI', 'US'];

export const askForApplets = (
  resource: ContentResource,
  region: string
): boolean => {
  if (!resource || !resource.code) return false;
  const sessionCode = resource.code;
  //TODO:WHY?? Add link to the documentation,
  const isLaboratory =
    sessionCode.includes('LAB') || sessionCode.startsWith('L');
  const replacedRegion = region?.replace('-LIT', '');
  return availableRegions.includes(replacedRegion as Region) || isLaboratory;
};

export function useGetApplets(): useGetAppletsReturn {
  const { currentSession, courseOrder, classroomId, locale, courseId } =
    useSessionDone();

  const appletsQuery = useAppletsBySessionCodeQuery(
    {
      classroomId: classroomId!,
      locale: locale,
      courseId: courseId,
      courseOrder: courseOrder,
      sessionCode: currentSession?.code!,
    },
    {
      retry: false,
    }
  );

  const isRealError =
    appletsQuery.isError &&
    appletsQuery.data?.appletsBySessionCode?.length === 0;

  return {
    data: appletsQuery.data?.appletsBySessionCode?.filter((applet) =>
      Boolean(applet?.data)
    ),
    isError: isRealError,
    isPending: appletsQuery.isPending,
  };
}
