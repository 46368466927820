import { useMemo, useState } from 'react';
import { useParams } from 'react-router-domv6';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import {
  useOrganizationQuery,
  useTeachersQuery,
} from '@innovamat/glow-api-client';
import type { Teacher } from '@innovamat/glow-api-client';
import { Button, HeadingSection } from '@innovamat/glimmer-components';

import { sortByOwner } from './teacher-row';
import { sortByTwoFields } from '../../utils/sort';

import {
  UpdatePeriodAnnouncementPanel,
  useCheckIsUpdatePeriod,
} from '../../../components/update-period-announcement-panel';

import { SchoolTeachersTableSkeleton } from './components/school-teachers-table/school-teachers-table.skeleton';
import { SchoolTeachersTable } from './components/school-teachers-table';
import { InviteTeachersToOrganizationModal } from './components/invite-teachers-to-organization-modal';

function SchoolTeachers(): JSX.Element {
  const { organizationId = '' } = useParams();
  const [isAddTeacherToOrgModalOpen, setIsAddTeacherToOrgModalOpen] =
    useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    data: organizationQuery,
    isSuccess,
    isLoading: isLoadingOrg,
  } = useOrganizationQuery(
    { id: organizationId },
    { enabled: !!organizationId, staleTime: Infinity }
  );

  const isUpdatePeriod = useCheckIsUpdatePeriod();

  const isPlatformEnabled = Boolean(
    organizationQuery?.organization?.platformEnabled
  );

  const { data: teachersQuery, isLoading } = useTeachersQuery<{
    teachers: Teacher[];
  }>(
    { organizationId, isPlatformEnabled },
    { enabled: isSuccess, staleTime: Infinity }
  );

  const isLoadingTeachers = isLoading || isLoadingOrg;

  const onSuccessAddTeachers = (): void => {
    const queryKey = useTeachersQuery.getKey({
      organizationId,
      isPlatformEnabled,
    });

    queryClient.refetchQueries({ queryKey });
  };

  const teachers = useMemo(() => {
    return (
      teachersQuery?.teachers
        .sort((a, b) =>
          sortByTwoFields(a.lastName, a.firstName, b.lastName, b.firstName)
        )
        .sort(sortByOwner) || []
    );
  }, [teachersQuery?.teachers]);

  return (
    <>
      <HeadingSection
        title={t('reports.admin.title.teachers')}
        actions={
          <Button
            onClick={() => setIsAddTeacherToOrgModalOpen(true)}
            rightIcon="AddUpIcon"
            variant="secondary"
          >
            {t('teachers.buttons.addTeachers')}
          </Button>
        }
      />

      <UpdatePeriodAnnouncementPanel />

      {isLoadingTeachers ? (
        <SchoolTeachersTableSkeleton />
      ) : (
        <SchoolTeachersTable
          isPlatformEnabled={isPlatformEnabled}
          isUpdatePeriod={isUpdatePeriod}
          onAddTeachers={() => setIsAddTeacherToOrgModalOpen(true)}
          organizationId={organizationId}
          teachers={teachers}
        />
      )}

      {isAddTeacherToOrgModalOpen && (
        <InviteTeachersToOrganizationModal
          onClose={() => setIsAddTeacherToOrgModalOpen(false)}
          onSuccess={onSuccessAddTeachers}
        />
      )}
    </>
  );
}

export { SchoolTeachers };
