import {
  Action,
  getRealStepValue,
  PlayerState,
  ResourceType,
} from '@innovamat/resource-viewer';

type Props = {
  prevState: PlayerState;
  state: PlayerState;
  action: Action;
  setEventData: (
    eventType: string,
    eventProperties?: any,
    addUserData?: boolean
  ) => void;
};

export const onResourceViewerEvents = ({
  prevState,
  state,
  action,
  setEventData,
}: Props): void => {
  if (!state.resource) return;
  const { isInFullscreen, totalSteps, currentResource, currentStep } = state;
  const resource =
    'resources' in state.resource
      ? state.resource?.resources[currentResource]
      : state.resource;

  if (!resource) return;

  const typeResource = resource?.type;
  const numPagesPdf = 'pages' in resource ? resource?.pages?.length : 1;

  const getFileLength = () => {
    if (typeResource === ResourceType.vimeo) return null;
    if (typeResource === ResourceType.pdf) return numPagesPdf;
    return undefined;
  };

  const getProgress = () => {
    if (typeResource === ResourceType.pdf) return currentStep + 1;
    if (typeResource === ResourceType.vimeo) return 0;
    return undefined;
  };

  const getSessionValues = () => {
    if (!state.resource || !('resources' in state.resource)) return {};

    return {
      session_id: state.resource.id,
      session_name: state.resource?.title,
      resource_order: currentResource + 1,
      resources_total: state.resource?.resources.length,
      step_order: getRealStepValue(
        currentResource,
        currentStep,
        state.resource
      ),
      steps_total: totalSteps,
      /// override fields when is inside Session
      resource_id: resource.id,
      resource_name: resource.title,
      resource_type: resource.type,
      file_id: 'fileId' in resource ? resource?.fileId : undefined,
    };
  };

  const player = {
    resource_id: resource?.id,
    resource_name: resource?.title,
    resource_type: resource?.type,
    is_fullscreen: isInFullscreen,
    file_id: 'fileId' in resource ? resource?.fileId : undefined,
    ...getSessionValues(),
  };

  const isInsidePdf =
    prevState.currentResource === currentResource &&
    prevState.currentStep !== currentStep;

  switch (action.type) {
    case 'PLAYER_STARTED':
      setEventData('player_started', {
        ...player,
        file_progress: action.payload.progress,
        file_length: action.payload.duration,
      });
      break;
    case 'PLAYER_PAUSED':
      setEventData('player_paused', {
        ...player,
        file_progress: action.payload.progress,
        file_length: action.payload.duration,
      });
      break;
    case 'PLAYER_ENDED':
      setEventData('player_ended', {
        ...player,
        file_progress: action.payload.progress,
        file_length: action.payload.duration,
      });
      break;
    case 'ENTER_FULLSCREEN':
      setEventData('enter_fullscreen', {
        ...player,
        file_progress: action.payload.progress,
        file_length: action.payload.duration,
      });
      break;
    case 'EXIT_FULLSCREEN':
      setEventData('exit_fullscreen', {
        ...player,
        file_progress: action.payload.progress,
        file_length: action.payload.duration,
      });
      break;
    case 'SET_NEXT_RESOURCE':
      if (isInsidePdf) {
        setEventData('file_fragment_next', {
          ...player,
          file_progress: currentStep + 1,
          file_length: numPagesPdf,
        });
      } else {
        setEventData('session_player_next', {
          ...player,
          file_progress: getProgress(),
          file_length: getFileLength(),
        });
      }
      break;
    case 'SET_PREVIOUS_RESOURCE':
      if (isInsidePdf) {
        setEventData('file_fragment_previous', {
          ...player,
          file_progress: currentStep + 1,
          file_length: numPagesPdf,
        });
      } else {
        setEventData('session_player_previous', {
          ...player,
          file_progress:
            typeResource === ResourceType.vimeo
              ? 0
              : typeResource === ResourceType.pdf
              ? numPagesPdf
              : undefined,
          file_length: getFileLength(),
        });
      }

      break;
    case 'RESOURCE_NAVIGATE':
      setEventData('session_player_navigation', {
        ...player,
        file_progress: getProgress(),
        file_length: getFileLength(),
      });
      break;
    // case 'RESOURCE_LEFT':
    //   setLoggingData('resource_left', {
    //     ...player,
    //     file_progress: getProgress(),
    //     file_length: 'pages' in resource ? resource?.pages.length : undefined,
    //   });
    //   break;
    case 'ON_APPLET_COMPLETED':
      // TODO
      break;
    default:
      break;
  }
};
