import { getAvatar } from '@innovamat/glimmer-assets';

export function normalizeImgSource(value: string | object): string {
  return typeof value === 'object' && value !== null && 'src' in value
    ? (value.src as string)
    : (value as string);
}

type AvatarImageProps = {
  avatar?: string | null;
  icon?: number | null;
  courseOrder: number;
};

export function getAvatarImage({
  avatar,
  icon,
  courseOrder,
}: AvatarImageProps): string {
  if (avatar) return avatar;

  if (icon !== undefined && icon !== null) {
    const avatarIcon = getAvatar(icon, courseOrder) as unknown as any;
    return avatarIcon?.src;
  }

  return '';
}
