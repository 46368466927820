import { Skeleton } from '@innovamat/glimmer-components';
import { Container, ToolsContainer } from './shortcuts';

export function ShortcutsSkeleton(): JSX.Element {
  return (
    <Container>
      <Skeleton width="20%" height="32" radius="4" />
      <ToolsContainer>
        <Skeleton width="100%" height="72" radius="8" />
        <Skeleton width="100%" height="72" radius="8" />
        <Skeleton width="100%" height="72" radius="8" />
        <Skeleton width="100%" height="72" radius="8" />
      </ToolsContainer>
    </Container>
  );
}
