import { GoogleClassroomResponse, Query } from '../types';
import { useGetGoogleClassroomsQuery } from '@innovamat/glow-api-client';

type Props = {
  enabled: boolean;
};

export const GOOGLE_CLASSROOMS_KEY = ['googleClassrooms'];

export function useGetGoogleClassrooms({ enabled }: Props) {
  const query = useGetGoogleClassroomsQuery(
    {},
    {
      enabled,
    }
  );

  return {
    isSuccess: query.isSuccess,
    isLoading: query.isLoading || query.isFetching,
    isError: query.isError,
    data: query.data?.getGoogleClassrooms?.classrooms,
    refetch: query.refetch,
  } as Query<GoogleClassroomResponse[]>;
}
