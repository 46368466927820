import styled from '@emotion/styled';
import HeliLoading from '../assets/heli_loading.gif';
import { useMemo } from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export function ResourceSkeleton(): JSX.Element {
  // Workaround to force the browser to reload the gif
  const uniqueKey = useMemo(() => Date.now(), []);

  return (
    <Container>
      <img
        alt="Loading"
        src={`${HeliLoading.src}?${uniqueKey}`}
        width={64}
        height={64}
      />
    </Container>
  );
}
