import { useKeyPress } from '@innovamat/hooks';
import useResourceNavigation from './useResourceNavigation';

export default function useControls({
  preventArrowKeysEvents,
}: {
  preventArrowKeysEvents?: boolean;
}) {
  const { handleNext, handlePrev } = useResourceNavigation();

  useKeyPress(
    {
      ArrowRight: handleNext,
      ArrowLeft: handlePrev,
    },
    preventArrowKeysEvents
  );
}
