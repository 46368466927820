import usePlayerDispatch from '../providers/PlayerProviders/usePlayerDispatch';
import useVideoContext from '../providers/VideoProviders/useVideoContext';

export default function useResourceNavigation() {
  const dispatch = usePlayerDispatch();
  const { duration, getProgress } = useVideoContext();

  const handleNext = () => {
    dispatch({
      type: 'SET_NEXT_RESOURCE',
      payload: {
        duration,
        progress: getProgress(),
      },
    });
  };

  const handlePrev = () => {
    dispatch({
      type: 'SET_PREVIOUS_RESOURCE',
      payload: {
        duration,
        progress: getProgress(),
      },
    });
  };

  return {
    handleNext,
    handlePrev,
  };
}
