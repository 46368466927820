import { Trans } from 'react-i18next';
import { NumberedStep } from '../NumberedStep';
import { PlayerLink } from '../../utils/htmlParser/components';
import { navigateToResource } from '../../utils/navigateToResource';

type SupportExtensionStepProps = {
  hasSupport?: boolean;
  hasExtension?: boolean;
  hasSupportLink?: string;
  hasExtensionLink?: string;
  partNumber: number;
};

const getSupportExtensionTranslation = (
  hasSupport?: boolean,
  hasExtension?: boolean
): string => {
  if (!hasSupport) return '';
  return hasExtension
    ? 'smartguides.parts.support-and-extension'
    : 'smartguides.parts.support';
};

const Link = ({ children, id }: { children: any; id?: string }) => {
  if (!children) return '';
  if (!id) return <b>{children[0]}</b>;

  return (
    <PlayerLink
      label={children}
      id={id}
      navigateToResource={navigateToResource}
    />
  );
};

export function SupportExtensionStep({
  hasSupport,
  hasExtension,
  hasSupportLink,
  hasExtensionLink,
  partNumber,
}: SupportExtensionStepProps): JSX.Element {
  return (
    <NumberedStep
      part={partNumber}
      content={[
        {
          __typename: 'Text',
          text: (
            <Trans
              i18nKey={getSupportExtensionTranslation(hasSupport, hasExtension)}
              components={{
                bs: <Link id={hasSupportLink}>{null}</Link>,
                be: <Link id={hasExtensionLink}>{null}</Link>,
              }}
            />
          ) as unknown as string,
        },
      ]}
    />
  );
}
