import { ScrollPosition } from '../../types/prepr';
import useAccordion from './useAccordion';

type Props = {
  // eslint-disable-next-line no-undef
  itemsLength: number;
  scrollPosition: ScrollPosition;
  onScroll: (index: number | undefined) => void;
  // eslint-disable-next-line no-undef
  renderChildren: (index: number) => JSX.Element;
  getIsOpen: (index: number) => boolean;
  component: any;
};

export default function Accordion({
  itemsLength,
  scrollPosition,
  onScroll,
  getIsOpen,
  renderChildren,
  component: Component,
}: Props) {
  const { setRef } = useAccordion({
    onScroll,
    scrollPosition,
    getIsOpen,
    itemsLength,
  });

  return (
    <>
      {[...Array(itemsLength)].map((_, index) => (
        <Component
          key={index}
          id={`accordion-${index}`}
          ref={(el: any) => setRef(index, el)}
        >
          {renderChildren(index)}
        </Component>
      ))}
    </>
  );
}
