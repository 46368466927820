import { Trans } from 'react-i18next';
import styled from 'styled-components';

import { AnnouncementPanel } from '@innovamat/glimmer-components';
import { dates } from '@innovamat/radiance-utils';
import { useOrganization, useUser } from '@innovamat/ga-features';

const Wrapper = styled(AnnouncementPanel)`
  margin-top: 1rem;
`;

const useCheckIsUpdatePeriod = (): boolean => {
  const { organization } = useOrganization();
  const { isRole } = useUser();

  return Boolean(organization?.academicYear.isUpdatePeriod && !isRole.Advisor);
};

const UpdatePeriodAnnouncementPanel = (): JSX.Element | null => {
  const { organization } = useOrganization();
  const { isRole } = useUser();

  const isUpdatePeriod = Boolean(
    organization?.academicYear.isUpdatePeriod && !isRole.Advisor
  );

  const messageKey = isRole.Admin
    ? 'admin.updatePeriodWarning'
    : 'teacher.updatePeriodWarning';

  const updatingPeriodFinishingDate = dates.formatDateFromSql(
    organization?.nextAcademicYear?.teacherStartDate
  );

  if (!isUpdatePeriod) {
    return null;
  }

  return (
    <Wrapper
      text={
        <Trans
          i18nKey={messageKey}
          values={{ date: updatingPeriodFinishingDate }}
          components={{ bold: <strong /> }}
        />
      }
      type="warning"
      canClose={false}
    />
  );
};

export { UpdatePeriodAnnouncementPanel, useCheckIsUpdatePeriod };
