import { useRef } from 'react';

const MAX_RETRIES = 30;
const TIMEOUT_TO_RETRY = 1000;

// Custom hook for the retry logic
export function useConditionalPdfIFrameReloadTimeout() {
  const retryCountRef = useRef(0);

  const retry = () => {
    const retryCount = retryCountRef.current;
    if (retryCount < MAX_RETRIES) {
      setTimeout(() => {
        const iFrame = document.getElementById(
          'pdfIframe'
        ) as HTMLIFrameElement;
        if (
          iFrame &&
          iFrame.contentWindow &&
          iFrame.contentWindow.document &&
          iFrame.contentWindow.document.body.children.length <= 0
        ) {
          const pdfUrl = iFrame.src;
          iFrame.src = '';
          iFrame.src = `${pdfUrl}?t=${Date.now()}`;

          retryCountRef.current = retryCount + 1;

          retry();
        }
      }, TIMEOUT_TO_RETRY);
    }
  };

  return retry;
}
