'use client';

import { Route, Routes } from 'react-router-domv6';
import 'react-toastify/dist/ReactToastify.css';
import '../config/i18n';

import {
  APP_PATHS,
  AppletSelector,
  ManipulativeEnvironment,
  PrintGuides,
  ReportsFullScreenProvider,
  useAppHeight,
} from '@innovamat/ga-features';
import { SnackBarContainer } from '@innovamat/glimmer-components';
import {
  RegionalConfigVariablesProvider,
  isUSRegion,
} from '@innovamat/regional-config-variables';

import { AdminClassroomNavigationWrapper } from './components/admin-classroom-navigation-wrapper';
import { AdminNavigationWrapper } from './components/admin-navigation-wrapper';
import { ClassroomNavigationWrapper } from './components/classroom-navigation-wrapper';
import { CourseNavigationWrapper } from './components/course-navigation-wrapper';
import { DigitalPracticeNavigationWrapper } from './components/digital-practice-navigation-wrapper';
import { InitDatadog } from './components/init-datadog';
import { LocaleManager } from './components/locale-manager';
import { Providers } from './components/providers';
import { ResourceRouter } from './components/resource-router';
import { StudentViewManagement } from './components/student-view-management';

import { storage } from '@innovamat/radiance-utils';
import { CORPORATE_WEBSITE, ENVIRONMENT, GA_URL } from '../utils/variables';
import { EmailPreferencesPage } from './components/email-preferences-page';
import { ReferralRedirect } from './components/referral-redirect';
import { CourseSelectorPage } from './pages/course-selector-page';
import { FamilyPaymentsTeachersPage } from './pages/family-payments-teachers-page';
import { JoinToClassroomPage } from './pages/join-to-classroom-page';
import { LandingPage } from './pages/landing-page';
import { SettingsPage } from './pages/settings-page';

storage.initRedirectIfTokenIsInvalid({ redirectUrl: GA_URL });

export async function ManagerRouting(): Promise<JSX.Element> {
  useAppHeight();

  const environment = ENVIRONMENT || 'local';
  const fastApiUrl = CORPORATE_WEBSITE || '';
  const region = (await isUSRegion(fastApiUrl)) ? 'us' : 'nonUs';

  return (
    <RegionalConfigVariablesProvider environment={environment} region={region}>
      <Providers>
        <SnackBarContainer autoClose={3000} closeButton={false} />
        <LocaleManager />
        <StudentViewManagement />
        <InitDatadog />
        <ReferralRedirect />

        <Routes>
          <Route path={APP_PATHS.LANDING_PAGE_URL} element={<LandingPage />} />

          <Route
            path={`${APP_PATHS.PREFERENCES_URL}/:encodedMail`}
            element={<EmailPreferencesPage />}
          />
          <Route path={APP_PATHS.SETTINGS_URL} element={<SettingsPage />} />
          <Route
            path={APP_PATHS.MANIPULATIVE_URL}
            element={<ManipulativeEnvironment />}
          />
          <Route path={APP_PATHS.APPLETS_URL} element={<AppletSelector />} />

          <Route
            path={APP_PATHS.JOIN_TO_CLASSROOM_URL}
            element={<JoinToClassroomPage />}
          />

          {/* COURSE SELECTOR */}
          <Route
            path={`${APP_PATHS.COURSE_SELECTOR_BASE_URL}/:navType`}
            element={
              <ReportsFullScreenProvider>
                <CourseSelectorPage />
              </ReportsFullScreenProvider>
            }
          />

          {/*TEACHER FAMILY PAYMENTS */}
          <Route
            path={APP_PATHS.FAMILY_PAYMENTS_URL}
            element={<FamilyPaymentsTeachersPage />}
          />

          {/* CLASSROOM NAVIGATION */}
          <Route path={`${APP_PATHS.CLASSROOM_URL}/:classroomId`}>
            <Route
              path=":menu?/:submenu?/page"
              element={
                <ReportsFullScreenProvider>
                  <ClassroomNavigationWrapper />
                </ReportsFullScreenProvider>
              }
            />
            <Route path=":menu/print/:ids" element={<PrintGuides />} />
            <Route path=":menu/:type/:id" element={<ResourceRouter />} />
          </Route>

          {/* COURSE NAVIGATION */}
          <Route path={`${APP_PATHS.COURSE_URL}/:courseId`}>
            <Route path="applets" element={<AppletSelector />} />
            <Route
              path=":menu?/:submenu?/page"
              element={<CourseNavigationWrapper />}
            />
            <Route path=":menu/print/:ids" element={<PrintGuides />} />
            <Route path=":menu/:type/:id" element={<ResourceRouter />} />
          </Route>

          {/* DIGITAL PRACTICE */}
          <Route path={`${APP_PATHS.DIGITAL_PRACTICE_URL}/:classroomId`}>
            <Route
              path=":menu?/:submenu?/page"
              element={<DigitalPracticeNavigationWrapper />}
            />
            <Route path=":menu/:type/:id" element={<ResourceRouter />} />
          </Route>

          {/* ADMIN NAVIGATION */}
          <Route path={`${APP_PATHS.ORGANIZATION_URL}/:organizationId`}>
            <Route
              path="classrooms/:classroomId/:menu?/:submenu?/page"
              element={
                <ReportsFullScreenProvider>
                  <AdminClassroomNavigationWrapper />
                </ReportsFullScreenProvider>
              }
            />
            <Route
              path=":menu?/:submenu?"
              element={<AdminNavigationWrapper />}
            />
          </Route>
        </Routes>
      </Providers>
    </RegionalConfigVariablesProvider>
  );
}

export default ManagerRouting;
