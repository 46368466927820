import { Modal } from '@innovamat/glimmer-components';
import { useMySettings } from '../providers/my-settings-provider';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

type MySettingsModalSaveProps = {
  goBack: () => void;
};

export function MySettingsModalSave({
  goBack,
}: MySettingsModalSaveProps): JSX.Element | null {
  const {
    onCancel,
    onSaveChanges,
    showModalSave,
    onCloseModal,
    isSavingSettings,
    isSuccessSettings,
  } = useMySettings();
  const { t } = useTranslation();
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (isSuccessSettings && isSaved) {
      goBack();
    }
  }, [isSuccessSettings]);

  return showModalSave ? (
    <Modal
      title={t('Settings.SaveChanges')}
      semantic={{ type: 'warning', text: t('common.modal.important') }}
      isOpen
      closeOnClickOutside
      closeOnEsc
      onClose={onCloseModal}
      buttons={[
        {
          children: t('Settings.Guardar'),
          variant: 'accent',
          loading: isSavingSettings,
          onClick: () => {
            onSaveChanges();
            setIsSaved(true);
          },
        },
        {
          children: t('Settings.salir_sin_guardar'),
          variant: 'tertiary',
          onClick: onCancel,
        },
      ]}
      modalWidth={680}
    >
      <>{t('Settings.description')}</>
    </Modal>
  ) : null;
}
