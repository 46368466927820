import styled from '@emotion/styled';

const Wrapper = styled('span')<{
  isOpaque: boolean;
  clampNumber: number;
  hasElipsis: boolean;
}>`
  ${({ hasElipsis, clampNumber }) =>
    hasElipsis &&
    `
    -webkit-line-clamp:${clampNumber};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    `}

  color: ${({ theme, isOpaque }) =>
    isOpaque
      ? theme.tokens.color.alias.cm.text['text-subtle'].value
      : theme.tokens.color.alias.cm.text['text'].value};

  margin-bottom: ${({ theme }) => theme.spaces.small};

  display: -webkit-box;
  &:first-of-type {
    margin-top: 2px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

type TextRowProps = {
  children: string;
  className?: string;
  elipsisOnRows?: number;
  isOpaque?: boolean;
};

const TextRow = ({
  children,
  className,
  elipsisOnRows = 0,
  isOpaque = false,
}: TextRowProps) => {
  return (
    <Wrapper
      clampNumber={elipsisOnRows}
      className={className}
      hasElipsis={elipsisOnRows > 0}
      isOpaque={isOpaque}
    >
      {children}
    </Wrapper>
  );
};

export { TextRow };
