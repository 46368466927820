import styled from '@emotion/styled';
import { OnboardingForm } from '@innovamat/ga-features';
import { useSettingsQuery } from '@innovamat/glow-api-client';
import { useEffect, useState } from 'react';

const HEADER_HEIGHT = 4;

const OnboardingWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: ${HEADER_HEIGHT}rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - ${HEADER_HEIGHT}rem);
  z-index: 10;
  background-color: white;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  transition: opacity 0.5s;
`;

export function OnboardingPage(): JSX.Element | null {
  const { data } = useSettingsQuery();

  const [hasPositions, setHasPositions] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!data) return;

    const hasPositions = data?.settings?.positions.length > 0;
    setHasPositions(hasPositions);
    document.body.style.overflow = hasPositions ? 'auto' : 'hidden';
  }, [data]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(!hasPositions);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [hasPositions]);

  const handleOnboardingClose = (): void => {
    setIsVisible(false);
    setTimeout(() => {
      setHasPositions(true);
      document.body.style.overflow = 'auto';
    }, 300);
  };

  if (!data || hasPositions) {
    return null;
  } else {
    return (
      <OnboardingWrapper isVisible={isVisible}>
        <OnboardingForm onClose={() => handleOnboardingClose()} />
      </OnboardingWrapper>
    );
  }
}
