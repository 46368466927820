export const getLocaleShortFormat = (locale: string): string => {
  if (locale === 'en_US' || locale === 'en-US') return 'en-US';
  if (locale === 'va') return 'ca';
  return locale.split(/[-_]/)[0];
};

export function createAcceptLanguage(url: string, fallbacks: string[]): string {
  const levels = 9;
  return fallbacks
    .slice(0, levels)
    .filter(Boolean)
    .reduce((acc, cur, i) => acc + `,${cur};q=0.${levels - i}`, url);
}
