import { useRemoveClassroomConnectionMutation } from '@innovamat/glow-api-client';

export default function useRemoveClassroomConnection() {
  const { mutate, isPending, isSuccess, isError, data, reset, error } =
    useRemoveClassroomConnectionMutation();

  const onRemoveClassroomConnection = async (classroomId: string) => {
    mutate({ classroomId });
  };

  return {
    onRemoveClassroomConnection,
    removeClassroomConnectionStatus: {
      isLoading: isPending,
      isError,
      isSuccess,
      data,
      error,
      reset,
    },
  };
}
