import { useUpdateUserRoleMutation } from '@innovamat/glow-api-client';
import { Roles } from '@innovamat/radiance-utils';
import { updateUserRoleInCache } from '../cache-actions/update-teacher-role';
import { useQueryClient } from '@tanstack/react-query';

type UpdateTeacherRoles = {
  onUpdateRole: (roles: Roles[], teacherId: string) => void;
};

type Props = {
  organizationId: string;
  isPlatformEnabled: boolean;
  onSuccess: () => void;
};

function useUpdateTeacherRoles({
  organizationId,
  isPlatformEnabled,
  onSuccess,
}: Props): UpdateTeacherRoles {
  const { mutate } = useUpdateUserRoleMutation();
  const queryClient = useQueryClient();

  const handleUpdateRole = (roles: Roles[], teacherId: string): void => {
    mutate(
      {
        body: { id: teacherId, roles },
      },
      {
        onSuccess: () => {
          updateUserRoleInCache({
            queryClient,
            organizationId,
            roles,
            teacherId,
            isPlatformEnabled,
          });
          onSuccess();
        },
        onError: () => {
          console.error('Error updating roles');
        },
      }
    );
  };

  return {
    onUpdateRole: handleUpdateRole,
  };
}

export { useUpdateTeacherRoles };
