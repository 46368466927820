export const mapPartSteps = (dataSteps: any[]) => {
  const processedSteps = [];
  let currentBlock = null;
  const nonNumberedStepContent = new Set(['Subactividad', 'Agrupacin']);
  const arr = [];

  if (!dataSteps) return [];

  for (const item of dataSteps) {
    if (item.__typename === 'PasoNumerado') {
      if (currentBlock) {
        processedSteps.push(currentBlock, ...arr);
        arr.length = 0;
      }

      currentBlock = { ...item, content: [] };
      continue;
    }

    if (!currentBlock) {
      processedSteps.push(item);
      continue;
    }

    if (nonNumberedStepContent.has(item.__typename)) {
      arr.push(item);
      continue;
    }

    currentBlock.content.push(item);
  }

  if (currentBlock) {
    processedSteps.push(currentBlock);
  }
  return processedSteps;
};
