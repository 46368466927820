import { useTranslation } from 'react-i18next';
import { useOnboardingForm } from '../context/onboarding-form-context';
import {
  OnboardingCardSelect,
  SelectContainer,
} from '../onboarding-form.styled';
import { Position } from '../onboarding-form.types';

export function SelectPositionsStep(): JSX.Element {
  const { t } = useTranslation();
  const { selectedPositions, setSelectedPositions } = useOnboardingForm();
  const positions = [
    'teacher',
    'assistant',
    'coordinator',
    'head_pedagogic',
    'secretary',
    'it_manager',
  ];

  const handleClick = (position: Position) => {
    if (selectedPositions.includes(position)) {
      setSelectedPositions(selectedPositions.filter((p) => p !== position));
    } else {
      setSelectedPositions([...selectedPositions, position]);
    }
  };

  return (
    <SelectContainer>
      {positions.map((position) => (
        <OnboardingCardSelect
          key={position}
          status={
            selectedPositions.includes(position as Position)
              ? 'selected'
              : 'default'
          }
          onClick={() => handleClick(position as Position)}
        >
          {t('positions.' + position)}
        </OnboardingCardSelect>
      ))}
    </SelectContainer>
  );
}
