import { createContext, useContext, useState } from 'react';

type ReportsFullScreenProviderValue = {
  isFullScreen: boolean;
  closeFullScreen: () => void;
  openFullScreen: () => void;
};

const ReportsFullScreenContext = createContext<ReportsFullScreenProviderValue>({
  isFullScreen: false,
  closeFullScreen: () => {},
  openFullScreen: () => {},
});

function ReportsFullScreenProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const closeFullScreen = (): void => {
    setIsFullScreen(false);
  };

  const openFullScreen = (): void => {
    setIsFullScreen(true);
  };

  return (
    <ReportsFullScreenContext.Provider
      value={{
        isFullScreen,
        closeFullScreen,
        openFullScreen,
      }}
    >
      {children}
    </ReportsFullScreenContext.Provider>
  );
}

function useReportsFullScreen(): ReportsFullScreenProviderValue {
  const context = useContext(ReportsFullScreenContext);
  if (context === undefined) {
    throw new Error(
      'useReportsFullScreen must be used within a ReportsFullScreenProvider'
    );
  }
  return context;
}

export { ReportsFullScreenProvider, useReportsFullScreen };
