import { ClassroomQuery } from '@innovamat/glow-api-client';
import { useEffect } from 'react';
import { useGoogleImport } from '../../../../google-classroom';

type Props = {
  classroom: ClassroomQuery['classroom'];
};

function useStudentsInitSync({ classroom }: Props): void {
  const { onSetClassroomToSyncro } = useGoogleImport();

  const isClassroomImportedFromGoogle = classroom?.provider === 'google';

  useEffect(() => {
    const classroomToSyncro =
      isClassroomImportedFromGoogle &&
      classroom.courseOrder &&
      classroom.providerId
        ? classroom
        : null;

    onSetClassroomToSyncro(classroomToSyncro);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [classroom, isClassroomImportedFromGoogle]);
}

export { useStudentsInitSync };
