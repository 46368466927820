import ContentLoader from 'react-content-loader';

export const SpacesLoader = (props: any) => {
  const width = props.width || 240;
  const height = props.height || 16;

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="33" width="166" height="19" />
      <rect x="0" y="56" width="60" height="17" />
      <circle cx="407" cy="49" r="15" />
      <rect x="0" y="81" rx="8" ry="8" width={width} height="302" />
    </ContentLoader>
  );
};
