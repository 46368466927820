import { useGoogleLogin, CodeResponse } from '@react-oauth/google';
import axios from 'axios';
import { useState } from 'react';

// NOTE: This hook needs to be used wrapped in GoogleOAuthProvider from '@react-oauth/google';

type Error = 'SIGNIN' | 'UPLOAD';

type Props = {
  file: any; // TODO CHECK THIS
  fileName: string;
  googleClientId: string;
  googleSecret: string;
  onError?: (type: Error) => void;
  onSuccess?: (url: string) => void;
};
export function useSaveGoogleDrive({
  file,
  fileName,
  googleClientId,
  googleSecret,
  onError,
  onSuccess
}: Props): {
  signIn: () => void;
  loading: boolean;
} {
  const [loading, setLoading] = useState(false);

  const getToken = async (response: any): Promise<string> => {
    const redirectUri = `${window.location.origin}`;

    const res = await axios.post(
      `https://oauth2.googleapis.com/token?client_id=${encodeURIComponent(
        googleClientId
      )}&client_secret=${encodeURIComponent(
        googleSecret || ''
      )}&code=${encodeURIComponent(
        response.code
      )}&grant_type=authorization_code&redirect_uri=${encodeURIComponent(
        redirectUri
      )}`
    );
    return res.data.access_token;
  };

  const handleGoogleSuccess = async (response: CodeResponse) => {
    setLoading(true);
    try {
      const tokenId = await getToken(response);

      const boundary = "foo_bar_baz";
      const delimiter = "\r\n--" + boundary + "\r\n";
      const close_delim = "\r\n--" + boundary + "--";

      const type = file.type;

      const metadata = {
        'name': fileName,
        'mimeType': type
      };
            
      const metadataPart = delimiter + 'Content-Type: application/json\r\n\r\n' + JSON.stringify(metadata) + delimiter;
      const filePart = 'Content-Type: ' + type + '\r\n\r\n';
      const body = [metadataPart, filePart, file, close_delim];

      const multipartRequestBody = new Blob(body, { type: 'multipart/related; boundary=' + boundary });

      try {
        const response = await axios.post(
          'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
          multipartRequestBody,
          {
            headers: {
              Authorization: `Bearer ${tokenId}`,
              'Content-Type': `multipart/related; boundary="${boundary}"`,
            },
          }
        );
        console.log('File uploaded:', response.data);
        setLoading(false);
        const fileId = response.data.id;
        const fileUrl = `https://drive.google.com/file/d/${fileId}/view`;
        onSuccess?.(fileUrl);
      } catch (error) {
        onError?.('UPLOAD');
        console.log('Error uploading file:', error);
        setLoading(false);
      }
    } catch (error) {
      onError?.('UPLOAD');
      console.log(error);
      setLoading(false);
    }
  };

  const signIn = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: () => onError?.('SIGNIN'),
    flow: 'auth-code',
    ux_mode: 'popup',
    redirect_uri: undefined,
    scope: 'https://www.googleapis.com/auth/drive.file',
  });

  return { signIn, loading };
}
