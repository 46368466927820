import { createContext, useContext } from 'react';
import { ImportType } from '../types/imports';

type ImportCSVValue = {
  canSkipValidationStep: boolean;
  organizationId: string;
  region: string;
  showImportCSV: boolean;
  goToImportCSVPage: (type: ImportType) => void;
  goToImportsPage: () => void;
  handleImportUpdates: () => void;
  onGoBack: () => void;
} & NeededMutationProps;

type NeededMutationProps = {
  organizationsApiUrl: string;
};

const ImportCSVContext = createContext<ImportCSVValue | undefined>(undefined);

type Props = {
  children: JSX.Element;
} & ImportCSVValue;

function ImportCSVProvider({
  canSkipValidationStep,
  children,
  organizationId,
  organizationsApiUrl,
  region,
  showImportCSV,
  goToImportCSVPage,
  goToImportsPage,
  handleImportUpdates,
  onGoBack,
}: Props): JSX.Element {
  const value: ImportCSVValue = {
    canSkipValidationStep,
    organizationId,
    organizationsApiUrl,
    region,
    showImportCSV,
    goToImportCSVPage,
    goToImportsPage,
    handleImportUpdates,
    onGoBack,
  };

  return (
    <ImportCSVContext.Provider value={value}>
      {children}
    </ImportCSVContext.Provider>
  );
}

function useImportCSV(): ImportCSVValue {
  const context = useContext(ImportCSVContext);
  if (context === undefined) {
    throw new Error('useImportCSV must be used within a ImportCSVProvider');
  }
  return context;
}

export { ImportCSVProvider, useImportCSV };
