import { Colors } from '@innovamat/glimmer-components';

type BannersFilter = {
  locale: string;
  region?: string;
  roles?: string[];
  course?: number;
  permissions?: {
    assessment?: boolean;
    intervention?: boolean;
  };
};

const VISIBLE_OPTIONS = {
  LAUNCHER: 'LAUNCHER',
  INSIDE_CLASS: 'INSIDE_CLASS',
} as const;

type VisibleOptions = (typeof VISIBLE_OPTIONS)[keyof typeof VISIBLE_OPTIONS];

type FormatBanner = {
  id: string;
  colour: Colors;
  ctaLink: string;
  ctaText: string;
  cta: boolean;
  ctaExternal: boolean;
  text: string;
  inlineTopicId?: string;
  title: string;
  icon: {
    width: number;
    height: number;
    url: string;
    name: string;
  };
  importance: number;
  canBeClosed: boolean;
  visibleAt: VisibleOptions;
};

type BannerRole = {
  roles: string;
};

type BannerIcon = {
  _id: string;
  name: string;
  description?: string;
  width?: number;
  height?: number;
  url: string;
};

type BannerData = {
  _id: string;
  section_tr: string;
  section_dp: string;
  section_cv: string;
  colour: Colors;
  cta_link: string;
  cta_text: string;
  cta: string;
  text: string;
  title: string;
  cta_external: boolean;
  can_be_closed: boolean;
  demo_users?: boolean;
  inline_topic_id?: string;
  icon?: BannerIcon;
  importance: string;
  roles: BannerRole[];
  visible_at: VisibleOptions;
  only_for_intervention?: boolean;
  only_for_assessment?: boolean;
};

type BannersResponse = {
  CM20Banners: {
    items: BannerData[];
  };
};

export type {
  BannersFilter,
  BannerData,
  BannerIcon,
  FormatBanner,
  BannersResponse,
  VisibleOptions,
};

export { VISIBLE_OPTIONS };
