import { Grid, Grounder } from '@innovamat/glimmer-components';

import { useUser } from '../../../../user-management';

import { Container, ColWithMargin } from '../styles';
import { DistrictAdminSchools } from './district-admin-schools';
import { LandingPageDistrictAdminSkeleton } from './landing-page-district-admin-skeleton';

function LandingPageDistrictAdmin(): JSX.Element {
  const { user } = useUser();

  return user ? (
    <Container>
      <Grid.Row justifyContent="center">
        <ColWithMargin md={10} lg={8}>
          <DistrictAdminSchools />
        </ColWithMargin>
      </Grid.Row>
      <Grounder />
    </Container>
  ) : (
    <LandingPageDistrictAdminSkeleton />
  );
}

export { LandingPageDistrictAdmin };
