import styled from '@emotion/styled';
import {
  CourseNavigator,
  LateralMenu,
  useNavigation,
} from '@innovamat/ga-features';
import { HEADER_HEIGHT } from './header';
import { useParams } from 'react-router-domv6';
import { useState } from 'react';
import { useRedirectSitemapNotFound } from '../../hooks/use-redirect-sitemap-not-found';

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const MenuFrame = styled.div<{ isOverflowVisible: boolean }>`
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
  min-width: 17rem;
  max-width: 17rem;
  position: sticky;
  top: ${HEADER_HEIGHT}rem;
  height: calc(100vh - ${HEADER_HEIGHT}rem);
  margin-bottom: -2.625rem;
  overflow-y: ${({ isOverflowVisible }) =>
    isOverflowVisible ? 'visible' : 'auto'};
  z-index: ${({ isOverflowVisible }) => (isOverflowVisible ? '20' : '0')};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const PageFrame = styled.div`
  flex: 1;
  padding-top: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 2rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 1rem;
  }
`;

const Gap = styled.div`
  padding-bottom: 0.5rem;
`;

type Props = {
  sitemapTree?: any;
  hideLateralMenu?: boolean;
  children: JSX.Element;
  className?: string;
};

export function MainLayout({
  children,
  sitemapTree,
  hideLateralMenu = false,
  className,
}: Props): JSX.Element {
  useRedirectSitemapNotFound();

  const { courseId } = useParams();
  const { navigateBetweenClassesOrCourses } = useNavigation();

  const [isCourseNavigatorOpen, setIsCourseNavigatorOpen] = useState(false);

  return (
    <Container className={className}>
      {!hideLateralMenu && (
        <MenuFrame isOverflowVisible={isCourseNavigatorOpen}>
          {courseId && (
            <>
              <CourseNavigator
                courseId={courseId}
                onSelectCourse={navigateBetweenClassesOrCourses}
                onToggle={(isOpen) => setIsCourseNavigatorOpen(isOpen)}
              />
              <Gap />
            </>
          )}
          <LateralMenu sitemapTree={sitemapTree} />
        </MenuFrame>
      )}
      <PageFrame className="page-frame">{children}</PageFrame>
    </Container>
  );
}
