import { InlineManualUser, Roles, storage } from '@innovamat/radiance-utils';
import { User } from '../types';

import { jwtDecode } from 'jwt-decode';

export const getSchoolName = async (crmId: string): Promise<string> => {
  const url = `https://hook.integromat.com/2cdh1fjedovsttl5vl4xwudej588fpkh?crm_id=${crmId}`;
  return await fetch(url)
    .then((res) => res)
    .then((res) => res.json());
};

export const mapUserToInlineManualUser = (
  user: User,
  isInIOS?: boolean,
  isInWebView?: boolean
): InlineManualUser => ({
  uid: user.id,
  username: user.email,
  name: user.name,
  surname: user.familyName,
  roles: user.roles as Roles[],
  group: user.organizationName,
  uuid: user.organizationId,
  crmid: user.crmId || null,
  language: user.locale === 'va' ? 'ca' : user.locale,
  region: user.region,
  os: isInIOS && isInWebView ? 'ios' : isInWebView ? 'android' : undefined,
});

export type CookiesUser = {
  id: string;
  email: string;
  roles: Roles[];
  school: string;
  crmId: string | null;
  region: string;
  locale: string;
  name: string;
  fullName: string;
  familyName: string;
  accessToken: string;
  refreshToken: string;
};

export type IdToken = {
  aud: string;
  iat: number;
  exp: number;
  jti: string;
  name: string;
  family_name: string;
  given_name: string;
  preferred_username: string;
  locale: string;
  region: string;
  phone_number: string | null;
  crm_id: string | null;
  email: string;
};

export type AccessToken = {
  aud: string;
  jti: string;
  iat: number;
  exp: number;
  sub: string;
  scopes: string[];
  username: string;
  roles: string[];
  school: string;
  region: string;
  version: number;
};

export function getUserFromCookies(): CookiesUser | undefined {
  const tokenInfo = storage.tokenInfo.get();

  if (!tokenInfo) {
    return undefined;
  }

  const { access_token, refresh_token, id_token } = tokenInfo;

  if (!access_token || !refresh_token || !id_token) {
    return undefined;
  }

  const decodedAccessToken = jwtDecode(access_token) as AccessToken;
  const decodedIdToken = jwtDecode(id_token) as IdToken;

  return {
    id: decodedAccessToken?.sub!,
    crmId: decodedIdToken?.crm_id,
    email: decodedIdToken?.email!,
    roles: decodedAccessToken?.roles! as Roles[],
    school: decodedAccessToken?.school!,
    region: decodedIdToken?.region!,
    locale: decodedIdToken?.locale!,
    name: decodedIdToken?.given_name!,
    fullName: decodedIdToken?.name!,
    familyName: decodedIdToken?.family_name!,
    accessToken: access_token,
    refreshToken: refresh_token,
  };
}
