import { CompletedResource } from '../types/completedResources';
import { appletMapper } from './appletMapper';
import { geogebraMapper } from './geogebraMapper';
import { responseMapper } from './responseMapper';
import sessionMapper from './sessionMapper';
import { pdfMapper } from './pdfMapper';
import { videoMapper } from './videoMapper';
import { geniallyMapper } from './geniallyMapper';
import { genericMapper } from './genericMapper';
import { ResourceResponse } from '../types/resourceResponse';

export type Params = {
  response: ResourceResponse;
  locale: string;
  completedResources?: CompletedResource[];
  isStudent?: boolean;
  isBloqued?: boolean;
};

export const resourceMapper = ({
  response,
  locale,
  completedResources,
  isStudent = false,
}: Params) => {
  const mappedResponse = responseMapper(response);
  const { type } = mappedResponse;

  const isBloqued = !!isStudent && mappedResponse.visibility !== 'visible';

  switch (type) {
    case 'session':
      return sessionMapper({
        response: mappedResponse,
        locale,
        completedResources,
        isStudent,
      });
    case 'vimeo':
      return videoMapper(mappedResponse, locale, isBloqued, isStudent);
    case 'geogebra':
      return geogebraMapper(mappedResponse, locale, isBloqued, isStudent);
    case 'applet':
      return appletMapper({
        response: mappedResponse,
        locale,
        isBloqued,
        isStudent,
      });
    case 'pdf':
      return pdfMapper({
        locale,
        isBloqued,
        response: mappedResponse,
        completedResources,
        isStudent,
      });
    case 'genially':
      return geniallyMapper({
        locale,
        response,
      });
    case 'generic':
      return genericMapper({
        response,
        locale,
      });
    default:
      return undefined;
  }
};
