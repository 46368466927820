import { useTranslation } from 'react-i18next';

import {
  MaterialCard,
  MaterialsList,
  MaterialsListBody,
  MaterialsListHeader,
  Typography,
} from '@innovamat/glimmer-components';

import {
  TypographySkeleton,
  MaterialCardSkeleton,
} from '../../../../skeletons';

import type { StagedMaterials } from '../../current-materials.types';

type Props = {
  hasErrors?: boolean;
  isDisabled?: boolean;
  isEditMode?: boolean;
  isLoading?: boolean;
  stagedMaterials: StagedMaterials[];
  onOrderMaterial?: (stageId: string, materialId: string) => void;
  onReturnMaterial?: (
    stageId: string,
    materialId: string,
    isMaxValueReached: boolean
  ) => void;
};

function StagedMaterialsList({
  hasErrors = false,
  isDisabled = false,
  isEditMode = false,
  isLoading = true,
  stagedMaterials = [],
  onOrderMaterial,
  onReturnMaterial,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const handleOrderMaterial = (stageId: string, materialId: string): void => {
    onOrderMaterial?.(stageId, materialId);
  };

  const handleReturnMaterial = (
    stageId: string,
    materialId: string,
    isMaxValueReached: boolean
  ): void => {
    onReturnMaterial?.(stageId, materialId, isMaxValueReached);
  };

  return (
    <>
      {isLoading || hasErrors || stagedMaterials?.length === 0 ? (
        <>
          <MaterialsList>
            <MaterialsListHeader>
              <TypographySkeleton height={24} />
            </MaterialsListHeader>
            <MaterialsListBody>
              <MaterialCardSkeleton />
              <MaterialCardSkeleton />
              <MaterialCardSkeleton />
              <MaterialCardSkeleton />
              <MaterialCardSkeleton />
            </MaterialsListBody>
          </MaterialsList>
          <MaterialsList>
            <MaterialsListHeader>
              <TypographySkeleton height={24} />
            </MaterialsListHeader>
            <MaterialsListBody>
              <MaterialCardSkeleton />
              <MaterialCardSkeleton />
              <MaterialCardSkeleton />
              <MaterialCardSkeleton />
              <MaterialCardSkeleton />
            </MaterialsListBody>
          </MaterialsList>
        </>
      ) : (
        <>
          {stagedMaterials.map((stagedMaterial) => (
            <MaterialsList key={stagedMaterial.id} isDisabled={isDisabled}>
              <MaterialsListHeader>
                <Typography.H4>{stagedMaterial.name}</Typography.H4>
              </MaterialsListHeader>

              <MaterialsListBody>
                {stagedMaterial!.materials.map((material) => (
                  <MaterialCard
                    isEditMode={isEditMode}
                    key={material.id}
                    material={material}
                    units={t('common.unitsAbbreviation')}
                    maxValueTooltipMessage={t(
                      'materialCard.maxValueTooltipMessage'
                    )}
                    minValueTooltipMessage={t(
                      'materialCard.minValueTooltipMessage'
                    )}
                    onIncrement={() =>
                      handleOrderMaterial(stagedMaterial.id, material.id)
                    }
                    onDecrement={() => {
                      const isMaxValueReached =
                        material.quantity - material.controlsCounterValue >=
                        material.maxReturnableItems - 1;

                      handleReturnMaterial(
                        stagedMaterial.id,
                        material.id,
                        isMaxValueReached
                      );
                    }}
                  />
                ))}
              </MaterialsListBody>
            </MaterialsList>
          ))}
        </>
      )}
    </>
  );
}

export default StagedMaterialsList;
