import {
  Course,
  GoogleClassroom,
  useGoogleClassroomSync,
} from '@innovamat/ga-features';
import { createContext, useEffect, useMemo, useState } from 'react';
import ImportClassroomLoading from '../components/import-classroom-loading';

import { Classroom, useCourses } from '@innovamat/glow-api-client';
import { useQueryClient } from '@tanstack/react-query';
import { useUser } from '../../user-management';
import ImportClassroomModal from '../components/import-classroom-modal/import-classroom-modal';
import SyncroClassroomModal from '../components/syncro-classroom-modal/syncro-classroom-modal';
import { useSyncroClassroomsModal } from '../hooks/use-syncro-classrooms-modal';

type GoogleClassroomSyncProviderProps = {
  children: React.ReactNode;
};

export const GoogleImportContext = createContext<
  GoogleImportStateValue | undefined
>(undefined);

type GoogleImportStateValue = {
  showImportModal: boolean;
  isSuccessInSyncro: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  onOpenSyncroModal: () => void;
  onSetClassroomToSyncro: (classroomToSyncro: Classroom | null) => void;
};

export function GoogleImportProvider({
  children,
}: GoogleClassroomSyncProviderProps) {
  const { user } = useUser();
  const { organizationId, region } = user || {};

  const [classroomToSyncro, setClassroomToSyncro] = useState<Classroom | null>(
    null
  );
  const [showImportingModal, setShowImportingModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const { courses: apolloCourses } = useCourses({
    organizationId: organizationId!,
    regionCode: region!,
  });

  const client = useQueryClient();

  const {
    classroomsQuery,
    importingClassroomsStatus,
    handleImportClassrooms,
    handleConnect,
  } = useGoogleClassroomSync();

  const {
    isSuccessInSyncro,
    isErrorInSyncro,
    isStudentAlreadyExistsErrorSyncro,
    isStudentAlreadyExistsAsATeacherErrorSyncro,
    showSyncroModal,
    onCloseSyncroModal,
    onOpenSyncroModal,
    handleSyncroClassroom,
    showLoadingAnimation,
    isGoogleClassroomDeletedInGoogleError,
    handleConvertClassroomToInnovamat,
    handleDeleteGoogleClassroom,
  } = useSyncroClassroomsModal({ classroomToSyncro });

  const onImportClassrooms = (importClassrooms: GoogleClassroom[]) => {
    setShowImportingModal(true);
    handleImportClassrooms(importClassrooms);
  };

  const onSetClassroomToSyncro = (classroomToSyncro: Classroom | null) => {
    setClassroomToSyncro(classroomToSyncro);
  };

  const handleCloseModal = () => {
    setShowImportModal(false);
    importingClassroomsStatus.reset();
  };

  const handleOpenModal = () => {
    handleConnect(() => setShowImportModal(true));
  };

  const courses = useMemo(
    () =>
      apolloCourses.map(
        (course) =>
          ({
            id: course.id,
            name: course.name,
            order: course.order,
            region: course.region,
          } as Course)
      ),
    [apolloCourses]
  );

  const mappedClassroomsQuery = useMemo(
    () => ({
      ...classroomsQuery,
      data:
        classroomsQuery.data?.map(
          (classroom) =>
            ({
              google_id: classroom.google_id,
              name: classroom.name,
              imported: classroom.imported,
              course: courses.find(
                (course) => course.order === classroom.course_order
              ),
            } as GoogleClassroom)
        ) || [],
    }),
    [classroomsQuery, courses]
  );

  const isErrorGettingClassrooms =
    mappedClassroomsQuery.isError ||
    (importingClassroomsStatus.isError &&
      importingClassroomsStatus.data?.length === 0);

  const isStudentAlreadyExistsErrorImport =
    importingClassroomsStatus.error?.extensions?.code ===
    'STUDENTS_ALREADY_EXISTS_ERROR';

  const isStudentAlreadyExistsAsATeacherErrorImport =
    importingClassroomsStatus.error?.extensions?.code ===
    'STUDENTS_ALREADY_EXISTS_AS_A_TEACHER_ERROR';

  const isClassroomAlreadyExistsErrorImport =
    importingClassroomsStatus.error?.extensions?.code ===
    'GOOGLE_CLASSROOM_NAME_CONFLICT';

  const notImportedClassrooms = mappedClassroomsQuery.data.find(
    (classroom) =>
      classroom.google_id ===
      importingClassroomsStatus.error?.extensions?.notImportedClassrooms?.[0]
        .google_id
  );

  // show loading at least 5 seconds when is importing
  useEffect(() => {
    if (importingClassroomsStatus.isSuccess) {
      setTimeout(() => {
        setShowImportingModal(false);
      }, 5000);
    }
    if (importingClassroomsStatus.isError) {
      setShowImportingModal(false);
    }

    client.invalidateQueries({ queryKey: ['Classrooms'] });
    client.invalidateQueries({ queryKey: ['Organization'] });
  }, [importingClassroomsStatus.isSuccess, importingClassroomsStatus.isError]);

  // close main modal when importing
  useEffect(() => {
    if (!showImportingModal && !importingClassroomsStatus.isError) {
      setShowImportModal(false);
    }
  }, [importingClassroomsStatus.isError, showImportingModal]);

  // get classrooms when modal is open
  useEffect(() => {
    if (showImportModal) {
      classroomsQuery.refetch();
    }
  }, [showImportModal]);

  // disabled scroll when modals are open
  useEffect(() => {
    document.body.style.overflowY =
      showImportModal ||
      showImportingModal ||
      showSyncroModal ||
      showSyncroModal
        ? 'hidden'
        : 'auto';
  }, []);

  const value = {
    showImportModal,
    isSuccessInSyncro,
    handleOpenModal,
    handleCloseModal,
    onOpenSyncroModal,
    onSetClassroomToSyncro,
  };

  return (
    <GoogleImportContext.Provider value={value}>
      {children}
      <ImportClassroomLoading
        open={showImportingModal || showLoadingAnimation}
      />
      <ImportClassroomModal
        courses={courses}
        importedClassrooms={importingClassroomsStatus.data}
        isStudentAlreadyExistsError={isStudentAlreadyExistsErrorImport}
        isStudentAlreadyExistsAsATeacherError={
          isStudentAlreadyExistsAsATeacherErrorImport
        }
        isErrorGettingClassrooms={isErrorGettingClassrooms}
        isErrorImportingClassrooms={importingClassroomsStatus.isError}
        isClassroomAlreadyExistsErrorImport={
          isClassroomAlreadyExistsErrorImport
        }
        notImportedClassrooms={notImportedClassrooms}
        isLoading={mappedClassroomsQuery.isLoading}
        isModalOpen={showImportModal}
        isSuccess={mappedClassroomsQuery.isSuccess}
        myClassrooms={mappedClassroomsQuery.data}
        onCloseModal={handleCloseModal}
        onSubmit={onImportClassrooms}
      />
      <SyncroClassroomModal
        isStudentAlreadyExistsError={isStudentAlreadyExistsErrorSyncro}
        isStudentAlreadyExistsAsATeacherError={
          isStudentAlreadyExistsAsATeacherErrorSyncro
        }
        isGoogleClassroomDeletedInGoogleError={
          isGoogleClassroomDeletedInGoogleError
        }
        isError={isErrorInSyncro}
        isModalOpen={showSyncroModal}
        onCloseModal={onCloseSyncroModal}
        onSyncroClassroom={handleSyncroClassroom}
        onConvertClassroomToInnovamat={handleConvertClassroomToInnovamat}
        onDeleteClassroom={handleDeleteGoogleClassroom}
      />
    </GoogleImportContext.Provider>
  );
}
