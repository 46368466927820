import { useContext } from 'react';

import { PlayerDispatchContext } from './PlayerProvider';

export default function usePlayerDispatch() {
  const context = useContext(PlayerDispatchContext);

  if (context === undefined) {
    throw new Error('usePlayerDispatch must be used within a PlayerProvider');
  }

  return context;
}
