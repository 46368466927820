import { saveAs } from 'file-saver';

import { detectIsInWebview } from '@innovamat/webview-bridge';

export function downloadFile(fileURL: string, fileName: string) {
  // Send the url to webview to open the file
  if (detectIsInWebview()) {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName;
    link.click();
  } else {
    fetch(fileURL, {
      method: 'GET',
      headers: {},
      mode: 'cors',
      credentials: 'same-origin',
      redirect: 'follow',
    })
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, fileName))
      .catch(() => {
        console.log('BAD FILE', fileName);
      });
  }
}
