import React, { createContext } from 'react';
import { SpaceChallenge, Space } from './Spaces.types';
import { TFunction } from 'i18next';

export type SpacesProviderValue = {
  handleMarkSpace: (space: Space, active: number) => void;
  handleMarkChallengeDone: (space: Space, challenge: SpaceChallenge) => void;
  handleSaveActiveChallenge: (space: Space, active: number) => void;
  setEventData: (key: string, data: any) => void;
  classroomId?: string;
  academicYearId?: string;
  goToResource: (
    resource: string,
    id: string,
    params: Record<string, string>,
    isSession?: boolean,
    isChallenge?: boolean
  ) => void;
  t: TFunction<'translation', undefined, 'translation'>;
};

export const SpacesStateContext = createContext<
  SpacesProviderValue | undefined
>(undefined);

type SpaceProviderProps = {
  value: SpacesProviderValue;
  children: React.ReactNode;
};

export function SpacesProvider({ value, children }: SpaceProviderProps) {
  return (
    <SpacesStateContext.Provider value={value}>
      {children}
    </SpacesStateContext.Provider>
  );
}
