import { Modal } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  rtiEnabled: boolean;
  onConfirm: () => void;
  isLoadingRti: boolean;
};

export function ConfirmRtiModal({
  isOpen,
  rtiEnabled,
  onClose,
  onConfirm,
  isLoadingRti: isLoading,
}: Props): JSX.Element {
  const { t } = useTranslation();
  return (
    <Modal
      modalWidth={700}
      closeButton="inner"
      isOpen={isOpen}
      onClose={onClose}
      title={
        rtiEnabled
          ? t('rti.confirm-modal.start-title')
          : t('rti.confirm-modal.end-title')
      }
      semantic={{
        text: t('common.modal.important'),
        type: 'warning',
      }}
      buttons={[
        {
          children: t('rti.confirm-modal.cancel'),
          variant: 'accent',
          onClick: onClose,
        },
        {
          children: rtiEnabled
            ? t('rti.confirm-modal.start-confirm')
            : t('rti.confirm-modal.end-confirm'),
          variant: 'tertiary',
          loading: isLoading,
          disabled: isLoading,
          onClick: onConfirm,
        },
      ]}
    >
      <>{t('rti.confirm-modal.description')}</>
    </Modal>
  );
}
