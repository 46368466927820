import React from 'react';
import { List, MaterialRow } from './MaterialsTable.styles';

type Material = {
  course: string;
  id: string;
  language: string;
  name: string;
  totalOperationQuantity: number;
  units: string;
};

type MaterialsTableProps = {
  materials: Material[];
};

const MaterialsTable = ({ materials }: MaterialsTableProps) => {
  return (
    <List>
      {materials.map((material, i) => (
        <MaterialRow key={`${material.id}_${i}`}>
          <span>{material.course}</span>
          <span className="materialName">{material.name}</span>
          <span>{material.language}</span>
          <span className="totalOperationQuantity">
            {material.totalOperationQuantity}
            <span>{material.units}</span>
          </span>
        </MaterialRow>
      ))}
    </List>
  );
};

export default MaterialsTable;
