import { useMemo } from 'react';
import { useContentListsQuery } from '../../generated/types';
import { useCompletedResources } from '../use-completed-resources';
import { mapContentListResponse } from '../use-page/mapper';
import { ContentListType } from '../use-page/types';
import { ContentList, ContentListsResponse } from './types';

type Props = {
  contentListsIds: string[];
  academicYearId: string;
  classroomId: string;
  isStudentView?: boolean;
};

type Return = {
  isLoading: boolean;
  isError: boolean;
  data: ContentListType[];
};

export function useContentLists({
  contentListsIds,
  classroomId,
  academicYearId,
  isStudentView,
}: Props): Return {
  const completedResources = useCompletedResources({
    classroomId,
    academicYearId,
  });

  const { data, isLoading, isError } = useContentListsQuery({
    ids: contentListsIds,
  });

  const contentListsData =
    data?.contentLists as ContentListsResponse['contentLists'];

  const contentLists: ContentListType[] = useMemo(() => {
    if (!contentListsData) return [];
    return contentListsData.map((contentListData: ContentList) =>
      mapContentListResponse(
        contentListData,
        completedResources,
        Boolean(isStudentView)
      )
    );
  }, [completedResources, isStudentView, contentListsData]);

  return {
    isLoading,
    isError,
    data: contentLists,
  };
}
