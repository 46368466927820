import { Classroom } from '@innovamat/glow-api-client';
import { QueryClient } from '@tanstack/react-query';

type UpdateProps = {
  queryClient: QueryClient;
  orgId: string;
  updatedClassroom: Classroom;
};

type ClassroomsQueryData = { classrooms: Classroom[] };

function updateClassroomInCache({
  queryClient,
  orgId,
  updatedClassroom,
}: UpdateProps): void {
  const mineVariables = ['Classrooms', { mine: true, orgId }];
  const notMineVariables = ['Classrooms', { mine: false, orgId }];

  const mineCurrentClassrooms =
    queryClient.getQueryData<ClassroomsQueryData>(mineVariables)?.classrooms;

  const notMineCurrentClassrooms =
    queryClient.getQueryData<ClassroomsQueryData>(notMineVariables)?.classrooms;

  if (mineCurrentClassrooms) {
    const updatedClassrooms = mineCurrentClassrooms.map((classroom) =>
      classroom.id === updatedClassroom.id ? updatedClassroom : classroom
    );

    queryClient.setQueryData(mineVariables, {
      classrooms: updatedClassrooms,
    });
  }

  if (notMineCurrentClassrooms) {
    const updatedClassrooms = notMineCurrentClassrooms.map((classroom) =>
      classroom.id === updatedClassroom.id ? updatedClassroom : classroom
    );

    queryClient.setQueryData(notMineVariables, {
      classrooms: updatedClassrooms,
    });
  }
}

export { updateClassroomInCache };
