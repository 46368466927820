import {
  HEADER_INDEX,
  LAST_NAME_FIELD,
  NAME_FIELD,
  SIS_ID_FIELD,
} from '../utils/constants';

function isCsvValid(csvData: string[][]): boolean {
  try {
    const headers = csvData[HEADER_INDEX];
    const nameIndex = headers.indexOf(NAME_FIELD);
    const lastNameIndex = headers.indexOf(LAST_NAME_FIELD);
    const sisIdIndex = headers.indexOf(SIS_ID_FIELD);

    const hasNameColumn = nameIndex !== -1;
    const hasLastNameColumn = lastNameIndex !== -1;
    const hasSisIdColumn = sisIdIndex !== -1;

    if (!hasNameColumn || !hasLastNameColumn || !hasSisIdColumn) {
      return false;
    }

    for (let i = 1; i < csvData.length; i++) {
      const sisId = csvData[i][sisIdIndex].trim();
      if (!sisId) {
        return false;
      }
    }

    return true;
  } catch (error) {
    return false;
  }
}

export { isCsvValid };
