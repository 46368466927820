import { useGlowApiClient } from './graphql-provider';

export const useFetch = <TData, TVariables>(
  query: string,
  headers?: Record<string, string>
): ((variables?: TVariables) => Promise<TData>) => {
  const { graphqlClient } = useGlowApiClient();

  return async (variables?: TVariables) => {
    return graphqlClient.request(query, variables, headers);
  };
};
