import ContentLoader from 'react-content-loader';

type Props = {
  height?: number;
  width?: number;
};

const TypographySkeleton = ({ height = 16, width = 150 }: Props) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
  </ContentLoader>
);

export { TypographySkeleton };
