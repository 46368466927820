import { Wizard } from '@innovamat/glimmer-components';
import {
  OnboardingProvider,
  useOnboardingForm,
} from './context/onboarding-form-context';

export function OnboardingForm(props: { onClose: () => void }): JSX.Element {
  return (
    <OnboardingProvider {...props}>
      <OnboardingFormBase />
    </OnboardingProvider>
  );
}

function OnboardingFormBase(): JSX.Element {
  const { steps, currentStep, goToNextStep, goToPrevStep } =
    useOnboardingForm();

  return (
    <Wizard
      steps={steps}
      currentStep={currentStep}
      handleNextStep={goToNextStep}
      handlePrevStep={goToPrevStep}
      colWidth={6}
    />
  );
}
