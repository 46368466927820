export { default as Apertura } from './Apertura';
export { default as Cierre } from './Cierre';
export { default as Dessarollo } from './Dessarollo';
export { default as HandsOn } from './HandsOn';
export { default as Move } from './Move';
export { default as Register } from './Register';
export { default as ResetTests } from './ResetTests';
export { default as WarmUp } from './WarmUp';
export { default as Abacus } from './Abacus';
export { default as AddCircle } from './AddCircle';
export { default as AddImage } from './AddImage';
export { default as AnalyticsGraph } from './AnalyticsGraph';
export { default as Argumentar } from './Argumentar';
export { default as ArrowDownMedium } from './ArrowDownMedium';
export { default as ArrowDown } from './ArrowDown';
export { default as ArrowDownSm } from './ArrowDownSm';
export { default as ArrowLeft } from './ArrowLeft';
export { default as ArrowLeft2 } from './ArrowLeft2';
export { default as ArrowLeft3 } from './ArrowLeft3';
export { default as ArrowRight } from './ArrowRight';
export { default as ArrowRight3 } from './ArrowRight3';
export { default as ArrowUp } from './ArrowUp';
export { default as ArrowUp2 } from './ArrowUp2';
export { default as ArrowUpReports } from './ArrowUpReports';
export { default as Bell } from './Bell';
export { default as BigZoomIn } from './BigZoomIn';
export { default as BoardGameJenga } from './BoardGameJenga';
export { default as BubbleInfo } from './BubbleInfo';
export { default as CalendarDate } from './CalendarDate';
export { default as CameraVideo } from './CameraVideo';
export { default as Camera } from './Camera';
export { default as Chair } from './Chair';
export { default as ChangingAgrupation } from './ChangingAgrupation';
export { default as ChatUser } from './ChatUser';
export { default as Chat } from './Chat';
export { default as CheckCircle } from './CheckCircle';
export { default as Check } from './Check';
export { default as CheckList } from './CheckList';
export { default as Clip } from './Clip';
export { default as Clipboard } from './Clipboard';
export { default as Close } from './Close';
export { default as CloseBig } from './CloseBig';
export { default as ContentWrite } from './ContentWrite';
export { default as ContentApplet } from './ContentApplet';
export { default as ContentBase } from './ContentBase';
export { default as ContentFlag } from './ContentFlag';
export { default as Copy } from './Copy';
export { default as Crics } from './Crics';
export { default as Cube } from './Cube';
export { default as Dashboard } from './Dashboard';
export { default as DefaultAvatar } from './DefaultAvatar';
export { default as DeleteTrash } from './DeleteTrash';
export { default as Depart } from './Depart';
export { default as DigitalResources } from './DigitalResources';
export { default as Doneinclass } from './Doneinclass';
export { default as DotsLock } from './DotsLock';
export { default as DownloadFileGuides } from './DownloadFileGuides';
export { default as DownloadFile } from './DownloadFile';
export { default as DownloadPdf } from './DownloadPdf';
export { default as DownloadXls } from './DownloadXls';
export { default as DownloadZip } from './DownloadZip';
export { default as Download } from './Download';
export { default as DrawerSend } from './DrawerSend';
export { default as ELearningMonitor } from './ELearningMonitor';
export { default as EditFamily } from './EditFamily';
export { default as EditList } from './EditList';
export { default as EditStudent } from './EditStudent';
export { default as Edit } from './Edit';
export { default as EducativeToysMath } from './EducativeToysMath';
export { default as EducativeToys } from './EducativeToys';
export { default as Enter } from './Enter';
export { default as Error } from './Error';
export { default as Espais } from './Espais';
export { default as Evaluate } from './Evaluate';
export { default as Evaluation } from './Evaluation';
export { default as ExpandPlayer } from './ExpandPlayer';
export { default as Expand } from './Expand';
export { default as ExpandSquare } from './ExpandSquare';
export { default as Extension } from './Extension';
export { default as FileSearch } from './FileSearch';
export { default as FileXls } from './FileXls';
export { default as Fly } from './Fly';
export { default as Geogebra } from './Geogebra';
export { default as Geometry } from './Geometry';
export { default as GoogleClassroom } from './GoogleClassroom';
export { default as GoogleDrive } from './GoogleDrive';
export { default as Google } from './Google';
export { default as Home } from './Home';
export { default as HugeGroup } from './HugeGroup';
export { default as IcWorldProblems } from './IcWorldProblems';
export { default as Import } from './Import';
export { default as ImportCsv } from './ImportCsv';
export { default as Indagar } from './Indagar';
export { default as Info } from './Info';
export { default as Info2 } from './Info2';
export { default as Interaction } from './Interaction';
export { default as JumpActivity } from './JumpActivity';
export { default as Keyboard } from './Keyboard';
export { default as KingBadge } from './KingBadge';
export { default as King } from './King';
export { default as LabFlask } from './LabFlask';
export { default as Land } from './Land';
export { default as LargeLeftArrow } from './LargeLeftArrow';
export { default as LargeRightArrow } from './LargeRightArrow';
export { default as Learning } from './Learning';
export { default as LegalScaleUnequal } from './LegalScaleUnequal';
export { default as LittleGroupRegister } from './LittleGroupRegister';
export { default as Littlegroup } from './Littlegroup';
export { default as Logout } from './Logout';
export { default as Luck } from './Luck';
export { default as MailSend } from './MailSend';
export { default as Managment } from './Managment';
export { default as Manipulative } from './Manipulative';
export { default as MapsMark } from './MapsMark';
export { default as MapsPin } from './MapsPin';
export { default as Material } from './Material';
export { default as Measure } from './Measure';
export { default as Menu } from './Menu';
export { default as Minus } from './Minus';
export { default as Modelizar } from './Modelizar';
export { default as MonitorDownload } from './MonitorDownload';
export { default as MonitorTouch } from './MonitorTouch';
export { default as MultipleUsers } from './MultipleUsers';
export { default as Notdoneinclass } from './Notdoneinclass';
export { default as NotesTask } from './NotesTask';
export { default as Notes } from './Notes';
export { default as Numeration } from './Numeration';
export { default as OpenArrow } from './OpenArrow';
export { default as OpenBook } from './OpenBook';
export { default as OutdoorsLandscape } from './OutdoorsLandscape';
export { default as Package } from './Package';
export { default as PageAvailability } from './PageAvailability';
export { default as PaginateFilter1 } from './PaginateFilter1';
export { default as PaginateFilter2 } from './PaginateFilter2';
export { default as PaginateFilter3 } from './PaginateFilter3';
export { default as PaginateFilter4 } from './PaginateFilter4';
export { default as PaginateFilter5 } from './PaginateFilter5';
export { default as PaginateFilter6 } from './PaginateFilter6';
export { default as PasswordLock } from './PasswordLock';
export { default as Pencil } from './Pencil';
export { default as Pill } from './Pill';
export { default as Pin } from './Pin';
export { default as PlayClass } from './PlayClass';
export { default as Play2 } from './Play2';
export { default as Play } from './Play';
export { default as PlayerArrowLeft } from './PlayerArrowLeft';
export { default as PlayerArrowRight } from './PlayerArrowRight';
export { default as Plus } from './Plus';
export { default as Preparem } from './Preparem';
export { default as Printing } from './Printing';
export { default as QuestionCircle } from './QuestionCircle';
export { default as RatingStar } from './RatingStar';
export { default as Refresh } from './Refresh';
export { default as RegisterHugeGroup } from './RegisterHugeGroup';
export { default as Retract } from './Retract';
export { default as Rule } from './Rule';
export { default as SamCultural } from './SamCultural';
export { default as SamHistory } from './SamHistory';
export { default as SamMath } from './SamMath';
export { default as SamSocioafectives } from './SamSocioafectives';
export { default as SamVideo } from './SamVideo';
export { default as SchoolBoardMaths } from './SchoolBoardMaths';
export { default as SchoolTeacher } from './SchoolTeacher';
export { default as Search } from './Search';
export { default as SendEmail } from './SendEmail';
export { default as Settings } from './Settings';
export { default as ShipmentUpload } from './ShipmentUpload';
export { default as ShowMore } from './ShowMore';
export { default as Shrink } from './Shrink';
export { default as Solutions } from './Solutions';
export { default as Star } from './Star';
export { default as Statistics } from './Statistics';
export { default as StudentPermission } from './StudentPermission';
export { default as StudyDesk } from './StudyDesk';
export { default as Suport } from './Suport';
export { default as Tab } from './Tab';
export { default as TaskListClock } from './TaskListClock';
export { default as Tetris } from './Tetris';
export { default as TimeStopwatch } from './TimeStopwatch';
export { default as Touch } from './Touch';
export { default as Tramo7 } from './Tramo7';
export { default as Tramo8 } from './Tramo8';
export { default as Tramo9 } from './Tramo9';
export { default as Transfer } from './Transfer';
export { default as Trust } from './Trust';
export { default as UncheckCircle } from './UncheckCircle';
export { default as Unlink } from './Unlink';
export { default as UserPairsRegister } from './UserPairsRegister';
export { default as UserPairs } from './UserPairs';
export { default as UserRegister } from './UserRegister';
export { default as User } from './User';
export { default as VidaAula } from './VidaAula';
export { default as VideoGameController } from './VideoGameController';
export { default as Video } from './Video';
export { default as VideoDone } from './VideoDone';
export { default as VideoDuration } from './VideoDuration';
export { default as View } from './View';
export { default as ViewDisabled } from './ViewDisabled';
export { default as Warning } from './Warning';
export { default as Whatsapp } from './Whatsapp';
export { default as World } from './World';
export { default as Zoomin } from './Zoomin';
export { default as Zoomout } from './Zoomout';
