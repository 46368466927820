import React from 'react';
import styles from './ToggleButton.module.scss';

type Props = {
  isActive?: boolean;
  isDisabled?: boolean;
  onChange?: (checked: boolean) => void;
  id: string;
  dataTestId?: string;
};

function ToggleButton({
  isActive,
  isDisabled = false,
  onChange,
  id,
  dataTestId,
}: Props) {
  return (
    <div className={styles.toggleContainer}>
      <input
        className={styles.toggleInput}
        id={id}
        type="checkbox"
        checked={isActive}
        onChange={(e) => onChange?.(e.target.checked)}
        disabled={isDisabled}
        data-testid={dataTestId}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={styles.toggleLabel} htmlFor={id} />
    </div>
  );
}

export default ToggleButton;
