import {
  AnnouncementPanel,
  Button,
  Modal,
} from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { Description } from '../import-classroom-modal/import-classroom-modal.styles';
import { ModalContent, ModalFooter } from './syncro-classroom-modal.styles';

type SyncroClassroomModalProps = {
  isGoogleClassroomDeletedInGoogleError?: boolean;
  isStudentAlreadyExistsError?: boolean;
  isStudentAlreadyExistsAsATeacherError?: boolean;
  isError?: boolean;
  isModalOpen: boolean;
  onCloseModal: () => void;
  onSyncroClassroom: () => void;
  onConvertClassroomToInnovamat: () => void;
  onDeleteClassroom: () => void;
};

export default function SyncroClassroomModal({
  isGoogleClassroomDeletedInGoogleError,
  isStudentAlreadyExistsError,
  isStudentAlreadyExistsAsATeacherError,
  isError,
  isModalOpen,
  onCloseModal,
  onSyncroClassroom,
  onConvertClassroomToInnovamat,
  onDeleteClassroom,
}: SyncroClassroomModalProps) {
  const { t } = useTranslation();

  // Code below is necessary to make the integration test work because Modal Component is a Portal
  let modalRoot = document.getElementById('modal') as HTMLElement;
  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'modal');
    document.body.appendChild(modalRoot);
  }

  if (isGoogleClassroomDeletedInGoogleError) {
    return (
      <Modal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        closeOnClickOutside
        closeButton="inner"
        title={t('modal.synchro.class.error_classroom_deleted.title')}
        modalWidth={600}
        customFooter={
          <ModalFooter>
            <Button variant="secondary" onClick={onDeleteClassroom}>
              {t('modal.syncrho.class.error_classroom_deleted.cta_delete')}
            </Button>
            <Button onClick={onConvertClassroomToInnovamat}>
              {t('modal.syncrho.class.error_classroom_deleted.cta_mantain')}
            </Button>
          </ModalFooter>
        }
      >
        <ModalContent>
          <Description>
            {t('modal.synchro.class.error_classroom_deleted.subtitle', {
              provider: 'Google Classroom',
            })}
          </Description>
        </ModalContent>
      </Modal>
    );
  }

  if (
    isError ||
    isStudentAlreadyExistsError ||
    isStudentAlreadyExistsAsATeacherError
  ) {
    return (
      <Modal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        closeOnClickOutside
        closeButton="inner"
        title={t('modal.syncro.class.title')}
        buttons={[
          {
            variant: 'accent',
            onClick: onCloseModal,
            children: t('modal.connect.class.cta.finish'),
          },
        ]}
        modalWidth={600}
      >
        <AnnouncementPanel
          canClose={false}
          type="error"
          text={t(
            isStudentAlreadyExistsError
              ? 'modal.class.import.banner.error.student_already_exists'
              : isStudentAlreadyExistsAsATeacherError
              ? 'modal.class.import.banner.error.this_is_a_teacher'
              : 'modal.class.import.banner.error'
          )}
        />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={onCloseModal}
      onSubmit={onSyncroClassroom}
      closeOnClickOutside
      closeButton="inner"
      title={t('modal.syncro.class.title')}
      buttons={[
        {
          variant: 'secondary',
          onClick: onCloseModal,
          children: t('modal.syncro.class.cta.cancel'),
        },
        {
          variant: 'accent',
          onClick: onSyncroClassroom,
          children: t('modal.syncro.class.cta.syncro'),
        },
      ]}
      modalWidth={600}
    >
      <ModalContent>
        <Description>{t('modal.syncro.class.subtitle')}</Description>
      </ModalContent>
    </Modal>
  );
}
