import { Grid } from '@innovamat/glimmer-components';
import { Skeleton } from '@innovamat/glimmer-components';
import {
  ParentLandingContainer,
  MainCol,
  StudentsCol,
  StudentsDivDesktop,
  StudentsDivMobile,
} from './landing-page-parent';
import { Container as BlockContainer } from './components';
import { ToolsContainer } from './components';

function TitleSkeleton(): JSX.Element {
  return <Skeleton width="260px" height="32px" />;
}

type Props = { isMobile?: boolean };

function StudentsInfoSkeleton({ isMobile }: Props): JSX.Element {
  return (
    <BlockContainer>
      <TitleSkeleton />
      <Skeleton width="100%" height={isMobile ? '156px' : '288px'} />
    </BlockContainer>
  );
}

function LandingPageParentSkeleton(): JSX.Element {
  return (
    <ParentLandingContainer>
      <Grid.Row justifyContent="center">
        <MainCol md={8} lg={7}>
          {/* Video */}
          <BlockContainer>
            <TitleSkeleton />
            <Skeleton width="100%" height="156px" />
          </BlockContainer>

          {/* Students info mobile */}
          <StudentsDivMobile>
            <StudentsInfoSkeleton isMobile />
          </StudentsDivMobile>

          {/* Shortcuts */}
          <BlockContainer>
            <TitleSkeleton />
            <ToolsContainer>
              <Skeleton width="100%" height="72px" />
              <Skeleton width="100%" height="72px" />
              <Skeleton width="100%" height="72px" />
            </ToolsContainer>
          </BlockContainer>

          {/* FAQS */}
          <BlockContainer>
            <TitleSkeleton />
            <Skeleton width="100%" height="56px" />
            <Skeleton width="100%" height="56px" />
          </BlockContainer>
        </MainCol>

        {/* Students info desktop */}
        <StudentsCol md={4} lg={3}>
          <StudentsDivDesktop>
            <StudentsInfoSkeleton isMobile />
          </StudentsDivDesktop>
        </StudentsCol>
      </Grid.Row>
    </ParentLandingContainer>
  );
}

export { LandingPageParentSkeleton, StudentsInfoSkeleton };
