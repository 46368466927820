import { useEffect } from 'react';
import { getAcceptLanguage } from '@innovamat/localization';
import {
  AppletResource,
  AppletsInfo,
  Tokens,
  UnityPayload,
} from '@innovamat/resource-viewer';
import {
  ActivityParameters,
  UserParameters,
  MessageType,
  WebAppletWrapper,
} from '@innovamat/resource-viewer';
import { authAxiosInstance } from '@innovamat/ga-features';

type UnityProps = {
  unityPayload: UnityPayload;
  unityActivity: any;
  onUpdateTokens: (tokens: Tokens) => void;
};

export type ActivityType = 'MOTOR' | 'QUIZ' | 'WEB' | 'ACTIVITY';

type Props = {
  type: ActivityType;
  unityProps?: UnityProps;
  onCloseApplet: () => void;
  onAppletIsCompleted: (args?: MessageType['arguments']) => void;
  onException: () => void;
  sceneName: string;
  packOrVariation: string;
  userParameters: UserParameters;
  appletInfo: AppletsInfo;
  activityParameters?: ActivityParameters;
  setEventData: (eventType: string, eventProperties?: any) => void;
  slackApiUrl: string;
  slackApiTokens: string;
  apolloServer: string;
};

const SessionWrapper = ({
  type,
  unityProps,
  onCloseApplet,
  onAppletIsCompleted,
  onException,
  sceneName,
  packOrVariation,
  userParameters,
  appletInfo,
  activityParameters,
  setEventData,
  slackApiUrl,
  slackApiTokens,
  apolloServer,
}: Props) => {
  const { unityPayload, unityActivity, onUpdateTokens } = unityProps || {};
  const { language, school, uid } = userParameters;
  const { regionParameters, region, solverUrl, webContentsUrl } = appletInfo;

  const handleOnCloseApplet = (): void => {
    onCloseApplet();
  };

  const handleOnAppletIsCompleted = (args?: MessageType['arguments']): void => {
    onAppletIsCompleted(args);
  };
  // End Applet health monitoring

  // ENABLE messages for secondary on 'secondary-web-errors' slack channel
  appletInfo.slackApiUrl = slackApiUrl;
  appletInfo.slackApiTokens = slackApiTokens;

  const appletWrapperLanguage = region
    ? getAcceptLanguage(region, language)
    : language;

  return type === 'WEB' ? (
    <WebAppletWrapper
      userParameters={{
        ...userParameters,
        language: appletWrapperLanguage,
      }}
      regionalParameters={regionParameters}
      activityParameters={activityParameters!}
      onCloseApplet={onCloseApplet}
      onAppletIsCompleted={onAppletIsCompleted}
      webContentsUrl={webContentsUrl!}
      solverUrl={solverUrl!}
      setEventData={setEventData}
    />
  ) : (
    <AppletResource
      apolloServer={apolloServer}
      axiosInstance={authAxiosInstance}
      setEventData={setEventData}
      payload={unityPayload}
      unityActivity={unityActivity}
      onCloseApp={handleOnCloseApplet}
      onAppletIsCompleted={handleOnAppletIsCompleted}
      sceneName={sceneName}
      packOrVariation={packOrVariation}
      language={language}
      appletInfo={appletInfo}
      style={{ maxWidth: '100% !important' }}
      onUpdateTokens={onUpdateTokens}
      school={school}
      uid={uid}
      onException={onException}
    />
  );
};

export default SessionWrapper;
