import { useReducer } from 'react';

import type { Material, StagedMaterials } from '../current-materials.types';
import { cloneDeep } from '@innovamat/radiance-utils';

const ActionType = {
  CLEAR_CART: 'CLEAR_CART',
  INITIALIZE_STORE: 'INITIALIZE_STORE',
  ORDER_RETURN_ACTION: 'ORDER_RETURN_ACTION',
  SET_EDIT_MODE: 'SET_EDIT_MODE',
} as const;

const initialState = {
  cart: [] as Material[],
  initialStagedMaterials: [] as StagedMaterials[],
  isEditMode: false,
  materials: [] as Material[],
  stagedMaterials: [] as StagedMaterials[],
  storedOrganizationId: '',
};

type Action = {
  type: (typeof ActionType)[keyof typeof ActionType];
  // Todo - type payload, don't use any
  payload?: any;
};

type MaterialsState = typeof initialState;

const materialsReducer = (
  state: MaterialsState,
  { type, payload }: Action
): MaterialsState => {
  const checkIsMaterialInCart = () =>
    state.cart.find(
      (currentMaterial: Material) => currentMaterial.id === payload.materialId
    );

  const getStageIndex = () =>
    state.stagedMaterials.findIndex((stage) => stage.id === payload.stageId);

  const getMaterialIndex = (stageIndex: number) =>
    state.stagedMaterials[stageIndex].materials.findIndex(
      (material) => material.id === payload.materialId
    );

  switch (type) {
    case ActionType.INITIALIZE_STORE:
      return {
        cart: payload.cart,
        initialStagedMaterials: payload.initialStagedMaterials,
        isEditMode: payload.isEditMode,
        materials: payload.materials,
        stagedMaterials: payload.stagedMaterials,
        storedOrganizationId: payload.storedOrganizationId,
      };

    case ActionType.CLEAR_CART:
      return {
        ...state,
        cart: [] as Material[],
        stagedMaterials: state.initialStagedMaterials,
      };

    case ActionType.ORDER_RETURN_ACTION: {
      const stageIndex = getStageIndex();
      const materialIndex = getMaterialIndex(stageIndex);
      const newStagedMaterials = cloneDeep(state.stagedMaterials);

      newStagedMaterials[stageIndex].materials[
        materialIndex
      ].controlsCounterValue += payload.operationQuantity;

      if (!checkIsMaterialInCart()) {
        // if not in cart, add it
        return {
          ...state,
          stagedMaterials: [...newStagedMaterials],
          cart: [
            ...state.cart,
            {
              ...state.materials.find(
                (currentMaterial: Material) =>
                  currentMaterial.id === payload.materialId
              ),
              totalOperationQuantity: payload.operationQuantity,
            } as Material,
          ],
        };
      }
      // if in cart, update totalOperationQuantity
      return {
        ...state,
        stagedMaterials: [...newStagedMaterials],
        cart: state.cart
          .map((currentMaterialInCart: Material) => {
            if (currentMaterialInCart.id === payload.materialId) {
              const quantityResult =
                currentMaterialInCart.totalOperationQuantity +
                payload.operationQuantity;

              // if the totaloperationQuantity plus operationQuantity is 0, remove it from cart
              if (quantityResult === 0) {
                return {} as Material;
              }

              return {
                ...currentMaterialInCart,
                totalOperationQuantity: quantityResult,
              };
            }

            return currentMaterialInCart;
          })
          .filter((element) => {
            return Object.keys(element).length !== 0;
          }),
      };
    }

    case ActionType.SET_EDIT_MODE: {
      return {
        ...state,
        isEditMode: payload.isEditMode,
      };
    }

    default:
      return state;
  }
};

function useCurrentMaterialsReducer() {
  const [
    {
      initialStagedMaterials,
      isEditMode,
      cart,
      materials,
      stagedMaterials,
      storedOrganizationId,
    },
    dispatch,
  ] = useReducer(materialsReducer, initialState);

  return {
    clearCart: () => dispatch({ type: ActionType.CLEAR_CART }),
    init: (state: MaterialsState) =>
      dispatch({
        type: ActionType.INITIALIZE_STORE,
        payload: {
          ...initialState,
          ...state,
        },
      }),
    orderMaterial: (stageId: string, materialId: string) =>
      dispatch({
        type: ActionType.ORDER_RETURN_ACTION,
        payload: { stageId, materialId, operationQuantity: 1 },
      }),
    returnMaterial: (stageId: string, materialId: string) =>
      dispatch({
        type: ActionType.ORDER_RETURN_ACTION,
        payload: { stageId, materialId, operationQuantity: -1 },
      }),
    setIsEditMode: (status: boolean) =>
      dispatch({
        type: ActionType.SET_EDIT_MODE,
        payload: { isEditMode: status },
      }),
    cart,
    initialStagedMaterials,
    isEditMode,
    materials,
    stagedMaterials,
    storedOrganizationId,
  };
}

export default useCurrentMaterialsReducer;

export type { MaterialsState };
