import React, {
  createContext,
  useReducer,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { FullScreenHandle } from 'react-full-screen';
import { useUpdateEffect } from '@innovamat/hooks';
import playerReducer, { initState } from '../../state/reducer';
import { Action, Dispatch, PlayerState } from '../../types/state';
import {
  AppletsInfo,
  ResourceViewerProps,
  VideoOptions,
} from '../../types/resourceViewer';
import { AxiosInstance } from 'axios';
import { useIOSFullScreen } from '../../hooks/useIOSFullScreen';

type PlayerProviderProps = ResourceViewerProps & {
  children: React.ReactNode;
  preventArrowKeysEvents?: boolean;
  fullscreen: FullScreenHandle;
  setEventData: (
    eventType: string,
    eventProperties?: any,
    addUserData?: boolean
  ) => void;
  axiosInstance: AxiosInstance;
  apolloServer: string;
  videoOptions?: VideoOptions;
  school: string;
  uid: string;
};

type PlayerProviderValue = PlayerState & {
  translations: Record<string, string>;
  preventArrowKeysEvents?: boolean;
  appletInfo: AppletsInfo | undefined;
  fullscreen: FullScreenHandle;
  fullscreenIOS: boolean;
  handleFullScreenInIOS: () => void;
  isSinglePdfOrGenially: boolean;
  setEventData: (
    eventType: string,
    eventProperties?: any,
    addUserData?: boolean
  ) => void;
  axiosInstance: AxiosInstance;
  apolloServer: string;
  videoOptions?: VideoOptions;
  school: string;
  uid: string;
  autoplay?: boolean;
};

export const PlayerStateContext = createContext<
  PlayerProviderValue | undefined
>(undefined);
export const PlayerDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

export default function PlayerProvider({
  children,
  onDispatchEvent,
  resource,
  translations,
  fullscreen,
  appletInfo,
  preventArrowKeysEvents,
  setEventData,
  axiosInstance,
  apolloServer,
  videoOptions,
  school,
  uid,
  autoplay,
}: PlayerProviderProps) {
  const createStore = useCallback(
    (state: PlayerState, action: Action) => {
      const reducer = playerReducer(state, action);
      onDispatchEvent?.(state, reducer, action);
      return reducer;
    },
    [onDispatchEvent]
  );

  const { fullscreenIOS, handleFullScreenInIOS } = useIOSFullScreen();

  const [state, dispatch] = useReducer(createStore, {
    ...initState,
  });

  useUpdateEffect(() => {
    dispatch({
      type: 'TOGGLE_FULLSCREEN',
    });
  }, [fullscreen?.active]);

  useEffect(() => {
    if (!resource) return;
    dispatch({
      type: 'SET_RESOURCE',
      payload: resource,
    });
  }, [resource]);

  const isSinglePdfOrGenially =
    (resource && !('resources' in resource) && resource.type === 'pdf') ||
    resource?.type === 'genially';

  // const value: PlayerProviderValue = useMemo(
  const value: PlayerProviderValue = useMemo(
    () => ({
      ...state,
      preventArrowKeysEvents,
      translations,
      appletInfo,
      fullscreen,
      fullscreenIOS,
      handleFullScreenInIOS,
      isSinglePdfOrGenially,
      setEventData,
      axiosInstance,
      apolloServer,
      videoOptions,
      school,
      uid,
      autoplay,
    }),
    [
      state,
      preventArrowKeysEvents,
      translations,
      appletInfo,
      fullscreen,
      fullscreenIOS,
      handleFullScreenInIOS,
      isSinglePdfOrGenially,
      setEventData,
      axiosInstance,
      apolloServer,
      videoOptions,
      school,
      uid,
      autoplay,
    ]
  );

  return (
    <PlayerStateContext.Provider value={value}>
      <PlayerDispatchContext.Provider value={dispatch}>
        {children}
      </PlayerDispatchContext.Provider>
    </PlayerStateContext.Provider>
  );
}
