import { useDevice } from '@innovamat/glimmer-components';
import { useEffect, useRef, useState } from 'react';

export default function useAccordion({
  scrollPosition,
  onScroll,
  itemsLength,
  getIsOpen,
}: any) {
  const refs = useRef<Array<HTMLDivElement | null>>([]);
  const { scrollTop, clientHeight } = scrollPosition;
  const [isScrollingDown, setIsScrollingDown] = useState<boolean>(true);
  const { isMobile } = useDevice();

  const lastScrollY = useRef<number>(scrollTop);

  const scrollingPercentages = {
    scrollingDownMin: isMobile ? 8 : 21,
    scrollingDownMax: isMobile ? 12 : 26,

    scrollingUpMinWhenOpen: isMobile ? -92 : -45,
    scrollingUpMaxWhenOpen: isMobile ? -88 : -43,

    scrollingUpMin: isMobile ? -18 : -6,
    scrollingUpMax: isMobile ? -16 : -4,
  };

  const setRef = (index: number, al: any) => {
    refs.current[index] = al;
  };

  useEffect(() => {
    refs.current = refs.current.slice(0, itemsLength);
  }, [itemsLength]);

  useEffect(() => {
    const scrollY = scrollTop;
    if (scrollY < lastScrollY.current) {
      setIsScrollingDown(false);
    } else {
      setIsScrollingDown(true);
    }
    lastScrollY.current = scrollY;
  }, [scrollTop]);

  useEffect(() => {
    const elementsRef = refs.current;
    elementsRef.forEach((ref, index) => {
      if (!ref) return;
      const elDistanceToTop = clientHeight
        ? (ref.getBoundingClientRect().top * 100) / clientHeight
        : 0;

      const isOpen = getIsOpen(index);

      if (scrollTop >= 4) {
        if (isScrollingDown) {
          if (
            elDistanceToTop >= scrollingPercentages.scrollingDownMin &&
            elDistanceToTop <= scrollingPercentages.scrollingDownMax
          ) {
            onScroll(index);
          }
        } else if (isOpen) {
          if (
            elDistanceToTop >= scrollingPercentages.scrollingUpMinWhenOpen &&
            elDistanceToTop <= scrollingPercentages.scrollingUpMaxWhenOpen
          ) {
            onScroll(index);
          }
        } else if (
          elDistanceToTop >= scrollingPercentages.scrollingUpMin &&
          elDistanceToTop <= scrollingPercentages.scrollingUpMax
        ) {
          onScroll(index);
        }
      } else {
        onScroll(undefined);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop]);

  return {
    setRef,
  };
}
