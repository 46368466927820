import { useEffect, useMemo, useRef, useState } from 'react';
import WeeklyResultsHeader from './weekly-results.header';
import {
  getStudentsRows,
  preprocessWeeklyReport,
} from '../../utils/preprocess';
import { getWeeklyColumns } from './utils/get-weekly-columns';
import { useTranslation } from 'react-i18next';
import { Modal } from '@innovamat/glimmer-components';
import { getDateLabel } from '../../utils/common';
import useStickyTable from '../../hooks/use-sticky-table';
import { Stage } from '@innovamat/radiance-utils';
import SummaryDrawer from '../../components/summary-drawer';
import useSummaryDrawer from '../../hooks/use-summary-drawer';
import NotesOfTheWeek from '../../components/notes-of-the-week';
import useDownloadReports from '../../hooks/use-download-reports';
import usePracticePeriod from '../../hooks/use-practice-period';
import { renderTables } from '../../utils/render-tables';
import {
  Course,
  useWeeklyReportsDatesQuery,
  useCoreActivitiesQuery,
  CoreActivitiesByBubble,
  WeeklyReport,
  useWeeklyReportsQuery,
  Classroom,
} from '@innovamat/glow-api-client';

type Props = {
  isSecundaria: boolean;
  isEarlyYears: boolean;
  setEventData: (eventType: string, eventProperties?: any) => void;
  educationalStage: Stage;
  courses: Course[];
  isNewManager?: boolean;
  handleNavigateToResource: (appletId: string) => void;
  handleNavigate: (url: string, queryParams?: string) => void;
  locationInfo: {
    search: string;
    pathname: string;
  };
  trainingZonePrimaryReports: boolean;
  classroom: Classroom;
  hasLastYearReport?: boolean | string;
  isLastYearReport?: boolean;
};

export type TimeSchedule = 'home' | 'school' | 'all';

export type ReportDate = {
  from: string;
  to: string;
};

type WeeklyReportsQuery = {
  weeklyReports: WeeklyReport[];
};

export function WeeklyResults({
  isSecundaria,
  isEarlyYears,
  setEventData,
  educationalStage,
  courses,
  isNewManager,
  handleNavigateToResource,
  handleNavigate,
  locationInfo,
  trainingZonePrimaryReports,
  classroom,
  hasLastYearReport,
  isLastYearReport,
}: Props): JSX.Element {
  const [expandedColumn, setExpandedColumn] = useState({});
  const [visibleAppletPreview, setVisibleAppletPreview] = useState('');
  const [interactingAppletPreview, setInteractingAppletPreview] =
    useState(false);
  const interactingRef = useRef(interactingAppletPreview);
  interactingRef.current = interactingAppletPreview;
  const [reportDate, setReportDate] = useState<ReportDate | null>(null);
  const [practicePeriod, setPracticePeriod] = useState<TimeSchedule>('all');
  const [currentReport, setCurrentReport] = useState<string>('');

  const { t } = useTranslation();
  const { isStickyTable, headerRef } = useStickyTable();

  const stage = isSecundaria ? 'secondary' : 'primary';

  useEffect(() => {
    if (reportDate) refetch();
  }, [reportDate, practicePeriod]);

  useEffect(() => {
    handleSendEvent('weekly_dashboard_access');
  }, []);

  useEffect(() => {
    setPracticePeriod('all');
    setExpandedColumn({});
  }, [reportDate]);

  useEffect(() => {
    setExpandedColumn({});
  }, [practicePeriod]);

  const handleSendEvent = (event: string, parameters?: {}): void => {
    setEventData(event, {
      classroom_id: classroom.id,
      dashbord_tab: 'WeeklyResults',
      report_period: '',
      report_week: reportDate?.from,
      educational_stage: educationalStage,
      ...parameters,
    });
  };

  const { data, isLoading, error, refetch } =
    useWeeklyReportsQuery<WeeklyReportsQuery>(
      {
        id: classroom.id,
        from: encodeURIComponent(reportDate?.from || ''),
        to: encodeURIComponent(reportDate?.to || ''),
        practicePeriod: practicePeriod,
        stage,
      },
      { enabled: !!reportDate, retry: false }
    );

  const isWarningList = (error as Error)?.message?.includes('WARNING_LIST');

  const { data: { weeklyReportsDates } = {} } = useWeeklyReportsDatesQuery({
    id: classroom.id,
    stage,
  });

  const reportsOptions = useMemo(() => {
    if (!weeklyReportsDates) return [];
    return weeklyReportsDates?.map((report) => ({
      ...report,
      value: report!.date_from!,
      label: report!.title || getDateLabel(report!),
    }));
  }, [weeklyReportsDates]);

  const handleShowAppletPreview = (
    sceneName: string,
    interacting?: boolean
  ): void => {
    setInteractingAppletPreview(!!interacting);
    if (!sceneName) {
      setTimeout(() => {
        if (!interactingRef.current) setVisibleAppletPreview('');
      }, 200);
    } else {
      setVisibleAppletPreview(sceneName);
    }
  };

  const handleSelectReportDate = (reportDate: {
    from: string;
    to: string;
  }): void => {
    setReportDate(reportDate);
    handleSendEvent('report_week_change', {
      report_week: reportDate?.from,
    });
  };

  const mainReport = data?.weeklyReports?.[0];

  const mainReportData = {
    columns: getWeeklyColumns({
      isSecundaria,
      isEarlyYears,
      expandedColumn: expandedColumn[mainReport?.id || ''],
      visibleAppletPreview,
      handleShowAppletPreview,
      isStickyTable,
      contentsAverage: mainReport?.average.contents,
      otherContentsAverage: mainReport?.average.otherContents,
      t,
      id: mainReport?.id || '',
      practicePeriod,
      handleSendEvent,
      isNewManager,
      handleNavigateToResource,
      trainingZonePrimaryReports,
    }),
    rows: getStudentsRows(mainReport),
    id: mainReport?.id || '',
    averageRow: preprocessWeeklyReport(mainReport?.average),
    lessonsDone: mainReport?.lessonsDone,
    courseOrder: mainReport?.courseOrder,
  };

  const onExpandColumn = (column: string, tableId: string): void => {
    setExpandedColumn((prevState) => ({
      ...prevState,
      [tableId]: column,
    }));
    handleSendEvent(column ? 'expand_column' : 'contract_column', {
      column_expanded: column,
    });
  };

  const isCurrentWeek = reportDate?.from === reportsOptions[0]?.date_from;

  const checkReportHasData = (students: any) => {
    if (isSecundaria) {
      return students?.some(
        (report: any) => report.trainingZone?.timeSpent || report.timeSpentAll
      );
    } else {
      return students?.some(
        (report: any) =>
          report.appletSelectorActivities?.length || report.timeSpentAll
      );
    }
  };

  const adaptedReports = data?.weeklyReports?.slice(1);

  const adaptedReportsData = adaptedReports?.map((adaptedReport: any) => {
    const id = adaptedReport?.id;
    const columns = getWeeklyColumns({
      isSecundaria,
      isEarlyYears,
      expandedColumn: expandedColumn[id],
      visibleAppletPreview,
      handleShowAppletPreview,
      isStickyTable,
      contentsAverage: adaptedReport?.average.contents,
      otherContentsAverage: adaptedReport?.average.otherContents,
      t,
      id,
      practicePeriod,
      handleSendEvent,
      handleNavigateToResource,
      trainingZonePrimaryReports,
    });
    const rows = getStudentsRows(adaptedReport);
    const averageRow = preprocessWeeklyReport(adaptedReport?.average);
    const courseOrder = adaptedReport?.courseOrder;
    return { columns, rows, id, averageRow, courseOrder };
  });

  const tableHasData = checkReportHasData(mainReport?.students);

  const {
    showDrawer,
    disconnectedStudents,
    observationsNotes,
    studentsWithDifficulties,
    handleCloseSummaryDrawer,
    handleOpenSummaryDrawer,
  } = useSummaryDrawer({
    isSecundaria,
    studentsRows: mainReportData.rows,
    averageRow: mainReportData.averageRow,
    t,
    handleSendEvent,
    reportDate,
    classroomId: classroom.id,
    loadingTable: isLoading,
    isCurrentWeek,
    tableHasData,
    isNewManager,
    handleNavigate,
    locationInfo,
  });

  const coreActivitiesBubblesIds = studentsWithDifficulties.map((s) => s.id);

  const { data: coreActivities, isLoading: coreActivitiesLoading } =
    useCoreActivitiesQuery(
      {
        bubblePlanId: mainReport?.id!,
        bubblesIds: coreActivitiesBubblesIds,
      },
      { enabled: coreActivitiesBubblesIds.length > 0 || !!mainReport?.id }
    );

  const {
    handleDownload,
    googleDriveLoading,
    openGoogleDriveFile,
    googleDriveUrl,
    onCloseModalGoogleDriveModal,
  } = useDownloadReports({
    educationalStage,
    practicePeriod,
    classroomName: classroom.name || '',
    reportDate,
    studentsRows: mainReportData.rows,
    averageRow: mainReportData.averageRow,
    adaptedReportsData,
    handleSendEvent,
    reportType: 'weekly',
    courses,
  });

  const isDisabledSummaryButton = (): boolean => {
    return (
      isCurrentWeek ||
      isLoading ||
      (!isCurrentWeek && !isLoading && !tableHasData) ||
      coreActivitiesLoading
    );
  };

  const practicePeriodOptions = usePracticePeriod({
    isSecundaria,
    loadingTable: isLoading,
    practicePeriod,
    setPracticePeriod,
    handleSendEvent,
    scheduleStart: classroom.scheduleStart || '09:00:00',
    scheduleEnd: classroom.scheduleEnd || '17:00:00',
  });

  const handlePreviousWeek = (): void => {
    const index = reportsOptions.findIndex(
      (r) => r.date_from === reportDate?.from
    );
    if (index === -1) return;
    const previousWeek = reportsOptions[index + 1];
    if (!previousWeek) return;
    setReportDate({ from: previousWeek.date_from!, to: previousWeek.date_to! });
    handleSendEvent('report_previous_week_change');
  };

  const showPreviousWeekButton =
    isCurrentWeek && reportsOptions && reportsOptions.length > 1;

  return (
    <>
      <WeeklyResultsHeader
        reportDate={reportDate}
        handleSelectReportDate={handleSelectReportDate}
        expandedColumn={!!expandedColumn}
        loadingTable={isLoading}
        reportsOptions={reportsOptions}
        tableHasData={tableHasData}
        headerRef={headerRef}
        handleOpenSummaryDrawer={handleOpenSummaryDrawer}
        setCurrentReport={setCurrentReport}
        handleDownload={handleDownload}
        googleDriveLoading={googleDriveLoading}
        isDisabledSummaryButton={isDisabledSummaryButton()}
        practicePeriodOptions={practicePeriodOptions}
        hasLastYearReport={hasLastYearReport}
        isLastYearReport={isLastYearReport}
      />
      {renderTables({
        loading: isLoading,
        checkReportHasData,
        mainReportData,
        adaptedReportsData,
        isWarningList,
        isCurrentWeek,
        handleSendEvent,
        onExpandColumn,
        t,
        handlePreviousWeek,
        showPreviousWeekButton,
        courses,
        isNewManager,
        reportType: 'weekly',
      })}
      {showDrawer && mainReportData && (
        <SummaryDrawer
          handleClose={handleCloseSummaryDrawer}
          showDrawer={showDrawer}
          size="sm"
          title={t('reports.weeklyNotes.title')}
          date={currentReport}
          children={
            <NotesOfTheWeek
              observationsNotes={observationsNotes}
              disconnectedStudents={disconnectedStudents}
              studentsWithDifficulties={studentsWithDifficulties}
              coreActivities={
                coreActivities?.coreActivities as CoreActivitiesByBubble[]
              }
              lessonsDone={mainReportData?.lessonsDone}
              onClose={handleCloseSummaryDrawer}
              isSecundaria={isSecundaria}
              isEarlyYears={isEarlyYears}
              averageRow={mainReportData.averageRow}
              totalStudents={mainReportData.rows.length}
              connectedStudents={
                mainReportData.rows.filter((s) => s.timeSpentAll).length
              }
              practicePeriod={practicePeriod}
            />
          }
        />
      )}
      <Modal
        isOpen={!!googleDriveUrl}
        onClose={onCloseModalGoogleDriveModal}
        title={t('modal.googleDrive.title')}
        closeOnEsc
        closeOnClickOutside
        modalWidth={700}
        buttons={[
          {
            children: t('modal.googleDrive.button.open'),
            variant: 'accent',
            onClick: openGoogleDriveFile,
          },
          {
            children: t('common.cancel'),
            variant: 'tertiary',
            onClick: onCloseModalGoogleDriveModal,
          },
        ]}
      >
        <>{t('modal.googleDrive.content.description')}</>
      </Modal>
    </>
  );
}
