import { useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useEventListener } from './useEventListener';

type Props = {
  time?: number;
  onInactive: () => void;
};

export function useIdle({ time = 30 * 1000, onInactive }: Props) {
  const isTabFocused = useRef(true);

  const handleOnIdle = () => {
    if (isTabFocused.current) {
      onInactive();
    }
  };

  const handleOnActive = () => {
    isTabFocused.current = true;
  };

  useEventListener('focus', () => {
    isTabFocused.current = true;
  });

  useEventListener('blur', () => {
    isTabFocused.current = false;
  });

  useIdleTimer({
    timeout: time,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  return null;
}
