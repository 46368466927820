import { SVGProps } from 'react';
export const PlaceholderLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    fill="none"
    viewBox="0 0 386 313"
    {...props}
  >
    <path
      fill="#DEBADC"
      d="M234.647 120.876a2.845 2.845 0 0 0-4.975 2.437l38.365 168.533c2.93 12.87-3.373 26.065-15.225 31.873-11.853 5.808-26.143 2.705-34.519-7.496l-89.221-108.66a2.846 2.846 0 0 0-4.974 2.437l38.365 168.533c2.93 12.87-3.373 26.064-15.226 31.873-11.852 5.808-26.142 2.704-34.517-7.496L-60.654 191.761c-9.974-12.147-8.213-30.08 3.935-40.054 12.147-9.974 30.08-8.212 40.054 3.935l89.221 108.661a2.847 2.847 0 0 0 4.974-2.438L39.165 93.332c-2.93-12.87 3.374-26.065 15.226-31.873s26.143-2.705 34.518 7.496l89.221 108.661a2.846 2.846 0 0 0 4.975-2.438L144.739 6.645c-2.929-12.87 3.374-26.064 15.226-31.872s26.142-2.705 34.518 7.495l119.195 145.165c9.974 12.147 8.212 30.08-3.935 40.054-12.147 9.974-30.08 8.213-40.055-3.935l-35.041-42.676ZM363.922 233.471c-12.147 9.974-30.08 8.212-40.054-3.935-9.975-12.147-8.213-30.08 3.934-40.054 12.148-9.974 30.081-8.213 40.055 3.935 9.974 12.147 8.212 30.08-3.935 40.054Z"
    />
  </svg>
);
