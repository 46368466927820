import defaultAppletThumbnail from '../../assets/images/defaultAppletThumbnailGray.png';
import Star from '../star';
import { useEffect, useState } from 'react';
import { Icon } from '@innovamat/innova-components';
import { Loader } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import {
  Background,
  Container,
  Thumbnail,
  ThumbnailContainer,
  StarsContainer,
  ButtonsContainer,
  ButtonStyled,
  ButtonNextActivity,
  IconStyled,
} from './feedback-screen.styles';
import soundStarReward1 from '../../assets/sounds/fx_feedback_starReward01.mp3';
import soundStarReward2 from '../../assets/sounds/fx_feedback_starReward02.mp3';
import soundStarReward3 from '../../assets/sounds/fx_feedback_starReward03.mp3';
import useGetStarsFeedback from '../../hooks/use-get-stars-feedback';
import { normalizeImgSource } from '@innovamat/ga-features';

type Props = {
  backgroundImg: string;
  thumbnail?: string;
  onClose: () => void;
  hasNoMoreApplets: boolean;
  onStartNextApplet: (isTrainingZone: boolean) => void;
  userId?: string;
  activityId?: string;
  initialStars?: number;
  isLoading: boolean;
};

const starsAmount = [1, 2, 3];

const FeedbackScreen = (props: Props) => {
  const {
    backgroundImg,
    thumbnail,
    onClose,
    hasNoMoreApplets,
    onStartNextApplet,
    userId,
    activityId,
    isLoading,
  } = props;

  const [triggerZoomInAnimation, setTriggerZoomInAnimation] = useState(false);
  const [triggerFirstStar, setTriggerFirstStar] = useState(false);
  const [triggerSecondStar, setTriggerSecondStar] = useState(false);
  const [triggerThirdStar, setTriggerThirdStar] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const { t } = useTranslation();

  const newStars = useGetStarsFeedback({
    userId,
    activityId,
    onEmptyStars: onClose,
  });

  const animationRewards = () => {
    if (!newStars) return;
    setTimeout(() => {
      setTriggerZoomInAnimation(true);
    }, 1000);

    setTimeout(() => {
      setTriggerFirstStar(true);
    }, 1500);

    setTimeout(() => {
      setTriggerSecondStar(true);
    }, 2000);

    setTimeout(() => {
      setTriggerThirdStar(true);
    }, 2500);

    setTimeout(() => {
      setTriggerZoomInAnimation(false);
    }, 3000);
  };

  useEffect(() => {
    if (newStars === undefined || newStars === null) return;
    animationRewards();
    setTimeout(() => {
      setShowButtons(true);
    }, 4000);
    if (hasNoMoreApplets) {
      setTimeout(() => {
        onClose();
      }, 4000);
    }
  }, [newStars]);

  const triggerStar = (index: number) => {
    if (index === 1) return triggerFirstStar;
    if (index === 2) return triggerSecondStar;
    return triggerThirdStar;
  };

  const successStar = (numberStar: number) =>
    !!(newStars && triggerStar(numberStar) && Number(newStars) >= numberStar);

  const triggerAudioStar = (index: number, triggerAudio: boolean) => {
    if (!triggerAudio) return undefined;
    if (index === 1) return soundStarReward1;
    if (index === 2) return soundStarReward2;
    return soundStarReward3;
  };

  return (
    <Background id="Background" img={backgroundImg}>
      {isLoading ? (
        <Loader size="md" color="white" />
      ) : (
        <>
          <Container>
            <ThumbnailContainer>
              <Thumbnail
                src={thumbnail || normalizeImgSource(defaultAppletThumbnail)}
                alt="thumb"
              />
            </ThumbnailContainer>
            <StarsContainer zoomIn={triggerZoomInAnimation}>
              {starsAmount.map((stars) => {
                return (
                  <Star
                    size={72}
                    success={successStar(stars)}
                    opacity
                    audio={triggerAudioStar(stars, true)}
                  />
                );
              })}
            </StarsContainer>
          </Container>
          <ButtonsContainer visible={showButtons && !hasNoMoreApplets}>
            <ButtonStyled onClick={onClose}>
              <IconStyled icon="ArrowLeft" />
              {t('weeklyPractice.feedBacktrainingZone.goBackClassroom')}
            </ButtonStyled>
            <ButtonNextActivity onClick={() => onStartNextApplet(true)}>
              {t('weeklyPractice.feedBacktrainingZone.nextActivity')}
              <Icon icon="ArrowRight" />
            </ButtonNextActivity>
          </ButtonsContainer>
        </>
      )}
    </Background>
  );
};

export default FeedbackScreen;