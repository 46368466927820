import { Maybe } from '@innovamat/glow-api-client';
import { SchoolStudent } from '../types/student';

const isLess = (
  a: Maybe<string> | undefined,
  b: Maybe<string> | undefined
): boolean => {
  return a!.toUpperCase() < b!.toUpperCase();
};

const isMore = (
  a: Maybe<string> | undefined,
  b: Maybe<string> | undefined
): boolean => {
  return a!.toUpperCase() > b!.toUpperCase();
};

const sortStudentsList = (a: SchoolStudent, b: SchoolStudent) => {
  if (a.courseOrder! < b.courseOrder!) return -1;
  if (a.courseOrder! > b.courseOrder!) return 1;
  if (isLess(a.classroomName, b.classroomName)) return -1;
  if (isMore(a.classroomName, b.classroomName)) return 1;
  if (isLess(a.lastName, b.lastName)) return -1;
  if (isMore(a.lastName, b.lastName)) return 1;
  if (isLess(a.firstName, b.firstName)) return -1;
  if (isMore(a.firstName, b.firstName)) return 1;
  return 0;
};

export { sortStudentsList };
