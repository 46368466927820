import { ErrorDetail, ErrorEmail, ErrorType } from './types';

const ROLE_CONFLICT_ERROR_TYPES = {
  StudentSameOrgToParent: 'user_invitation.convert_student_to_parent',
  StudentJrSameOrgToParent: 'user_invitation.convert_student_junior_to_parent',
  StudentDiffOrgToParent:
    'parent_invitation.convert_student_to_parent_school_id_does_not_match',
  StudentJrDiffOrgToParent:
    'parent_invitation.convert_student_junior_to_parent_school_id_does_not_match',
  TeacherAdminOwnerToParent:
    'user_profile.invitation_type_incompatible_with_user_roles',
};

const isOnlyStudentJrError = (errorTypes: string[]): boolean => {
  return errorTypes.every(
    (error) => error === ROLE_CONFLICT_ERROR_TYPES.StudentJrSameOrgToParent
  );
};

const isStudentAlreadyExistsError = (errorTypes: string[]): boolean => {
  const uniqueErrors = new Set(errorTypes);

  for (const error of uniqueErrors) {
    if (
      error !== ROLE_CONFLICT_ERROR_TYPES.StudentSameOrgToParent &&
      error !== ROLE_CONFLICT_ERROR_TYPES.StudentJrSameOrgToParent
    ) {
      return false;
    }
  }

  return uniqueErrors.size > 0;
};

const hasRoleConflictError = (error: ErrorDetail): boolean => {
  return error.extensions?.exception?.['code'] === 'ROLE_CONFLICT';
};

type ValidateRoleConflictNotParentOrStudentJr =
  | {
      errorEmails: string[];
      errors: ErrorEmail[];
    }
  | undefined;

const validateRoleConflictNotParentOrStudentJr = (
  error: ErrorDetail
): ValidateRoleConflictNotParentOrStudentJr => {
  const errorTypesObjects = error.extensions?.['types'];
  const errorTypes = errorTypesObjects?.map(
    (typeObj: ErrorEmail) => typeObj.type
  );
  const hasExplicitErrorType = !!errorTypesObjects;
  if (
    hasExplicitErrorType &&
    !isStudentAlreadyExistsError(errorTypes) &&
    !isOnlyStudentJrError(errorTypes)
  ) {
    const errors = errorTypesObjects.filter(
      (typeObj: ErrorEmail) =>
        typeObj.type !== ROLE_CONFLICT_ERROR_TYPES.StudentSameOrgToParent &&
        typeObj.type !== ROLE_CONFLICT_ERROR_TYPES.StudentJrSameOrgToParent
    );

    const errorEmails = errors.map((typeObj: ErrorEmail) => typeObj.email);

    return {
      errorEmails,
      errors,
    };
  }

  return undefined;
};

const getExistsErrorMessage = (errorEmails: ErrorEmail[]): ErrorType | null => {
  const existUsers = errorEmails.filter(
    (email) => email.type !== 'email.invalid'
  );

  if (existUsers.length > 0) {
    const values: ErrorType['values'] =
      existUsers.length === 1
        ? { email: existUsers[0].email }
        : { emails: existUsers.map((e) => e.email).join(', ') };

    const key =
      existUsers.length === 1
        ? 'parents.existUserError'
        : 'parents.existUsersError';

    return {
      values,
      key,
    };
  }

  return null;
};

const getInvalidErrorMessage = (
  errorEmails: ErrorEmail[]
): ErrorType | null => {
  const invalidEmails = errorEmails.filter(
    (email) => email.type === 'email.invalid'
  );

  if (invalidEmails.length > 0) {
    const values: ErrorType['values'] =
      invalidEmails.length === 1
        ? { email: invalidEmails[0].email }
        : { emails: invalidEmails.map((e) => e.email).join(', ') };

    const key =
      invalidEmails.length === 1
        ? 'parents.invalidEmailError'
        : 'parents.invalidEmailsError';

    return {
      values,
      key,
    };
  }

  return null;
};

export {
  getExistsErrorMessage,
  getInvalidErrorMessage,
  hasRoleConflictError,
  isOnlyStudentJrError,
  isStudentAlreadyExistsError,
  ROLE_CONFLICT_ERROR_TYPES,
  validateRoleConflictNotParentOrStudentJr,
};
