import { useFlag } from '@innovamat/flags';
import {
  BannerToDisplay,
  LandingPageAdmin,
  LandingPageAdminSkeleton,
  LandingPageAdvisor,
  LandingPageDistrictAdmin,
  LandingPageTeacher,
  LandingPageParent,
  useOrganization,
  useUser,
  APP_PATHS,
} from '@innovamat/ga-features';
import { roles } from '@innovamat/radiance-utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-domv6';
import { Logo } from '../components/logo';
import { ResourceSkeleton } from '../components/resource-skeleton';
import { Header } from '../layout/header';
import { OnboardingPage } from './onboarding-page';

export function LandingPage(): JSX.Element {
  const { user, isStudentViewActive } = useUser();
  const { organization } = useOrganization();
  const navigate = useNavigate();

  const { value: showOnboardingFormFlag } = useFlag(
    'showonboardingform',
    false,
    {
      identifier: '',
      custom: {
        region: user?.region ?? '',
      },
    }
  );

  const isStudent = roles.hasStudentRole(user?.roles);
  const isLead = roles.hasLeadRole(user?.roles);

  useEffect(() => {
    const classroomId = user?.studentData?.classroomId;
    if (isStudent && !isStudentViewActive && classroomId) {
      navigate(`${APP_PATHS.DIGITAL_PRACTICE_URL}/${classroomId}/page`);
    }
  }, [isStudent, isStudentViewActive, navigate, user]);

  useEffect(() => {
    if (isLead) {
      navigate(`${APP_PATHS.COURSE_SELECTOR_BASE_URL}${APP_PATHS.COURSE_URL}`);
    }
  }, [isLead, navigate]);

  if (!user) {
    return <ResourceSkeleton />;
  }

  const renderPageBasedOnRole = (): JSX.Element => {
    if (roles.hasParentRole(user?.roles)) {
      return <LandingPageParent />;
    }

    if (roles.hasDistrictAdminRole(user?.roles)) {
      return <LandingPageDistrictAdmin />;
    }

    if (roles.hasAdvisorRole(user?.roles)) {
      return <LandingPageAdvisor />;
    }

    if (roles.hasAdminRole(user?.roles) || roles.hasOwnerRole(user?.roles)) {
      return <LandingPageAdmin />;
    }

    if (roles.hasTeacherRole(user?.roles)) {
      return <LandingPageTeacher />;
    }

    return <LandingPageAdminSkeleton />;
  };

  const teacherStartDate = organization?.academicYear.teacherStartDate;
  const currentDate = new Date();

  const isTeacherStartDatePassed =
    currentDate > new Date(teacherStartDate ?? '');

  const shouldShowOnboarding =
    (roles.hasTeacherRole(user?.roles) ||
      roles.hasAdminRole(user?.roles) ||
      roles.hasOwnerRole(user?.roles) ||
      roles.hasDistrictAdminRole(user?.roles)) &&
    isTeacherStartDatePassed &&
    showOnboardingFormFlag;

  return (
    <>
      <Header leftActions={<Logo />} />
      <BannerToDisplay />
      {shouldShowOnboarding && <OnboardingPage />}
      {renderPageBasedOnRole()}
    </>
  );
}
