import {
  useMarkChallengeMutation,
  useMarkSpaceMutation,
} from '@innovamat/glow-api-client';
import { updateChallengeFromSpaceInCache } from '../cache-actions';
import { useQueryClient } from '@tanstack/react-query';
import { Space, SpaceChallenge } from '@innovamat/smart-guides';

type HandleChallengeInPlannerProps = {
  classroomId: string;
  sessionId: string;
  academicYearId: string;
  onSuccess?: () => void;
  onError?: () => void;
};

function useHandleChallengeInPlanner({
  classroomId,
  sessionId,
  academicYearId,
  onSuccess,
  onError,
}: HandleChallengeInPlannerProps) {
  const queryClient = useQueryClient();
  const markChallengeMutation = useMarkChallengeMutation();
  const markSpaceMutation = useMarkSpaceMutation();

  const handleMarkChallengeInPlanner = (
    space: Space,
    challenge: SpaceChallenge
  ) => {
    const spacesBody = {
      body: {
        classroomId: classroomId!,
        sessionId: sessionId!,
      },
    };

    updateChallengeFromSpaceInCache(
      queryClient,
      spacesBody,
      space.preprId,
      challenge.id
    );

    markChallengeMutation.mutate(
      {
        body: {
          academicYearId: academicYearId!,
          challengeId: challenge.id,
          classroomId: classroomId!,
          done: challenge.checked,
        },
      },
      {
        onSuccess(data) {
          if (!data.markChallenge?.errors) {
            onSuccess?.();
            return;
          }

          onError?.();
          updateChallengeFromSpaceInCache(
            queryClient,
            spacesBody,
            space.preprId,
            challenge.id
          );
        },
        onError() {
          onError?.();
          updateChallengeFromSpaceInCache(
            queryClient,
            spacesBody,
            space.preprId,
            challenge.id
          );
        },
      }
    );

    markSpaceMutation.mutate({
      body: {
        classroomId: classroomId!,
        academicYearId: academicYearId!,
        sessionId: sessionId!,
        spaceId: space.preprId,
        pinned: space.pinned,
        challengeId: challenge.id,
      },
    });

    // Code to send event
    const key = challenge.checked ? 'challenge_done' : 'challenge_undone';
    // setEventData(key, {
    //   challenge_id: challenge.id,
    //   challenge_name: challenge.title,
    //   challenge_order: challenge.number + 1,
    //   challenges_total: space.challenges.length,
    //   space_id: space.preprId,
    //   space_name: space.title,
    //   space_order: getSpaceOrder(space.id),
    //   spaces_total: spaces?.length,
    //   planning_id: spaceSession.id,
    //   planing_name: title,
    //   is_pinned: space.pinned,
    //   session_id: id,
    //   session_name: title,
    // });
  };

  return {
    handleMarkChallengeInPlanner,
    isSuccess: markChallengeMutation.isSuccess,
  };
}

export { useHandleChallengeInPlanner };
