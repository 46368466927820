import { useEffect } from 'react';

function useNoOverflow(trigger: boolean) {
  useEffect(() => {
    if (trigger) {
      document.body.classList.add('drawer-open');
    } else {
      document.body.classList.remove('drawer-open');
    }
  }, [trigger]);
}
export default useNoOverflow;
