import { useLayoutEffect } from 'react';
import {
  distanceToTop,
  getScrollParent,
  handleStickyHorizontalScroll,
} from '../utils';

const useStickyHeaderHandler = (
  id: string,
  topStickyOffset: number,
  hasFirstRow: boolean,
  isNewManager?: boolean
): void => {
  useLayoutEffect(() => {
    const appContent = getScrollParent(document.getElementById(id));
    const stickyHeader = document.getElementById(`sticky-header+${id}`);
    let lastScrollTop = 0;

    if (stickyHeader) {
      stickyHeader.style.display = 'none';
    }

    const tableElement = document.getElementById(`table+${id}`);
    const tableContainer = document.getElementById(id);
    const parentHeight = tableContainer!.parentElement!.clientHeight;

    const onScroll = (): void => {
      handleStickyHorizontalScroll(id);

      if (!appContent) return;

      const scrollTop = appContent.scrollTop;

      if (!stickyHeader || !tableElement || !tableContainer) return;

      const distance = distanceToTop(tableElement, appContent);
      if (!distance) return;
      const firstRowOffset = stickyHeader!.clientHeight;

      if (scrollTop > lastScrollTop && distance < topStickyOffset) {
        const stickyHeaderTopPosition = isNewManager
          ? `${topStickyOffset}px`
          : '-34px';
        Object.assign(stickyHeader.style, {
          display: 'table-header-group',
          position: 'sticky',
          left: '0',
          zIndex: '18',
          top: stickyHeaderTopPosition,
        });

        if (isNewManager) {
          tableContainer.style.transform = `translateY(-${stickyHeader.clientHeight}px)`;
          tableContainer.parentElement!.style.maxHeight = `${parentHeight}px`;
        }
        if (hasFirstRow) {
          if (!isNewManager) {
            tableContainer.style.transform = `translateY(-${stickyHeader.clientHeight}px)`;
          }
          tableContainer.parentElement!.style.maxHeight = `${parentHeight}px`;
        }
      }
      if (
        scrollTop < lastScrollTop &&
        distance > topStickyOffset + firstRowOffset
      ) {
        stickyHeader.style.display = 'none';
        if (isNewManager) {
          tableContainer.style.transform = `translateY(0px)`;
        }
        if (hasFirstRow) {
          if (!isNewManager) {
            tableContainer.style.transform = `translateY(0px)`;
          }
        }
      }

      lastScrollTop = scrollTop;
    };

    if (isNewManager) {
      window.addEventListener('scroll', onScroll);
    } else {
      appContent?.addEventListener('scroll', onScroll);
    }

    return () => {
      if (isNewManager) {
        window.removeEventListener('scroll', onScroll);
      } else {
        appContent?.removeEventListener('scroll', onScroll);
      }
    };
  }, [id, hasFirstRow, topStickyOffset, isNewManager]);
};

export default useStickyHeaderHandler;
