import { Content, Maybe, OtherContents } from '@innovamat/glow-api-client';
import {
  renderAppletSelector,
  renderBubbles,
  renderBubblesSubLabels,
  renderOtherContents,
  renderReviewSubLabels,
  renderSelectorAppletsSublabel,
  renderTime,
  renderTrainingZone,
  renderTrainingZoneSublabel,
  renderScore,
  renderEllipsableText,
  renderAppletsWorkedOn,
  renderTraining,
  renderMultipleSublabels,
} from '../../../utils/render-rows';
import { TFunction } from 'i18next';
import { Column, Row } from '@innovamat/glimmer-components';
import { TimeSchedule } from '../weekly-results';
import { getDataTestId } from '../../../utils/common';

type Props = {
  isSecundaria: boolean;
  expandedColumn: string;
  visibleAppletPreview: string;
  handleShowAppletPreview: (sceneName: string, interacting?: boolean) => void;
  isStickyTable: boolean;
  contentsAverage?: Maybe<Array<Maybe<Content>>>;
  otherContentsAverage?: Maybe<OtherContents> | undefined;
  isEarlyYears: boolean;
  t: TFunction<'translation', undefined, 'translation'>;
  id: string;
  practicePeriod: TimeSchedule;
  handleSendEvent: (event: string, parameters?: {}) => void;
  isNewManager?: boolean;
  handleNavigateToResource: (appletId: string) => void;
  trainingZonePrimaryReports: boolean;
};

export const getWeeklyColumns = ({
  isSecundaria,
  expandedColumn,
  visibleAppletPreview,
  handleShowAppletPreview,
  isStickyTable,
  contentsAverage,
  otherContentsAverage,
  isEarlyYears,
  t,
  id,
  practicePeriod,
  handleSendEvent,
  isNewManager,
  handleNavigateToResource,
  trainingZonePrimaryReports,
}: Props): Column<Row>[] => {
  const columns: Column<Row>[] = [
    {
      id: 'firstName',
      label: t('reports.name.title.column'),
      width: 100,
      sortable: true,
      sticky: true,
      render: (value, row) =>
        renderEllipsableText(
          value,
          row,
          t,
          `WERE_TABLE_STUDENTNAME_S${row.uuid}`
        ),
      sortIgnoreCase: true,
      align: 'left',
      dataTestId: 'WERE_TABLE_NAME',
    },
    {
      id: 'lastName',
      label: t('reports.surname.title.column'),
      width: 100,
      sortable: true,
      sticky: true,
      render: (value, row) =>
        renderEllipsableText(
          value,
          row,
          t,
          `WERE_TABLE_STUDENTSURNAME_S${row.uuid}`
        ),
      sortIgnoreCase: true,
      align: 'left',
      dataTestId: 'WERE_TABLE_SURNAME',
    },
    {
      id: 'separator-1',
      width: 9,
      sticky: true,
      separator: true,
      isDarkSeparator: true,
    },
    {
      id: 'statementsCompleted',
      label: t('reports.appletsDone.title.column'),
      width: 96,
      labelTooltip: t('reports.appletsDone.title.column'),
      render: (value, row) => {
        const baseName = 'APPLETSWORKED';
        const defaultSuffix = 'AVERAGEAPPLETSWORKED';
        const uuidPrefix = row.uuid ? 'S' : '';
        return renderAppletsWorkedOn(
          value,
          row,
          isSecundaria,
          practicePeriod,
          getDataTestId('WERE', baseName, uuidPrefix, row.uuid, defaultSuffix)
        );
      },
      dataTestId: 'WERE_TABLE_APPLETSWORKED',
    },
    {
      id: 'timeSpent',
      label: t('reports.time.title.column'),
      width: 96,
      render: (_, row) =>
        renderTime(
          row,
          practicePeriod,
          'weeklyResults',
          isSecundaria,
          getDataTestId('WERE', 'TIMESPENT', row.uuid, 'AVERAGETIMESPENT')
        ),
      align: 'left',
      dataTestId: 'WERE_TABLE_TIMESPENT',
    },
    {
      id: 'separator-2',
      width: 9,
      separator: true,
    },
    {
      id: 'averageScore',
      label: isSecundaria
        ? t('reports.secondary.score.title.column')
        : t('reports.primary.score.title.column'),
      width: 102,
      sortable: true,
      render: (value, row) => {
        const isNotRepresentativeScore = row.appletsCompleted < 4;
        return renderScore(
          value,
          getDataTestId('WERE', 'WEEKLYAVERAGE', row.uuid, 'AVERAGESCORE'),
          isNotRepresentativeScore
        );
      },
      dataTestId: 'WERE_TABLE_WEEKLYAVERAGE',
    },
    {
      id: 'separator-3',
      width: 9,
      separator: true,
    },
    {
      id: 'otherContents',
      label: isSecundaria ? t('reports.review.title.column') : '',
      width: 105,
      subLabel: () => {
        return renderReviewSubLabels(
          otherContentsAverage,
          'otherContents' === expandedColumn,
          t
        );
      },
      extendable: true,
      render: (_, row) =>
        renderOtherContents(
          row,
          otherContentsAverage!,
          'otherContents' === expandedColumn,
          !!expandedColumn,
          getDataTestId(
            'WERE',
            'OTHERCONTENTS',
            row.uuid,
            'AVERAGEOTHERCONTENTS'
          )
        ),
      numberOfContents: otherContentsAverage?.contents?.length,
      isHiddenColumn: !isSecundaria || !otherContentsAverage?.contents?.length,
      dataTestId: 'WERE_TABLE_OTHERCONTENTS',
    },
    {
      id: 'separator-4',
      width: 9,
      separator: true,
      isDarkSeparator: true,
      isHiddenColumn:
        isEarlyYears ||
        (contentsAverage?.length === 0 &&
          otherContentsAverage?.contents?.length === 0),
    },
    {
      id: 'trainingZone',
      label: isSecundaria
        ? t('reports.trainingZone.title.column')
        : t('reports.weeklyPractice.training.title'),
      width: isSecundaria ? '100%' : 150,
      minWidth: isSecundaria ? '200px' : 150,
      subLabel: () => {
        return isSecundaria
          ? renderTrainingZoneSublabel(t)
          : renderMultipleSublabels({
              values: [
                t('reports.weeklyPractice.training.dedication'),
                t('reports.weeklyPractice.training.meanScore'),
              ],
              proportions: '1.5fr 1fr',
            });
      },
      render: (value, row) =>
        isSecundaria
          ? renderTrainingZone(row, `WERE_TABLE_TRAININGZONE_S${row.uuid}`)
          : renderTraining({
              value,
              proportions: '1fr 1fr',
              practicePeriod: practicePeriod,
            }),
      isHiddenColumn: isEarlyYears || !trainingZonePrimaryReports,
      dataTestId: 'WERE_TABLE_TRAININGZONE',
    },
    {
      id: 'separator-5',
      width: 9,
      separator: true,
      isDarkSeparator: true,
      isHiddenColumn:
        isEarlyYears || isSecundaria || !trainingZonePrimaryReports,
    },
    {
      id: 'appletSelector',
      label: t('reports.appletsSelector.title.column'),
      width: '100%',
      minWidth: '200px',
      render: (_, row) => renderAppletSelector(row, t),
      subLabel: () => renderSelectorAppletsSublabel(t),
      isHiddenColumn: isSecundaria || isEarlyYears,
      infoColumn: t('reports.information.selectorApplets.tooltip'),
      dataTestId: 'WERE_TABLE_APPLETSELECTOR',
    },
    {
      id: 'table-end',
      width: '100%',
    },
  ];

  const contentColumns = contentsAverage?.map((content) => {
    const { contentTextCode, skillTextCode, applets } = content!;
    const contentId = `${contentTextCode}${skillTextCode}`;
    const label = skillTextCode
      ? `${t(contentTextCode!)}: ${t(skillTextCode)}`
      : `${t(contentTextCode!)}`;
    const contentAverage = contentsAverage?.find(
      (c) =>
        c?.contentTextCode === contentTextCode &&
        c?.skillTextCode === skillTextCode
    )?.applets;

    return {
      id: contentId,
      label,
      labelTooltip: label,
      width: 120,
      subLabel: () => {
        return renderBubblesSubLabels({
          applets: applets!,
          isExpanded: contentId === expandedColumn,
          visibleAppletPreview,
          handleShowAppletPreview,
          t,
          handleSendEvent,
          stickyTable: isStickyTable,
          isContents: false,
          id,
          isNewManager,
          handleNavigateToResource,
        });
      },
      extendable: true,
      render: (value: any, row: Row) =>
        renderBubbles(
          value,
          contentAverage,
          contentId === expandedColumn,
          !!expandedColumn,
          getDataTestId(
            'WERE',
            `BLOCK_${contentId.toUpperCase()}`,
            'S',
            row.uuid,
            `AVERAGEBLOCK_${contentId.toUpperCase()}`
          )
        ),
      isDynamicLabel: true,
      expandedColumn: id === expandedColumn,
      numberOfApplets: applets?.length,
      dataTestId: `WERE_TABLE_BLOCK_${contentId.toUpperCase()}`,
    };
  });

  if (contentColumns?.length) {
    const index = columns.findIndex((column) => column.id === 'separator-3');
    columns.splice(index + 1, 0, ...contentColumns);
  }

  return columns;
};
