import { useJoinToClassroom } from '../join-to-classroom-context';
import { TitleContainer } from '../join-to-classroom.styled';
import { Course, useCourses } from '@innovamat/glow-api-client';
import { AvatarCard, CardSelect } from '@innovamat/glimmer-components';
import { SelectStepSkeleton } from './select-classrooms-step.skeleton';
import { useTranslation } from 'react-i18next';
import { GroupedList, Element } from '../components/grouped-list';

export function SelectCoursesStep() {
  const { t } = useTranslation();
  const { onGoBack, orgId, region, handleSelectCourse, getCardStatus } =
    useJoinToClassroom();

  const { courses, groupCoursesByStage, isLoading } = useCourses({
    organizationId: orgId,
    regionCode: region,
  });
  const groupedCourses = groupCoursesByStage(courses);

  const elements: Element<Course>[] = groupedCourses.map((group) => {
    return {
      title: t(`common.stage-${group.stage.toLocaleLowerCase()}`),
      elements: group.courses || [],
    } as Element<Course>;
  });

  return (
    <>
      <TitleContainer>
        {isLoading && <SelectStepSkeleton />}
        {elements && (
          <GroupedList
            items={elements}
            renderElement={(course) => (
              <CardSelect
                status={getCardStatus(course.id, 'course')}
                onClick={() => handleSelectCourse(course.id)}
              >
                <AvatarCard>
                  <AvatarCard.AvatarStage
                    courseName={course.name || ''}
                    courseOrder={course.order || 0}
                  />
                  <AvatarCard.Text>{course.name}</AvatarCard.Text>
                </AvatarCard>
              </CardSelect>
            )}
          />
        )}
      </TitleContainer>
    </>
  );
}
