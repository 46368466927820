import { Modal, Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';

type UnlinkTeacherModalProps = {
  isLoading: boolean;
  teacherName: string;
  classroomName: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const UnlinkTeacherModal = ({
  classroomName,
  isLoading,
  teacherName,
  onConfirm,
  onCancel,
}: UnlinkTeacherModalProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Modal
      buttons={[
        {
          children: t('teachers.unlinktTeacher.confirmUnlink'),
          'aria-label': t('teachers.unlinktTeacher.confirmUnlink'),
          loading: isLoading,
          variant: 'accent',
          onClick: onConfirm,
          role: 'button',
        },
        {
          children: t('confirmModal.action.cancel'),
          'aria-label': t('confirmModal.action.cancel'),
          variant: 'tertiary',
          onClick: onCancel,
          role: 'button',
        },
      ]}
      isOpen={true}
      modalWidth={450}
      onClose={onCancel}
      title={t('teachers.unlinkTeacher')}
    >
      <Typography.Body1>
        {t('teachers.unlinkTeacher.confirm', {
          name: teacherName,
          classroom: classroomName,
        })}
      </Typography.Body1>
    </Modal>
  );
};

export { UnlinkTeacherModal };
