import { useState } from 'react';
import { SpacesCard } from '@innovamat/glimmer-components';
import { CardContainer } from '../Spaces.styles';
import { Space } from '../Spaces.types';
import useSpaces from '../useSpaces';

type CardParams = {
  space: Space;
};

export default function Card({ space }: CardParams) {
  const { title, description, pinned, challenges, id, challengeId } = space;
  const {
    handleMarkChallengeDone,
    handleMarkSpace,
    handleSaveActiveChallenge,
    goToResource,
    classroomId,
    t,
  } = useSpaces();

  const challengeIdIndex = challenges?.findIndex((c) => c.id === challengeId);
  const [active, setActive] = useState(challengeIdIndex);

  const formatedChallenges = challenges?.map((challenge) => ({
    ...challenge,
    challengeTitle: t('digitalguides.ey.challenge', {
      num: challenge.number,
    }),
    label: challenge.checked
      ? t('digitalguides.ey.challenge.sessiondone')
      : t('spaces.challenge.labelMark', 'Finalizar reto'),
    showCheckbox: !!classroomId,
    onChange: () =>
      handleMarkChallengeDone(space, {
        ...challenge,
        checked: !challenge.checked,
      }),
  }));

  const handleSetActive = (value: number) => {
    setActive(value);
    if (classroomId) {
      handleSaveActiveChallenge(space, value);
    }
  };

  const handleClick = () => {
    if (challenges[active].id) {
      goToResource(
        'session',
        id,
        { challengeId: challenges[active].id },
        true,
        true
      );
    } else {
      goToResource('session', id, {}, true, true);
    }
  };

  return (
    <CardContainer>
      <SpacesCard
        title={title}
        description={t(description)}
        challenges={formatedChallenges}
        isPinned={pinned}
        onPinItem={() => handleMarkSpace(space, active)}
        key={id}
        active={active}
        handleSetActive={handleSetActive}
        onClick={handleClick}
        tooltipPin={t('spaces.card.pin')}
        showPin={!!classroomId}
        enableConfettiAnimation
      />
    </CardContainer>
  );
}
