import { decodeStringWithAscii } from '@innovamat/radiance-utils';
import { createRoot } from 'react-dom/client';
import { getDimensionsColor } from '../colorsSelector';
import { LatexElement } from './latex';
// import DimensionTooltip from '../../components/DimensionTooltip';
// import { DIMENSIONS } from '../../templates/EarlyYear/EarlyYear.mappers';
// import i18n from '../../../../config/i18n';

export const mapTextDimensions = (
  item: LatexElement | undefined
): LatexElement | undefined => {
  if (!item) return undefined;

  const regex = new RegExp(/\{\{(.*?)\}\}/, 'gm');
  let content = item.body;
  const expressions = content.match(regex);

  if (!expressions)
    return {
      id: item.id,
      body: content,
    };

  const dimensionIds: Record<string, [string, string]> = {};
  for (let index = 0; index < expressions.length; index++) {
    const expression = expressions[index];

    const dimensionArr = expression.split('|');
    const dimensionNumber = dimensionArr[dimensionArr.length - 1].replace(
      '}}',
      ''
    );

    const colors = getDimensionsColor(dimensionNumber);
    dimensionArr.pop();
    const value = dimensionArr.join('').replace('{{', '');

    const id = `moment-${item.id}-${index}`;
    dimensionIds[id] = [value, dimensionNumber];

    content = content.replace(
      expression,
      `<span id="${id}" style="color: ${colors?.dark}; font-weight: bold" >${value}</span>`
    );
  }

  setTimeout(() => {
    Object.entries(dimensionIds).forEach(([id, [value]]) => {
      const dimensionElement = document.getElementById(id);
      if (dimensionElement) {
        const isFormatted = dimensionElement?.getAttribute('done');
        if (!isFormatted) {
          dimensionElement.setAttribute('done', 'true');

          const wrapper = createRoot(dimensionElement);
          // const dimensionText = DIMENSIONS[type as keyof typeof DIMENSIONS];
          // TODO: hover is adding an space after the value, publish without hover
          wrapper.render(
            decodeStringWithAscii(value)
            // <DimensionTooltip
            //   text={decodeStringWithAscii(value)}
            //   tooltip={i18n.t(dimensionText)}
            //   type={type}
            // />
          );
        }
      }
    });
  }, 500);

  const newItem = {
    ...item,
    body: content,
  };

  return newItem;
};
