export enum ResourceType {
  genially = 'genially',
  pdf = 'pdf',
  vimeo = 'vimeo',
  applet = 'applet',
  webgl = 'webgl',
  geogebra = 'geogebra',
  manipulativeenvironment = 'manipulativeEnvironment',
  session = 'session',
}
