import { ConfigCatProvider, PollingMode } from 'configcat-react';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  cacheTime?: string;
  sdkKey: string;
};

export function FlagsProvider({ children, cacheTime, sdkKey }: Props) {
  return (
    <ConfigCatProvider
      options={{
        cacheTimeToLiveSeconds: cacheTime ? parseInt(cacheTime) : undefined,
      }}
      pollingMode={PollingMode.LazyLoad}
      sdkKey={sdkKey}
    >
      {children}
    </ConfigCatProvider>
  );
}
