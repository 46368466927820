import { Classroom } from '@innovamat/glow-api-client';
import { QueryClient } from '@tanstack/react-query';

type ClassroomsQueryData = { classrooms: Classroom[] };

function deleteClassroomFromCache({
  queryClient,
  orgId,
  classroomId,
}: {
  queryClient: QueryClient;
  orgId: string;
  classroomId: string;
}): void {
  const variablesMine = ['Classrooms', { mine: true, orgId }];
  const variablesNotMine = ['Classrooms', { mine: false, orgId }];

  const currentClassroomsMine =
    queryClient.getQueryData<ClassroomsQueryData>(variablesMine)?.classrooms;

  const currentClassroomsNotMine =
    queryClient.getQueryData<ClassroomsQueryData>(variablesNotMine)?.classrooms;

  if (currentClassroomsMine) {
    queryClient.setQueryData(variablesMine, {
      classrooms: currentClassroomsMine.filter(
        (classroom) => classroom.id !== classroomId
      ),
    });
  }

  if (currentClassroomsNotMine) {
    queryClient.setQueryData(variablesNotMine, {
      classrooms: currentClassroomsNotMine.filter(
        (classroom) => classroom.id !== classroomId
      ),
    });
  }
}

export { deleteClassroomFromCache };
