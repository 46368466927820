import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-domv6';
import {
  Space,
  SpaceSession,
  Spaces,
  formatPlanningSpaces,
} from '@innovamat/smart-guides';

import { useUser } from '../../../user-management';
import { useSpacesQuery } from '@innovamat/glow-api-client';
import { snack } from '@innovamat/glimmer-components';

import { useHandleSpace } from '../../hooks/use-handle-space';
import { useHandleChallengeInPlanner } from '../../hooks/use-handle-challenge-in-planner';
import { useNavigation } from '../../../navigation';
import { useEventLogging } from '@innovamat/event-logging';

type Params = {
  spaceSession: SpaceSession;
  onGoBack: () => void;
};

export function SpacesWrapper({ spaceSession, onGoBack }: Params) {
  const [spaces, setSpaces] = useState<Space[]>([]);

  const { spaces: spacesFromPrepr, title } = spaceSession;
  const { t } = useTranslation();
  const { classroomId, id } = useParams();
  const { goToResource } = useNavigation();
  const [searchParams] = useSearchParams();

  const { user } = useUser();
  const academicYearId = user?.organization?.academicYearId;

  const spacesBody = {
    body: {
      classroomId: classroomId || 'self',
      sessionId: id!,
    },
  };
  const spacesQuery = useSpacesQuery(spacesBody);

  const getSpaceOrder = (spaceId: string) => {
    const ids = Array.isArray(spaces) ? spaces?.map((i) => i.id) : null;
    const orderSpace = ids?.indexOf(spaceId);
    if (orderSpace !== undefined) return orderSpace + 1;
    return orderSpace;
  };

  const { handleMarkChallengeInPlanner, isSuccess: markChallengeIsSuccess } =
    useHandleChallengeInPlanner({
      classroomId: classroomId!,
      sessionId: id!,
      academicYearId: academicYearId!,
      onError: () => {
        snack.error(t('session.markSessionAsDone.fail'));
      },
    });

  const {
    handleMarkSpace,
    handleSaveActiveChallenge,
    isSuccess: markSpaceIsSuccess,
  } = useHandleSpace({
    classroomId: classroomId!,
    sessionId: id!,
    academicYearId: academicYearId!,
    onError: () => {
      snack.error(t('session.markSessionAsDone.fail'));
    },
  });

  useEffect(() => {
    if (spacesQuery.isSuccess || markSpaceIsSuccess || markChallengeIsSuccess) {
      if (spacesQuery.data) {
        const updatedSpaces = formatPlanningSpaces(
          spacesFromPrepr,
          spacesQuery.data.spaces
        );
        setSpaces(updatedSpaces);
      }
    }
  }, [
    spacesQuery.data,
    spacesFromPrepr,
    spacesQuery.isSuccess,
    markSpaceIsSuccess,
    markChallengeIsSuccess,
  ]);

  const handleGoToResource = (
    type: string,
    id: string,
    params: Record<string, string>
  ): void => {
    goToResource({
      type,
      resourceId: id,
      challengeId: params.challengeId,
      pageId: searchParams.get('fromPage') || undefined,
      listId: searchParams.get('fromList') || undefined,
    });
  };

  return (
    <Spaces
      handleMarkSpace={handleMarkSpace}
      handleMarkChallengeDone={handleMarkChallengeInPlanner}
      handleSaveActiveChallenge={handleSaveActiveChallenge}
      isLoading={spacesQuery.isLoading}
      classroomId={classroomId}
      academicYearId={academicYearId}
      setEventData={() => {}}
      goToResource={handleGoToResource}
      onGoBack={onGoBack}
      session={spaceSession}
      t={t}
      spaces={spaces}
    />
  );
}
