import { RefObject, useEffect, useRef, useState } from 'react';
import { MIN_HEIGHT } from '../components/resource-layout/header.styled';

const updateHeaderHeight = (height: number): void => {
  document.documentElement.style.setProperty('--heightHeader', `${height}px`);
};

export default function useIsSticky(): {
  headerRef: RefObject<HTMLDivElement>;
  isSticky: boolean;
  stickyRef: RefObject<HTMLDivElement>;
} {
  const headerRef = useRef<HTMLDivElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    let initialHeight: number | undefined;

    const header = stickyRef.current;
    if (!header) return;

    const observer = new IntersectionObserver(([entry]) => {
      const isCurrentlySticky = !entry.isIntersecting;
      const headerHeight = (header.lastChild as Element)?.clientHeight || 0;

      const newHeight = isCurrentlySticky
        ? MIN_HEIGHT
        : initialHeight || headerHeight;

      updateHeaderHeight(newHeight);

      if (!initialHeight) {
        initialHeight = headerHeight;
      }

      setIsSticky(isCurrentlySticky);
    });

    observer.observe(header);

    return () => observer.unobserve(header);
  }, []);

  return { headerRef, isSticky, stickyRef };
}
