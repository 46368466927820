import { BooleanElement, PrepElement } from '../../../types/prepr';
import { MOMENT_CONTENT_TYPES_ICONS } from '../../../types/moments';
import { InnerHtmlParser } from '../../InnerHtmlParser';
import { MOMENT_CONTENT_TYPES_TEXT } from '../../../types/moments';
import { Icon } from '@innovamat/innova-components';
import { InnerTipBlock, ListItem, StyledText } from './MomentBlock.Styles';
import { useDigitalGuides } from '../../../context';

type Props = {
  in_a_list?: BooleanElement | undefined;
  text?: PrepElement | undefined;
  inner_tip?: PrepElement | undefined;
  index: number;
  color: string;
  floatingContent?: JSX.Element;
};
export default function MomentBlockInList({
  in_a_list,
  text,
  inner_tip,
  index,
  color,
  floatingContent,
}: Props) {
  const { t } = useDigitalGuides();

  if (inner_tip) {
    const key = MOMENT_CONTENT_TYPES_TEXT[inner_tip.body];

    return (
      <InnerTipBlock
        className="innerTip"
        title={t(`digitalguides.se.${key.toLowerCase()}`)}
        key={`tip-${index}`}
      >
        <Icon icon={MOMENT_CONTENT_TYPES_ICONS[inner_tip.body]} />
        <InnerHtmlParser text={text} />
      </InnerTipBlock>
    );
  }

  const wrapInListItem = (children: JSX.Element) => {
    return in_a_list?.value ? (
      <ListItem textColor={color}>
        {floatingContent}
        {children}
      </ListItem>
    ) : (
      <div>
        {floatingContent}
        {children}
      </div>
    );
  };

  return wrapInListItem(
    <StyledText key={`text-${index}`} className="blockContent">
      <InnerHtmlParser text={text} />
    </StyledText>
  );
}
