import { Column, Row } from '@innovamat/glimmer-components';
import { TFunction } from 'i18next';

import { Stage } from '@innovamat/radiance-utils';

import { ClassroomStudentsTableActionsCell } from '../components/classroom-students-table.actions-cell';
import type { ClassroomStudent } from '../types/classroom-student';

import {
  renderAvatar,
  renderDigitalPracticeSubLabel,
  renderEyDigitalPractice,
  renderHomeAccess,
  renderHomeAccessSublabel,
  renderIntervention,
  renderInterventionSubLabel,
  renderPrimaryDigitalPractice,
  renderSecondaryDigitalPractice,
  renderStudentName,
} from './render-students-table';

type Option = { value: string | number; label: string };

type Props = {
  availableLanguages: Option[];
  classroomHasDigitalPracticeAccess: boolean;
  classroomHasHomeAccess: boolean;
  educationOptions: Option[];
  isRti: boolean;
  isAnonymized: boolean;
  selectedInputKey: string;
  selectedStudentId: string;
  stage: string | null;
  trainingTimeLimitOptions: Option[];
  weeklyTimeLimitOptions: Option[];
  isClassroomImportedFromGoogle: boolean;
  handleBlurInput: () => void;
  handleDeleteStudent: (student: ClassroomStudent | undefined) => void;
  handleInputChange: (key: string, value: any, selectedId?: string) => void;
  handleInviteParents: (studentId: string) => void;
  handleMoveStudent: (student: ClassroomStudent | undefined) => void;
  handleOpenAvatarEditor: (id: string, img: string | null | undefined) => void;
  handleOpenChangePasswordModal: (studentId: string) => void;
  handleOpenCompleteManuallyModal: (studentId: string) => void;
  handleOpenDigitalPracticeAccessModal: () => void;
  handleResendInvitation: (studentId: string) => void;
  handleResetTests: (student: ClassroomStudent | undefined) => void;
  handleSelect: (studentId: string, inputKey: string) => void;
  handleToggleDigitalPracticeAccess: (
    value: boolean,
    studentId: string
  ) => void;
  handleToggleHomeAccess: (value: boolean, id: string) => void;
  handleToggleIndividualAccess: (value: boolean, id: string) => void;
  handleToggleRti: (studentId: string, value: boolean) => void;
  t: TFunction<'translation', undefined, 'translation'>;
};

export const getStudentsColumns = ({
  availableLanguages,
  classroomHasDigitalPracticeAccess,
  classroomHasHomeAccess,
  educationOptions,
  isRti,
  isAnonymized,
  selectedInputKey,
  selectedStudentId,
  stage,
  trainingTimeLimitOptions,
  weeklyTimeLimitOptions,
  isClassroomImportedFromGoogle,
  handleBlurInput,
  handleDeleteStudent,
  handleInputChange,
  handleInviteParents,
  handleMoveStudent,
  handleOpenAvatarEditor,
  handleOpenChangePasswordModal,
  handleOpenCompleteManuallyModal,
  handleOpenDigitalPracticeAccessModal,
  handleResendInvitation,
  handleResetTests,
  handleSelect,
  handleToggleDigitalPracticeAccess,
  handleToggleHomeAccess,
  handleToggleIndividualAccess,
  handleToggleRti,

  t,
}: Props): Column<Row>[] => {
  const columns: Column<Row>[] = [
    {
      id: 'firstName',
      label: t('students.table.labels.student-information'),
      width: '25%',
      minWidth: isAnonymized && stage !== Stage.SECUNDARIA ? '368px' : '268px',
      align: 'left',
      render: (_, row) =>
        renderStudentName({
          t,
          isClassroomImportedFromGoogle,
          selectedInputKey,
          selectedStudentId,
          isAnonymized: isAnonymized && stage !== Stage.SECUNDARIA,
          handleBlurInput,
          handleInputChange,
          handleSelect,
          ...row,
        }),
      subLabel: () =>
        renderStudentName({
          t,
          isClassroomImportedFromGoogle,
          isHeader: true,
          selectedInputKey,
          selectedStudentId,
          isAnonymized: isAnonymized && stage !== Stage.SECUNDARIA,
          handleBlurInput,
          handleInputChange,
          handleSelect,
        }),
    },
    {
      id: 'separator-1',
      width: 9,
      separator: true,
    },
  ];
  if (stage === Stage.INFANTIL) {
    columns.unshift({
      id: 'avatar',
      width: '64px',
      minWidth: '64px',
      align: 'center',
      render: (_, row) =>
        renderAvatar({
          handleOpenAvatarEditor,
          ...row,
        }),
    });
  }

  if (stage === Stage.INFANTIL) {
    columns.push({
      id: 'digitalPractice',
      label: t('students.table.labels.individualized-practice'),
      width: '50%',
      minWidth: '528px',
      align: 'left',
      render: (_, row) =>
        renderEyDigitalPractice({
          t,
          educationOptions,
          handleInputChange,
          availableLanguages,
          selectedStudentId,
          selectedInputKey,
          handleSelect,
          handleBlurInput,
          handleToggleHomeAccess,
          weeklyTimeLimitOptions,
          handleInviteParents,
          ...row,
        }),
      subLabel: () => renderDigitalPracticeSubLabel({ t, stage }),
    });
  }

  if (!isRti && stage === Stage.PRIMARIA) {
    columns.push(
      {
        id: 'digitalPractice',
        label: t('students.table.labels.individualized-practice'),
        width: '42%',
        minWidth: '428px',
        align: 'left',
        render: (_, row) =>
          renderPrimaryDigitalPractice({
            t,
            educationOptions,
            handleInputChange,
            availableLanguages,
            selectedStudentId,
            selectedInputKey,
            handleSelect,
            handleBlurInput,
            handleToggleIndividualAccess,
            stage,
            isClassroomImportedFromGoogle,
            ...row,
          }),
        subLabel: () => renderDigitalPracticeSubLabel({ t, stage }),
      },
      {
        id: 'separator-2',
        width: 9,
        separator: true,
      },
      {
        id: 'homeAccess',
        label: '',
        width: '33%',
        minWidth: '332px',
        align: 'left',
        render: (_, row) =>
          renderHomeAccess({
            t,
            handleInputChange,
            weeklyTimeLimitOptions,
            classroomHasDigitalPracticeAccess,
            classroomHasHomeAccess,
            handleToggleDigitalPracticeAccess,
            trainingTimeLimitOptions,
            handleInviteParents,
            ...row,
          }),
        subLabel: () =>
          renderHomeAccessSublabel({
            t,
            classroomHasDigitalPracticeAccess,
            handleOpenDigitalPracticeAccessModal,
          }),
      }
    );
  } else if (isRti && stage === Stage.PRIMARIA) {
    columns.push({
      id: 'intervention',
      label: t('students.table.labels.intervention'),
      width: '',
      minWidth: '428px',
      align: 'left',
      render: (_, row) =>
        renderIntervention({
          t,
          handleToggleRti,
          ...row,
        }),
      subLabel: () =>
        renderInterventionSubLabel({
          t,
        }),
    });
  }

  if (stage === Stage.SECONDARY || stage === Stage.SECUNDARIA) {
    columns.push({
      id: 'digitalPractice',
      label: t('students.table.labels.individualized-practice'),
      width: '60%',
      minWidth: '768px',
      align: 'left',
      render: (_, row) =>
        renderSecondaryDigitalPractice({
          t,
          educationOptions,
          handleInputChange,
          availableLanguages,
          selectedStudentId,
          selectedInputKey,
          ...row,
        }),
      subLabel: () => renderDigitalPracticeSubLabel({ t, stage }),
    });
  }

  columns.push({
    id: 'actions',
    width: '100%',
    sticky: true,
    stickyPosition: 'right',
    render: (_, row) => {
      return (
        <ClassroomStudentsTableActionsCell
          student={row as ClassroomStudent}
          onMoveStudent={handleMoveStudent}
          onDeleteStudent={handleDeleteStudent}
          onChangePassword={handleOpenChangePasswordModal}
          onResetTests={handleResetTests}
          onResendInvitation={handleResendInvitation}
          onCompleteInvitation={handleOpenCompleteManuallyModal}
          isClassroomImportedFromGoogle={isClassroomImportedFromGoogle}
          stage={stage}
        />
      );
    },
  });

  return columns;
};
