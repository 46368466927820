import React, { createContext, useContext, useEffect } from 'react';

import { Classroom } from '@innovamat/radiance-utils';
import { detectIsInIOS, detectIsInWebview } from '@innovamat/webview-bridge';
import { useQueryClient } from '@tanstack/react-query';
import { eventLogging } from './EventLogging';

type EventLoggingProviderProps = {
  children: React.ReactNode;
  variables: {
    RUDDERSTACK_APIKEY: string;
    RUDDERSTACK_URL: string;
    ITERABLE_APIKEY: string;
    ENVIRONMENT: string;
  };
};

type EventLoggingContextType = {
  setEventData: (
    eventName: string,
    eventData: Record<string, string | number | boolean | undefined | null>
  ) => void;
};

const EventLoggingContext = createContext<EventLoggingContextType>({
  setEventData: () => {},
});

const init = ({
  RUDDERSTACK_APIKEY,
  RUDDERSTACK_URL,
  ITERABLE_APIKEY,
  ENVIRONMENT,
}: EventLoggingProviderProps['variables']): void => {
  eventLogging.init({
    iterableApiKey: ITERABLE_APIKEY,
    rudderstackApiKey: RUDDERSTACK_APIKEY,
    rudderstackUrl: RUDDERSTACK_URL,
    isInWebview: detectIsInWebview(),
    isInIOS: detectIsInIOS(),
    isInLocal: ENVIRONMENT !== 'production' && ENVIRONMENT !== 'staging',
  });
};

type ClassroomQuery =
  | {
      classroom: Classroom;
    }
  | undefined;

export const EventLoggingProvider = ({
  children,
  variables,
}: EventLoggingProviderProps) => {
  const queryClient = useQueryClient();

  const getUser = () => {
    return queryClient.getQueryData<any>(['User']);
  };

  const getCurrentClassroom = () => {
    const classroomQueries = queryClient.getQueriesData<ClassroomQuery>({
      queryKey: ['Classroom'],
      exact: false,
    });

    if (!classroomQueries) {
      return null;
    }

    const currentClassroom = classroomQueries?.[0]?.[1]?.classroom;
    return currentClassroom;
  };

  useEffect(() => {
    init(variables);
  }, []);

  // const isStudentViewActive = !!getPreviousRoles();

  const setEventData = (
    eventType: string,
    eventProperties: any = {},
    addUserData = true
  ) => {
    const payload = { ...eventProperties };
    const currentClassroom = getCurrentClassroom();
    const user = getUser();

    if (addUserData) {
      if (user) {
        const role = user.roles ? user.roles?.join() : 'Not defined';
        const school_id = user.organizationId
          ? user.organizationId
          : 'Not defined';
        const { organizationName, crmId, locale, region, id } = user;
        payload.username = user?.email;
        payload.role = role;
        payload.school_id = school_id;
        payload.school_name = organizationName;
        payload.crm_id = crmId;
        payload.language = locale;
        payload.region = region;
        payload.user_uuid = id;

        if (currentClassroom) {
          payload.classroom_id = currentClassroom.id;
          payload.course = currentClassroom.courseOrder;
        }

        if (user.isStudentViewActive) {
          payload.in_student_view = true;
        }
      }

      payload.app_version = 'manager-1.0.0';
    }

    if (addUserData && !user) return;
    eventLogging.log(eventType, payload);
  };

  return (
    <EventLoggingContext.Provider value={{ setEventData }}>
      {children}
    </EventLoggingContext.Provider>
  );
};

export const useEventLogging = () => {
  const context = useContext(EventLoggingContext);
  if (context === null) {
    throw new Error(
      'useEventLogging must be used within an EventLoggingProvider'
    );
  }
  return context;
};
