import React from 'react';
import { useEventListener } from '@innovamat/hooks';
import { unity } from '@innovamat/radiance-utils';
import { UAParser } from 'ua-parser-js';

export const LOAD_ACTIVITY = 'load_activity';
export const CLOSE_WEBVIEW = 'close_webview';
export const LOGOUT_WEBVIEW = 'logout_webview';

declare global {
  interface Window {
    uniWebViewRunning: boolean;
    uniWebViewAccessToken: string;
  }
}

type UnityBridgePayload = {
  sessionEntity?: {};
  sceneName: string;
  packOrVariation: string | number;
  regionParameters: {};
  platformApiUrl: string;
  accessToken?: string;
  refreshToken?: string;
  idToken?: string;
  userId?: string;
  language: string;
  isSecondary?: boolean;
  userToken?: string;
  uuid?: string;
};

export function sendEventToWebview(
  eventName: string,
  prevPayload?: UnityBridgePayload
): void {
  const isInWebview = detectIsInWebview();
  if (isInWebview) {
    const currentUrl = window.location.href;

    const payload = prevPayload
      ? {
          ...prevPayload,
          language: unity.getUnityLanguage(prevPayload.language),
          currentUrl,
        }
      : {};

    window.location.href = `uniwebview://action?eventName=${eventName}&payload=${encodeURIComponent(
      JSON.stringify(payload)
    )}`;
  }
}

export function detectIsInWebview(): boolean {
  if (typeof window !== 'undefined') {
    return (
      window.uniWebViewRunning ||
      window.navigator.userAgent.includes(' uniWebView/')
    );
  }
  return false;
}

export function detectIsInIOS(): boolean {
  if (typeof window !== 'undefined') {
    const parser = new UAParser(window.navigator.userAgent);
    return (
      window.navigator.userAgent.includes(' iOS') ||
      parser.getOS().name === 'iOS'
    );
  }
  return false;
}

type WebviewBridgeValue = {
  isInWebview: boolean;
  isInIOS: boolean;
  sendEventToWebview: (
    event_name: string,
    payload?: UnityBridgePayload
  ) => void;
  buildVersion: string;
  isGreenApp: boolean;
};

export const WebviewBridgeContext = React.createContext<
  WebviewBridgeValue | undefined
>(undefined);

type Props = {
  children: JSX.Element;
};

export function WebviewBridgeProvider({ children }: Props): JSX.Element {
  const isInWebview = detectIsInWebview();
  const isInIOS = detectIsInIOS();

  let buildVersion = 'black-app';
  if (typeof window !== 'undefined') {
    buildVersion = window.navigator.userAgent.includes('uniWebView/')
      ? window.navigator.userAgent.split('uniWebView/').pop() || 'black-app'
      : 'black-app';
  }

  buildVersion = buildVersion.replace(' iOS', '');
  if (buildVersion === '1.0') {
    buildVersion = 'black-app';
  }

  const isGreenApp = buildVersion !== 'black-app';

  useEventListener('eventFromWebview', (e: any) => {
    const { event_name } = e.detail;
    console.log(event_name);
  });

  const value = {
    isInWebview,
    isInIOS,
    sendEventToWebview,
    buildVersion,
    isGreenApp,
  };

  return (
    <WebviewBridgeContext.Provider value={value}>
      {children}
    </WebviewBridgeContext.Provider>
  );
}
