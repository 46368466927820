import React, { createContext } from 'react';

export type EnvVariablesValues = {
  APOLLO_SERVER: string;
  API_USERS: string;
  USERS_CLIENT_ID: string;
  GA: string;
  CONFIG_CAT_KEY: string;
  CONFIG_CAT_CACHE_TIME: string;
  WEBAPP: string;
  INTEGROMAT_GET_CRM_SCHOOL: string;
  POEDITOR_API_URL: string;
  POEDITOR_API_TOKEN: string;
  POEDITOR_PROJECT_ID: string;
  RUDDERSTACK_APIKEY: string;
  RUDDERSTACK_URL: string;
  VERSION: string;
  ITERABLE_APIKEY: string;
  INLINE_MANUAL: string;
  ORGANIZATIONS: string;
  API_PLATFORM: string;
  STANDALONE_BUILD_DOMAIN: string;
  UNITY_BUILD: string;
  WEBCONTENTS_URL: string;
  API_SOLVER_URL: string;
  CORPORATE_WEBSITE: string;
  INTEGROMAT_SESSION_REVIEW: string;
  INTEGROMAT_SESSION_ERROR: string;
  GOOGLE_CLIENT_ID: string;
  GOOGLE_SECRET: string;
  PREPR_GRAPHQL: string;
  MANAGER_NEXT: string;
};

const EnvVariablesContext = createContext<EnvVariablesValues | undefined>(
  undefined
);

type Props = {
  children: React.ReactNode;
  envVariables: EnvVariablesValues;
};

export function EnvVariablesProvider({ children, envVariables }: Props) {
  return (
    <EnvVariablesContext.Provider value={envVariables}>
      {children}
    </EnvVariablesContext.Provider>
  );
}

export const useEnvVariables = (): EnvVariablesValues => {
  const context = React.useContext(EnvVariablesContext);
  if (!context) {
    throw new Error('useEnvVariables should be inside EnvVariablesProvider');
  }
  return context;
};
