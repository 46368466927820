import { useTranslation } from 'react-i18next';

import { snack } from '@innovamat/glimmer-components';

import type { Organization } from '@innovamat/glow-api-client';
import { useChangeOrganizationMutation } from '@innovamat/glow-api-client';

import { useUser } from '../../../../user-management';
import { SearchOrganizationInput } from '../../../components/search-organization-input';
import { updateCurrentUserData } from '../../../utils/update-current-user-data';

type Props = {
  onChangeOrganization: () => void;
};

function SearchOrganization({ onChangeOrganization }: Props): JSX.Element {
  const { t } = useTranslation();
  const { user } = useUser();

  const changeOrganizationMutation = useChangeOrganizationMutation();

  const handleSelect = (organization: Organization): void => {
    changeOrganizationMutation.mutate(
      { body: { schoolId: organization.id!, userId: user?.id! } },
      {
        onSuccess: async () => {
          onChangeOrganization();
          await updateCurrentUserData(organization);
          window.location.reload();
        },
        onError: () => {
          snack.error(t('errors.organizations.changeOrganization'));
        },
      }
    );
  };

  return <SearchOrganizationInput onSelect={handleSelect} />;
}

export { SearchOrganization };
