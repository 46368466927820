import { Button, Dropdown } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useGoogleImport } from '../../../google-classroom';
import { useAddActionDropdown } from '../../hooks/use-add-action-dropdown';

type Props = {
  isClassroomImportedFromGoogle: boolean;
  isDisabled?: boolean;
  handleAddEmptyRow: () => void;
};

function ClassroomStudentsAddDropdown({
  isClassroomImportedFromGoogle,
  isDisabled = false,
  handleAddEmptyRow,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { onOpenSyncroModal } = useGoogleImport();

  const { getOptions } = useAddActionDropdown({
    type: 'student',
    onAddDefault: handleAddEmptyRow,
    onImportFromGoogle: onOpenSyncroModal,
    isClassroomImportedFromGoogle,
  });

  return (
    <Dropdown openOnHover={!isDisabled}>
      <Dropdown.Toggle>
        <Button disabled={isDisabled} rightIcon="AddUpIcon" variant="secondary">
          {t('students.button.addStudent')}
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Content position={'bottomRight'}>
        {getOptions().map((option) => (
          <Dropdown.Item {...option} />
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
}

export { ClassroomStudentsAddDropdown };
