/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import Select, { GroupTypeBase } from 'react-select';

import cx from 'classnames';
import { SelectComponents } from 'react-select/src/components';
import cssStyles from './SelectOption.module.scss';
import SubLabel from '../SubLabel/SubLabel';

export type Option = {
  value: string;
  label: string;
};

type Props = {
  error?: Error;
  description?: string;
  label?: string;
  placeholder?: string;
  options: Option[];
  selectorClassName?: string;
  value?: string | undefined;
  onChange?: (option: Option) => void;
  required?: boolean;
  optionalText?: string;
  disabled?: boolean;
  loading?: boolean;
  noBgColor?: boolean;
  onInputChange?: (value: string) => void;
  components?: Partial<SelectComponents<Option, false, GroupTypeBase<Option>>>;
  hasBorder?: boolean;
  styles?: any;
  menuPortalTarget?: any;
  isOptionDisabled?: (option: Option) => boolean;
};

function SelectOption({
  error,
  description,
  label,
  options,
  selectorClassName,
  value,
  disabled,
  loading,
  required,
  optionalText,
  components,
  hasBorder,
  styles,
  isOptionDisabled,
  ...rest
}: Props) {
  const containerClass = cx(cssStyles.inputContainer, {
    [cssStyles.hasError]: error,
  });

  const selectorClass = cx(cssStyles.selector, {
    [selectorClassName as string]: !!selectorClassName,
    [cssStyles.hasBorder]: hasBorder,
  });

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      backgroundColor: state.selectProps.noBgColor ? 'transparent' : '#e5e5e5',
      borderColor: base.borderColor,
      height: '40px',
      outline: hasBorder ? '1px solid #949494 !important' : base.outline,
      outlineWidth:
        hasBorder && state.isFocused ? '2px !important' : base.outlineWidth,
      outlineColor:
        hasBorder && state.isFocused ? '#333333 !important' : base.outlineColor,
      '&:hover': {
        borderColor: base.borderColor,
      },
      cursor: state.isDisabled ? 'inherit' : 'pointer',
    }),
    menu: (base: any, state: any) => ({
      ...base,
      marginTop: '2px',
      marginBottom: '2px',
      zIndex: 100,
    }),
    option: (base: any, state: any) => ({
      ...base,
      height: '48px',
      alignItems: 'center',
      display: 'flex',
      color: state.isDisabled ? '#949494' : '#333',
      cursor: state.isDisabled ? 'inherit' : 'pointer',
      backgroundColor: state.isSelected ? '#e5e5e5' : cssStyles.backgroundColor,
      '&:hover': {
        backgroundColor: state.isSelected ? '#e5e5e5' : '#f5f5f5',
      },
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }),
    dropdownIndicator: (base: any, state: any) => ({
      ...base,
      color: state.isDisabled ? '#949494' : '#333',
      '&:hover': {
        color: '#333',
      },
    }),
    ...styles,
  };

  return (
    <div className={containerClass}>
      {error ? (
        <SubLabel>{error.message}</SubLabel>
      ) : (
        description && <SubLabel>{description}</SubLabel>
      )}
      <Select
        isLoading={loading}
        isDisabled={disabled}
        isOptionDisabled={isOptionDisabled}
        value={options.find((opt) => opt.value === value)}
        styles={customStyles}
        className={selectorClass}
        options={options as any}
        components={{
          ...components,
          IndicatorSeparator: () => null,
        }}
        {...rest}
      />
      {label && (
        <div>
          <label className={cssStyles.label} htmlFor={label}>
            {label}
          </label>
          {!required && (
            <SubLabel className={cssStyles.optionalText}>
              {optionalText}
            </SubLabel>
          )}
        </div>
      )}
    </div>
  );
}

export default SelectOption;
