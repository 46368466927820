import {
  Typography,
  Tooltip,
  useCheckOverflow,
} from '@innovamat/glimmer-components';
import { useCallback, useState } from 'react';
import styled from '@emotion/styled';

type ClassroomElementProps = {
  text: string;
  classroomName: string;
  classroomId: string;
  courseOrder: number;
  goToClassroom?: (classroomId: string) => void;
};

const Text = styled(Typography.Link2)<{ onClick?: () => void }>`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme, onClick }) =>
    !onClick &&
    `color: ${theme.tokens.color.alias.cm.text.text.value};
    text-decoration: none;
    cursor: default;
    `};
`;

const StyledTooltip = styled(Tooltip)`
  display: block !important;
`;

const TextWrapper = styled.p`
  max-width: 134px;
`;

const ClassroomElement = ({
  text,
  classroomName,
  classroomId,
  courseOrder,
  goToClassroom,
}: ClassroomElementProps): JSX.Element => {
  const [textRef, setTextRef] = useState<HTMLParagraphElement | null>(null);

  const onSetTextRef = useCallback((ref: HTMLParagraphElement | null) => {
    setTextRef(ref);
  }, []);

  const { hasOverflow } = useCheckOverflow({
    text: textRef?.children[0],
    container: textRef,
    dependencies: [onSetTextRef, textRef],
  });

  return (
    <StyledTooltip
      content={hasOverflow ? text : undefined}
      popperOptions={{ strategy: 'fixed' }}
    >
      <TextWrapper ref={onSetTextRef}>
        <Text
          data-testid={`${courseOrder}_${classroomName}_CLASSROOMNAME`}
          onClick={goToClassroom ? () => goToClassroom(classroomId) : undefined}
        >
          {text}
        </Text>
      </TextWrapper>
    </StyledTooltip>
  );
};

export default ClassroomElement;
