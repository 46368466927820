import { useEffect } from 'react';

const useCartExitPrompt = (isEmptyCart: boolean): void => {
  const handleBeforeUnload = (event: BeforeUnloadEvent): string => {
    event.preventDefault();

    if (event) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    }

    return '';
  };

  useEffect(() => {
    if (!isEmptyCart) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return (): void => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isEmptyCart]);
};

export { useCartExitPrompt };
