import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  ActivityParameters,
  MessageType,
  RegionalParameters,
  UserParameters,
} from '../../types/webApplet';

const IframeWrapper = styled.iframe`
  border: none;
  display: flex;
  height: 100%;
  width: 100%;
`;

type Props = {
  userParameters: UserParameters;
  regionalParameters: RegionalParameters;
  activityParameters: ActivityParameters;
  onCloseApplet: () => void;
  onAppletIsCompleted: (args: MessageType['arguments']) => void;
  webContentsUrl: string;
  solverUrl: string;
  setEventData: (
    eventType: string,
    eventProperties?: any,
    addUserData?: boolean
  ) => void;
};

export const WebAppletWrapper = ({
  activityParameters,
  onAppletIsCompleted,
  onCloseApplet,
  regionalParameters,
  userParameters,
  webContentsUrl,
  solverUrl,
  setEventData,
}: Props): JSX.Element => {
  const iFrame = useRef<HTMLIFrameElement | null>(null);
  const [isIframeLoaded, setIframeIsLoaded] = useState<boolean>(false);

  function sendMessageToWebContentsInstance(message: any): void {
    if (iFrame === null) {
      throw Error('Trying to send message to null iframe instance');
    }

    iFrame.current?.contentWindow?.postMessage(message, '*');
  }

  function handleMessageEvent(message: MessageEvent): void {
    switch (message.data.eventId) {
      case 'AppIsClosed':
        onCloseApplet();
        break;
      case 'ActivityIsCompleted':
        onAppletIsCompleted(message.data.arguments);
        break;
      case 'ConnectionAppletTimeout':
      // TODO: handle timeout when the Wiris applet is not responding
      case 'SendEvent':
        setEventData(
          message.data.arguments.eventId,
          message.data.arguments.payload
        );
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (isIframeLoaded) {
      sendMessageToWebContentsInstance({
        eventId: 'initWebContents',
        payload: {
          activityParameters,
          regionalParameters,
          userParameters,
          solverUrl,
        },
      });
    }
  }, [isIframeLoaded]);

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent, false);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
      if (isIframeLoaded) {
        iFrame.current?.remove();
        iFrame.current = null;
      }
    };
  }, []);

  return (
    <IframeWrapper
      key={activityParameters.id}
      onLoad={() => setIframeIsLoaded(true)}
      ref={iFrame}
      src={`${webContentsUrl}/embedded.html`}
      title="WebContents"
      sandbox="allow-scripts allow-forms allow-same-origin"
      allow="clipboard-read; clipboard-write"
    />
  );
};
