import { Space } from '@innovamat/smart-guides';
import { getSpacesInCache, updateSpacesInCache } from '../cache-actions';
import { useQueryClient } from '@tanstack/react-query';
import { useMarkSpaceMutation } from '@innovamat/glow-api-client';

type HandleSpaceProps = {
  classroomId: string;
  sessionId: string;
  academicYearId: string;
  onSuccess?: () => void;
  onError?: () => void;
};

function useHandleSpace({
  classroomId,
  sessionId,
  academicYearId,
  onSuccess,
  onError,
}: HandleSpaceProps) {
  const queryClient = useQueryClient();
  const { mutate, isSuccess } = useMarkSpaceMutation();

  const spacesBody = {
    body: {
      classroomId: classroomId!,
      sessionId: sessionId!,
    },
  };

  const handleMarkSpace = (space: Space, active: number) => {
    const body = {
      classroomId: classroomId!,
      academicYearId: academicYearId!,
      sessionId: sessionId!,
      spaceId: space.preprId,
      pinned: !space.pinned,
      challengeId: space.challenges[active].id,
    };

    const prevSpace = getSpacesInCache(queryClient, spacesBody)[body.spaceId];

    const prevSpaceBody = {
      classroomId: classroomId!,
      academicYearId: academicYearId!,
      sessionId: sessionId!,
      spaceId: space.preprId,
      pinned: prevSpace.pinned,
      challengeId: prevSpace.challenges[active]?.id,
    };

    // Optimistic cache update
    updateSpacesInCache(queryClient, spacesBody, body);

    mutate(
      {
        body,
      },
      {
        onSuccess(data) {
          if (!data.markSpace?.errors) {
            onSuccess?.();
          } else {
            onError?.();
            updateSpacesInCache(queryClient, spacesBody, prevSpaceBody);
          }
        },
        onError(error) {
          onError?.();
          updateSpacesInCache(queryClient, spacesBody, prevSpaceBody);
        },
      }
    );

    // Code to send event
    const key = !space.pinned ? 'unpin_challenge' : 'pin_challenge';
    // setEventData(key, {
    //   challenge_id: space.challenges[active].id,
    //   challenge_name: space.challenges[active].title,
    //   challenge_order: active + 1,
    //   challenges_total: space.challenges.length,
    //   space_id: space.preprId,
    //   space_name: space.title,
    //   space_session_id: space.id,
    //   space_order: getSpaceOrder(space.id),
    //   spaces_total: spaces?.length,
    //   planning_id: spaceSession.id,
    //   planing_name: title,
    //   is_done: space.challenges[active].checked,
    //   session_id: id,
    //   session_name: title,
    // });
  };

  const handleSaveActiveChallenge = (space: Space, active: number) => {
    mutate({
      body: {
        classroomId: classroomId!,
        academicYearId: academicYearId!,
        sessionId: sessionId!,
        spaceId: space.preprId,
        pinned: space.pinned,
        challengeId: space.challenges[active].id,
      },
    });

    // Code to send event
    const key = 'change_challenge';
    // setEventData(key, {
    //   challenge_id: space.challenges[active].id,
    //   challenge_name: space.challenges[active].title,
    //   challenge_order: active + 1,
    //   challenges_total: space.challenges.length,
    //   space_id: space.preprId,
    //   space_name: space.title,
    //   space_session_id: space.id,
    //   space_order: getSpaceOrder(space.id),
    //   spaces_total: spaces?.length,
    //   planning_id: spaceSession.id,
    //   planning_name: title,
    //   is_pinned: space.pinned,
    //   is_done: space.challenges[active].checked,
    //   session_id: id,
    //   session_name: title,
    // });
  };

  return {
    handleMarkSpace,
    handleSaveActiveChallenge,
    isSuccess,
  };
}

export { useHandleSpace };
