const IMPORT_TYPES = {
  NEW_IMPORT: 'newImport',
  SCHOOL_STUDENT: 'schoolStudent',
  STUDENT: 'student',
  CLASSROOM: 'classroom',
  TEACHER: 'teacher',
} as const;

type ImportType = (typeof IMPORT_TYPES)[keyof typeof IMPORT_TYPES];

const ERRORS = {
  SIS_ID_EXISTS: 'exists_student_with_sis_id',
  EMAIL_EXISTS: 'exists_student_with_email',
  NOT_VALIDATED: 'not_validated_student',
} as const;

type CSVData = string[][];

type ErrorType = (typeof ERRORS)[keyof typeof ERRORS];

type StudentValidation = {
  email: string | null;
  errorType: ErrorType;
  rowNumber: number;
  studentName: string;
  sisId: string;
  overwrites: {
    id: string;
    classroom: string;
    courseOrder: number;
    firstName: string;
    lastName: string;
    sisId?: string | null | undefined;
    email?: string | null | undefined;
  } | null;
};

const STEPS = {
  IMPORTING: 'importing',
  SELECT: 'select',
  SKIP_VALIDATION: 'skip_validation',
  UPLOADED: 'uploaded',
  VALIDATION: 'validation',
} as const;

type Step = (typeof STEPS)[keyof typeof STEPS];

type CSVImport = {
  id: string;
  owner: string;
  organization: string;
  originalFilename: string;
  status: ImportStatus;
  createdAt: string;
  completedAt: string;
  items: CSVImportItem[];
};

const IMPORT_STATUS = {
  QUEUED: 'queued',
  PROCESSING: 'processing',
  SUCCESS: 'success',
  FAILED: 'failed',
} as const;

type ImportStatus = (typeof IMPORT_STATUS)[keyof typeof IMPORT_STATUS];

type CSVImportItem = {
  id: string;
  number: string;
  status: string;
  message: string;
};

export type {
  ImportType,
  StudentValidation,
  ErrorType,
  CSVData,
  Step,
  CSVImport,
  ImportStatus,
  CSVImportItem,
};

export { ERRORS, IMPORT_STATUS, IMPORT_TYPES, STEPS };
