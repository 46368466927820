import type { SxProps, Theme } from '@mui/material';

import type { ThemeType } from '@innovamat/glimmer-components';

export const tableStyles = (theme: ThemeType): SxProps<Theme> => ({
  marginTop: '1rem',
  'th > div > h6:only-child': {
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  'tbody tr': {
    backgroundColor:
      theme.tokens.color.alias.cm.surface['surface-primary'].value,
  },
  'tbody tr:hover': {
    backgroundColor:
      theme.tokens.color.alias.cm.surface['surface-secondary'].value,
  },
  'tbody tr:hover .MuiTableCell-root': {
    backgroundColor:
      theme.tokens.color.alias.cm.surface['surface-secondary'].value +
      ' !important',
  },
  'tbody tr:hover .action-cell': {
    opacity: 1,
    pointerEvents: 'all',
  },
  th: {
    borderColor:
      theme.tokens.color.specific.reports.tables['divider-secondary'].value,
  },
  td: {
    height: '4rem',
  },
  'div:has(> div.separator-wrapper)': {
    position: 'initial',
  },
  '.separator-wrapper': {
    backgroundColor: 'transparent !important',
  },
  '.table-button': {
    minHeight: '2.5rem',
    justifyContent: 'flex-start',
    padding: '1rem 0.5rem',
  },
  '.table-button p': {
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.875rem',
    fontWeight: '400',
  },
  '.fake-focus': {
    outline: `1px solid ${theme.tokens.color.alias.cm.border['border-default'].value}`,
    outlineOffset: '-1px',
  },
  '.fake-focus :hover': {
    outline: `1px solid ${theme.tokens.color.alias.cm.border['border-default'].value}`,
    outlineOffset: '-1px',
    left: '0',
  },
  '.table-input': {
    fontSize: '0.875rem',
  },
});

export const skeletonTableStyles = (theme: ThemeType): SxProps<Theme> => ({
  'th > div > h6': {
    color: theme.tokens.color.alias.cm.text['text-subtle'].value,
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  'th > div': {
    minHeight: '2rem !important',
  },
  th: {
    borderColor:
      theme.tokens.color.specific.reports.tables['divider-secondary'].value,
  },
  td: {
    height: '4rem',
  },
});
