import React from 'react';

import { Wrapper } from './TextRow.styles';

type TextRowProps = {
  children: string;
  className?: string;
  elipsisOnRows?: number;
  isOpaque?: boolean;
};

const TextRow = ({
  children,
  className,
  elipsisOnRows = 0,
  isOpaque = false,
}: TextRowProps) => {
  return (
    <Wrapper
      clampNumber={elipsisOnRows}
      className={className}
      hasElipsis={elipsisOnRows > 0}
      isOpaque={isOpaque}
    >
      {children}
    </Wrapper>
  );
};

export default TextRow;
