import styled from 'styled-components';
import { IconType } from '@innovamat/glimmer-icons';
import { Typography, theme, Icon } from '@innovamat/glimmer-components';

type NotesIconType = 'info' | 'check' | 'cross' | 'infoObservations';

type Props = {
  title: string;
  iconType?: NotesIconType;
  explanation?: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

const Explanation = styled(Typography.Body3)`
  margin: 16px 0 8px;
`;

function NotesOfTheWeekTitle({ title, iconType, explanation }: Props) {
  const notesIcons = {
    info: 'InfoIcon',
    check: 'CheckIcon',
    cross: 'ErrorIcon',
    infoObservations: 'InfoIcon',
  };

  function getIcon(type: NotesIconType): IconType {
    return notesIcons[type] as IconType;
  }

  function getColor(type: NotesIconType) {
    if (type === 'infoObservations') {
      return theme.tokens.color.alias.cm.icon['icon-info'].value;
    }
    if (type === 'info') {
      return theme.tokens.color.alias.cm.icon['icon-warning'].value;
    }
    if (type === 'check') {
      return theme.tokens.color.alias.cm.icon['icon-success'].value;
    }
    if (type === 'cross') {
      return theme.tokens.color.specific.reports.semantic['no-data'].value;
    }
    return undefined;
  }
  return (
    <Container>
      <InnerContainer>
        {iconType && (
          <Icon
            icon={getIcon(iconType)}
            bgColor={getColor(iconType)}
            size="M"
          />
        )}
        <Typography.H4>{title}</Typography.H4>
      </InnerContainer>
      {explanation && (
        <Explanation
          color={theme.tokens.color.alias.cm.text['text-subtle'].value}
        >
          {explanation}
        </Explanation>
      )}
    </Container>
  );
}

export default NotesOfTheWeekTitle;
