import { useTranslation } from 'react-i18next';
import { Parent } from '../views/classroom-students/utils/render-students-table';

export function useParentsStatus({
  parents,
  disabled,
}: {
  parents: Parent[];
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const activeParentsCount = parents.filter(
    (parent) => !parent.userInfo.token
  ).length;

  const getParentStatusText = () => {
    if (parents.length === 1) {
      return parents[0].userInfo.email;
    }
    if (isSomeParentsActive && !isAllParentsActive) {
      return `(${activeParentsCount}/${parents.length} ${t(
        'students.table.active'
      )})`;
    }
    return `${parents.length} ${t('students.table.emails')}`;
  };

  const isSomeParentsActive = activeParentsCount > 0;
  const isAllParentsActive = activeParentsCount === parents.length;

  const getChipType = () =>
    isSomeParentsActive && !disabled ? 'success' : 'default';
  const getChipText = () => {
    if (disabled) {
      return t('generic.status.disabled');
    }

    return isSomeParentsActive
      ? t('generic.status.active')
      : t('generic.status.pending');
  };

  return {
    getParentStatusText,
    getChipType,
    getChipText,
  };
}
