export const translateAppletTexts = (
  applet: any,
  translations: {},
  textsArray: string
) => {
  try {
    let trans;
    trans = `[${textsArray.split('textsArray = {')[1]}`;
    trans = trans.replace(/}$/, ']').replace(/“/g, '"').replace(/”/g, '"');
    trans = JSON.parse(trans);
    const translated = trans.map((t: string) => translations[t] || t);
    trans.forEach((_t: string, index: number) => {
      applet?.evalCommand(
        `SetValue(textsArray, ${index + 1}, "${translated[index]}")`
      );
    });
    applet?.evalCommand('RunClickScript(captionsUpdate)');
  } catch (e) {
    console.error('Error parsing translations');
  }
};
