import React from 'react';
import Icon from '../Icon';
import {
  CountryLanguage,
  CountrySelected,
  Region,
  Language,
  CountryLanguageTexts,
} from './CountryRegionButton.styles';

type CountryRegionButtonProps = {
  region: string;
  language: string;
  isMobile?: boolean;
  handleCountryLanguageClick?: React.MouseEventHandler<HTMLDivElement>;
};

function CountryRegionButton({
  region,
  language,
  isMobile,
  handleCountryLanguageClick,
}: CountryRegionButtonProps) {
  return (
    <CountryLanguage
      onClick={handleCountryLanguageClick}
      data-testid="country-selector"
    >
      <CountrySelected>
        <Icon icon="World" />
        <CountryLanguageTexts isMobile={isMobile}>
          <Region>{region}</Region>
          <Language>{language}</Language>
        </CountryLanguageTexts>
      </CountrySelected>
    </CountryLanguage>
  );
}

export default CountryRegionButton;
