// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H85xFACdkaIjewxS span{font-size:14px;margin-left:5px}.tJbhgPoJAZg7e0eS{margin-left:8px}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/IconSelector/IconSelector.module.scss"],"names":[],"mappings":"AACE,uBACE,cAAA,CACA,eAAA,CAIJ,kBACE,eAAA","sourcesContent":[".option {\n  span {\n    font-size: 14px;\n    margin-left: 5px;\n  }\n}\n\n.icon {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": "H85xFACdkaIjewxS",
	"icon": "tJbhgPoJAZg7e0eS"
};
export default ___CSS_LOADER_EXPORT___;
