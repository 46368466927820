import React from 'react';

import { Container } from './MaterialsListBody.styles';

type MaterialsListBodyProps = {
  children: React.ReactNode | React.ReactNode[];
};

function MaterialsListBody({ children }: MaterialsListBodyProps) {
  return <Container>{children}</Container>;
}

export default MaterialsListBody;
