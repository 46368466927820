import { useState, useEffect, useRef, useCallback, RefObject } from 'react';

const useElementVisibility = (): {
  isElementHidden: boolean;
  elementRef: RefObject<HTMLDivElement>;
} => {
  const [isElementHidden, setIsElementHidden] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const checkAncestorsForDisplayNone = useCallback(
    (element: HTMLElement | null) => {
      let currentElement: HTMLElement | null = element;
      while (currentElement) {
        if (window.getComputedStyle(currentElement).display === 'none') {
          return true;
        }
        currentElement = currentElement.parentElement;
      }
      return false;
    },
    []
  );

  useEffect(() => {
    const checkVisibility = (): void => {
      if (elementRef.current) {
        const isHidden = checkAncestorsForDisplayNone(elementRef.current);
        setIsElementHidden(isHidden);
      }
    };

    const observer = new MutationObserver(checkVisibility);

    let currentElement: HTMLElement | null = elementRef.current;
    while (currentElement) {
      observer.observe(currentElement, { attributes: true });
      currentElement = currentElement.parentElement;
    }

    checkVisibility();

    return () => observer.disconnect();
  }, []);

  return { isElementHidden, elementRef };
};

export default useElementVisibility;
