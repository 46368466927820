import { Applet } from '@innovamat/glow-api-client';

export const getActivitiesWithoutDuplicates = (
  activities: Applet[]
): Applet[] =>
  activities.reduce((acc: Applet[], activity) => {
    const alreadyInAcc: Applet | undefined = acc.find(
      (a: Applet) =>
        a.sceneName === activity.sceneName && a.pack === activity.pack
    );
    return alreadyInAcc ? acc : acc.concat([activity]);
  }, []);
