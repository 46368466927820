import React from 'react';

import { Wrapper, ArrowIcon } from './ExpandButton.styles';

const ExpandButton = ({ row, onRowClick, isExpanded }: any) => {
  return (
    <Wrapper onClick={() => onRowClick(row)} isExpanded={isExpanded}>
      <ArrowIcon icon={'ArrowUp'} />
    </Wrapper>
  );
};

export default ExpandButton;
