import React from 'react';

import { Wrapper } from './OrderStatusTag.styles';

import type { OrderStatus } from './OrderStatusTag.types';

type Props = {
  orderStatus: OrderStatus;
  tag: string;
};

function OrderStatusTag({ orderStatus, tag }: Props) {
  return (
    <Wrapper orderStatus={orderStatus}>
      <span>{tag}</span>
    </Wrapper>
  );
}

export { OrderStatusTag };
