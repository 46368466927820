import { useQueryClient } from '@tanstack/react-query';
import {
  useTeachersQuery,
  useUnlinkTeacherMutation,
} from '@innovamat/glow-api-client';
import type { Teacher, UnlinkTeacherBody } from '@innovamat/glow-api-client';

type ErrorUnlinkTeacher = {
  error: unknown;
  body: {
    classroomId: string;
    teacherId: string;
  };
};

type Props = {
  isPlatformEnabled: boolean;
  organizationId: string;
  onSuccess?: (body: UnlinkTeacherBody) => void;
  onError?: (error: ErrorUnlinkTeacher) => void;
};

type UnlinkTeacher = {
  classroomId: string;
  teacher: Teacher;
};

type UseUnlinkTeacher = (props: Props) => {
  isPending: boolean;
  handleUnlinkTeacher: ({ classroomId, teacher }: UnlinkTeacher) => void;
};

const useUnlinkTeacher: UseUnlinkTeacher = ({
  isPlatformEnabled,
  organizationId,
  onSuccess,
  onError,
}: Props) => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useUnlinkTeacherMutation();

  const handleUnlinkTeacher = ({
    classroomId,
    teacher,
  }: UnlinkTeacher): void => {
    mutate(
      {
        body: {
          classroomId,
          teacherId: teacher.id,
        },
      },
      {
        onSuccess: (_, { body }) => {
          const queryKey = useTeachersQuery.getKey({
            classroomId: classroomId ?? '',
            organizationId,
            isPlatformEnabled,
          });

          queryClient.refetchQueries({ queryKey });

          onSuccess?.(body!);
        },
        onError: (error) => {
          onError?.({ error, body: { classroomId, teacherId: teacher.id } });
        },
      }
    );
  };

  return { isPending, handleUnlinkTeacher };
};

export { useUnlinkTeacher };
export type { ErrorUnlinkTeacher, UseUnlinkTeacher };
