import { useContext } from 'react';
import { GoogleImportContext } from '../providers';

export function useGoogleImport() {
  const context = useContext(GoogleImportContext);
  if (context === undefined) {
    throw new Error(
      'useGoogleImport must be used within a ImportGoogleProvider'
    );
  }
  return context;
}
