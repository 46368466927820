import { useUpdateEffect } from '@innovamat/hooks';
import usePlayerDispatch from '../providers/PlayerProviders/usePlayerDispatch';
import usePlayerState from '../providers/PlayerProviders/usePlayerState';
import { getResource } from '../state/selectors';

export default function useResourceEvents() {
  const dispatch = usePlayerDispatch();
  const { fullscreen, resource, currentResource, currentStep } =
    usePlayerState();
  const subResource = getResource(resource, currentResource);

  const handleAppletIsCompleted = () => {
    dispatch({ type: 'ON_APPLET_COMPLETED' });
  };

  // This method was used for automatically playing next resource when a video ends,
  // It is not used anywhere now but I decided to maintain it just in case they require the
  // functionality again.
  const handleSetNextPage = (duration: number) => {
    dispatch({
      type: 'SET_NEXT_RESOURCE',
      payload: {
        duration,
        progress: 0,
      },
    });
  };

  // this event is dispatched inside Video component
  useUpdateEffect(() => {
    if (!subResource || subResource.type === 'vimeo') return;

    const eventType = fullscreen?.active
      ? 'ENTER_FULLSCREEN'
      : 'EXIT_FULLSCREEN';

    const isPdf = 'pages' in subResource;

    dispatch({
      type: eventType,
      payload: {
        duration: isPdf ? subResource.pages.length : undefined,
        progress: isPdf ? currentStep + 1 : undefined,
      },
    });
  }, [fullscreen?.active]);

  return {
    handleAppletIsCompleted,
    handleSetNextPage,
  };
}
