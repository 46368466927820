import { MarkSessionBody } from '@innovamat/glow-api-client';
import { QueryClient } from '@tanstack/react-query';
import { updateCompletedResources } from './update-completed-resources';

type updateSessionDoneCacheProps = {
  classroomId: string | undefined;
  queryClient: QueryClient;
  markedSessionBody: MarkSessionBody;
};

export function updateSessionDoneCache({
  queryClient,
  classroomId,
  markedSessionBody,
}: updateSessionDoneCacheProps): void {
  updateCompletedResources({
    queryClient,
    body: markedSessionBody,
  });
}
