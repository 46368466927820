export const sortByTwoFields = (
  aFirst: string | undefined | null,
  aSecond: string | undefined | null,
  bFirst: string | undefined | null,
  bSecond: string | undefined | null
) => {
  const af = aFirst?.toLocaleUpperCase();
  const as = aSecond?.toLocaleUpperCase();
  const bf = bFirst?.toLocaleUpperCase();
  const bs = bSecond?.toLocaleUpperCase();

  if (!af) return -1;
  if (!bf) return 1;
  if (af === bf) {
    if (!as || !bs) return 0;
    return as.localeCompare(bs);
  }
  return af.localeCompare(bf);
};
