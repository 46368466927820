import { useEffect, useState } from 'react';

import { useEventLogging } from '@innovamat/event-logging';
import {
  useCurrentNavigationValues,
  type ListNavigation,
  type NavigationEvents,
  type NavigationMode,
  type Page,
} from '..';
import { useNavigationEventData } from '../../navigation';

type useGetNavigationListProps = {
  title: string;
  navigationList: ListNavigation[] | null;
  topMenu: string;
  onNavigate?: (navigationEvents: NavigationEvents) => void;
};

type NavigationListData = {
  page_id?: string;
  page_name?: string;
  list_id?: string;
  lists_total?: number;
  list_name?: string;
  list_order?: number;
  resource_id?: string;
  resource_name?: string;
  resource_type?: string;
  resource_order_in_list?: number;
  resources_total_in_list?: number;
  navigation_mode: NavigationMode;
};

const getNotSeenDuration = (duration: number | undefined, progress: number) => {
  return duration ? (duration * (100 - progress)) / 100 : 0;
};

const isVideoAlmostSeen = (duration: number | undefined, progress: number) => {
  return progress >= 90 && getNotSeenDuration(duration, progress) <= 60;
};

const mapPageToResourceNavigationList = (page: Page): ListNavigation[] => {
  return page.content_lists.map((list) => ({
    id: list.id,
    title: list.name,
    resources: list.resources.map((resource) => ({
      id: resource.id,
      title: resource.name,
      enabled: true,
      fromList: list.id,
      fromPage: page.id,
      checked:
        resource.type === 'vimeo'
          ? isVideoAlmostSeen(
              resource.video_info.duration,
              +(resource.video_info.progress || 0)
            )
          : !!resource.is_done,
      type: resource.type,
      isChechedDisabled: !resource.is_completable,
      solutionsEnabled: resource.solutions_enabled,
      hasSolutions: resource.has_solutions,
      isDigitalGuide: resource.has_digital_guide,
      listType: list.list_type,
    })),
  }));
};

function useGetNavigationList({
  pageResources,
}: {
  pageResources: Page | undefined;
}): useGetNavigationListProps {
  const { getTypeOfPagePermission, currentPage } = useCurrentNavigationValues();
  const pageType = currentPage
    ? getTypeOfPagePermission(currentPage)
    : undefined;

  const { resourceId: fromResourceId, getResourceData } =
    useNavigationEventData();

  const { setEventData } = useEventLogging();
  const url = new URL(window.location.href);
  const [navigationList, setNavigationList] = useState<ListNavigation[] | null>(
    null
  );
  const [title, setTitle] = useState<string>('');

  const resource = getResourceData();

  const onNavigate = (navigationEvents: NavigationEvents) => {
    if (!navigationEvents) return;

    const navigationListData: NavigationListData = {
      page_id: navigationEvents.pageId,
      page_name: title,
      list_id: navigationEvents.listId,
      lists_total: undefined,
      list_name: undefined,
      list_order: undefined,
      resource_id: navigationEvents.resourceId,
      resource_name: undefined,
      resource_type: navigationEvents.type,
      resource_order_in_list: undefined,
      resources_total_in_list: undefined,
      navigation_mode: navigationEvents.navigationMode,
    };

    if (navigationList) {
      const listIndex = navigationList.findIndex(
        (list) => list.id === navigationEvents.listId
      );
      const list = navigationList[listIndex];
      const resourceIndex = list?.resources.findIndex(
        (resource) => resource.id === navigationEvents.resourceId
      );
      const resource = list?.resources[resourceIndex];

      navigationListData.lists_total = navigationList.length;
      navigationListData.list_name = list?.title;
      navigationListData.list_order = listIndex ? listIndex + 1 : undefined;
      navigationListData.resource_name = resource?.title;
      navigationListData.resource_order_in_list = resourceIndex
        ? resourceIndex + 1
        : undefined;
      navigationListData.resources_total_in_list = list?.resources.length;
    }

    setEventData('resource_accessed_from_paginator', {
      page_type: pageType,
      from_resource_id: fromResourceId,
      from_resource_name: resource?.name,
      from_url: url.href,
      ...navigationListData,
    });
  };

  useEffect(() => {
    if (!pageResources) return;

    const _navigationList = mapPageToResourceNavigationList(pageResources);

    setNavigationList(_navigationList);
    setTitle(pageResources.name);
  }, [pageResources]);

  return {
    title,
    navigationList,
    topMenu: 'curriculum',
    onNavigate,
  };
}

export default useGetNavigationList;
