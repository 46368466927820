import { ImportCSV, ImportType, Imports } from '@innovamat/ga-features';

import styled from '@emotion/styled';
import { Grounder } from '@innovamat/glimmer-components';
import { useParams, useSearchParams } from 'react-router-domv6';
import { ImportCSVWrapper } from '../components/import-csv-wrapper';

const UploadWrapper = styled.div`
  max-width: 54.5rem;
  margin: 1.5rem auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 1.5rem auto;
  }
`;

export function ImportsPage(): JSX.Element {
  const { submenu } = useParams();
  const [params] = useSearchParams();

  const type =
    (params.get('from') as ImportType) || ('newImport' as ImportType);

  return (
    <ImportCSVWrapper>
      <>
        {submenu === 'upload' ? (
          <UploadWrapper>
            <ImportCSV type={type} />
          </UploadWrapper>
        ) : (
          <Imports />
        )}
        <Grounder />
      </>
    </ImportCSVWrapper>
  );
}
