/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './Dropdown.module.scss';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import Icon from '../Icon';
import * as Icons from '../SvgIcons';
import InnerHtml from '../InnerHtml';

type Props<T> = {
  element?: JSX.Element | ((isOpen: boolean) => JSX.Element);
  children?: JSX.Element;
  options?: T[];
  render?: (option: T) => void;
  clickable?: boolean;
  disabled?: boolean;
  className?: string;
  disabledStopPropagation?: boolean;
  position?: 'bottomLeft' | 'bottomRight' | 'topRight' | 'topLeft';
  dropdownTestId?: string;
};

export type DropdownItemProps = {
  key: string;
  label: string;
  icon?: keyof typeof Icons;
  value?: string;
  href?: string;
  onSelectOption?: (value?: DropdownItemProps) => void;
  subtitle?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  id?: string;
};

function Item(props: DropdownItemProps) {
  const { key, label, onSelectOption, subtitle, icon, isActive, isDisabled } =
    props;
  const itemClasses = cx(styles.item, {
    [styles.active]: isActive,
    [styles.disabled]: isDisabled,
  });

  return (
    <div
      tabIndex={0}
      role="menuitem"
      className={itemClasses}
      key={key}
      onClick={() => onSelectOption && onSelectOption(props)}
      onKeyDown={() => onSelectOption && onSelectOption(props)}
    >
      {icon && <Icon icon={icon} />}
      <div>
        <InnerHtml text={label as string} />
        {subtitle && <InnerHtml className={styles.subtitle} text={subtitle} />}
      </div>
    </div>
  );
}

export function Dropdown<T>({
  element,
  children,
  options,
  clickable,
  disabled,
  render,
  className,
  disabledStopPropagation = false,
  position = 'bottomLeft',
  dropdownTestId,
}: Props<T>) {
  const [positionHeight, setPositionHeight] = useState();
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  const dropdownClass = cx(styles.dropdown, {
    [className as string]: !!className,
  });

  const dropClasses = cx(styles.content, {
    [styles.clickable]: !!clickable,
    [styles.display]: show,
    [styles.disabled]: disabled,
  });

  useOnClickOutside(ref, () => setShow(false));

  useEffect(() => {
    if (position === 'topRight') {
      setPositionHeight(ref.current?.clientHeight);
    }
  }, [position, ref.current?.clientHeight]);

  const dropdownStyles =
    position === 'topRight'
      ? {
          bottom: positionHeight,
          right: 'auto',
        }
      : undefined;

  return (
    <div
      tabIndex={0}
      role="button"
      onKeyPress={(e) => {
        e.stopPropagation();
        setShow((prevVal) => !prevVal);
      }}
      ref={ref}
      className={dropdownClass}
      onClick={(e) => {
        if (!disabledStopPropagation) e.stopPropagation();
        setShow((prevVal) => !prevVal);
      }}
    >
      {typeof element === 'function' ? element(show) : element}

      <div
        className={dropClasses}
        style={dropdownStyles}
        data-testid={dropdownTestId}
      >
        {options?.map((option) => render!(option))}
        {children}
      </div>
    </div>
  );
}

Dropdown.Item = Item;
