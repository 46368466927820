import type { Organization } from '@innovamat/glow-api-client';
import { QueryClient } from '@tanstack/react-query';

type Props = {
  queryClient: QueryClient;
  orgId: string;
  timezone: string;
};

type OrganizationQueryData = { organization: Organization };

function updateOrganizationTimezone({
  queryClient,
  orgId,
  timezone,
}: Props): void {
  const variables = ['Organization', { id: orgId }];

  const currentOrganization =
    queryClient.getQueryData<OrganizationQueryData>(variables)?.organization;

  if (currentOrganization) {
    queryClient.setQueryData<OrganizationQueryData>(variables, {
      organization: {
        ...currentOrganization,
        timezone: timezone,
      },
    });
  }
}

export { updateOrganizationTimezone };
