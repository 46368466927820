import { useLocation } from 'react-router-domv6';
import { ErrorPage, ERROR_TYPE } from '@innovamat/ga-features';

import {
  APP_PATHS,
  CurrentMaterials,
  CurrentMaterialsProvider,
  FamilyPayments,
  MaterialsCheckout,
  OrdersReturnsHistory,
} from '@innovamat/ga-features';

const getPageContent = (location: string | undefined): JSX.Element => {
  switch (location) {
    case APP_PATHS.CURRENT_MATERIALS_URL:
      return <CurrentMaterials />;

    case APP_PATHS.HISTORY_URL:
      return <OrdersReturnsHistory />;

    case APP_PATHS.MATERIALS_CHECKOUT_URL:
      return <MaterialsCheckout />;

    case APP_PATHS.FAMILY_PAYMENTS_URL:
      return <FamilyPayments />;

    default:
      return <ErrorPage errorType={ERROR_TYPE.NOT_FOUND} />;
  }
};

function OrdersReturnsPage(): JSX.Element {
  const { pathname } = useLocation();
  const locationPath = pathname.split('/').filter(Boolean).pop() || '';

  return (
    <CurrentMaterialsProvider>
      {getPageContent(`/${locationPath}`)}
    </CurrentMaterialsProvider>
  );
}

export { OrdersReturnsPage };
