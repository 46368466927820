import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionsDropdown,
  CreateItem,
} from '../../components/common-table-components/actions-dropdown';

type ActionsCellProps = {
  email: string;
  isInvitation: boolean;
  isNewEmail: boolean;
  onCompleteManually: (email: string) => void;
  onResendInvitation: (email: string) => void;
  onSetParentToChangePass: (email: string) => void;
  onUnlinkParent: (email: string) => void;
};

function ActionsCell({
  email,
  isInvitation,
  isNewEmail,
  onCompleteManually,
  onResendInvitation,
  onSetParentToChangePass,
  onUnlinkParent,
}: ActionsCellProps): JSX.Element {
  const { t } = useTranslation();

  const items: CreateItem[] = useMemo(() => {
    const options: CreateItem[] = [
      {
        text: t('students.inviteParentsModal.unlink'),
        icon: 'LeaveClassIcon',
        onSelectItem: () => onUnlinkParent(email),
        state: 'active',
      },
      {
        text: t('common.updatePassword'),
        icon: 'PasswordIcon',
        onSelectItem: () => onSetParentToChangePass(email),
        state: isInvitation || isNewEmail ? 'disabled' : 'active',
      },
      {
        text: t('students.inviteParentsModal.resendAccess'),
        icon: 'MailIcon',
        onSelectItem: () => onResendInvitation(email),
        state: isInvitation ? 'active' : 'disabled',
      },
      {
        text: t('students.completeManually'),
        icon: 'EditIcon',
        onSelectItem: () => onCompleteManually(email),
        state: isInvitation ? 'active' : 'disabled',
      },
    ];

    return options;
  }, []);

  return (
    <ActionsDropdown
      dropdownId="school-teacher-options-button"
      items={items}
      icon="OptionsIcon"
      isIconVisible
    />
  );
}

export { ActionsCell };
