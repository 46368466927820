import { useRef, useState } from 'react';

export function useDelayAction(timeout = 300) {
  const [isDoingAction, setIsDoingAction] = useState(false);
  const ref = useRef(false);

  const handleEndAction = () => {
    ref.current = false;
    setIsDoingAction(false);
  };

  const handleStartAction = () => {
    ref.current = true;
    setTimeout(() => {
      if (ref.current) {
        setIsDoingAction(true);
      }
    }, timeout);
  };

  return {
    isDoingAction,
    handleStartAction,
    handleEndAction,
  };
}
