import { Classroom, Roles, roles } from '@innovamat/radiance-utils';
import { createContext, useContext, useState } from 'react';
import {
  ContentResource,
  ContentResourceType,
} from '../../contents/types/page';
import { SessionDoneModal } from '../components/session-done-modal';
import { TFunction } from 'i18next';

export type SessionDoneProviderProps = {
  children: React.ReactNode;
  academicYearId: string;
  classrooms: Classroom[] | undefined;
  classroomId: string | undefined;
  courseOrder: number;
  userRoles: Roles[];
  region: string;
  locale: string;
  courseId: string;
  email: string;
  t: TFunction<'translation', undefined, 'translation'>;
  setEventData: (
    eventType: string,
    eventProperties?: any,
    addUserData?: boolean
  ) => void;
  onGoToApplet: (appletId: string) => void;
  webhooksInfo: WebHooksInfo;
};

type WebHooksInfo = {
  INTEGROMAT_REVIEW_WEBHOOK_URL: string;
  INTEGROMAT_ERROR_WEBHOOK_URL: string;
  name: string;
  lastName: string;
  organizationId: string;
  organizationName: string;
  courseName: string;
};

type SessionDoneValue = {
  academicYearId: string;
  classroomId: string | undefined;
  getIsNavigatingInCourseAndHasClassroom: (
    type: ContentResourceType,
    isCompletable: boolean
  ) => boolean;
  onSetCurrentSession: (session: ContentResource | undefined) => void;
  currentSession: ContentResource | undefined;
  region: string;
  courseOrder: number;
  locale: string;
  courseId: string;
  email: string;
  t: TFunction<'translation', undefined, 'translation'>;
  onCloseModal: () => void;
  onShowOnlyRatingModal: (resource: ContentResource) => void;
  showInModalOnlyRating: boolean;
  isModalOpen: boolean;
  setEventData: (
    eventType: string,
    eventProperties?: any,
    addUserData?: boolean
  ) => void;
  onGoToApplet: (appletId: string) => void;
  canRateSession: (resource: ContentResource) => boolean;
  webhooksInfo: WebHooksInfo;
};

const SessionDoneContext = createContext<SessionDoneValue | undefined>(
  undefined
);

function SessionDoneProvider({
  children,
  academicYearId,
  classrooms,
  classroomId,
  userRoles,
  courseOrder,
  region,
  locale,
  courseId,
  email,
  setEventData,
  t,
  onGoToApplet,
  webhooksInfo,
}: SessionDoneProviderProps): JSX.Element {
  const [currentSession, setCurrentSession] = useState<
    ContentResource | undefined
  >(undefined);

  const [showInModalOnlyRating, setShowInModalOnlyRating] = useState(false);

  const getIsNavigatingInCourseAndHasClassroom = (
    type: string,
    isCompletable: boolean
  ): boolean => {
    if (type !== 'session') return false;
    if (!isCompletable) return false;
    if (!roles.hasTeacherRole(userRoles)) return false;
    if (classroomId || !classrooms) return false;

    return classrooms.some(
      (classroom) => classroom.courseOrder === courseOrder
    );
  };

  const onShowOnlyRatingModal = (resource: ContentResource) => {
    setShowInModalOnlyRating(true);
    onSetCurrentSession(resource);
  };

  const onSetCurrentSession = (session: ContentResource | undefined): void => {
    setCurrentSession(session);
  };

  const onCloseModal = () => {
    setCurrentSession(undefined);
    setShowInModalOnlyRating(false);
  };

  const canRateSession = (resource: ContentResource): boolean => {
    if (!resource) return false;
    if (roles.hasStudentRole(userRoles) && !resource.is_done) return false;
    return true;
  };

  const value: SessionDoneValue = {
    academicYearId,
    classroomId,
    getIsNavigatingInCourseAndHasClassroom,
    onSetCurrentSession,
    currentSession,
    region,
    courseOrder,
    locale,
    courseId,
    email,
    t,
    onCloseModal,
    onShowOnlyRatingModal,
    showInModalOnlyRating,
    isModalOpen: Boolean(currentSession),
    setEventData,
    onGoToApplet,
    canRateSession,
    webhooksInfo,
  };

  return (
    <SessionDoneContext.Provider value={value}>
      {children}
      {value.isModalOpen && <SessionDoneModal />}
    </SessionDoneContext.Provider>
  );
}

function useSessionDone(): SessionDoneValue {
  const context = useContext(SessionDoneContext);
  if (context === undefined) {
    throw new Error('useSessionDone must be used within a SessionDoneProvider');
  }
  return context;
}

export { SessionDoneProvider, useSessionDone };
