import { Dropdown } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useAddActionDropdown } from '../../../hooks/use-add-action-dropdown';
import { useOrganization } from '../../../hooks/use-organization';

type Props = {
  isDeletedStudentsPage: boolean;
  isDisabled?: boolean;
};

function AddStudentDropdown({
  isDeletedStudentsPage,
  isDisabled,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { isOrgAnonymized } = useOrganization();

  const { getOptions } = useAddActionDropdown({
    type: 'schoolStudent',
  });

  if (!isDeletedStudentsPage && !isOrgAnonymized)
    return (
      <Dropdown openOnHover={true} disabled={isDisabled}>
        <Dropdown.Toggle label={t('students.button.addStudent')} />
        <Dropdown.Content position={'bottomRight'}>
          {getOptions().map((option) => (
            <Dropdown.Item {...option} />
          ))}
        </Dropdown.Content>
      </Dropdown>
    );

  return <></>;
}

export { AddStudentDropdown };
