import {
  WorkBook,
  WorkSheet,
  utils,
  writeFile,
  writeXLSX,
} from 'xlsx-js-style';
import { Row } from '@innovamat/glimmer-components';
import { transformStudentWeeklyData } from './preprocess-weekly-excel';
import {
  transformStudentGeneralData,
  transformStudentGeneralWeeklyData,
} from './preprocess-general-excel';
import { TFunction } from 'i18next';
import { Stage, xlsxToBlob } from '@innovamat/radiance-utils';
import { isNullOrUndefined } from './common';
import { TimeSchedule } from '../views/weekly-results/weekly-results';
import { ReportData, ReportType } from './render-tables';
import { Course } from '@innovamat/glow-api-client';

interface FileData {
  fileName: string;
  studentData: Row[];
  averageData: Row;
  adaptedReportsData?: ReportData[];
  t: TFunction<'translation', undefined, 'translation'>;
  educationalStage: Stage;
  practicePeriod?: TimeSchedule;
  courses?: Course[]; // TODO: Change it to mandatory when course in general progress
}

const styleCells = (ws: WorkSheet) => {
  Object.keys(ws).forEach((cell) => {
    if (!isNullOrUndefined(ws[cell].v)) {
      ws[cell].s = {
        ...ws[cell].s,
        alignment: { horizontal: 'center' },
      };
    }

    if (ws[cell].t === 's' && ws[cell].v?.includes('HEADER')) {
      ws[cell].s = {
        ...ws[cell].s,
        fill: { fgColor: { rgb: 'F7F7F7' } },
        font: { bold: true },
        alignment: { horizontal: 'center', wrapText: true },
      };
      ws[cell].v = ws[cell].v.replace('HEADER', '');
    }
  });
};

export const exportReportToXlsx = (
  type: ReportType,
  fileData: FileData,
  download = true
): void | Blob => {
  const {
    fileName,
    t,
    studentData,
    averageData,
    adaptedReportsData,
    educationalStage,
    practicePeriod = 'all',
  } = fileData;

  let transformedData;
  const extraTabData = [];
  switch (type) {
    case 'weekly': {
      transformedData = transformStudentWeeklyData(
        studentData,
        averageData,
        t,
        educationalStage,
        practicePeriod
      );
      adaptedReportsData?.forEach((adaptedReport, index) => {
        const courseName =
          fileData.courses?.find(
            (course) => course.order === adaptedReport.courseOrder
          )?.name || '';
        const tabName =
          courseName ||
          t('reports.downloadExcel.adaptedStudents.fileName', {
            index: index + 1,
          });
        extraTabData.push({
          name: tabName,
          data: transformStudentWeeklyData(
            adaptedReport.rows,
            adaptedReport.averageRow,
            t,
            educationalStage,
            practicePeriod
          ),
        });
      });
      break;
    }
    case 'general': {
      transformedData = transformStudentGeneralData(
        studentData,
        averageData,
        t
      );

      const allWeekScoresNull = studentData.every(
        (student) => student.weekScores === null
      );

      if (!allWeekScoresNull) {
        extraTabData.push({
          name: t('reports.general.column.weeklyProgress'),
          data: transformStudentGeneralWeeklyData(studentData, t),
        });
      }
      adaptedReportsData?.forEach((adaptedReport, index) => {
        const courseName = fileData.courses?.find(
          (course) => course.order === adaptedReport.courseOrder
        )?.name;
        const tabName =
          courseName ||
          t('reports.downloadExcel.adaptedStudents.fileName', {
            index: index + 1,
          });
        extraTabData.push({
          name: tabName,
          data: transformStudentGeneralData(
            adaptedReport.rows,
            adaptedReport.averageRow,
            t
          ),
        });
        if (!allWeekScoresNull) {
          extraTabData.push({
            name: `${t('reports.general.column.weeklyProgress')} - ${
              courseName || index + 1
            }`,
            data: transformStudentGeneralWeeklyData(adaptedReport.rows, t),
          });
        }
      });
      break;
    }
  }
  const ws = generateWorksheet(transformedData);

  const extraTabs = extraTabData.map(({ name, data }) => ({
    name,
    ws: generateWorksheet(data),
  }));

  styleCells(ws);
  extraTabs.forEach((extra) => styleCells(extra.ws));

  const wb = generateXlsx({ ws, extraTabs, t });

  if (download) downloadXlsx(wb, fileName);
  else {
    const xlsx = writeXLSX(wb, { type: 'binary' });
    const blob = xlsxToBlob(xlsx);
    return blob;
  }
};

const generateWorksheet = (transformedData: any[]): WorkSheet => {
  const ws = utils.json_to_sheet(transformedData);
  const range = utils.decode_range(ws['!ref']!); // Obtain cell range
  const numCols = range.e.c - range.s.c + 1;
  const numRows = range.e.r - range.s.r + 1;
  const defaultWidth = { wpx: 80 };
  const defaultHeight = { hpx: 20 };
  ws['!cols'] = Array(numCols).fill(defaultWidth);
  ws['!rows'] = [{ hpx: 60 }, ...Array(numRows).fill(defaultHeight)];
  return ws;
};

const generateXlsx = ({
  ws,
  t,
  extraTabs,
}: {
  ws: WorkSheet;
  multipleWs?: WorkSheet[];
  t: TFunction<'translation', undefined, 'translation'>;
  extraTabs?: { name: string; ws: WorkSheet }[];
}): WorkBook => {
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, t('common.classroom'));
  extraTabs?.forEach(({ name, ws }) => {
    utils.book_append_sheet(wb, ws, name);
  });
  return wb;
};

const downloadXlsx = (wb: WorkBook, fileName: string): void => {
  writeFile(wb, `${fileName}.xlsx`);
};
