import { useEffect } from 'react';
import { useStarsFeedbackTrainingZoneQuery } from '@innovamat/glow-api-client';

type Props = {
  userId: string | undefined;
  activityId: string | undefined;
  onEmptyStars: () => void;
};

export default function useGetStarsFeedback({
  userId,
  activityId,
  onEmptyStars,
}: Props) {
  const { data, isSuccess } = useStarsFeedbackTrainingZoneQuery(
    {
      userId: userId!,
      activityId: activityId!,
    },
    { enabled: !!userId && !!activityId }
  );

  useEffect(() => {
    if (isSuccess && !data) {
      onEmptyStars();
    }
  }, [onEmptyStars, data, isSuccess]);

  return data?.starsFeedbackTrainingZone;
}
