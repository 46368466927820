import BlockContent from '../../BlockContent';
import BlockWithIcon from '../../BlockWithIcon';
import { MomentContentType } from '../../../types/momentContent';
import { getMomentColor } from '../../../utils/colorsSelector';
import { MOMENT_CONTENT_TYPES_ICONS } from '../../../types/moments';
import { InnerHtmlParser } from '../../InnerHtmlParser';
import MomentBlockTable from './MomentBlock.Table';
import { ItemInList, SubMoment } from '../helpers';
import { AssetElement } from '../../../types/prepr';
import { MOMENT_CONTENT_TYPES_TEXT } from '../../../types/moments';
import { ImageModal } from '../../ImagesModal/ImagesModal';
import {
  Container,
  List,
  StyledPart,
  StyledText,
  Wrapper,
} from './MomentBlock.Styles';
import MomentBlockAsset from './MomentBlock.Asset';
import MomentBlockInList from './MomentBlock.InList';
import { useDigitalGuides } from '../../../context';

type Props = {
  momentContent: MomentContentType;
  momentType: string;
  subMoment?: SubMoment[];
  onClickImage: (image: ImageModal | null) => void;
};

export default function MomentBlockItem({
  momentContent,
  momentType,
  subMoment,
  onClickImage,
}: Props) {
  const { t } = useDigitalGuides();

  const part = momentContent.items.part_name;
  const type = momentContent.items.type?.body;
  const isOnlyContentColoured = momentContent.items.coloured_content?.value;

  const getColor = () => {
    const coloured = momentContent.items.moment_coloured?.value;
    const greyColoured = momentContent.items.grey_coloured?.value;

    if (greyColoured || coloured || isOnlyContentColoured) {
      const colorValue = coloured || isOnlyContentColoured ? momentType : '0';
      return getMomentColor(colorValue);
    }
    return undefined;
  };

  const renderSubMoment = () => {
    return (
      <List>
        {subMoment?.map((sub, index) => {
          if (!sub) return null;

          // render a text
          if ('body' in sub && sub.label === 'Text') {
            return (
              <StyledText className="blockContent" key={`block-${index}`}>
                <InnerHtmlParser text={sub} />
              </StyledText>
            );
          }

          // render  table created in prepr with custom config
          if ('tableContent' in sub) {
            return (
              <MomentBlockTable
                color={colors?.tableBg || colors?.normal}
                key={`table-${index}`}
                momentTable={sub}
                onClickImage={onClickImage}
              />
            );
          }

          // render a simple block of images
          if ('label' in sub && sub.label === 'Asset') {
            const images = sub as AssetElement;
            return (
              <Container isBigImage key={`block-${index}`}>
                {images.items.map((image, subIndex) => {
                  return (
                    <MomentBlockAsset
                      id={`${index}-${subIndex}`}
                      asset={image}
                      onClickImage={onClickImage}
                      color={colors?.dark}
                    />
                  );
                })}
              </Container>
            );
          }

          if ('items' in sub) {
            const { items } = sub;

            if ('in_a_list' in items) {
              const next = subMoment[index + 1];
              if (!next) {
                return (
                  <MomentBlockInList
                    color={colors?.dark!}
                    index={index}
                    {...items}
                  />
                );
              }
              const { items: nextItems } = next as ItemInList;
              if ('content_image' in nextItems) {
                const { content_image } = nextItems;
                return (
                  <MomentBlockInList
                    color={colors?.dark!}
                    index={index}
                    floatingContent={
                      <Container
                        noMargin
                        isBigImage={false}
                        key={`image-${index}`}
                        style={{ float: 'right' }}
                      >
                        <MomentBlockAsset
                          id={`${index}-0`}
                          asset={content_image!.items[0]}
                          onClickImage={onClickImage}
                          color={colors?.dark}
                        />
                      </Container>
                    }
                    {...items}
                  />
                );
              }

              return (
                <MomentBlockInList
                  color={colors?.dark!}
                  index={index}
                  {...items}
                />
              );
            }

            if ('content_big_image' in items) {
              const { content_big_image } = items;
              return (
                <>
                  {content_big_image && (
                    <Container isBigImage key={`bigImage-${index}`}>
                      <MomentBlockAsset
                        id={`${index}-1`}
                        asset={content_big_image.items[0]}
                        onClickImage={onClickImage}
                        color={colors?.dark}
                      />
                    </Container>
                  )}
                </>
              );
            }
            return null;
          }
          return null;
        })}
      </List>
    );
  };

  const renderContent = () => {
    const content = momentContent.items.content;

    const isBigImage = 'big_image' in momentContent.items;
    const assets = momentContent.items.big_image || momentContent.items.image;

    const key = MOMENT_CONTENT_TYPES_TEXT[type!];

    const asset = assets?.items[0];

    return (
      <BlockContent title={t(`digitalguides.se.${key.toLowerCase()}`)}>
        <Container isBigImage={isBigImage}>
          {content && (
            <StyledText className="blockContent">
              <InnerHtmlParser text={content} />
            </StyledText>
          )}
          {asset && (
            <MomentBlockAsset
              id={'asset'}
              asset={asset}
              onClickImage={onClickImage}
              isBigAsset={isBigImage}
              width={272}
              color={colors?.dark}
            />
          )}
        </Container>
        {renderSubMoment()}
      </BlockContent>
    );
  };

  const colors = getColor();

  if (part) {
    const partColor = getMomentColor(momentType);
    return (
      <StyledPart bgColor={partColor.normal} textColor={partColor.light}>
        <InnerHtmlParser text={part} />
      </StyledPart>
    );
  }

  if (!type) return null;

  return (
    <BlockWithIcon
      icon={MOMENT_CONTENT_TYPES_ICONS[type]}
      color={colors?.normal}
    >
      {colors ? (
        <Wrapper
          titleColor={colors.normal}
          color={colors.dark}
          bgColor={colors.light}
          noPadding={!!isOnlyContentColoured}
        >
          {renderContent()}
        </Wrapper>
      ) : (
        renderContent()
      )}
    </BlockWithIcon>
  );
}
