import { AllMaterials, MaterialListType, Printable } from '../types/Materials';
import { Stage } from '../types/Stage';
import { convertToParagraphList } from '../utils/htmlParser/convert-to-paragraph-list/convert-to-paragraph-list';
import { getMaterialsByStage } from '../utils/materialsList';

export function mapMaterials(
  materialsResponse: any,
  printables: Printable[],
  stage: Stage
): AllMaterials {
  const classroomMaterials: string[] = [];
  const materialsList: MaterialListType[] = [];
  const videos: string[] = [];
  const applets: string[] = [];
  const projectables: string[] = [];

  materialsResponse?.forEach((item: any) => {
    if (item.classroom_material) {
      classroomMaterials.push(item.classroom_material);
    }

    if (item.material_list) {
      const material = getMaterialsByStage(stage)[item.material_list];

      materialsList.push({
        id: item.material_list,
        value: material.key,
        isOptional: item.optional || false,
        additionalText: item.additional_text || '',
        image: material.image,
      });
    }

    if (item.video) {
      videos.push(item.video);
    }

    if (item.applet) {
      applets.push(item.applet);
    }

    if (item.projectable) {
      projectables.push(item.projectable);
    }
  });

  return {
    classroomMaterials,
    materialsList,
    videos: convertToParagraphList(videos),
    applets: convertToParagraphList(applets),
    projectables: convertToParagraphList(projectables),
    printables,
  };
}
