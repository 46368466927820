import { useState, useEffect } from 'react';

import { CounterBadge } from './counter-badge';
import { LanguageFlag } from '../language-flag';
import { MaterialCardControls } from './material-card-controls';
import { Tooltip } from '../Tooltip';
import type { FlagType } from '../language-flag';

import { ACTION_TYPES } from './material-card.types';
import type { ActionType, Material } from './material-card.types';

import {
  CardBody,
  CardContent,
  CardWrapper,
  ColumnLeft,
  ColumnRight,
  Counter,
  Image,
  InfoContainer,
  Language,
  LanguageWrapper,
  Name,
  Title,
  Units,
} from './material-card.styles';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';

type MaterialCardProps = {
  isEditMode: boolean;
  material: Material;
  maxValueTooltipMessage: string;
  minValue?: number;
  minValueTooltipMessage: string;
  units: string;
  onDecrement: () => void;
  onIncrement: () => void;
};

const MaterialCard = ({
  isEditMode,
  material,
  maxValueTooltipMessage,
  minValue = 1,
  minValueTooltipMessage,
  units,
  onDecrement,
  onIncrement,
}: MaterialCardProps): EmotionJSX.Element => {
  const [badgeValue, setBadgeValue] = useState(
    () => material.controlsCounterValue - material.quantity
  );
  const [isBadgeVisible, setIsBadgeVisible] = useState(false);
  const [isDecrementDisabled, setIsDecrementDisabled] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState('');

  const getActionType = (): ActionType | '' => {
    if (material.quantity === material.controlsCounterValue) {
      return '';
    }

    if (material.quantity < material.controlsCounterValue) {
      return ACTION_TYPES.INCREMENT;
    }

    return ACTION_TYPES.DECREMENT;
  };

  useEffect(() => {
    setIsBadgeVisible(material.controlsCounterValue - material.quantity !== 0);

    const isMinValueReached = material.controlsCounterValue === minValue;
    const isMaxValueReached =
      material.quantity - material.controlsCounterValue >=
      material.maxReturnableItems;

    setIsDecrementDisabled(isMinValueReached || isMaxValueReached);

    if (isMinValueReached) {
      setTooltipMessage(minValueTooltipMessage);
    } else if (isMaxValueReached) {
      setTooltipMessage(maxValueTooltipMessage);
    } else {
      setTooltipMessage('');
    }

    setBadgeValue(material.controlsCounterValue - material.quantity);
  }, [material, minValue]);

  return (
    <CardWrapper isOpened={isEditMode}>
      <MaterialCardControls
        counterValue={material.controlsCounterValue}
        isDecrementDisabled={isDecrementDisabled}
        isOpened={isEditMode}
        onDecrement={onDecrement}
        onIncrement={onIncrement}
        tooltipMessage={tooltipMessage}
      />
      <CardContent
        height="11.563rem"
        width="100%"
        elevation="elevation 2"
        borderRadius="sm"
      >
        <CardBody>
          {isBadgeVisible && (
            <CounterBadge
              variant={
                badgeValue > 0 ? ACTION_TYPES.INCREMENT : ACTION_TYPES.DECREMENT
              }
              content={Math.abs(badgeValue)}
            />
          )}
          <Image src={material.image} />
          <Title>{material.course}</Title>
          <InfoContainer>
            <ColumnLeft>
              <Tooltip
                content={material.name}
                hasChildrenWithEllipsis
                offset={[0, 5]}
                placement="top"
                popperOptions={{ strategy: 'fixed' }}
              >
                <Name>{material.name}</Name>
              </Tooltip>

              <LanguageWrapper>
                <LanguageFlag flagName={material.flagName as FlagType} />
                <Language>{material.language}</Language>
              </LanguageWrapper>
            </ColumnLeft>
            <ColumnRight>
              <Counter variant={getActionType()}>{material.quantity}</Counter>
              <Units>{units}</Units>
            </ColumnRight>
          </InfoContainer>
        </CardBody>
      </CardContent>
    </CardWrapper>
  );
};

export { MaterialCard };
