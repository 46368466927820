const waitForBoxToOpen = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export async function onClickBox<T>(
  index: number,
  item: T,
  getIsOpen: (index: number) => boolean,
  onSelectBox: (item: T, index: number) => void,
  onScrollBox: (index: number) => void
) {
  const ref = document.getElementById(`accordion-${index}`);
  const isOpen = getIsOpen(index);

  if (ref) {
    onSelectBox(item, index);
    if (!isOpen) {
      onScrollBox(index);
      await waitForBoxToOpen(100);
      ref.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
