import ContentLoader from 'react-content-loader';
import { useGlimmerTheme } from '../theme';
import { hexToOpacity } from '@innovamat/radiance-utils';

export const Skeleton = ({
  width,
  height,
  radius = '4',
  className,
}: {
  width: string;
  height: string;
  radius?: string;
  className?: string;
}): JSX.Element => {
  const theme = useGlimmerTheme();

  const foregroundColor = hexToOpacity(
    theme.tokens.color.specific.element.skeleton.value,
    0.6
  );

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={height}
      backgroundColor={theme.tokens.color.specific.element.skeleton.value}
      foregroundColor={foregroundColor}
      className={className}
    >
      <rect x="0" y="0" width="100%" height={height} rx={radius} />
    </ContentLoader>
  );
};
