import { Session } from '@innovamat/resource-viewer';
import { TFunction } from 'i18next';
import 'tippy.js/dist/tippy.css';
import { ImageModal } from './components/ImagesModal/ImagesModal';
import { PlayerBlock } from './components/PlayerBlock';
import EarlyYearDigitalGuideWrapper from './templates/EarlyYear/EarlyYear';
import EarlyYearClassroomLifeWrapper from './templates/EarlyYear/EarlyYearClassroomLife';
import { ChallengesResponse } from './templates/EarlyYear/types/challenges';
import SecondaryDigitalGuideWrapper from './templates/Secondary';
import { DigitalGuide } from './types/digitalGuide';
import { ScrollPosition } from './types/prepr';

type Props = {
  resource: Session;
  guide: DigitalGuide;
  images: ImageModal[];
  canMarkAsDone?: boolean;
  onMarkChallengeAsDone?: (challengeId: string, value: boolean) => void;
  scrollPosition: ScrollPosition;
  challenges?: ChallengesResponse | undefined;
  onEvent: (key: string, data: any) => void;
  regionParameters?: any;
  isStudent: boolean;
  t: TFunction<'translation', undefined, 'translation'>;
};

export function DigitalGuideWrapper({
  guide,
  resource,
  images,
  canMarkAsDone,
  onMarkChallengeAsDone,
  scrollPosition,
  challenges,
  onEvent,
  regionParameters,
  isStudent,
  t,
}: Props) {
  switch (guide.preprType) {
    case 'earlyYear':
      return (
        <EarlyYearDigitalGuideWrapper
          challenges={challenges}
          guide={guide}
          images={images}
          canMarkAsDone={canMarkAsDone}
          resource={resource}
          onMarkChallengeAsDone={onMarkChallengeAsDone}
          scrollPosition={scrollPosition}
          onEvent={onEvent}
          t={t}
        />
      );
    case 'earlyYearClassroomLife':
      return (
        <EarlyYearClassroomLifeWrapper
          challenges={challenges}
          guide={guide}
          images={images}
          canMarkAsDone={canMarkAsDone}
          resource={resource}
          onMarkChallengeAsDone={onMarkChallengeAsDone}
          scrollPosition={scrollPosition}
          onEvent={onEvent}
          t={t}
        />
      );
    case 'secondary':
      if (isStudent) {
        return <PlayerBlock />;
      }
      return (
        <SecondaryDigitalGuideWrapper
          regionParameters={regionParameters}
          guide={guide}
          images={images}
          resource={resource}
          scrollPosition={scrollPosition}
          onEvent={onEvent}
          t={t}
        />
      );
    default:
      return null;
  }
}
