import { Grid, Grounder } from '@innovamat/glimmer-components';

import { useUser } from '../../../../user-management';

import { Shortcuts, ShortcutsSkeleton } from '../../../../contents';
import { MyClasses } from '../../../components/my-classes';
import { MySchool } from '../../../components/my-school';

import { LandingPageAdvisorSkeleton } from './landing-page-advisor-skeleton';
import { SearchOrganization } from './search-organization';

import { useState } from 'react';
import { ColWithMargin, Container } from '../styles';

function LandingPageAdvisor(): JSX.Element {
  const { user } = useUser();

  const [isChangingOrganization, setIsChangingOrganization] = useState(false);

  const showGeneralSkeleton = !user || isChangingOrganization;

  return (
    <>
      <Container>
        <Grid.Row justifyContent="center">
          <ColWithMargin md={10} lg={8}>
            <SearchOrganization
              onChangeOrganization={() => setIsChangingOrganization(true)}
            />
          </ColWithMargin>
        </Grid.Row>
      </Container>

      {showGeneralSkeleton ? (
        <LandingPageAdvisorSkeleton />
      ) : (
        <Container>
          <Grid.Row justifyContent="center">
            <ColWithMargin md={10} lg={8}>
              <MySchool organizationId={user?.organization?.id!} />
            </ColWithMargin>

            <Grid.Col md={10} lg={8}>
              <MyClasses />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row justifyContent="center">
            <Grid.Col md={10} lg={8}>
              {user ? (
                <Shortcuts
                  organizationId={user.organizationId}
                  roles={user.roles}
                />
              ) : (
                <ShortcutsSkeleton />
              )}
            </Grid.Col>
          </Grid.Row>
          <Grounder />
        </Container>
      )}
    </>
  );
}

export { LandingPageAdvisor };
