import { ButtonWrapper } from './button-circle.styles';
import { Icon } from '../../Icon';
import { StateLayer } from '../../../utils/common.styled';
import { Icons } from '@innovamat/glimmer-icons';

type ButtonCircleProps = {
  icon: keyof typeof Icons;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonCircle = ({ icon, className, ...rest }: ButtonCircleProps) => {
  return (
    <ButtonWrapper
      className={`${className} ripple`}
      icon={icon}
      type="button"
      {...rest}
    >
      <StateLayer className="button-stateLayer" />
      <Icon icon={icon} size="M" />
    </ButtonWrapper>
  );
};

export { ButtonCircle };
export type { ButtonCircleProps };
