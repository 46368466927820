import { useRegionParameters } from './use-region-parameters';
import { usePlatformUrl } from './use-platform-url';
import { useUser } from '../../user-management';
import { useCurrentNavigationValues } from '../../navigation/hooks/use-current-navigation-values';
import { useEnvVariables } from '../../env-variables-manager';
import { Stage } from '@innovamat/radiance-utils';
import { useSolverUrl } from './use-solver-url';

export function useAppletInfo() {
  const { tokenInfo, user } = useUser();
  const { regionParameters } = useRegionParameters();
  const { platformUrl } = usePlatformUrl();
  const { educationalLevel } = useCurrentNavigationValues();
  const { solverUrl } = useSolverUrl();
  const {
    USERS_CLIENT_ID,
    UNITY_BUILD,
    STANDALONE_BUILD_DOMAIN,
    WEBCONTENTS_URL,
  } = useEnvVariables();

  return {
    regionParameters,
    unityBuild: UNITY_BUILD,
    unityStandaloneBuildDomain: STANDALONE_BUILD_DOMAIN,
    educationalLevel: educationalLevel || Stage.SECUNDARIA,
    refreshToken: tokenInfo?.refresh_token!,
    accessToken: tokenInfo?.access_token!,
    clientID: USERS_CLIENT_ID,
    idToken: tokenInfo?.id_token!,
    platformUrl,
    region: user?.region,
    solverUrl,
    webContentsUrl: WEBCONTENTS_URL,
  };
}
