import { TFunction } from 'i18next';
import { Column, Row } from '@innovamat/glimmer-components';
import {
  renderCompletedSession,
  renderCompletedSessionsSublabels,
  renderCurrentAndInitialNumbers,
  renderEllipsableText,
} from './render-catch-up-rows';
import { InterventionReportWeeks } from '@innovamat/glow-api-client';

type ColumType = 'NAME' | 'SURNAME' | 'ARITHFLUENCY';

type Props = {
  t: TFunction<'translation', undefined, 'translation'>;
  interventionWeeks: InterventionReportWeeks;
};

export const getCatchUpColumns = ({
  t,
  interventionWeeks,
}: Props): Column<Row>[] => {
  const columns: Column<Row>[] = [
    {
      id: 'firstName',
      label: t('reports.name.title.column'),
      width: 100,
      sortable: true,
      sticky: true,
      render: (value, row) => {
        return renderEllipsableText(value, getDataTestId(row, 'NAME'));
      },
      sortIgnoreCase: true,
      align: 'left',
      dataTestId: 'NAME',
    },
    {
      id: 'lastName',
      label: t('reports.surname.title.column'),
      width: 100,
      sortable: true,
      sticky: true,
      sortIgnoreCase: true,
      render: (value, row) => {
        return renderEllipsableText(value, getDataTestId(row, 'SURNAME'));
      },
      align: 'left',
      dataTestId: 'SURNAME',
    },
    {
      id: 'separator-1',
      width: 9,
      sticky: true,
      separator: true,
      isDarkSeparator: true,
    },
    {
      id: 'arithmeticFluency',
      label: t('reports.catch-up.arithmeticFluency.title.column'),
      width: 110,
      sticky: true,
      render: (_, row) => {
        const { currentArithmeticFluency, initialArithmeticFluency } = row;
        return renderCurrentAndInitialNumbers(
          currentArithmeticFluency,
          initialArithmeticFluency,
          getDataTestId(row, 'ARITHFLUENCY')
        );
      },
      subLabel: t('reports.catch-up.arithmeticFluency.subtitle.column'),
      infoColumn: t('reports.catch-up.information.arithmeticFluency.tooltip'),
      align: 'center',
      dataTestId: 'ARITHFLUENCY',
    },
    {
      id: 'separator-2',
      width: 9,
      sticky: true,
      separator: true,
    },
    {
      id: 'completedSessions',
      label: t('reports.catch-up.completedSessions.title.column'),
      width: '100%',
      render: (_, { weeks, lastWeekInIntervention, uuid }) =>
        renderCompletedSession(
          weeks,
          interventionWeeks.currentWeekNumber,
          lastWeekInIntervention,
          uuid
        ),
      subLabel: () => {
        return renderCompletedSessionsSublabels(
          interventionWeeks,
          t,
          interventionWeeks.currentWeekNumber
        );
      },
    },
  ];

  const getDataTestId = (row: Row, type: ColumType): string => {
    const interventionType =
      row.lastWeekInIntervention != null ? 'INTRE_DEATABLE' : 'INTRE_TABLE';
    return `${interventionType}_${type}_S${row.uuid}`;
  };

  return columns;
};
