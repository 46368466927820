import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-domv6';

type ResourceData = {
  name: string;
  uuid: string;
  type: string;
};

type NavigationEventData = {
  resourceId: string | undefined;
  classroomId: string | undefined;
  getResourceData: () => ResourceData | undefined;
};

function useNavigationEventData(): NavigationEventData {
  const queryClient = useQueryClient();

  const { id, type, classroomId } = useParams();

  const getResourceData = (): ResourceData | undefined => {
    if (!id) return undefined;

    const queryData = queryClient.getQueriesData<{ resource: ResourceData }>({
      queryKey: ['Resource', { id, classroomId: classroomId || 'self', type }],
    });
    return queryData?.[0][1]?.resource;
  };

  return {
    resourceId: id,
    classroomId,
    getResourceData,
  };
}

export { useNavigationEventData };
