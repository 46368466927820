import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useSearchParams } from 'react-router-domv6';

import {
  ClassroomQuery,
  Course,
  useClassroomQuery,
  useCourses,
} from '@innovamat/glow-api-client';
import { stages } from '@innovamat/radiance-utils';

import { useUser } from '../../user-management';
import { ProcessedSectionItem } from '../types';
import { NAV_TYPE, NavType } from '../utils';
import { useSitemapTree } from './use-sitemap-tree';

const PERMISSIONS = {
  fullAccess: 'full',
  demoAccess: 'demo',
} as const;

type PermissionType = (typeof PERMISSIONS)[keyof typeof PERMISSIONS];

type SitemapItem = Exclude<ProcessedSectionItem, string>;

type NavigationValues = {
  getFromPage: () => string;
  getRouteByPageId: (pageId: string) => string;
  getSitemapByRoute: (route: string) => SitemapItem;
  getTypeOfPagePermission: (item: SitemapItem) => PermissionType;
  currentPage: SitemapItem;
  navType: NavType;
  currentClassroom: ClassroomQuery['classroom'] | undefined;
  currentCourse: Course | undefined;
  courseOrder: number | null;
  courseId: string | null;
  educationalLevel: string | null;
  currentRoute: string | null;
  isClassroomFetching: boolean;
};

function useCurrentNavigationValues(): NavigationValues {
  const {
    classroomId,
    courseId: paramsCourseId,
    organizationId,
    menu,
    submenu,
  } = useParams();
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const { pathname, state } = useLocation();
  const { user, isRole } = useUser();

  const { getCourseById } = useCourses({
    regionCode: user?.region!,
    organizationId: user?.organizationId!,
  });

  const { data: classroomQuery, isFetching: isClassroomFetching } =
    useClassroomQuery(
      {
        id: classroomId!,
        orgId: user?.organizationId!,
      },
      {
        enabled: !!classroomId && !!user?.organizationId && !isRole.Student,
      }
    );

  const currentCourse = getCourseById(paramsCourseId!);

  const courseOrder =
    classroomQuery?.classroom?.courseOrder || currentCourse?.order || null;

  const courseId =
    classroomQuery?.classroom?.courseId || currentCourse?.id || null;

  const navType = useMemo(() => {
    if (pathname.includes(NAV_TYPE.Student)) {
      return NAV_TYPE.Student;
    }

    if (pathname.includes(NAV_TYPE.Manipulative)) {
      return NAV_TYPE.Manipulative;
    }

    if (classroomId && organizationId) {
      return NAV_TYPE.AdminClassroom;
    }

    if (classroomId) {
      return NAV_TYPE.Classroom;
    }

    if (organizationId) {
      return NAV_TYPE.Admin;
    }

    if (pathname.includes(NAV_TYPE.Learning)) {
      return NAV_TYPE.Learning;
    }

    if (courseId) {
      return NAV_TYPE.Course;
    }

    return null;
  }, [pathname, classroomId, courseId, organizationId]);

  const currentRoute = useMemo(() => {
    return submenu ? `/${menu}/${submenu}` : `/${menu}`;
  }, [submenu, menu]);

  const { getRouteByPageId, getSitemapByRoute } = useSitemapTree({
    region: user?.region!,
    courseOrder:
      classroomQuery?.classroom?.courseOrder! || currentCourse?.order!,
    level: 'MANAGER',
    locale: user?.locale!,
    navType,
  });

  const getFromPage = (): string => {
    if (state?.from) {
      return state.from.slice(1) as string;
    }

    if (params?.get('fromPage')) {
      const pageId = params.get('fromPage') as string;

      if (getRouteByPageId(pageId).length > 0) {
        return classroomId
          ? `${NAV_TYPE.Classroom}/${classroomId}${getRouteByPageId(
              pageId
            )}/page`
          : `${NAV_TYPE.Course}/${courseId}${getRouteByPageId(pageId)}/page`;
      }
    }

    if (navType === NAV_TYPE.Student)
      return `${NAV_TYPE.Student}/${classroomId}/page`;
    if (navType === NAV_TYPE.Classroom)
      return `${NAV_TYPE.Classroom}/${classroomId}/page`;
    if (navType === NAV_TYPE.Course)
      return `${NAV_TYPE.Course}/${courseId}/page`;
    return `${NAV_TYPE.Classroom}/${organizationId}/page`;
  };

  const getTypeOfPagePermission = (item: SitemapItem): PermissionType => {
    return item.fullPageId ? PERMISSIONS.fullAccess : PERMISSIONS.demoAccess;
  };

  const currentPage = useMemo(() => {
    const page = getSitemapByRoute(
      submenu ? `/${menu}/${submenu}` : `/${menu}`
    );
    return page;
  }, [getSitemapByRoute, menu, submenu]);

  useEffect(() => {
    switch (navType) {
      case NAV_TYPE.Course:
        document.title = t('title.course_content_navigation');
        break;
      case NAV_TYPE.Learning:
        document.title = t('title.training');
        break;
      case NAV_TYPE.Manipulative:
        document.title = t('title.manipulative');
        break;
      default:
        document.title = t('title.classroom_manager');
    }
  }, [navType, t]);

  return {
    getFromPage,
    getRouteByPageId,
    getSitemapByRoute,
    getTypeOfPagePermission,
    navType,
    currentClassroom: classroomQuery?.classroom,
    currentCourse,
    courseOrder,
    courseId,
    educationalLevel: courseOrder
      ? stages.getStageFromOrder(courseOrder)
      : null,
    currentPage,
    currentRoute,
    isClassroomFetching,
  };
}

export { PERMISSIONS, useCurrentNavigationValues };
export type { SitemapItem };
