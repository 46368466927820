import { useContext } from 'react';
import { GoogleClassroomSyncStateContext } from '../providers';

export function useGoogleClassroomSync() {
  const context = useContext(GoogleClassroomSyncStateContext);
  if (context === undefined) {
    throw new Error(
      'useGoogleClassroomSync must be used within a GoogleClassroomSyncProvider'
    );
  }
  return context;
}
