// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CwiCaFs7uoYmnwUD{display:flex;align-items:center;gap:16px}.CwiCaFs7uoYmnwUD h5{color:#14a395}.CwiCaFs7uoYmnwUD img{height:24px}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/Logo/Logo.module.scss","webpack://./../../libs/innova-components/src/lib/assets/scss/_variables.scss"],"names":[],"mappings":"AAEA,kBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,qBACE,aCNM,CDSR,sBACE,WAAA","sourcesContent":["@import '../../assets/scss/variables';\n\n.logo {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n\n  h5 {\n    color: $primary;\n  }\n\n  img {\n    height: 24px;\n  }\n}\n","// Color Definition\n$corp: #18c6b5;\n$primary: #14a395;\n$primaryDark: #108478;\n\n$black: #333;\n$dark02: #757575;\n$dark03: #949494;\n$dark04: #ccc;\n$dark05: #e5e5e5;\n$dark06: #f5f5f5;\n$white: #fff;\n\n$stage01: #f9745f;\n$stage02: #6ecbe0;\n$stage03: #ffc74a;\n\n$semantic01: #14a395;\n$semantic02: #f90;\n$semantic03: #e8304f;\n$semantic04: #006aff;\n\n$semantic01b: #d9f0ee;\n$semantic02b: #ffefd6;\n$semantic03b: #fbdee3;\n$semantic04b: #d6e7ff;\n\n$comp01: #73affb;\n$comp02: #5b81b6;\n\n$pink: #e061eb;\n$orange: #ff9750;\n$blue: #5fa8ff;\n\n// Font Sizes\n$xlarge: 48px;\n$large: 32px;\n$caption: 24px;\n$medium: 20px;\n$normal: 16px;\n$small: 14px;\n$xsmall: 12px;\n$micro: 10px;\n\n// Font family\n$roboto: 'Roboto', sans-serif;\n$robotoMedium: 'Roboto Medium', sans-serif;\n$rubik: 'Rubik Regular', sans-serif;\n$rubikMedium: 'Rubik Medium', sans-serif;\n$rubikSemibold: 'Rubik Semibold', sans-serif;\n\n// Global sizes\n$topBarHeight: 72px;\n$sideBarWidth: 232px;\n$iconSizeM: 24px;\n$iconSizeL: 32px;\n\n$elevation1: 0 2px 4px rgba(0, 0, 0, 0.24);\n$elevation2: 0 4px 8px rgba(0, 0, 0, 0.24);\n$elevation3: 0 8px 16px rgba(0, 0, 0, 0.24);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "CwiCaFs7uoYmnwUD"
};
export default ___CSS_LOADER_EXPORT___;
