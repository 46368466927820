import ContentLoader from 'react-content-loader';

import {
  TableCellSkeleton,
  TableComponent,
  generateRowsForTableSkeleton,
  useColumnsCustomRenderConfig,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';

import type { Test } from '../tests-and-results';
import {
  Container,
  useTestAndResultsColumnsConfiguration,
} from './tests-and-results-table';

const TitleSkeleton = (): JSX.Element => {
  const theme = useGlimmerTheme();
  const backgroundColor = theme.tokens.color.global.neutral.neutral100.value;

  return (
    <ContentLoader
      speed={2}
      width="260px"
      height="24px"
      backgroundColor={backgroundColor}
      foregroundColor={backgroundColor}
    >
      <rect x="0" y="0" width="300px" height="24px" rx="8" />
    </ContentLoader>
  );
};

function TestsAndResultsTableSkeleton(): JSX.Element {
  const configurationWithoutRender = useTestAndResultsColumnsConfiguration();

  const mockObject: Test = {
    name: '',
    status: '',
    password: '',
    startDate: '',
    endDate: '',
  };

  const rowsNumber = 4;

  const customRenderConfig = {
    name: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    status: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    password: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    startDate: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
    endDate: (_: any, row: any) => (
      <TableCellSkeleton isLastRow={row?.rowNumber === rowsNumber} />
    ),
  };

  const columns = useColumnsCustomRenderConfig<Test>(
    customRenderConfig,
    configurationWithoutRender
  );

  const rows = generateRowsForTableSkeleton(rowsNumber, mockObject);

  return (
    <Container>
      {/* <TitleSkeleton /> */}
      <TableComponent
        columns={columns}
        id="tests-and-results-table-skeleton"
        key="tests-and-results-table-skeleton"
        rows={rows}
      />
    </Container>
  );
}

export { TestsAndResultsTableSkeleton };
