const genericKey = 'digitalguides.se.moment.video.';

const videoThemeMap = {
  1: {
    icon: 'SocialAfectiveIcon',
    key: genericKey + 'social',
  },
  2: {
    icon: 'MathsIcon',
    key: genericKey + 'math',
  },
  3: {
    icon: 'HistoricalIcon',
    key: genericKey + 'history',
  },
  4: {
    icon: 'CulturalReferenceIcon',
    key: genericKey + 'cultural',
  },
};

export const mapVideoThemes = (theme: number) => {
  return videoThemeMap[theme];
};
