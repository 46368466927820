import React, { useState, useEffect } from 'react';

import Tooltip from '../Tooltip';

import type { ActionType, Material } from './MaterialCard.types';

import CounterBadge from './CounterBadge';
import MaterialCardControls from './MaterialCardControls';

import {
  CardBody,
  CardContent,
  CardWrapper,
  ColumnLeft,
  ColumnRight,
  Counter,
  Image,
  InfoContainer,
  Language,
  Name,
  Title,
  Units,
} from './MaterialCard.styles';

type MaterialCardProps = {
  isEditMode: boolean;
  material: Material;
  minValue?: number;
  onDecrement: () => void;
  onIncrement: () => void;
  units: string;
};

function MaterialCard({
  isEditMode,
  material,
  minValue = 1,
  onDecrement,
  onIncrement,
  units,
}: MaterialCardProps) {
  const [badgeValue, setBadgeValue] = useState(
    () => material.controlsCounterValue - material.quantity
  );

  const [isBadgeVisible, setIsBadgeVisible] = useState(false);
  const [isDecrementDisabled, setIsDecrementDisabled] = useState(false);

  const getActionType = (): ActionType | '' => {
    if (material.quantity === material.controlsCounterValue) {
      return '';
    }

    if (material.quantity < material.controlsCounterValue) {
      return 'increment';
    }

    return 'decrement';
  };

  useEffect(() => {
    setIsBadgeVisible(material.controlsCounterValue - material.quantity !== 0);
    setIsDecrementDisabled(material.controlsCounterValue === minValue);
    setBadgeValue(material.controlsCounterValue - material.quantity);
  }, [material, minValue]);

  return (
    <CardWrapper>
      <MaterialCardControls
        counterValue={material.controlsCounterValue}
        isDecrementDisabled={isDecrementDisabled}
        onDecrement={onDecrement}
        onIncrement={onIncrement}
        isOpened={isEditMode}
      />
      <CardContent
        height="185px"
        width="136px"
        elevation="01"
        borderRadius="sm"
      >
        <CardBody>
          {isBadgeVisible && (
            <CounterBadge
              variant={badgeValue > 0 ? 'increment' : 'decrement'}
              content={Math.abs(badgeValue)}
            />
          )}
          <Image src={material.image} />
          <Title>{material.course}</Title>
          <InfoContainer>
            <ColumnLeft>
              <Tooltip
                content={material.name}
                placement="bottom"
                offset={[0, 5]}
              >
                <Name>{material.name}</Name>
              </Tooltip>
              <Language>{material.language}</Language>
            </ColumnLeft>
            <ColumnRight>
              <Counter variant={getActionType()}>{material.quantity}</Counter>
              <Units>{units}</Units>
            </ColumnRight>
          </InfoContainer>
        </CardBody>
      </CardContent>
    </CardWrapper>
  );
}

export default MaterialCard;
