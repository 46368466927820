import { useUpdateReportsPreferenceMutation } from '@innovamat/glow-api-client';
import { QueryClient } from '@tanstack/react-query';
import { updateTeachersInCache } from '../cache-actions/update-teachers';

type ErrorUpdateReportsPreference = {
  error: unknown;
  body: {
    classroomId: string;
    teacherId: string;
    report: boolean;
  };
};

type ReturnType = (isActiveReport: boolean, teacherId: string) => void;

const useTeacherUpdateReportsPreferenceMutation = ({
  classroomId,
  isPlatformEnabled,
  organizationId,
  queryClient,
  onError,
}: {
  classroomId: string;
  isPlatformEnabled: boolean;
  organizationId: string;
  queryClient: QueryClient;
  onError?: (error: ErrorUpdateReportsPreference) => void;
}): ReturnType => {
  const { mutate } = useUpdateReportsPreferenceMutation();

  const handleUpdateReportPreference = (
    isActiveReport: boolean,
    teacherId: string
  ): void => {
    if (!classroomId) return;

    mutate(
      {
        body: {
          classroomId,
          teacherId,
          report: isActiveReport,
        },
      },
      {
        onSuccess: () => {
          updateTeachersInCache({
            classroomId,
            isPlatformEnabled,
            organizationId,
            queryClient,
            teacherId,
          });
        },
        onError: (error) => {
          onError?.({
            error,
            body: { classroomId, teacherId, report: isActiveReport },
          });
        },
      }
    );
  };

  return handleUpdateReportPreference;
};

export { useTeacherUpdateReportsPreferenceMutation };

export type { ErrorUpdateReportsPreference };
