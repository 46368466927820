import { useLayoutEffect, useState } from 'react';

import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-domv6';

import { useFlag } from '@innovamat/flags';
import { APP_PATHS, useOrganization, useUser } from '@innovamat/ga-features';
import { dates } from '@innovamat/radiance-utils';

import { EmptyClassroom } from '@innovamat/glimmer-assets';
import {
  AnnouncementPanel,
  Button,
  Cart,
  EmptyState,
  Grid,
  Grounder,
  HeadingSection,
  InnerHtml,
  Modal,
} from '@innovamat/glimmer-components';

import { locales } from '@innovamat/localization';
import { getSolverPhoneNumberByRegion } from '../../shared/contact_phones';

import { useCartExitPrompt } from '../../hooks/use-cart-exit-prompt';
import { useParseMaterials } from './hooks/use-parse-materials';
import { useTermsModalVisibility } from './hooks/use-terms-modal-visibility';

import { useCurrentMaterialsContext } from '../../providers/use-current-materials-provider';

import StagedMaterialsList from './components/staged-materials-list';

import {
  useCheckHasOngoingOrdersQuery,
  useCourses,
  useGetCurrentMaterialsQuery,
} from '@innovamat/glow-api-client';

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const StyledAtag = styled.a`
  text-decoration: underline;
`;

const LeftContainer = styled(Grid.Col)`
  padding: 0 !important;
`;

const RightContainer = styled(Grid.Col)`
  padding: 0 !important;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    position: fixed;
    top: auto;
    bottom: 3.625rem;
  }
`;
const MobileGrounder = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 25rem;
    width: 100%;
  }
`;

const CartWrapper = styled.div`
  margin-top: 1.5rem;
  margin-left: 1rem;
  position: sticky;
  top: 5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0 2.5rem 0 0;
    max-height: 25rem;
    margin-left: 0;
  }
`;

function CurrentMaterials(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organization } = useOrganization();
  const { pathname } = useLocation();
  const { user } = useUser();

  const organizationId = organization?.id || '';

  const isTeacherPeriod = organization?.academicYear.isTeacherPeriod;

  const solverPhoneNumber = getSolverPhoneNumberByRegion(
    user?.physicalRegion,
    user?.locale
  );

  const getReleaseDate = (): string | undefined => {
    if (isTeacherPeriod) {
      return;
    }

    if (organization?.academicYear.isUpdatePeriod) {
      return dates.formatDateFromSql(
        organization?.nextAcademicYear?.teacherStartDate
      );
    }

    return dates.formatDateFromSql(organization?.academicYear.teacherStartDate);
  };

  const featureFlagUser = {
    identifier: '',
    email: user?.email ?? '',
    custom: { schoolId: organizationId },
  };

  const { value: shouldShowCurrentMaterialsFlag, loading: isLoadingFlag } =
    useFlag('shouldShowCurrentMaterials', true, featureFlagUser);

  const BASE_URL = pathname ? pathname.split('/').slice(0, -1).join('/') : '';

  const SEMANTIC_MODAL_TYPE = {
    ERROR: { type: 'error', text: t('common.modal.error') },
    SUCCESS: { type: 'success', text: t('common.modal.success') },
    WARNING: { type: 'warning', text: t('common.modal.important') },
  } as const;

  const {
    cart,
    isEditMode,
    stagedMaterials,
    storedOrganizationId,
    clearCart,
    init,
    orderMaterial,
    returnMaterial,
    setIsEditMode,
  } = useCurrentMaterialsContext();

  const { allCourses, isLoading: isLoadingCourses } = useCourses({
    regionCode: user ? user.region : '',
  });

  const {
    data: materialsData,
    error,
    isLoading: isLoadingMaterials,
  } = useGetCurrentMaterialsQuery(
    {
      organizationId,
      academicYearId: organization?.academicYearId || '',
    },
    {
      enabled: !!organization,
    }
  );

  const isSameOrganization = storedOrganizationId === organizationId;

  const { data: ongoingOrders } = useCheckHasOngoingOrdersQuery({
    organizationId,
  });

  const { shownMaxReturnableItemsDialog, setTermsDialogToViewed } =
    useTermsModalVisibility();

  useCartExitPrompt(cart.length === 0);

  useParseMaterials(
    materialsData?.getCurrentMaterials,
    allCourses,
    init,
    !!cart.length,
    isSameOrganization,
    organizationId
  );

  const [isOpenedModalModifyOrder, setIsOpenedModalModifyOrder] =
    useState(false);
  const [isReturnableItemsModalOpen, setIsReturnableItemsModalOpen] =
    useState(false);

  const handleAcceptCart = (): void => {
    setIsOpenedModalModifyOrder(true);
  };

  const handleCancelCart = (): void => {
    setIsEditMode(false);
    clearCart();
  };

  const handleCancelModal = (): void => {
    setIsOpenedModalModifyOrder(false);
  };

  const handleOrderMaterial = (stageId: string, materialId: string): void => {
    setIsReturnableItemsModalOpen(false);
    orderMaterial(stageId, materialId);
  };

  const handleCloseReturnableItemsModal = (): void => {
    setTermsDialogToViewed();
    setIsReturnableItemsModalOpen(false);
  };

  const handleConfirmModal = (): void => {
    setIsOpenedModalModifyOrder(false);
    navigate(`${BASE_URL}${APP_PATHS.MATERIALS_CHECKOUT_URL}`);
  };

  const handleClickModify = (): void => {
    setIsEditMode(true);
  };

  const renderNotification = (message: string): JSX.Element => {
    if (ongoingOrders?.checkHasOngoingOrders.hasOngoingOrders) {
      return (
        <AnnouncementPanel
          canClose={false}
          style={{ marginTop: '1.5rem' }}
          type="warning"
          text={
            <p>
              {message}{' '}
              <Link to={`${BASE_URL}${APP_PATHS.HISTORY_URL}`}>
                {t('currentMaterials.activeOrdersReturnsNotification.link')}
              </Link>
            </p>
          }
        />
      );
    }
    return <></>;
  };

  const handleReturnMaterial = (
    stageId: string,
    materialId: string,
    isMaxReturnableItemsReached: boolean
  ): void => {
    setIsReturnableItemsModalOpen(isMaxReturnableItemsReached);

    returnMaterial(stageId, materialId);
  };

  const hasFinishedLoading = !isLoadingMaterials && !isLoadingCourses;
  const hasNoMaterials = !materialsData?.getCurrentMaterials?.length;
  const hasNoStagedMaterials = stagedMaterials.length === 0;
  const hasOngoingOrders =
    ongoingOrders?.checkHasOngoingOrders.hasOngoingOrders;

  const isEmptyStateVisible =
    hasNoMaterials && hasNoStagedMaterials && !hasOngoingOrders;

  const renderContent = (): JSX.Element => {
    if (hasFinishedLoading && hasNoMaterials && hasNoStagedMaterials) {
      return (
        <>
          {hasOngoingOrders && (
            <LeftContainer sm={4} md={6} lg={8}>
              {renderNotification(
                t(
                  'currentMaterials.activeOrdersReturnsNotification.messageNoMaterials'
                )
              )}
            </LeftContainer>
          )}

          {!hasOngoingOrders && (
            <EmptyState
              Image={EmptyClassroom}
              subtitle={t('currentMaterials.noMaterialsSubtitle')}
              title={t('currentMaterials.noMaterialsMessage')}
            />
          )}
        </>
      );
    }

    return (
      <LeftContainer sm={4} md={6} lg={8}>
        {isTeacherPeriod &&
          hasFinishedLoading &&
          renderNotification(
            t('currentMaterials.activeOrdersReturnsNotification.message')
          )}

        {!isTeacherPeriod && hasFinishedLoading && (
          <AnnouncementPanel
            canClose={false}
            style={{ marginTop: '1.5rem' }}
            text={t('currentMaterials.blockedAlertMessage', {
              teacherStartDate: getReleaseDate(),
            })}
            type="warning"
          />
        )}

        <StagedMaterialsList
          hasErrors={!!error}
          isDisabled={!isTeacherPeriod}
          isEditMode={isEditMode}
          isLoading={isLoadingMaterials || isLoadingCourses}
          onOrderMaterial={handleOrderMaterial}
          onReturnMaterial={handleReturnMaterial}
          stagedMaterials={stagedMaterials}
        />
      </LeftContainer>
    );
  };

  useLayoutEffect(() => {
    if (!isSameOrganization) {
      setIsEditMode(false);
    }
  }, []);

  if (!isLoadingFlag && !shouldShowCurrentMaterialsFlag) {
    navigate('/');
  }

  return (
    <>
      <HeadingSection
        title={t('currentMaterials.title')}
        actions={
          hasFinishedLoading &&
          !isEditMode &&
          !isEmptyStateVisible &&
          isTeacherPeriod ? (
            <Button onClick={handleClickModify} variant="secondary">
              {t('common.modify')}
            </Button>
          ) : (
            <></>
          )
        }
      />
      <Grid.Container shouldFill={false}>
        <Grid.Row>
          {renderContent()}

          <RightContainer xs={4} md={6} lg={4}>
            <CartWrapper>
              {isEditMode && (
                <Cart
                  cancelButtonLabel={t('common.cancel')}
                  confirmButtonLabel={t('common.accept')}
                  materials={cart}
                  noModificationsMessage={t(
                    'currentMaterials.cart.noModificationsMessage'
                  )}
                  onAccept={handleAcceptCart}
                  onCancel={handleCancelCart}
                  ordersLabel={t('common.orders')}
                  returnsLabel={t('common.returns')}
                  title={t('common.summary')}
                />
              )}
            </CartWrapper>
          </RightContainer>
        </Grid.Row>
      </Grid.Container>

      {isEditMode && <MobileGrounder />}
      <Grounder />

      {isOpenedModalModifyOrder && (
        <Modal
          semantic={SEMANTIC_MODAL_TYPE.WARNING}
          buttons={[
            {
              children: t('currentMaterials.modalModifyOrder.confirmButton'),
              onClick: handleConfirmModal,
              variant: 'accent',
            },
            {
              children: t('common.cancel'),
              onClick: handleCancelModal,
              variant: 'secondary',
            },
          ]}
          closeButton="inner"
          isOpen={isOpenedModalModifyOrder}
          modalWidth="40rem"
          onClose={handleCancelModal}
          title={t('currentMaterials.modalModifyOrder.title')}
        >
          <InnerHtml
            text={t('currentMaterials.modalModifyOrder.message', {
              phoneNumber: solverPhoneNumber,
            })}
          />
        </Modal>
      )}

      {shownMaxReturnableItemsDialog && (
        <Modal
          semantic={SEMANTIC_MODAL_TYPE.WARNING}
          modalWidth="40rem"
          onClose={handleCloseReturnableItemsModal}
          isOpen={isReturnableItemsModalOpen}
          closeButton="inner"
          buttons={[
            {
              children: t('common.close'),
              onClick: handleCloseReturnableItemsModal,
              variant: 'accent',
            },
          ]}
          title={t('currentMaterials.returnableItemsModal.title')}
        >
          <Trans
            i18nKey="currentMaterials.returnableItemsModal.message"
            components={{
              historyLink: (
                <StyledLink
                  onClick={handleCloseReturnableItemsModal}
                  to="/school/ordersReturnsHistory"
                />
              ),
              returnPolicyLink: (
                <StyledAtag
                  href={`https://innovamat.zendesk.com/hc/${locales.getZendesk(
                    user?.locale || 'es'
                  )}/articles/18202437853329-Pol%C3%ADtica-de-devoluciones-de-Innovamat-Education`}
                  target="blank"
                />
              ),
            }}
          />
        </Modal>
      )}
    </>
  );
}

export { CurrentMaterials };
