import { TFunction } from 'i18next';
import { SitemapTreeQuery } from '@innovamat/glow-api-client';
import { NAV_TYPE, NavType } from '../utils';
import {
  getExtendedTree,
  getExternalMenuItem,
  getGeneralSection,
  getIcon,
  getMenuItem,
  isGeneralSection,
  isLearningSection,
  transformLearningSectionToFlatArray,
} from '../utils/process-helpers';
import {
  ProcessedSectionItem,
  ProcessedSitemapTree,
  SitemapTreeItem,
} from '../types';

type ProcessedSitemapTreeProps = {
  tree: SitemapTreeQuery['sitemapTree'];
  navType: NavType;
  t: TFunction<'translation', undefined, 'translation'>;
  isInStudentView: boolean;
  permissions?: {
    assessmentAndIntervention?: boolean;
    earlyIntervention?: boolean;
  };
};

export const processSitemapTree = ({
  tree,
  navType,
  t,
  isInStudentView,
  permissions,
}: ProcessedSitemapTreeProps): ProcessedSitemapTree => {
  const extendedTree = getExtendedTree(
    navType,
    tree,
    t,
    isInStudentView,
    permissions
  );

  // If the navigation type is LEARNING, we need to return a flat array of the learning section
  if (navType === NAV_TYPE.Learning) {
    return transformLearningSectionToFlatArray(extendedTree);
  }

  return extendedTree.map((section, index) => {
    if (!section) return null;

    if (isLearningSection(section)) {
      return null;
    }

    // If the section is the "General" section, we need to return the general section as an item
    if (isGeneralSection(section)) {
      return getGeneralSection(section, t, navType, index);
    }

    return {
      menuItemType: 'section',
      id: `${section.value}-${section.route}-${index}`,
      icon: getIcon(navType, section),
      value: section.value,
      route: section.route,
      items: section.items
        ?.map((item: SitemapTreeItem, index: number) => {
          if (!item || item.status !== 'ACTIVE') {
            return null;
          }

          if (item.type === 'group') {
            return item.value;
          }

          if (item.linkItemType === 'EXTERNAL') {
            return getExternalMenuItem(item, index);
          }

          return getMenuItem(item, index);
        })
        .filter(Boolean) as ProcessedSectionItem[],
    };
  }) as ProcessedSitemapTree;
};
