import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';
import styled from '@emotion/styled';

import {
  getNextAcademicYearId,
  SelectCoursePage,
  useNavigation,
  useUser,
} from '@innovamat/ga-features';
import { Breadcrumb, Grid, IconButton } from '@innovamat/glimmer-components';
import { useCourses } from '@innovamat/glow-api-client';
import { Header } from '../layout/header';
import { Logo } from '../components/logo';

const Container = styled(Grid.Container)`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export function CourseSelectorPage(): JSX.Element {
  const { t } = useTranslation();
  const { user, isRole } = useUser();

  const { navType } = useParams();
  const { goToCourse } = useNavigation();
  const { goToHome } = useNavigation();

  type SelectCourseTextKeys = {
    [key: string]: {
      metaTitle: string;
      title: string;
      description: string;
    };
  };

  const selectCourseTextKeys: SelectCourseTextKeys = {
    learning: {
      metaTitle: t('title.training'),
      title: t('learning-navigation.title'),
      description: t('learning-navigation.description'),
    },
    course: {
      metaTitle: t('title.course_content_navigation'),
      title: t('course-navigation.title'),
      description: t('course-navigation.description'),
    },
  };

  const nextAcademicYearId = user?.organization?.academicYearId
    ? getNextAcademicYearId(user?.organization?.academicYearId)
    : undefined;

  const { coursesForSelectCoursePage } = useCourses({
    activeLevel: 'MANAGER',
    nextAcademicYearId: nextAcademicYearId,
    organizationId: user?.organizationId!,
    regionCode: user?.region!,
  });

  useEffect(() => {
    if (!navType) return;
    document.title = selectCourseTextKeys[navType].metaTitle;
  }, [navType, t]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!navType) {
    return <></>;
  }

  return (
    <>
      <Header
        leftActions={
          isRole.Lead ? (
            <Logo onClickGoHome={false} />
          ) : (
            <>
              <IconButton icon="BackIcon" onClick={goToHome} />
              <Breadcrumb
                goFrom={goToHome}
                breadcrumbs={[
                  t('menu.Inicio'),
                  selectCourseTextKeys[navType].title,
                ]}
              />
            </>
          )
        }
        middleText={user && selectCourseTextKeys[navType].title}
      />
      <Container>
        <Grid.Row justifyContent="center">
          <Grid.Col xs={8}>
            <SelectCoursePage
              onSelectCourse={(course) => goToCourse(course.id)}
              title={selectCourseTextKeys[navType].title}
              description={selectCourseTextKeys[navType].description}
              courses={coursesForSelectCoursePage}
            />
          </Grid.Col>
        </Grid.Row>
      </Container>
    </>
  );
}
