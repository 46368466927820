import React from 'react';
import styles from './InputRangeSlider.module.scss';

type InputRangeSliderProps = {
  value: number
  min: number
  max: number
  step:number
  disabled?: boolean
  onChange: (e: number) => void
};

const InputRangeSlider = ({
  value, min, max, step, disabled, onChange,
}: InputRangeSliderProps) => (
  <input
    disabled={disabled}
    id="slider"
    type="range"
    min={min}
    max={max}
    step={step}
    value={value}
    className={styles.slider}
    onChange={(e) => onChange(parseFloat(e.target.value))}
  />
);

export default InputRangeSlider;
