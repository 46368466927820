import ClassesEmptyStateImage from '../assets/images/reports-error-image.png';
import { ReactComponent as ElectrifiedCat } from '../assets/images/electrified-cat.svg';
import { ReactComponent as EmptyClassroom } from '../assets/images/empty-classroom.svg';
import { ReactComponent as EmptyClassroomShadow } from '../assets/images/empty-classroom-shadow.svg';
import { ReactComponent as EmptyTestsAndResults } from '../assets/images/empty-tests-and-results.svg';
import { ReactComponent as EmptyFamilyPayments } from '../assets/images/empty-family-payments.svg';
import { ReactComponent as IconsFaces } from '../assets/images/icons-faces.svg';
import { ReactComponent as Superman } from '../assets/images/superman.svg';
import { ReactComponent as HappyStudentsWithTeacher } from '../assets/images/happy-students-with-teacher.svg';
import { ReactComponent as BlocksPreferences } from '../assets/images/blocks-preferences.svg';

export * from './lib';
export {
  ClassesEmptyStateImage,
  ElectrifiedCat,
  EmptyClassroom,
  EmptyClassroomShadow,
  EmptyFamilyPayments,
  EmptyTestsAndResults,
  IconsFaces,
  Superman,
  HappyStudentsWithTeacher,
  BlocksPreferences,
};
