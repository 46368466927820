import animationData from './success.json';
import { LottieAnimation } from '../lottie-animation';
import { LottieProps } from 'react-lottie-player';

export function SuccessAnimation(props: LottieProps): JSX.Element {
  return (
    <LottieAnimation
      animationData={animationData}
      width={180}
      height={180}
      {...props}
    />
  );
}
