import { useWebviewBridge } from '@innovamat/webview-bridge';
import {
  StartWeeklyPracticeDocument,
  StartWeeklyPracticeMutation,
  StartWeeklyPracticeMutationVariables,
  useFetch,
  GlowApiError,
} from '@innovamat/glow-api-client';
import { snack } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';

type Props = {
  locale: string;
};

export const NO_LESSONS_DONE_EXCEPTION =
  'innovamat.platform.didactics.classroom_has_no_lessons_done_exception';

export function useStartWeeklyPractice({ locale }: Props) {
  const { isInWebview, buildVersion } = useWebviewBridge();
  const { t } = useTranslation();

  const { mutate, isPending, isError, error } = useMutation<
    StartWeeklyPracticeMutation,
    GlowApiError,
    StartWeeklyPracticeMutationVariables,
    unknown
  >({
    mutationKey: ['StartWeeklyPractice'],
    mutationFn: useFetch<
      StartWeeklyPracticeMutation,
      StartWeeklyPracticeMutationVariables
    >(StartWeeklyPracticeDocument, {
      'build-version': isInWebview ? buildVersion : 'web',
    }).bind(null, { locale }),
  });

  useEffect(() => {
    if (isError) {
      const errors = error?.response.errors;
      const errorType = errors[0]?.extensions.response.body['type'];
      if (errorType !== NO_LESSONS_DONE_EXCEPTION)
        snack.error(t('WeeklyPractice.error.startWeeklyPractice'));
    }
  }, [isError]);

  return {
    startWeeklyPractice: mutate,
    loadingStartWeeklyPractice: isPending,
  };
}
