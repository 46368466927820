const getMathPracticeText = (tag: string) => {
  return `smartguides.mp_${tag.split(' ')[1]}`;
};

export function mapNewMathPractices(
  mathPractices?: string[]
): { tag: string; textKey: string }[] {
  if (!mathPractices) {
    return [];
  }
  return mathPractices.map((item: any) => {
    return {
      tag: item,
      textKey: getMathPracticeText(item),
    };
  });
}
