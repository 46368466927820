import { TableComponent, useGlimmerTheme } from '@innovamat/glimmer-components';
import { SchoolStudent } from '../../types/student';
import { useGetColumns } from '../../hooks/use-get-columns';
import { tableStyles } from '../../../../components/common-table-components/table-styles';

type Props = {
  isUpdatePeriod?: boolean;
  students: SchoolStudent[];
};

function SchoolStudentsTable({ isUpdatePeriod, students }: Props): JSX.Element {
  const { columns } = useGetColumns();
  const theme = useGlimmerTheme();

  return (
    <TableComponent
      backgroundColor="transparent"
      columns={columns}
      id="school-students-table"
      rows={students}
      sx={tableStyles(theme)}
      isDisabled={isUpdatePeriod}
    />
  );
}

export { SchoolStudentsTable };
