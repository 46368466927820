import { useRef } from 'react';

const delay = (n: number) => new Promise((resolve) => setTimeout(resolve, n));

const cancellablePromise = (promise: any) => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (value: any) =>
        isCanceled ? reject({ isCanceled, value }) : resolve(value),
      (error: any) => reject({ isCanceled, error })
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true;
    },
  };
};

const useCancellablePromises = () => {
  const pendingPromises = useRef<any>([]);

  const appendPendingPromise = (promise: any) => {
    pendingPromises.current = [...pendingPromises.current, promise];
  };

  const removePendingPromise = (promise: any) => {
    pendingPromises.current = pendingPromises.current.filter(
      (p: any) => p !== promise
    );
  };

  const clearPendingPromises = () =>
    pendingPromises.current.map((p: any) => p.cancel());

  const api = {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };

  return api;
};

export function useOnDoubleClick(onClick: any, onDoubleClick: any) {
  const api = useCancellablePromises();

  const handleClick = async () => {
    api.clearPendingPromises();
    const waitForClick = cancellablePromise(delay(300));
    api.appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        onClick();
      })
      .catch((errorInfo: any) => {
        api.removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleDoubleClick = () => {
    api.clearPendingPromises();
    onDoubleClick();
  };

  return [handleClick, handleDoubleClick];
}

// const [handleClick, handleDoubleClick] = useOnDoubleClick(
//   () => function to execute when click(),
//   () => function to execute when doubleClick()
// );

// <button onClick={handleClick} onDoubleClick={handleDoubleClick}>
//   test
// </button>;
