import { Params } from './resourceMapper';
import checkMissingLocale from '../utils/missingLocales';
import { ResourceType } from '../types/resourceType';

export const genericMapper = ({ response, locale }: Params) => {
  return {
    id: response.uuid,
    language: locale,
    type: response.type as ResourceType,
    description: checkMissingLocale(response.description!),
    title: checkMissingLocale(response.name!),
    path: response.file?.href!,
    fileId: response.file?.uuid!,
    fileName: response.file?.original_file_name!,
  };
};
