import { useAssignTeachersToClassroomMutation } from '@innovamat/glow-api-client';

import type { OrganizationQuery } from '@innovamat/glow-api-client';

type ErrorTeachersAssign = {
  error: unknown;
  body: {
    emails: string[];
    classroomId: string;
    organizationId: string;
    className: string;
    inviterId: string;
    language: string;
  };
};

type Props = {
  inviterId: string;
  organization: OrganizationQuery['organization'];
  onSuccess?: (body: Body) => void;
  onError?: (error: ErrorTeachersAssign) => void;
};

type Body = {
  classroomId: string;
  classroomName: string;
  emails: string[];
};

type ReturnType = {
  isPending: boolean;
  handleAssignTeacherToClassrooms: (body: Body) => void;
};

const useAssignTeachersToClassroom = ({
  inviterId,
  organization,
  onError,
  onSuccess,
}: Props): ReturnType => {
  const { mutate, isPending } = useAssignTeachersToClassroomMutation();

  const handleAssignTeacherToClassrooms = ({
    classroomId,
    classroomName,
    emails,
  }: Body): void => {
    if (!classroomId || !classroomName) return;

    mutate(
      {
        body: {
          classId: classroomId,
          className: classroomName,
          inviterId,
          language: organization?.language!,
          schoolId: organization?.id!,
          teacherEmails: emails,
        },
      },
      {
        onSuccess: () => {
          onSuccess?.({ classroomId, classroomName, emails });
        },
        onError(error) {
          onError?.({
            error,
            body: {
              className: classroomName,
              classroomId,
              emails,
              inviterId,
              language: organization?.language!,
              organizationId: organization?.id!,
            },
          });
        },
      }
    );
  };
  return { handleAssignTeacherToClassrooms, isPending };
};

export { useAssignTeachersToClassroom };
export type { ErrorTeachersAssign };
