import { useState, useEffect } from 'react';

export function useMedia(px: number) {
  const query = `(max-width: ${px}px)`;
  const [trigger, setTrigger] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const handleMatch = (e: any) => setTrigger(e.matches);

    mediaQueryList.addEventListener('change', handleMatch);
    // Set initial state
    setTrigger(mediaQueryList.matches);

    return () => mediaQueryList.removeEventListener('change', handleMatch);
  }, [px, query]);

  return trigger;
}
