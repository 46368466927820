import { useImportGoogleClassroomsMutation } from '@innovamat/glow-api-client';
import { useState } from 'react';
import { QueryClient } from '@tanstack/react-query';
import { ClassroomToImport } from '../types';
import { GOOGLE_CLASSROOMS_KEY } from './useGetGoogleClassrooms';

type ImportClassroomsProps = {
  client: QueryClient;
};

export default function useImportClassrooms({ client }: ImportClassroomsProps) {
  const [importedClassrooms, setImportedClassrooms] = useState<
    ClassroomToImport[]
  >([]);
  const { mutate, isPending, isSuccess, isError, data, error, reset } =
    useImportGoogleClassroomsMutation();

  const onImportClassrooms = async (
    classroomsToImport: ClassroomToImport[]
  ) => {
    mutate(
      { classroomsToImport },
      {
        onError: (error: any) => {
          if (error.extensions?.code !== 'IMPORTING_ERROR') return error;
          const imported = error.extensions
            .importedGoogleClassrooms as ClassroomToImport[];

          const classroomsInCache = client.getQueryData<ClassroomToImport[]>(
            GOOGLE_CLASSROOMS_KEY
          );

          if (classroomsInCache) {
            const newClassrooms = classroomsInCache.map((classroom) => {
              const importedClassroom = imported.find(
                (ic) => ic.google_id === classroom.google_id
              );
              if (importedClassroom) {
                return {
                  ...classroom,
                  imported: true,
                  course_order: importedClassroom.course_order,
                };
              }
              return classroom;
            });

            client.setQueryData(GOOGLE_CLASSROOMS_KEY, newClassrooms);
          }

          setImportedClassrooms(imported);
        },
      }
    );
  };

  return {
    onImportClassrooms,
    importingClassroomsStatus: {
      isLoading: isPending,
      isError,
      isSuccess,
      data: (data?.importGoogleClassrooms ||
        importedClassrooms) as ClassroomToImport[],
      error: error,
      reset,
    },
  };
}
