import {
  AnnouncementPanel,
  SchoolCard,
  Section,
} from '@innovamat/glimmer-components';
import { useOrganizationQuery } from '@innovamat/glow-api-client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-domv6';

import { useUser } from '@innovamat/ga-features';
import { SchoolSectionSkeleton } from './school-section-skeleton';

type Props = {
  organizationId: string;
};

const MySchool = ({ organizationId }: Props): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { user } = useUser();

  const {
    data: organizationQuery,
    isSuccess,
    isLoading,
    isError,
  } = useOrganizationQuery(
    { id: organizationId },
    {
      enabled: !!organizationId,
      staleTime: Infinity,
    }
  );

  if (!isSuccess || isLoading) {
    return <SchoolSectionSkeleton />;
  }

  return (
    <Section
      hasBackground
      title={t('lanzadera.admin.mySchoolSection.title', 'Centro')}
    >
      {isError && (
        <AnnouncementPanel
          text={t(
            'lanzadera.admin.mySchoolSection.request.error',
            'Ha ocurrido un error al cargar la escuela'
          )}
          type="error"
          canClose={false}
        />
      )}

      <SchoolCard
        classes={{
          label: t(
            'lanzadera.admin.mySchoolSection.schoolCard.classes',
            'Clases'
          ),
          value: organizationQuery?.organization?.numberOfClassrooms ?? 0,
        }}
        location={organizationQuery?.organization?.location ?? undefined}
        onClick={() => navigate(`/organization/${user?.organizationId ?? ''}`)}
        students={{
          label: t(
            'lanzadera.admin.mySchoolSection.schoolCard.students',
            'Alumnos'
          ),
          value: organizationQuery?.organization?.numberOfStudents ?? 0,
        }}
        schoolName={organizationQuery?.organization?.name ?? ''}
        teachers={{
          label: t(
            'lanzadera.admin.mySchoolSection.schoolCard.teachers',
            'Profesores'
          ),
          value: organizationQuery?.organization?.numberOfTeachers ?? 0,
        }}
      />
    </Section>
  );
};

export { MySchool };
