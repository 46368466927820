import { sortBy } from '@innovamat/radiance-utils';
import { useMemo } from 'react';
import { useEducationalPlansQuery } from '@innovamat/glow-api-client';
import { useOrganization } from './use-organization';
import { OptionType } from '@innovamat/glimmer-components';

type Props = {
  filterOrderCourse?: number | undefined | null;
};

function useEducationalPlans({ filterOrderCourse }: Props) {
  const { organization } = useOrganization();

  const { data, isLoading } = useEducationalPlansQuery(
    {
      regionCode: organization?.operativeRegion!,
      courseOrder: filterOrderCourse,
    },
    {
      enabled: !!organization && !!filterOrderCourse,
    }
  );

  const educationPlans = useMemo(
    () => data?.educationalPlans?.concat().sort(sortBy('courseOrder')) || [],
    [data]
  );

  const educationOptions: OptionType[] = useMemo(() => {
    // Workaround (chapuza) to filter only 1º and 2º in Brasil
    // TO DO: Backend should return only 1º and 2º if EY doesn't exist
    const filteredPlans =
      organization?.operativeRegion === 'BR'
        ? educationPlans.filter((plan) => plan?.courseOrder! > 5)
        : educationPlans;

    return filteredPlans.map((plan) => ({
      label: plan?.educationalPlanName!,
      value: plan?.id!,
    }));
  }, [educationPlans, organization]);

  const getEducationalPlanByCourse = (courseId: string) => {
    return (
      educationPlans.find((ed) => ed?.courseId === courseId) ||
      educationPlans[0]
    );
  };

  return {
    educationOptions,
    educationPlans,
    isLoading,
    getEducationalPlanByCourse,
  };
}

export { useEducationalPlans };
