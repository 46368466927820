import { snack } from '@innovamat/glimmer-components';
import { TFunction } from 'i18next';

type TeacherEmail =
  | {
      type: string;
      email: string;
    }[]
  | undefined;

function teacherEmailValidationSnack(
  errors: any,
  t: TFunction
): TeacherEmail | undefined {
  let failedEmails: TeacherEmail;

  if (errors) {
    errors.forEach((error: any) => {
      if (error.extensions?.exception?.['code'] === 'ROLE_CONFLICT') {
        failedEmails = error.extensions?.exception.types;
      }
    });
    failedEmails?.forEach(({ type, email }) => {
      if (type === 'email.invalid') {
        snack.error(
          t('teachers.inviteModal.error.invalidEmail', {
            email: email,
          })
        );
      } else {
        snack.error(
          t('teachers.inviteModal.error.roleConflict', {
            email: email,
          })
        );
      }
    });
    if (failedEmails) return failedEmails;
  }
  return undefined;
}

export { teacherEmailValidationSnack };
