import {
  PermissionCoursesQuery,
  usePermissionCoursesQuery,
} from '@innovamat/glow-api-client';
import { useUser } from '../../user-management';
import { useOrganization } from './use-organization';

type BasePermissions = {
  tutoringChat: boolean;
  earlyIntervention: boolean;
  adminDashboard: boolean;
  assessment: boolean;
};

type UsePermissions = {
  permissionsByCurrentCourse: BasePermissions;
  isLoading: boolean;
  hasSomePermissionsForAssessment: () => boolean;
  hasSomePermissionsForIntervention: () => boolean;
  allPermissions: PermissionCoursesQuery['permissionCourses'];
};

type Props = {
  courseId?: string | null | undefined;
};

function usePermissionCourses({ courseId }: Props): UsePermissions {
  const { user } = useUser();
  const organizationId = user?.organizationId ?? '';
  const { organization } = useOrganization();
  const academicYearId = organization?.academicYearId ?? '';

  const { data, isLoading } = usePermissionCoursesQuery(
    {
      academicYearId,
      organizationId,
    },
    {
      enabled: !!organizationId && !!academicYearId,
      staleTime: Infinity,
    }
  );

  const permissionsByCourse = (
    courseId: string | null | undefined
  ): BasePermissions => {
    const permissions = data?.permissionCourses?.find(
      (permission) => permission?.courseId === courseId
    );

    if (!permissions) {
      return {
        tutoringChat: false,
        earlyIntervention: false,
        adminDashboard: false,
        assessment: false,
      };
    }

    return {
      tutoringChat: !!permissions.permission?.tutoringChat,
      earlyIntervention: !!permissions.permission?.earlyIntervention,
      adminDashboard: !!permissions.permission?.adminDashboard,
      assessment: !!permissions.permission?.assessment,
    };
  };

  const hasSomePermissionsForAssessment = (): boolean => {
    const permissions = data?.permissionCourses?.some(
      (permissionCourse) => permissionCourse?.permission?.assessment
    );

    return !!permissions;
  };

  const hasSomePermissionsForIntervention = (): boolean => {
    const permissions = data?.permissionCourses?.some(
      (permissionCourse) => permissionCourse?.permission?.earlyIntervention
    );

    return !!permissions;
  };

  return {
    permissionsByCurrentCourse: permissionsByCourse(courseId),
    isLoading: isLoading,
    hasSomePermissionsForAssessment,
    hasSomePermissionsForIntervention,
    allPermissions: data?.permissionCourses,
  };
}

export { usePermissionCourses };
