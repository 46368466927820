import { ResourceResponse } from '../types/resourceResponse';
import { ResourceType } from '../types/resourceType';
import { Video } from '../types/video';
import checkMissingLocale from '../utils/missingLocales';
import { mapPrintable } from './contentsMapper';

export const videoMapper = (
  response: ResourceResponse,
  locale: string,
  isBloqued: boolean,
  isStudent: boolean
): Video => {
  const isNotSession = false;
  const printables = mapPrintable(response, isStudent, isNotSession);

  return {
    id: response.uuid,
    isBloqued,
    language: locale,
    type: response.type as ResourceType,
    url: response.file?.href || '',
    fileId: response.file?.uuid || '',
    description: checkMissingLocale(response.description!),
    title: checkMissingLocale(response.name!),
    printables,
    progress: response.progress || 0,
    tags: response.tags,
  };
};
