/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import { Column, Row, StyledRadioButton } from './RadioButton.styles';

type RadioButtonProps = {
  groupBy: string;
  label?: string;
  value?: string;
  disabled?: boolean;
  checked?: boolean;
  onEvent?: (value: string) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const RadioButton = ({
  groupBy,
  label,
  value,
  disabled,
  checked,
  onEvent,
}: RadioButtonProps) => {
  const radio = useRef(null);

  useEffect(() => {
    if (radio.current && checked) radio.current.checked = true;
  }, [checked]);

  return (
    <StyledRadioButton
      onClick={() => {
        if (!disabled && !radio.current.checked) radio.current.checked = true;
        if (onEvent) onEvent(value);
      }}
      isDisabled={disabled}
    >
      <Row>
        <Column>
          <input
            ref={radio}
            name={groupBy}
            value={value}
            disabled={disabled}
            type="radio"
          />
        </Column>
        {label && <Column>{label}</Column>}
      </Row>
    </StyledRadioButton>
  );
};

export default RadioButton;
