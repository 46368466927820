import { useWebviewBridge } from '@innovamat/webview-bridge';
import {
  TrainingZoneDocument,
  TrainingZoneQuery,
  TrainingZoneQueryVariables,
  useFetch,
  useTrainingZoneQuery
} from '@innovamat/glow-api-client';
import { useQuery } from '@tanstack/react-query';

type Props = {
  locale: string;
};

export function useTrainingZone({ locale }: Props) {
  const { isInWebview, buildVersion } = useWebviewBridge();

  const { data, isPending, refetch } = useQuery<TrainingZoneQuery>({
    queryKey: useTrainingZoneQuery.getKey(),
    queryFn: useFetch<TrainingZoneQuery, TrainingZoneQueryVariables>(
      TrainingZoneDocument,
      { 'build-version': isInWebview ? buildVersion : 'web' }
    ).bind(null, { locale }),
  });

  return {
    trainingZone: data?.trainingZone,
    loadingTrainingZone: isPending,
    refetchTrainingZone: refetch,
  };
}
