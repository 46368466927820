import { useNavigate } from 'react-router-domv6';
import { useTranslation } from 'react-i18next';

import {
  AnnouncementPanel,
  SchoolCard,
  Section,
  snack,
} from '@innovamat/glimmer-components';

import { SchoolSectionSkeleton } from '../../../components/my-school';

import {
  useAllOrganizationsQuery,
  useChangeOrganizationMutation,
  type Organization,
} from '@innovamat/glow-api-client';
import { useQueryClient } from '@tanstack/react-query';
import { updateCurrentUserData } from '../../../utils/update-current-user-data';
import { useUser } from '@innovamat/ga-features';

const DistrictAdminSchools = (): JSX.Element => {
  const {
    data: allOrganizationsQueryData,
    isSuccess,
    isError,
  } = useAllOrganizationsQuery();

  const organizations =
    (allOrganizationsQueryData?.allOrganizations as Organization[]) ||
    ([] as Organization[]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const changeOrganizationMutation = useChangeOrganizationMutation();

  const { user } = useUser();

  const onSelectOrganization = async (
    organization: Organization
  ): Promise<void> => {
    changeOrganizationMutation.mutate(
      { body: { schoolId: organization.id!, userId: user?.id! } },
      {
        onSuccess: async () => {
          await updateCurrentUserData(organization);
          queryClient.resetQueries();
          navigate(`/organization/${organization.id}`);
        },
        onError: () => {
          snack.error(t("Couldn't change the organization"));
        },
      }
    );
  };

  if (!isSuccess) {
    return <SchoolSectionSkeleton />;
  }

  return (
    <Section hasBackground title={t('lanzadera.admin.schoolsSection.title')}>
      {isError && (
        <AnnouncementPanel
          text={t('lanzadera.admin.schoolSection.request.error')}
          type="error"
          canClose={false}
        />
      )}

      {organizations.map((organization: Organization) => (
        <SchoolCard
          key={organization.id}
          classes={{
            label: t('lanzadera.admin.mySchoolSection.schoolCard.classes'),
            value: organization?.numberOfClassrooms ?? 0,
          }}
          location={organization?.location ?? undefined}
          onClick={() => onSelectOrganization(organization)}
          students={{
            label: t('lanzadera.admin.mySchoolSection.schoolCard.students'),
            value: organization?.numberOfStudents ?? 0,
          }}
          schoolName={organization?.name ?? ''}
          teachers={{
            label: t('lanzadera.admin.mySchoolSection.schoolCard.teachers'),
            value: organization?.numberOfTeachers ?? 0,
          }}
        />
      ))}
    </Section>
  );
};

export { DistrictAdminSchools };
