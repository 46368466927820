import { MediaType, MediaWidth } from '../types/Media';

function extractId(url: string) {
  const regex = /https:\/\/stream\.mux\.com\/([a-zA-Z0-9]+)/;
  const match = url.match(regex);

  return match ? match[1] : null;
}

const getWidth = (width: string): MediaWidth => {
  if (!width) return 50;
  return +width as MediaWidth;
};

export function mapMedia(data: any, width: string): MediaType[] {
  return data?.map((item: any) => {
    const media: MediaType = {
      id: item._id,
      type: item._type,
      width: getWidth(width),
      name: item.name,
      url: item.url,
      files: item.cdn_files?.map((file: any) => file.url),
      playbackId: extractId(item.url),
    };
    return media;
  });
}
