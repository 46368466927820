import { useReactToPrint } from 'react-to-print';
import { useCallback, useEffect, useRef, useState } from 'react';

export function usePrint(filename: string) {
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef<any>(null);
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAfterPrint = useCallback(() => {}, []);

  const handleBeforePrint = useCallback(() => {
    setDisplay(false);
  }, []);
  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        resolve(undefined);
      }, 2000);
    });
  }, [setLoading]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: filename,
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const onPrint = useCallback(() => {
    setDisplay(true);
    handlePrint();
  }, [handlePrint]);

  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current]);

  return {
    loading,
    onPrint,
    componentRef,
    display,
  };
}
