import { createContext, useContext } from 'react';

import { TFunction } from 'i18next';
import { PlayerInfo } from '../../types/PlayerInfo';

type User = {
  language: string;
  classroomId: string | undefined;
  organizationId: string | undefined;
  organizationName: string | undefined;
  userId: string | undefined;
  region: string | undefined;
  courseOrder: number;
  email: string | undefined;
  name: string | undefined;
};

export type DigitalGuideProviderProps = {
  id?: string;
  children: React.ReactNode;
  translations: Record<string, string>;
  playerInfo?: PlayerInfo;
  relatedItemsComponent?: JSX.Element;
  unblockedAppletsComponent?: JSX.Element;
  user?: User;
  t: TFunction<'translation', undefined, 'translation'>;
};

type DigitalGuideValue = {
  id: string;
  playerInfo?: PlayerInfo;
  translations: Record<string, string>;
  relatedItemsComponent?: JSX.Element;
  unblockedAppletsComponent?: JSX.Element;
  user?: User;
  t: TFunction<'translation', undefined, 'translation'>;
};

const SmartGuidesContext = createContext<DigitalGuideValue | undefined>(
  undefined
);

function DigitalGuideProvider({
  id,
  children,
  playerInfo,
  translations,
  user,
  relatedItemsComponent,
  unblockedAppletsComponent,
  t,
}: DigitalGuideProviderProps) {
  const value: DigitalGuideValue = {
    id: id || '',
    playerInfo,
    translations,
    relatedItemsComponent,
    unblockedAppletsComponent,
    user,
    t,
  };

  return (
    <SmartGuidesContext.Provider value={value}>
      {children}
    </SmartGuidesContext.Provider>
  );
}

function useDigitalGuides() {
  const context = useContext(SmartGuidesContext);
  if (context === undefined) {
    throw new Error(
      'useDigitalGuides must be used within a DigitalGuideProvider'
    );
  }
  return context;
}

export { DigitalGuideProvider, useDigitalGuides };
