import {
  ProgressReport,
  Block,
  WeeklyReport,
  Content,
} from '@innovamat/glow-api-client';
import { TFunction } from 'i18next';
import {
  BubbleNotes,
  ObservationsNotes,
  StudentsWithDifficulties,
} from '../views/weekly-results/weekly-results.types'; // TODO: Types from Apollo Codegen
import { Row } from '@innovamat/glimmer-components';

export const preprocessWeeklyReport = (report: any) => {
  if (!report) return;
  const contents = {};
  report.contents?.forEach((content: Content) => {
    const { contentTextCode, skillTextCode, score, applets } = content;
    const key = `${contentTextCode}${skillTextCode}`;
    contents[key] = {
      average: score,
      applets: applets,
    };
  });

  return {
    ...report,
    ...contents,
  };
};

export const getStudentsRows = (
  weeklyReport: WeeklyReport | null | undefined
): Row[] => {
  if (!weeklyReport?.students) return [];
  return weeklyReport.students
    .map((report) => preprocessWeeklyReport(report))
    .filter((s) => s.firstName);
};

export const sortByPropertyId = (property: string) => (a: any, b: any) => {
  const partsA = a[property].split('.').map(Number);
  const partsB = b[property].split('.').map(Number);

  for (let i = 0; i < partsA.length; i++) {
    if (partsA[i] !== partsB[i]) {
      return partsA[i] - partsB[i];
    }
  }
  return 0;
};

export const preprocessProgressReport = (report: any) => {
  if (!report) return;
  const blocks = {};
  report.blocks?.forEach((block: Block) => {
    const { score, contents, blockId } = block;
    blocks[blockId!] = {
      average: score,
      contents: contents,
    };
  });
  return {
    ...report,
    ...blocks,
  };
};

export const getProgressStudentsRows = (
  progressReport: ProgressReport | null | undefined
): Row[] => {
  if (!progressReport?.students) return [];
  return progressReport.students
    .map((report) => preprocessProgressReport(report))
    .filter((s) => s.firstName);
};

export const getDisconnectedStudents = (studentsRows: Row[]): string[] => {
  if (!studentsRows) return [];
  const studentsRowsDisconneted = studentsRows.filter(
    (s: Row) => s.firstName && s.timeSpentAll === null
  );
  const students = studentsRowsDisconneted.map(
    (student) => `${student.firstName} ${student.lastName}`
  );

  return students;
};

export const getPrimaryObservationNotes = (
  studentsRows: Row[],
  t: TFunction<'translation', undefined, 'translation'>
): ObservationsNotes[] => {
  if (!studentsRows) return [];
  const connectedStudentsRows = studentsRows.filter(
    (s: Row) => s.firstName && s.contents.length > 0
  );

  const bubbleTextCodeCounts = {};
  const totalStudents = studentsRows.length;

  connectedStudentsRows.forEach((student: Row) => {
    const bubbles = student.bubbles;
    bubbles.forEach((b: BubbleNotes) => {
      const bubbleTextCode = t(b.bubbleTextCode);
      bubbleTextCodeCounts[bubbleTextCode] =
        (bubbleTextCodeCounts[bubbleTextCode] || 0) + 1;
    });
  });

  const bubblesNotes = {};

  for (const bubbleTextCode in bubbleTextCodeCounts) {
    const count = bubbleTextCodeCounts[bubbleTextCode];
    const percentage = (count / totalStudents) * 100;
    bubblesNotes[bubbleTextCode] = percentage.toFixed(2);
  }

  const observationsNotes = Object.keys(bubblesNotes).map((key) => ({
    subtitle: key,
    percentage: bubblesNotes[key],
  }));

  return observationsNotes;
};

export const getSecondaryObservationsNotes = (
  studentsRows: Row[],
  averageRow: Row | undefined,
  t: TFunction<'translation', undefined, 'translation'>
): ObservationsNotes[] => {
  if (!studentsRows || !averageRow) {
    return [];
  }

  const averageContents = averageRow.contents as Row;
  const titlesContents = averageContents.map((c: Row) =>
    c.skillTextCode
      ? `${t(c.contentTextCode)}: ${t(c.skillTextCode)}`
      : t(c.contentTextCode)
  );

  const connectedStudentsRows = studentsRows.filter(
    (s: Row) => s.firstName && s.contents.length > 0
  );

  const connectedStudentsRowsContents = connectedStudentsRows.map((s: Row) =>
    s.contents.map((c: Row) =>
      c.skillTextCode
        ? `${t(c.contentTextCode)}: ${t(c.skillTextCode)}`
        : t(c.contentTextCode)
    )
  );

  const contentStudent = connectedStudentsRowsContents.map((contents) =>
    contents.join(', ')
  );

  const totalStudents = studentsRows.length;

  const studentsPercentagePerColumn = titlesContents.map((title: string) => {
    const totalStudentsPerColumn = contentStudent.filter((c: string) =>
      c.includes(title)
    ).length;
    return (totalStudentsPerColumn / totalStudents) * 100;
  });

  const observationsNotes = titlesContents.map(
    (title: string, index: number) => ({
      subtitle: title,
      percentage: studentsPercentagePerColumn[index],
    })
  );

  return observationsNotes;
};

export const getSecondaryStudentsWithDifficulties = (
  studentsRows: Row[],
  averageRow: Row | undefined,
  t: TFunction<'translation', undefined, 'translation'>
): StudentsWithDifficulties[] => {
  if (!studentsRows || !averageRow) {
    return [];
  }

  const averageContents = averageRow.contents as Row;
  const contentsTitles = averageContents.map((c: Row) =>
    c.skillTextCode
      ? `${t(c.contentTextCode)}: ${t(c.skillTextCode)}`
      : c.contentTextCode
  );

  const connectedStudentsRows = studentsRows.filter(
    (s: Row) => s.firstName && s.contents.length > 0
  );

  const studentsWithDifficulties = contentsTitles.map((title: string) => {
    const listOfStudentsWithDifficulties = connectedStudentsRows
      .filter((s: Row) => {
        const contentMatches = s.contents.find((c: Row) => {
          const contentTitle = c.skillTextCode
            ? `${t(c.contentTextCode)}: ${t(c.skillTextCode)}`
            : t(c.contentTextCode);
          return contentTitle === title;
        });
        return contentMatches && contentMatches.score < 4;
      })
      .map((s: Row) => `${s.firstName} ${s.lastName}`);

    return {
      subtitle: title,
      students: listOfStudentsWithDifficulties,
    };
  });

  return studentsWithDifficulties;
};

export const getPrimaryStudentsWithDifficulties = (
  studentsRows: Row[],
  averageRow: Row | undefined,
  t: TFunction<'translation', undefined, 'translation'>
): StudentsWithDifficulties[] => {
  if (!studentsRows || !averageRow) {
    return [];
  }

  const connectedStudentsRows = studentsRows.filter(
    (s: Row) => s.firstName && s.bubbles.length > 0
  );

  const bubbles = {};

  connectedStudentsRows.forEach((student) => {
    const studentName = `${student.firstName} ${student.lastName}`;
    student.bubbles.forEach((bubble: any) => {
      if (!bubble.hasDifficulty) return;
      const bubbleName = t(bubble.bubbleTextCode);
      if (bubbleName in bubbles) bubbles[bubbleName].students.push(studentName);
      else {
        bubbles[bubbleName] = {
          students: [studentName],
          id: bubble.readableId,
        };
      }
    });
  });

  const studentsWithDifficulties = Object.keys(bubbles).map((bubble) => ({
    subtitle: bubble,
    students: bubbles[bubble].students,
    id: bubbles[bubble].id,
  }));

  return studentsWithDifficulties;
};
