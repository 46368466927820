import { createContext, ReactNode, useContext } from 'react';

import { CurrentMaterialsState } from '../views/current-materials/current-materials.types';
import useCurrentMaterialsReducer from '../views/current-materials/hooks/use-current-materials-reducer';

const CurrentMaterialsContext = createContext<CurrentMaterialsState>(
  {} as CurrentMaterialsState
);

const CurrentMaterialsProvider = ({
  children,
}: {
  children: ReactNode;
}): ReactNode => {
  const state = useCurrentMaterialsReducer();

  return (
    <CurrentMaterialsContext.Provider value={state}>
      {children}
    </CurrentMaterialsContext.Provider>
  );
};

const useCurrentMaterialsContext = (): CurrentMaterialsState => {
  const context = useContext(CurrentMaterialsContext);

  if (context === undefined) {
    throw new Error(
      'useCurrentMaterialsContext must be used within a CurrentMaterialsProvider'
    );
  }

  return context;
};

export { CurrentMaterialsProvider, useCurrentMaterialsContext };
