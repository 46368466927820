import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

import {
  Button,
  Column,
  EmptyState,
  TableComponent,
  ToggleButton,
  Typography,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import { EmptyClassroom } from '@innovamat/glimmer-assets';
import type { Teacher } from '@innovamat/glow-api-client';

import { useTeacherUpdateReportsPreferenceMutation } from '../../../../hooks/use-teacher-update-reports-preference';
import { useUnlinkTeacher } from '../../../../hooks/use-unlink-teacher';

import { EllipsisCell } from '../../../../components/common-table-components/ellipsis-cell';
import { StatusCell } from '../../../../components/common-table-components/status-cell';
import { tableStyles } from '../../../../components/common-table-components/table-styles';

import { UnlinkTeacherModal } from '../unlink-teacher-confirm-modal';

import { ActionsCell } from './classroom-teachers-table.actions-cell';

// - use snack for errors

type ClassroomTeachersProps = {
  classroomId: string;
  classroomName: string;
  isDisabled?: boolean;
  isPlatformEnabled: boolean;
  organizationId: string;
  teachers: Teacher[];
  onAddTeachers: () => void;
};

const ClassroomTeachersTable = ({
  classroomId,
  classroomName,
  isDisabled = false,
  isPlatformEnabled,
  organizationId,
  teachers,
  onAddTeachers,
}: ClassroomTeachersProps): JSX.Element => {
  const [isUnlinkTeacherModalOpen, setIsUnlinkTeacherModalOpen] =
    useState(false);
  const [teacherToUnlink, setTeacherToUnlink] = useState<Teacher>();

  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const theme = useGlimmerTheme();

  const handleUpdateReportPreference =
    useTeacherUpdateReportsPreferenceMutation({
      classroomId,
      isPlatformEnabled,
      organizationId,
      queryClient,
    });

  const handleLeaveClass = (teacher: Teacher): void => {
    setIsUnlinkTeacherModalOpen(true);
    setTeacherToUnlink(teacher);
  };

  const { isPending: isTeacherUnlinking, handleUnlinkTeacher } =
    useUnlinkTeacher({
      isPlatformEnabled,
      organizationId,
      onSuccess: () => setIsUnlinkTeacherModalOpen(false),
    });

  const columns: Column<Teacher>[] = useMemo(
    () =>
      [
        {
          id: 'firstName',
          label: t('common.firstName'),
          width: 150,
          minWidth: '150px',
          render: (_, teacher) => {
            return (
              <Typography.Subtitle2>
                <EllipsisCell
                  text={teacher?.firstName ? teacher.firstName : '-'}
                />
              </Typography.Subtitle2>
            );
          },
        },
        {
          id: 'lastName',
          label: t('common.lastName'),
          width: 150,
          minWidth: '150px',
          render: (_, teacher) => {
            return (
              <Typography.Subtitle2>
                <EllipsisCell
                  text={teacher?.lastName ? teacher.lastName : '-'}
                />
              </Typography.Subtitle2>
            );
          },
        },
        {
          id: 'email',
          label: t('common.email'),
          width: 300,
          minWidth: '300px',
          render: (_, teacher) => {
            return <EllipsisCell text={teacher?.email ? teacher.email : '-'} />;
          },
        },
        {
          id: 'reportsEnabled',
          label: t('teachers.receiveReports'),
          width: 100,
          minWidth: '100px',
          render: (_, teacher) => {
            return (
              <div style={{ display: 'flex', alignSelf: 'center' }}>
                <ToggleButton
                  disabled={!teacher?.firstName && !teacher?.lastName}
                  aria-label="receive reports toggle button"
                  isActive={!!teacher?.reportsEnabled}
                  onToggle={(isChecked) =>
                    handleUpdateReportPreference(isChecked, teacher.id)
                  }
                />
              </div>
            );
          },
        },
        {
          id: 'lastLogin',
          label: t('common.status'),
          width: 150,
          minWidth: '150px',
          render: (_, teacher) => {
            return (
              <StatusCell
                expiresAt={teacher.expiresAt}
                isInvitation={teacher.isInvitation}
                isSchoolInvitation={teacher.isSchoolInvitation}
                lastLogin={teacher.lastLogin}
              />
            );
          },
        },
        {
          id: 'actions',
          width: 70,
          minWidth: '70px',
          sticky: true,
          stickyPosition: 'right',
          render: (_, teacher) => {
            return (
              <ActionsCell
                classroomId={classroomId}
                isPlatformEnabled={isPlatformEnabled}
                organizationId={organizationId}
                teacher={teacher}
                handleLeaveClass={handleLeaveClass}
              />
            );
          },
        },
      ] satisfies Column<Teacher>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teachers]
  );

  const hasTeachers = teachers.length > 0;

  return hasTeachers ? (
    <>
      <TableComponent
        isDisabled={isDisabled}
        backgroundColor="transparent"
        columns={columns}
        id="classroom-teachers-table"
        key={`teachers-table-${classroomId}`}
        rows={teachers}
        sx={tableStyles(theme)}
      />
      {isUnlinkTeacherModalOpen && (
        <UnlinkTeacherModal
          isLoading={isTeacherUnlinking}
          teacherName={teacherToUnlink?.firstName || ''}
          classroomName={classroomName}
          onConfirm={() =>
            handleUnlinkTeacher({ classroomId, teacher: teacherToUnlink! })
          }
          onCancel={() => setIsUnlinkTeacherModalOpen(false)}
        />
      )}
    </>
  ) : (
    <EmptyState
      Image={EmptyClassroom}
      title={t('teachers.emptyState.title')}
      subtitle={t('teachers.emptyState.subtitle')}
      action={
        <Button onClick={onAddTeachers}>
          {t('teachers.buttons.addTeachers')}
        </Button>
      }
    />
  );
};

export { ClassroomTeachersTable };
