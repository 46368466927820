import { Organization } from '@innovamat/glow-api-client';

// TODO: When ready use core-utilities rootRegions object
const regions = {
  SPAIN: 'ES',
  US: 'US',
} as const;

function checkIsSpanishOrganization(
  organization: Organization | undefined
): boolean {
  if (!organization) {
    return false;
  }

  return organization.region === regions.SPAIN;
}

function checkIfIsUSOrganization(
  organization: Organization | undefined
): boolean {
  if (!organization) {
    return false;
  }

  const region = organization.region?.replace('-LIT', '');

  return region === regions.US;
}

export { checkIfIsUSOrganization, checkIsSpanishOrganization };
