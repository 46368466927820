import { CourseTitle, Wrapper, TextWrapper } from '../join-to-classroom.styled';
import { Typography } from '@innovamat/glimmer-components';
import { IconsFaces } from '@innovamat/glimmer-assets';
import { useTranslation } from 'react-i18next';

export function SuccessStep(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper>
        <IconsFaces />
        <TextWrapper>
          <Typography.H4>
            {t('classrooms.steps.success.subtitle')}
          </Typography.H4>
          <CourseTitle>{t('classrooms.steps.success.description')}</CourseTitle>
        </TextWrapper>
      </Wrapper>
    </>
  );
}
