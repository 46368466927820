import { InnerHtml } from '@innovamat/innova-components';
import { mapTextDimensions } from '../utils/htmlParser/dimensions';
import { LatexElement, mapTextForLatex } from '../utils/htmlParser/latex';

type Props = {
  text: LatexElement | undefined;
};

export function InnerHtmlParser({ text }: Props) {
  return <InnerHtml text={mapTextForLatex(mapTextDimensions(text))} />;
}
