import { useState, useEffect, FunctionComponent } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

type ChartType =
  | 'area'
  | 'line'
  | 'bar'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap';

interface ChartProps {
  options: ApexOptions;
  series: {
    name: string;
    data: number[];
  }[];
  type: ChartType; // Chart type (e.g., 'line', 'bar', etc.)
  width: number | string; // Chart width, can be number or string (e.g., '100%', 500)
  height: string; // Chart height, as a string (e.g., '400px')
  id: string; // Chart DOM element ID
  'data-testid'?: string; // Optional 'data-testid' prop for testing
}

const ApexChartWrapper: FunctionComponent<ChartProps> = ({
  options,
  series,
  type,
  width,
  height,
  id,
  'data-testid': dataTestId,
}) => {
  const [ApexChartsComponent, setApexChartsComponent] = useState<
    typeof ReactApexChart | null
  >(null);

  useEffect(() => {
    // Dynamic import for react-apexcharts
    import('react-apexcharts').then((module) => {
      setApexChartsComponent(() => module.default);
    });
  }, []);

  // Fallback for React SPA or client-side rendering
  if (!ApexChartsComponent) return <div>Loading...</div>;

  // Using the dynamically imported ApexCharts component
  return (
    <ApexChartsComponent
      options={options}
      series={series}
      type={type}
      width={width}
      height={height}
      id={id}
      data-testid={dataTestId} // Properly pass 'data-testid' if it's provided
    />
  );
};

export default ApexChartWrapper;
