import { Row } from '@innovamat/glimmer-components';
import {
  getAppletReadableName,
  getTimeString,
  isNullOrUndefined,
} from './common';
import { getScoreColor } from './getScoreColor';
import { TimeSchedule } from '../views/weekly-results/weekly-results';
import { Stage } from '@innovamat/radiance-utils';

type TrainingData = Record<string, number | string>;
type Time = Record<string, string>;

type RowContentData = Record<
  string,
  | {
      t: 's' | 'n';
      v: string | number;
      s: { fill: { fgColor: { rgb: string } } };
    }
  | number
  | null
>;

type RowData = Record<string, string>;

type AllKeys = RowData & RowContentData & TrainingData;

export const transformStudentWeeklyData = (
  studentData: Row[],
  averageData: Row,
  t: (key: string) => string,
  educationalStage: Stage,
  practicePeriod: TimeSchedule
): Array<Record<string, string>> => {
  const isSecondary = educationalStage === Stage.SECUNDARIA;
  const isPrimary = educationalStage === Stage.PRIMARIA;
  const stage = isSecondary ? 'secondary' : 'primary';
  const firstNameKey = t('reports.name.title.column');
  const lastNameKey = t('reports.surname.title.column');
  const averageScoreKey = t(`reports.${stage}.score.title.column`);
  const statementsCompletedKey = t('reports.completedStatements.title.column');
  const appletsCompletedKey = t('reports.appletsDone.title.column');
  const timeSpentKey = t('reports.time.title.column');
  const timeSpentHomeKey = t(`reports.${stage}.filter.button.outSchoolTime`);
  const timeSpentSchoolKey = t(`reports.${stage}.filter.button.schoolTime`);

  return studentData.map((student) => {
    const time: Time = {};
    if (practicePeriod === 'all') {
      time[timeSpentKey] = getTimeString(student.timeSpentAll);
      time[timeSpentHomeKey] = getTimeString(student.timeSpentHome);
      time[timeSpentSchoolKey] = getTimeString(student.timeSpentSchool);
    } else if (practicePeriod === 'home') {
      time[timeSpentHomeKey] = getTimeString(student.timeSpentHome);
    } else {
      time[timeSpentSchoolKey] = getTimeString(student.timeSpentSchool);
    }

    const rowData = {
      [firstNameKey]: student.firstName,
      [lastNameKey]: student.lastName,
      [statementsCompletedKey]: isNullOrUndefined(student.statementsCompleted)
        ? ''
        : isSecondary
        ? `${student.statementsCompleted}/${student.statementsServed}`
        : student.statementsCompleted,
      [appletsCompletedKey]: isNullOrUndefined(student.appletsCompleted)
        ? ''
        : isSecondary
        ? `${student.appletsCompleted}/${student.appletsServed}`
        : student.appletsCompleted,
      ...time,
      [averageScoreKey]: {
        t: isNullOrUndefined(student.averageScore) ? 's' : 'n',
        v: isNullOrUndefined(student.averageScore)
          ? ''
          : parseFloat(student.averageScore?.toFixed(1)),
        s: {
          fill: {
            fgColor: {
              rgb: getScoreColor(student.averageScore).replace('#', ''),
            },
          },
        },
      },
    };

    const rowContentData: RowContentData = {};
    averageData.contents?.forEach((bc: any) => {
      const iterator = `${bc.contentTextCode}${bc.skillTextCode}`;
      const averageScore = student[iterator]?.average;
      const key = bc.skillTextCode
        ? `${t(bc.contentTextCode)}: ${t(bc.skillTextCode)}`
        : t(bc.contentTextCode);
      rowContentData[key] = {
        t: isNullOrUndefined(averageScore) ? 's' : 'n',
        v: isNullOrUndefined(averageScore)
          ? ''
          : parseFloat(averageScore.toFixed(1)),
        s: {
          fill: {
            fgColor: {
              rgb: getScoreColor(averageScore).replace('#', ''),
            },
          },
        },
      };
      bc?.applets.forEach((applet: any) => {
        const appletName = getAppletReadableName(
          applet!.data?.name,
          applet!.sceneName,
          applet!.pack
        );
        const studentApplet = student[iterator]?.applets?.find(
          (a: any) => a.uuid === applet.uuid
        );
        rowContentData[appletName] = studentApplet
          ? parseFloat(studentApplet.score?.toFixed(1))
          : null;
      });
    });

    const rowTrainingData: TrainingData = {};

    if (isPrimary) {
      const trainingDedicationKey = `${t(
        'reports.weeklyPractice.training.title'
      )}: ${t('reports.weeklyPractice.training.dedication')}`;

      const trainingAverageScoreKey = `${t(
        'reports.weeklyPractice.training.title'
      )}: ${t('reports.weeklyPractice.training.meanScore')}`;

      rowTrainingData[trainingDedicationKey] = isNullOrUndefined(
        student.trainingZone.timeSpent
      )
        ? ''
        : getTimeString(student.trainingZone.timeSpent);

      rowTrainingData[trainingAverageScoreKey] = isNullOrUndefined(
        student.trainingZone.averageScore
      )
        ? ''
        : parseFloat(student.trainingZone.averageScore.toFixed(1));
    }

    const allKeys: AllKeys = {
      ...rowData,
      ...rowContentData,
      ...rowTrainingData,
    };
    const modifiedKeys = Object.keys(allKeys).reduce(
      (newObject, currentKey) => {
        const newKey = `${currentKey}HEADER`;
        newObject[newKey] = allKeys[currentKey];
        return newObject;
      },
      {} as Record<string, string>
    );
    return modifiedKeys;
  });
};
