import { NAV_TYPE, useNavigation } from '@innovamat/ga-features';
import { useSitemapNavigation } from '../../hooks/use-sitemap-navigation';
import { Header } from '../layout/header';
import { MainLayout } from '../layout/main-layout';
import { ContentPageRouter } from './content-page-router';
import { SessionDoneProviderWrapper } from './session-done-provider';
import { NavBreadcrumb } from './nav-breadcrumb';
import { Navigate, useParams } from 'react-router-domv6';

export function CourseNavigationWrapper(): JSX.Element {
  const { menu, courseId, submenu } = useParams();
  const { goBackOrCourseSelector } = useNavigation();

  const {
    actualCourse,
    pageName,
    isPending,
    sitemap,
    sitemapTree,
    getFirstSitemapRoute,
    isPageIdAsSubmenu,
  } = useSitemapNavigation();

  if (!menu || (!sitemap && !isPending && !isPageIdAsSubmenu)) {
    const firstSitemapRoute = getFirstSitemapRoute();
    const route = `/${NAV_TYPE.Course}/${courseId}${firstSitemapRoute}/page`;
    return <Navigate to={route} replace />;
  }

  const title = sitemap?.value || '';
  const pageId = isPageIdAsSubmenu
    ? submenu
    : sitemap?.fullPageId || sitemap?.demoPageId;

  return (
    <>
      <Header
        sitemapTree={sitemapTree}
        leftActions={
          <NavBreadcrumb
            fromPageName={pageName}
            pageName={actualCourse?.name || ''}
            onGoBack={goBackOrCourseSelector}
          />
        }
        hasBackButton
        middleText={pageName}
      />
      <MainLayout sitemapTree={sitemapTree}>
        <SessionDoneProviderWrapper>
          <ContentPageRouter title={title} pageId={pageId} />
        </SessionDoneProviderWrapper>
      </MainLayout>
    </>
  );
}
