import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@innovamat/glimmer-components';
import { useEventLogging } from '@innovamat/event-logging';
import { useEnvVariables } from '@innovamat/ga-features';

import { DIGITAL_PRACTICE_ACCESS } from '../../../../../hooks/use-put-classroom';
import type { StudentInfo } from '../../hooks/use-parent-launcher';
import { Container } from '../parent-video';
import {
  Background,
  CardContainer,
  CardInfo,
  CardInfoItemWrapper,
  CardInfoRow,
  InfoItemText,
  InfoItemTooltip,
  SubtleCaptionText,
} from './student-info.styled';

type CardInfoItemProps = {
  title: string;
  value: string;
};

function CardInfoItem({ title, value }: CardInfoItemProps): JSX.Element {
  return (
    <CardInfoItemWrapper>
      <SubtleCaptionText>{title}</SubtleCaptionText>
      <InfoItemTooltip content={value}>
        <InfoItemText>{value}</InfoItemText>
      </InfoItemTooltip>
    </CardInfoItemWrapper>
  );
}

type StudentInfoCardProps = {
  student: StudentInfo;
  onNoAccessClick: (studentName: string) => void;
};

function StudentInfoCard({
  student,
  onNoAccessClick,
}: StudentInfoCardProps): JSX.Element {
  const { t } = useTranslation();
  const { setEventData } = useEventLogging();
  const { WEBAPP } = useEnvVariables();

  const {
    classroomId,
    classroomName,
    courseName,
    digitalPracticeAccess,
    fullName,
    studentId,
    teachers,
  } = student;

  const studentSessionAccess = (): void => {
    setEventData('parent_launcher_student_session_access', {
      student_uuid: studentId,
      classroom_uuid: classroomId,
    });
    window.location.href = WEBAPP;
  };

  const handleButtonClick = (): void => {
    if (digitalPracticeAccess === DIGITAL_PRACTICE_ACCESS.NO_ACCESS) {
      onNoAccessClick(fullName);
      return;
    }

    studentSessionAccess();
  };

  return (
    <CardContainer>
      <CardInfo>
        <Typography.Subtitle1>{fullName}</Typography.Subtitle1>
        <CardInfoRow>
          <CardInfoItem title={t('common.course')} value={courseName} />
          <CardInfoItem title={t('common.classroom')} value={classroomName} />
          <CardInfoItem title={t('common.parent')} value={teachers} />
        </CardInfoRow>
      </CardInfo>
      <Button fill onClick={handleButtonClick}>
        {t('parent-launcher.students.card.button')}
      </Button>
    </CardContainer>
  );
}

type StudentsInfoProps = {
  students: StudentInfo[];
  onNoAccessClick: (studentName: string) => void;
};

function StudentsInfo({
  students,
  onNoAccessClick,
}: StudentsInfoProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Container>
      <Typography.H3>{t('parent-launcher.students.title')}</Typography.H3>
      <Background>
        {students.length > 0 &&
          students.map((student, index) => (
            <StudentInfoCard
              key={index}
              student={student}
              onNoAccessClick={onNoAccessClick}
            />
          ))}

        {students.length === 0 && (
          <CardContainer>
            <CardInfo>
              <Typography.Subtitle1>
                {t('parent-launcher.students.noStudents')}
              </Typography.Subtitle1>
            </CardInfo>
          </CardContainer>
        )}
      </Background>
    </Container>
  );
}

export { StudentsInfo, StudentInfoCard };
