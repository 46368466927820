export function PlayButton({ onClick }: { onClick: () => void }) {
  return (
    <button
      onClick={onClick}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
      }}
      aria-label="Play Button"
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.7"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM12.5 21.8689L21.5 16.8384C22.1667 16.4658 22.1667 15.5342 21.5 15.1616L12.5 10.1311C11.8333 9.75852 11 10.2243 11 10.9696V21.0304C11 21.7757 11.8333 22.2415 12.5 21.8689Z"
          fill="#DCE5E3"
        />
      </svg>
    </button>
  );
}
