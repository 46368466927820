import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Typography } from '../Typography';
import { IconButton } from '../IconButton';
import { EllipsisText } from '../ellipsis-text';

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
`;

const stickyStyle = css`
  position: sticky;
  top: 0rem;
`;

const relativeStyle = css`
  position: relative;
`;

const Container = styled.div<{ isSticky: boolean; maxWidth?: string }>`
  padding-top: 1rem;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};

  ${({ isSticky }) => (isSticky ? stickyStyle : relativeStyle)}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-width: 0;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ActionsDivider = styled.div`
  width: 1px;
  height: 1.25rem;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.border['border-subtle'].value};
  margin: 0 1rem;
`;

const Underline = styled.div`
  border-bottom: 1px solid;
  border-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

const BottomWhiteSpace = styled.div`
  width: 100%;
  height: 1rem;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};
`;

const SuperTitle = styled(Typography.SuperTitle)`
  padding-top: 0.5rem;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const Title = styled(Typography.H3)`
  padding-bottom: 0.5rem;
`;

type Props = {
  closeButtonTooltip?: string;
  isNextDisabled?: boolean;
  isPreviousDisabled?: boolean;
  isSticky?: boolean;
  maxWidth?: string;
  nextButtonTooltip?: string;
  previousButtonTooltip?: string;
  superTitle?: string;
  title: string;
  onCloseDrawer: () => void;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
};

const DrawerHeader = ({
  closeButtonTooltip,
  isNextDisabled,
  isPreviousDisabled,
  isSticky = true,
  maxWidth,
  nextButtonTooltip,
  previousButtonTooltip,
  superTitle,
  title,
  onCloseDrawer,
  onClickNext,
  onClickPrevious,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container isSticky={isSticky} maxWidth={maxWidth}>
      <ContentWrapper>
        <TextWrapper>
          {superTitle && (
            <SuperTitle>
              <EllipsisText text={superTitle} />
            </SuperTitle>
          )}
          <Title>{title}</Title>
        </TextWrapper>

        <ActionsWrapper>
          {onClickPrevious && (
            <IconButton
              aria-label="Previous"
              icon="PreviousIcon"
              onClick={() => onClickPrevious?.()}
              size="M"
              state={isPreviousDisabled ? 'disabled' : undefined}
              tooltipText={
                previousButtonTooltip
                  ? previousButtonTooltip
                  : t('navigation.previous')!
              }
            />
          )}

          {onClickNext && (
            <IconButton
              aria-label="Next"
              icon="NextIcon"
              onClick={() => onClickNext?.()}
              size="M"
              state={isNextDisabled ? 'disabled' : undefined}
              tooltipText={
                nextButtonTooltip ? nextButtonTooltip : t('navigation.next')!
              }
            />
          )}

          {(onClickPrevious || onClickNext) && <ActionsDivider />}

          <IconButton
            aria-label="Close"
            size="M"
            tooltipText={
              closeButtonTooltip ? closeButtonTooltip : t('common.close')!
            }
            onClick={onCloseDrawer}
            icon="CloseIcon"
          />
        </ActionsWrapper>
      </ContentWrapper>
      <Underline />
      <BottomWhiteSpace />
    </Container>
  );
};

export { DrawerHeader };
