import { Classroom, ClassroomBody } from '@innovamat/glow-api-client';
import { useJoinToClassroom } from '../join-to-classroom-context';

import {
  ClassroomsPerCourse,
  CourseBlocks,
  CourseTitle,
  Container,
} from '../join-to-classroom.styled';
import { SelectStepSkeleton } from './select-classrooms-step.skeleton';
import { AnnouncementPanel } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ClassSelectCard } from '../components/class-select-card';
import { SelectClassroomEmptyState } from '../components/select-classrooms-empty-state';

export function FirstTimeSelectClassroomsStep(): JSX.Element {
  const { t } = useTranslation();
  const {
    isLoadingClassrooms,
    courses,
    getClassroomsByCourse,
    handleClick,
    getCardStatus,
    classroomsQueryError,
    selectedCourses,
    openEditModal,
  } = useJoinToClassroom();

  const coursesToRender =
    selectedCourses.length > 0
      ? courses.filter((course) => selectedCourses.includes(course.id))
      : undefined;

  return (
    <Container>
      {isLoadingClassrooms && <SelectStepSkeleton />}
      {classroomsQueryError && (
        <AnnouncementPanel
          text={t('classrooms.request.error')}
          type="error"
          canClose={false}
        />
      )}

      {coursesToRender &&
        coursesToRender.map((course: any) => (
          <CourseBlocks key={course.id}>
            <CourseTitle>{course.name}</CourseTitle>
            <ClassroomsPerCourse>
              {getClassroomsByCourse(course.id).map(
                (classroom: Classroom | ClassroomBody) => (
                  <ClassSelectCard
                    key={`card-${classroom.id}`}
                    classroom={classroom}
                    onClick={() => handleClick(classroom)}
                    onClickEdit={() => openEditModal(classroom)}
                    status={getCardStatus(classroom.id)}
                  />
                )
              )}
            </ClassroomsPerCourse>
          </CourseBlocks>
        ))}

      {!coursesToRender && <SelectClassroomEmptyState />}
    </Container>
  );
}

export function SelectClassroomsStep(): JSX.Element {
  const { t } = useTranslation();
  const {
    isLoadingClassrooms,
    courses,
    getClassroomsByCourse,
    handleClick,
    getCardStatus,
    classroomsQueryError,
    selectedCourses,
    openEditModal,
  } = useJoinToClassroom();

  const coursesToRender =
    selectedCourses.length > 0
      ? courses.filter((course) => selectedCourses.includes(course.id))
      : courses;

  const [isFakeLoading, setIsFakeLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsFakeLoading(false);
    }, 500);
  }, []);

  if (isLoadingClassrooms || isFakeLoading) {
    return (
      <Container>
        <SelectStepSkeleton />
      </Container>
    );
  }

  return (
    <Container>
      {classroomsQueryError && (
        <AnnouncementPanel
          text={t('classrooms.request.error')}
          type="error"
          canClose={false}
        />
      )}

      {coursesToRender &&
        coursesToRender.map((course: any) => (
          <CourseBlocks key={course.id}>
            <CourseTitle>{course.name}</CourseTitle>
            <ClassroomsPerCourse>
              {getClassroomsByCourse(course.id).map(
                (classroom: Classroom | ClassroomBody) => (
                  <ClassSelectCard
                    key={`card-${classroom.id}`}
                    classroom={classroom}
                    onClick={() => handleClick(classroom)}
                    onClickEdit={() => openEditModal(classroom)}
                    status={getCardStatus(classroom.id)}
                  />
                )
              )}
            </ClassroomsPerCourse>
          </CourseBlocks>
        ))}
    </Container>
  );
}
