import { useEventListener } from './useEventListener';

type Options = {
  [x: string]: () => void;
};

export function useKeyPress(options: Options, prevent?: boolean) {
  useEventListener('keydown', (event) => {
    const { key } = event as KeyboardEvent;
    if (prevent) return;
    options[key]?.();
  });
}
