import { IconButton } from '@innovamat/glimmer-components';
import { IconType } from '@innovamat/glimmer-icons';

type CustomArrowProps = {
  className: string;
  onClick: () => void;
  icon: IconType;
  dataTestId?: string;
};

export function CustomArrow({
  className,
  onClick,
  icon,
  dataTestId,
}: CustomArrowProps) {
  return (
    <IconButton
      className={className}
      onClick={onClick}
      icon={icon}
      isRounded={true}
      dataTestId={dataTestId}
    />
  );
}
