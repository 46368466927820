import { Region } from '@innovamat/localization';
import { compressQuery } from '@innovamat/radiance-utils';
import { StandardContent } from '@innovamat/resource-viewer';
import { GraphQLClient } from 'graphql-request';
import { mapSmartGuide } from '../mappers/mapSmartGuide';
import { Printable } from '../types/Materials';
import { PreprModel } from '../types/SmartGuide';
import { Stage } from '../types/Stage';
import { getSmartGuideDoc, getSmartGuidePartDoc } from './graphql';

const graphEndpoint =
  'https://graphql.prepr.io/4e97a14cc4ef4b754d68032281fe3f506e97c09765b011d6bae5d376c4f81ede';

const getParts = async (client: any, data: any, locales: string[]) => {
  const dataParts = data.partes || data.parte;
  return Promise.all(
    dataParts.map(async (partData: any) => {
      const model = partData.__typename;
      const doc = compressQuery(getSmartGuidePartDoc(model));

      const partsResponse: any = await client.request(doc, {
        locales: locales,
        id: partData._id,
      });

      return Object.values(partsResponse)[0];
    })
  );
};

export const getSmartGuide = async (
  locales: string[],
  id: string,
  model: PreprModel,
  printables: Printable[],
  assessables?: string[],
  userId?: string,
  standardContent?: StandardContent[],
  mathPractices?: string[],
  region?: Region
) => {
  const graphQLClient = new GraphQLClient(graphEndpoint, {
    headers: {
      'Prepr-Customer-Id': `${userId}`,
    },
  });

  const doc = compressQuery(getSmartGuideDoc(model));

  const smartGuideResponse: any = await graphQLClient.request(doc, {
    locales: locales,
    id: id,
  });

  const data: any = Object.values(smartGuideResponse)[0];

  const parts = await getParts(graphQLClient, data, locales);

  const smartGuideWithParts = {
    ...data,
    partes: parts,
  };

  delete smartGuideWithParts.parte;

  if (!smartGuideWithParts) {
    throw new Error(`SmartGuide not found with id ${id} and model ${model}`);
  }

  const stageByModel = {
    SmartGuideTaller: Stage.EarlyYear,
    SmartGuidePrimaria: Stage.Primary,
    SmartGuideSecundaria: Stage.Secondary,
    SmartGuideOpen: Stage.Secondary,
  };

  const stage = stageByModel[model as keyof typeof stageByModel];

  const smartGuide = mapSmartGuide(
    smartGuideWithParts,
    stage,
    model,
    printables,
    assessables,
    standardContent,
    mathPractices,
    region
  );

  return smartGuide;
};
