import { useState, useEffect, useRef } from 'react';

function useStickyTable(): {
  isStickyTable: boolean;
  headerRef: React.MutableRefObject<any>;
} {
  const [isStickyTable, setIsStickyTable] = useState(false);
  const headerRef = useRef(null);
  useEffect(() => {
    const headerElement = headerRef.current;
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    };
    const observer = new IntersectionObserver(([entry]) => {
      setIsStickyTable(!entry.isIntersecting);
    }, observerOptions);
    if (headerElement) {
      observer.observe(headerElement);
    }
    return () => {
      if (headerElement) {
        observer.unobserve(headerElement);
      }
    };
  }, []);
  return { isStickyTable, headerRef };
}
export default useStickyTable;
