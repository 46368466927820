import { Region } from '@innovamat/localization';
import { Moments } from '../types/moments';
import { getData } from './api';
import { getItemsFromLang } from './common';

export const mapMoments = async (
  moments: any,
  locale: string,
  region: Region
): Promise<Moments> => {
  const momentsIds = moments.items.flatMap(
    (item: any) => item.items.moment.items
  );

  const mappedMoments = await Promise.all(
    momentsIds.map((c: any) =>
      getData(c.id, 'items{moment_content{cdn_files}}')
    )
  );

  return {
    ...moments,
    items: mappedMoments.map((c: any) => {
      const moment = getItemsFromLang(c.items, locale, region);
      return {
        id: c.id,
        ...moment,
      };
    }),
  };
};
