import { SkeletonWrapper } from '../Spaces.styles';
import { SpacesLoader } from '../spaces-loader';

type SpacePlaceHolderParams = {
  numLoaders: number;
};

function SpacesPlaceholder({ numLoaders }: SpacePlaceHolderParams) {
  return (
    <SkeletonWrapper>
      {Array.from(Array(numLoaders).keys()).map((index) => (
        <SpacesLoader height={383} width={423} key={index} />
      ))}
    </SkeletonWrapper>
  );
}

export default SpacesPlaceholder;
