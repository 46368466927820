import { Row } from '@innovamat/glimmer-components';
import { getTimeString, isNullOrUndefined } from './common';
import { getScoreColor } from './getScoreColor';
import { dates, truncateWords } from '@innovamat/radiance-utils';

export const transformStudentGeneralData = (
  studentData: Row[],
  averageData: Row,
  t: (key: string) => string
): Row[] => {
  const firstNameKey = t('reports.name.title.column');
  const lastNameKey = t('reports.surname.title.column');
  const averageScoreKey = truncateWords(
    t('reports.score.course.title.column'),
    20
  );
  const appletsWorkedOnKey = t('reports.appletsDone.title.column');
  const timeSpentAllKey = t('reports.time.title.column');

  return studentData.map((student) => {
    const rowData = {
      [firstNameKey]: student.firstName,
      [lastNameKey]: student.lastName,
      [appletsWorkedOnKey]: `${student.appletsCompleted}`,
      [timeSpentAllKey]: getTimeString(student.timeSpentAll, 'hours'),
      [averageScoreKey]: {
        t: isNullOrUndefined(student.averageScore) ? 's' : 'n',
        v: isNullOrUndefined(student.averageScore)
          ? ''
          : parseFloat(student.averageScore?.toFixed(1)),
        s: {
          fill: {
            fgColor: {
              rgb: getScoreColor(student.averageScore).replace('#', ''),
            },
          },
        },
      },
    };
    averageData.blocks?.forEach((block: any) => {
      const text = block.blockTitle.text;
      const key = t(text);
      const score = student[block.blockId]?.average;
      rowData[key] = {
        t: isNullOrUndefined(score) ? 's' : 'n',
        v: isNullOrUndefined(score) ? '' : parseFloat(score.toFixed(1)),
        s: {
          fill: {
            fgColor: {
              rgb: getScoreColor(score).replace('#', ''),
            },
          },
        },
      };
      block.contents?.forEach((content: any) => {
        const { contentTextCode, contentTitle } = content;
        const text = contentTextCode || contentTitle.text;
        const title = t(text);
        const studentApplet = student[block.blockId]?.contents?.find(
          (c: any) => c.contentId === content.contentId
        );
        rowData[title] = studentApplet
          ? parseFloat(studentApplet.score?.toFixed(1))
          : null;
      });
    });

    const modifiedKeys = Object.keys(rowData).reduce(
      (newObject, currentKey) => {
        const newKey = `${currentKey}HEADER`;
        newObject[newKey] = rowData[currentKey];
        return newObject;
      },
      {}
    );
    return modifiedKeys;
  });
};

export const transformStudentGeneralWeeklyData = (
  studentData: Row[],
  t: (key: string) => string
): Row[] => {
  const firstNameKey = t('reports.name.title.column');
  const lastNameKey = t('reports.surname.title.column');

  return studentData.map((student) => {
    const rowData = {
      [firstNameKey]: student.firstName,
      [lastNameKey]: student.lastName,
    };

    if (student.weekScores) {
      student.weekScores.forEach((weekScore: any) => {
        const { score, weekStartDate, weekEndDate } = weekScore;
        const key = `${dates.getFormattedDate(
          weekStartDate
        )} - ${dates.getFormattedDate(weekEndDate)}`;
        rowData[key] = {
          t: isNullOrUndefined(score) ? 's' : 'n',
          v: isNullOrUndefined(score) ? '' : parseFloat(score.toFixed(1)),
          s: {
            fill: {
              fgColor: {
                rgb: getScoreColor(score).replace('#', ''),
              },
            },
          },
        };
      });
    }

    const modifiedKeys = Object.keys(rowData).reduce(
      (newObject, currentKey) => {
        const newKey = `${currentKey}HEADER`;
        newObject[newKey] = rowData[currentKey];
        return newObject;
      },
      {}
    );
    return modifiedKeys;
  });
};
