import { Icon, Tooltip, useGlimmerTheme } from '@innovamat/glimmer-components';

type Props = {
  tooltipText: string;
};

function WarningIcon({ tooltipText }: Props): JSX.Element {
  const theme = useGlimmerTheme();

  return (
    <Tooltip content={tooltipText} popperOptions={{ strategy: 'fixed' }}>
      <Icon
        icon="WarningIcon"
        iconColor="white"
        bgColor={theme.tokens.color.alias.cm.icon['icon-error'].value}
        size="M"
        hasStateLayer
      />
    </Tooltip>
  );
}

export default WarningIcon;
