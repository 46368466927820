export const responseMapper = (data: any) => {
  if (data.uuid) return data;
  return {
    ...data,
    resource_blocks: data.resource_blocks.map((rb: any) => ({
      ...rb,
      resources: rb.resources.map((r: any, i: number) => ({
        order: i,
        resource_view: r,
      })),
    })),
  };
};
