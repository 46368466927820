import ContentLoader from 'react-content-loader';

const ShippingAddressSkeleton = () => (
  <ContentLoader
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    height={78}
    speed={2}
    viewBox="0 0 250 78"
    width={250}
  >
    <rect x="0" y="0" rx="8" ry="8" width="250" height="78" />
  </ContentLoader>
);

export { ShippingAddressSkeleton };
