import { snack } from '@innovamat/glimmer-components';
import type { Teacher } from '@innovamat/glow-api-client';
import { useTeachersQuery } from '@innovamat/glow-api-client';
import { useCopyToClipboard } from '@innovamat/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../../../user-management';
import {
  ActionsDropdown,
  CreateItem,
} from '../../../../components/common-table-components/actions-dropdown';
import { useResendInvitation } from '../../../../hooks/use-resend-invitation';

type ActionsCellProps = {
  isDeleteOptionDisabled: boolean;
  isPlatformEnabled: boolean;
  isUpdatePeriod: boolean;
  organizationId: string;
  teacher: Teacher;
  onDeleteTeacher: (teacher: Teacher) => void;
  onEditClassrooms: (teacher: Teacher) => void;
};

function ActionsCell({
  isDeleteOptionDisabled,
  isPlatformEnabled,
  isUpdatePeriod,
  organizationId,
  teacher,
  onDeleteTeacher,
  onEditClassrooms,
}: ActionsCellProps): JSX.Element {
  const { t } = useTranslation();
  const { isRole } = useUser();
  const [_, copy] = useCopyToClipboard();
  const queryClient = useQueryClient();
  const onSuccessAddTeachers = (): void => {
    const queryKey = useTeachersQuery.getKey({
      organizationId,
      isPlatformEnabled,
    });

    queryClient.refetchQueries({ queryKey });
  };

  const { isResendingInvitation, onResendInvitation } = useResendInvitation({
    onSuccessAddTeachers,
  });

  const isInvitation = teacher?.isSchoolInvitation || teacher?.isInvitation;

  const items: CreateItem[] = useMemo(() => {
    const options: CreateItem[] = [
      {
        text: t('manager.table.deleteTeacher'),
        icon: 'DeleteIcon',
        state: isDeleteOptionDisabled ? 'disabled' : 'active',
        onSelectItem: () => onDeleteTeacher(teacher),
      },
    ];

    if (isInvitation) {
      options.push({
        text: t('teachers.resendInvitation'),
        icon: 'MailIcon',
        state: isResendingInvitation ? 'disabled' : 'active',
        onSelectItem: () => onResendInvitation(teacher),
      });
    }

    if (!isUpdatePeriod && !isInvitation) {
      options.push({
        text: t('manager.table.editClassrooms'),
        icon: 'EditIcon',
        state: 'active',
        onSelectItem: () => onEditClassrooms(teacher),
      });
    }

    if (isRole.Admin) {
      options.push({
        icon: 'CopyIcon',
        onSelectItem() {
          copy(teacher?.id || '');
          snack.success(t('manager.table.copiedId'));
        },
        state: 'active',
        text: t('manager.table.copyId'),
      });
    }

    return options;
  }, [
    isDeleteOptionDisabled,
    isInvitation,
    isResendingInvitation,
    onDeleteTeacher,
    onEditClassrooms,
    onResendInvitation,
    t,
    teacher,
  ]);

  return (
    <ActionsDropdown dropdownId="school-teacher-options-button" items={items} />
  );
}

export { ActionsCell };
