import { GA_URL } from './variables';

export function redirectToGa(): void {
  const currentUrl = window.location.href;
  const pattern =
    /https:\/\/(pr-[a-zA-Z0-9]+)\.manager\.pre\.innovamat\.cloud\/?/;

  const match = currentUrl.match(pattern);

  if (match) {
    const newUrl = `https://${match[1]}.app.innovamat.cloud`;
    window.location.replace(newUrl);
  } else {
    window.location.replace(GA_URL);
  }
}
