import { useContext } from 'react';
import { EarlyYearGuideContext } from './EarlyYearGuideProvider';

export default function useEarlyYearGuide() {
  const context = useContext(EarlyYearGuideContext);

  if (context === undefined) {
    throw new Error(
      'useEarlyYearGuide must be used within a EarlyYearGuideProvider'
    );
  }

  return context;
}
