import { Applet } from '../types/applet';
import { ResourceType } from '../types/resourceType';
import checkMissingLocale from '../utils/missingLocales';
import { mapPrintable } from './contentsMapper';
import { Params } from './resourceMapper';

export const appletMapper = ({
  response,
  locale,
  isBloqued = false,
  isStudent = false,
}: Params): Applet => {
  const isNotSession = false;
  const printables = mapPrintable(response, isStudent, isNotSession);

  return {
    title: checkMissingLocale(response.name!),
    description: checkMissingLocale(response.description!),
    id: response.uuid,
    isBloqued,
    type: response.type as ResourceType,
    appletCompleted: false,
    packOrVariation: response.pack!,
    sceneName: response.scene_name!,
    status: response.applet_status,
    language: locale,
    onAppletIsCompleted: undefined,
    payload: undefined,
    printables,
    tags: response.tags,
    thumbnail: response.thumbnail.href,
  };
};
