import {
  NAV_TYPE,
  useCurrentNavigationValues,
  useNavigation,
} from '@innovamat/ga-features';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-domv6';
import { useSitemapNavigation } from './use-sitemap-navigation';

function useRedirectSitemapNotFound(): void {
  const { pathname } = useLocation();
  const { menu } = useParams();
  const { navigateInSameNavType } = useNavigation();
  const { isPageIdAsSubmenu, getAllAvailableRoutes } = useSitemapNavigation();
  const { navType, currentRoute } = useCurrentNavigationValues();

  const allRoutes = getAllAvailableRoutes();

  const navigateToFirstRoute = (): void => {
    if (allRoutes.length > 0) {
      navigateInSameNavType(allRoutes[0] || '/');
    }
  };

  useEffect(() => {
    // If the user is in the admin section, we only want to redirect to the first route the first time
    if (navType === NAV_TYPE.Admin && !menu) {
      navigateToFirstRoute();
      return;
    }

    if (allRoutes.length === 0 || navType === NAV_TYPE.Admin) {
      return;
    }

    if (!allRoutes.includes(currentRoute!) && !isPageIdAsSubmenu) {
      navigateToFirstRoute();
    }
  }, [allRoutes, pathname]);
}

export { useRedirectSitemapNotFound };
