import { Button, Chip, Typography } from '@innovamat/glimmer-components';

import styled from '@emotion/styled';
import { Parent } from '../../../views/classroom-students/utils/render-students-table';
import { useParentsStatus } from '../../../hooks/use-parents-status';

type Props = {
  parents: Parent[];
  onClick: () => void;
  disabled?: boolean;
};

const StatusButton = styled(Button)<{ disabled: boolean }>`
  max-height: auto;
  max-width: 124px;
  text-align: start;

  ${({ disabled }) =>
    disabled &&
    `cursor: default; 
    background-color: transparent !important; 
    &:hover { background-color: inherit }
    `}
`;

const Text = styled(Typography.Caption)<{ disabled: boolean }>`
  margin-top: 2px;
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.tokens.color.alias.cm.text['text-disabled'].value
      : theme.tokens.color.alias.cm.text['text-subtle'].value};
  font-size: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export function ParentsStatusCell({
  parents,
  onClick,
  disabled = false,
}: Props): JSX.Element {
  const { getParentStatusText, getChipType, getChipText } = useParentsStatus({
    parents,
    disabled,
  });

  return (
    <StatusButton
      className="table-button"
      size="M"
      variant="tertiary"
      oneLine
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      <Chip text={getChipText()} type={getChipType()} />
      <Text disabled={disabled}>{getParentStatusText()}</Text>
    </StatusButton>
  );
}
