import { useState } from 'react';
import { Banner, Colors } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import reportImage from './reportImage.png';
import {
  APP_PATHS,
  useNavigation,
  normalizeImgSource,
} from '@innovamat/ga-features';
import { useEventLogging } from '@innovamat/event-logging';
import styled from 'styled-components';

const BannerStyled = styled(Banner)`
  margin-bottom: 16px;
`;

const BannerLastYear = () => {
  const { t } = useTranslation();
  const { navigateInSameNavType } = useNavigation();

  const { setEventData } = useEventLogging();

  const changeToLastReport = (): void => {
    setEventData('report_last_year', {});
    navigateInSameNavType(APP_PATHS.LAST_YEAR_REPORTS_URL);
  };

  const [shouldRender, setShouldRender] = useState(true);

  const closeBanner = () => {
    setShouldRender((prevState) => !prevState);
  };
  return (
    <>
      {shouldRender ? (
        <BannerStyled
          btnText={t('generalProgress.lastYearReport.bannerButton')}
          bgColor={Colors.BRAND}
          imgAlt=""
          imgSrc={normalizeImgSource(reportImage)}
          showBtn={true}
          text={t('generalProgress.lastYearReport.bannerBody')}
          title={t('reports.classroomLastYearReport.title')}
          onBtnClick={() => {
            changeToLastReport();
          }}
          onBtnCloseClick={closeBanner}
          canBeClosed={true}
          fill={true}
        />
      ) : null}
    </>
  );
};

export default BannerLastYear;
