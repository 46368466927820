import { snack } from '@innovamat/glimmer-components';
import {
  Classroom,
  ClassroomBody,
  GlowApiError,
  useDeleteClassroomMutation,
  useUnlinkTeacherMutation,
} from '@innovamat/glow-api-client';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../user-management';
import {
  deleteClassroomFromCache,
  updateClassroomInCache,
} from '../cache-actions';
import { useConfirmationModal } from './use-confirmation-modal';

type ConfirmationProps = {
  classroomName: string;
  classroomId: string;
};

function useManageClassrooms() {
  const { t } = useTranslation();
  const { user } = useUser();
  const [editClassroom, setEditClassroom] = useState<Classroom | undefined>(
    undefined
  );
  const queryClient = useQueryClient();
  const deleteClassroomMutation = useDeleteClassroomMutation();
  const unlinkMutation = useUnlinkTeacherMutation();

  const {
    requestConfirmation,
    isConfirmationOpen,
    confirmationModalProps,
    handleCloseConfirmationModal,
  } = useConfirmationModal();

  const handleDeleteClassWithConfirmation = ({
    classroomName,
    classroomId,
  }: ConfirmationProps) => {
    requestConfirmation({
      title: t('classrooms.deleteConfirmModal.title'),
      message: t('classrooms.deleteConfirmModal.message', {
        name: classroomName,
      }),
      onConfirm: () => handleDeleteClass(classroomId),
      confirmText: t('confirmModal.action.confirmDelete'),
    });
  };

  const handleOnError = (error: GlowApiError): void => {
    if (error) {
      error.response.errors.forEach((e) => {
        if (e.extensions?.response?.['status'] === 409) {
          snack.error(t('classroom.duplicated_name'));
        } else {
          snack.error(
            'Error: ' + JSON.parse(e.extensions?.response.body).detail
          );
        }
      });
    }
  };

  const handleDeleteClass = (classroomId: string) => {
    deleteClassroomMutation.mutate(
      {
        classroomId,
      },
      {
        onSuccess: () => {
          deleteClassroomFromCache({
            queryClient,
            orgId: user?.organizationId!,
            classroomId,
          });
          snack.success(t('classrooms.deleteConfirmModal.toast.success'));
          handleCloseConfirmationModal();
        },
        onError: () => {
          snack.error("Couldn't delete classroom");
        },
      }
    );
  };

  const handleLeaveClassWithConfirmation = ({
    classroomName,
    classroomId,
  }: ConfirmationProps) =>
    requestConfirmation({
      title: t('teachers.unlinkTeacher-myself'),
      message: t('teachers.unlinkTeacher-myself.confirm', {
        classroom: classroomName,
      }),
      onConfirm: () => handleLeaveClass(classroomId),
      confirmText: t('teachers.unlinktTeacher-myself.confirmUnlink'),
    });

  const handleLeaveClass = (classroomId: string) => {
    unlinkMutation.mutate(
      {
        body: {
          classroomId,
          teacherId: user?.id!,
        },
      },
      {
        onSuccess: () => {
          deleteClassroomFromCache({
            queryClient,
            orgId: user?.organizationId!,
            classroomId,
          });
          snack.success(t('teachers.unlinkTeacher.toast.success'));
          handleCloseConfirmationModal();
        },
        onError: () => {
          // TODO: Handle error
        },
      }
    );
  };

  const handleOnEditSuccess = (classroom: Classroom) => {
    setEditClassroom(undefined);
    snack.success(t('classroom.modal.toast.success.edit'));
    updateClassroomInCache({
      queryClient,
      orgId: user?.organizationId!,
      updatedClassroom: classroom,
    });
  };

  const confirmModalData = {
    isOpen: isConfirmationOpen,
    isPending: deleteClassroomMutation.isPending || unlinkMutation.isPending,
    onClose: handleCloseConfirmationModal,
    ...confirmationModalProps,
  };

  const editModalData = {
    show: editClassroom !== undefined,
    onClose: () => setEditClassroom(undefined),
    organizationId: user?.organizationId!,
    region: user?.region!,
    classroom: editClassroom,
    onError: handleOnError,
    onSuccess: (updatedClassroom: ClassroomBody) =>
      handleOnEditSuccess({
        ...editClassroom!,
        ...updatedClassroom,
      } as Classroom),
  };

  return {
    openEditModal: setEditClassroom,
    handleOnError,
    handleDeleteClassWithConfirmation,
    handleLeaveClassWithConfirmation,
    handleOnEditSuccess,
    editModalData,
    confirmModalData,
  };
}

export { useManageClassrooms };
