import { useState } from 'react';

export default function useBoxes() {
  const [openedBoxes, setOpenedBoxes] = useState<number[]>([]);

  const getIsOpen = (key: number) => openedBoxes.includes(key);

  const onSelectBox = (index: number) => {
    const isOpen = getIsOpen(index);

    if (isOpen) {
      setOpenedBoxes((prev) => prev.filter((k) => k !== index));
    } else {
      setOpenedBoxes((prev) => [...prev, index]);
    }
  };

  return { onSelectBox, getIsOpen };
}
