const CLASSROOM_STUDENTS_RTI_URL = '/students?tab=rti';
const CLASSROOM_STUDENTS_URL = '/students';
const CLASSROOM_URL = '/classroom';
const CLASSROOMS_URL = '/classrooms';
const COURSE_SELECTOR_BASE_URL = '/course-selector';
const COURSE_URL = '/course';
const CURRENT_MATERIALS_URL = '/current-materials';
const DIGITAL_PRACTICE_URL = '/digital-practice';
const FAMILY_PAYMENTS_URL = '/family-payments';
const HISTORY_URL = '/history';
const IMPORTS_URL = '/imports';
const JOIN_TO_CLASSROOM_FT_URL = '/join-to-classroom?ft=1';
const JOIN_TO_CLASSROOM_URL = '/join-to-classroom';
const LAST_YEAR_REPORTS_URL = '/reports/last-year';
const LEARNING_URL = '/learning';
const MANIPULATIVE_URL = '/manipulative';
const MATERIALS_CHECKOUT_URL = '/materials-checkout';
const ORDERS_RETURNS_URL = '/orders-returns';
const ORGANIZATION_URL = '/organization';
const SETTINGS_URL = '/settings';
const UPLOAD_URL = '/imports/upload';
const APPLETS_URL = '/applets';
const GENERAL_PROGRESS_URL = '/reports/general-progress';
const PREFERENCES_URL = '/preferences';
const LANDING_PAGE_URL = '/';
const FLUENCY_REPORTS_URL = '/reports/fluency';

export const STATIC_ROUTES = [
  LAST_YEAR_REPORTS_URL,
  IMPORTS_URL,
  UPLOAD_URL,
  FLUENCY_REPORTS_URL,
];

const APP_PATHS = {
  CLASSROOM_STUDENTS_RTI_URL,
  CLASSROOM_STUDENTS_URL,
  CLASSROOM_URL,
  CLASSROOMS_URL,
  COURSE_SELECTOR_BASE_URL,
  COURSE_URL,
  CURRENT_MATERIALS_URL,
  DIGITAL_PRACTICE_URL,
  FAMILY_PAYMENTS_URL,
  GENERAL_PROGRESS_URL,
  HISTORY_URL,
  IMPORTS_URL,
  JOIN_TO_CLASSROOM_FT_URL,
  JOIN_TO_CLASSROOM_URL,
  LAST_YEAR_REPORTS_URL,
  LEARNING_URL,
  MANIPULATIVE_URL,
  APPLETS_URL,
  MATERIALS_CHECKOUT_URL,
  ORDERS_RETURNS_URL,
  ORGANIZATION_URL,
  SETTINGS_URL,
  UPLOAD_URL,
  PREFERENCES_URL,
  LANDING_PAGE_URL,
} as const;

type AppPaths = (typeof APP_PATHS)[keyof typeof APP_PATHS];

export {
  CLASSROOM_STUDENTS_RTI_URL,
  CLASSROOM_STUDENTS_URL,
  CLASSROOM_URL,
  COURSE_SELECTOR_BASE_URL,
  CURRENT_MATERIALS_URL,
  DIGITAL_PRACTICE_URL,
  FAMILY_PAYMENTS_URL,
  HISTORY_URL,
  IMPORTS_URL,
  JOIN_TO_CLASSROOM_FT_URL,
  JOIN_TO_CLASSROOM_URL,
  LANDING_PAGE_URL,
  LAST_YEAR_REPORTS_URL,
  LEARNING_URL,
  MANIPULATIVE_URL,
  MATERIALS_CHECKOUT_URL,
  ORDERS_RETURNS_URL,
  ORGANIZATION_URL,
  SETTINGS_URL,
  UPLOAD_URL,
};

export { APP_PATHS };
export type { AppPaths };
