/* eslint-disable react-hooks/rules-of-hooks */
import { useLocation } from 'react-router-dom';
import { useLocation as useLocationv6 } from 'react-router-domv6';

export function useQueryParams() {
  try {
    return new URLSearchParams(useLocation().search);
  } catch (error) {
    return new URLSearchParams(useLocationv6().search);
  }
}
