import styled from '@emotion/styled';
import CellFluency from '../cell-fluency';
import { Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { CardType } from '@innovamat/glow-api-client';
import { CELL_VARIANT } from '../cell-fluency/cell-fluency';

type LegendItemProps = {
  status: CardType;
  label: string;
};

type LayoutLegend = 'rows' | 'line';

type Props = {
  layout?: LayoutLegend;
};

const ITEMS_PER_ROW = 3;

const LegendContainer = styled.div<{ layout: LayoutLegend }>`
  display: flex;
  flex-direction: ${({ layout }) => (layout === 'rows' ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
  gap: ${({ layout }) => (layout === 'rows' ? '4px' : '24px')};
  padding-left: 40px;
`;

const LegendItemWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 8px;
`;

const LegendRow = styled(Typography.Body2)`
  display: flex;
  gap: 32px;
  justify-content: center;
`;

const LegendItem = ({ status, label }: LegendItemProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <LegendItemWrapper>
      <CellFluency variant={CELL_VARIANT.CELL_SMALL_SIZE} status={status} />
      <Typography.Body2>{t(label)}</Typography.Body2>
    </LegendItemWrapper>
  );
};

function LegendFluency({ layout = 'rows' }: Props): JSX.Element {
  const statusLabels: Partial<Record<CardType, string>> = {
    notSeen: 'fluency.reports.legend.gray',
    inProgress: 'fluency.reports.legend.lightGreen',
    memorized: 'fluency.reports.legend.darkGreen',
    fairlyFailed: 'fluency.reports.legend.orange',
    mostlyFailed: 'fluency.reports.legend.red',
  };

  const items = Object.entries(statusLabels).map(([status, label]) => (
    <LegendItem key={status} status={status as CardType} label={label} />
  ));

  if (layout === 'line') {
    return <LegendContainer layout={layout}>{items}</LegendContainer>;
  }

  return (
    <LegendContainer layout={layout}>
      <LegendRow>{items.slice(0, ITEMS_PER_ROW)}</LegendRow>
      <LegendRow>{items.slice(ITEMS_PER_ROW)}</LegendRow>
    </LegendContainer>
  );
}

export default LegendFluency;
