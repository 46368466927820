import { AnnouncementPanel, Modal } from '@innovamat/glimmer-components';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useImportClassroomsModal } from '../../hooks/use-import-classrooms-modal';
import ClassroomItem from './classroom-item';
import {
  CustomTable,
  Description,
  LoaderWrapper,
  ModalContent,
} from './import-classroom-modal.styles';
import { ImportClassroomModalProps } from './import-classroom-modal.types';
import ModalLoader from './modal-loader';

export default function ImportClassroomModal({
  isModalOpen,
  onCloseModal,
  onSubmit,
  isLoading,
  isStudentAlreadyExistsError,
  isStudentAlreadyExistsAsATeacherError,
  isErrorGettingClassrooms,
  isErrorImportingClassrooms,
  isClassroomAlreadyExistsErrorImport,
  isSuccess,
  myClassrooms,
  importedClassrooms,
  courses,
  notImportedClassrooms,
}: ImportClassroomModalProps): JSX.Element {
  const { t } = useTranslation();
  const {
    mappedCourses,
    importClassrooms,
    resetState,
    onCheckClassroom,
    isClassroomSelectedToImport,
    maxClassroomsSelected,
    onSelectCourse,
  } = useImportClassroomsModal(courses);

  const areAllAlreadyImported = myClassrooms?.every((c) => c.imported);

  const handleOnCloseModal = (): void => {
    resetState();
    onCloseModal();
  };

  // Code below is necessary to make the integration test work because Modal Component is a Portal
  let modalRoot = document.getElementById('modal') as HTMLElement;
  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'modal');
    document.body.appendChild(modalRoot);
  }

  useEffect(() => {
    !isModalOpen && resetState();
  }, [isModalOpen]);

  if (
    isErrorGettingClassrooms ||
    isStudentAlreadyExistsError ||
    isStudentAlreadyExistsAsATeacherError
  ) {
    const getMessage = (): string => {
      if (isStudentAlreadyExistsError) {
        return t('modal.class.import.banner.error.student_already_exists');
      }

      if (isStudentAlreadyExistsAsATeacherError) {
        return t('modal.class.import.banner.error.this_is_a_teacher');
      }

      if (isClassroomAlreadyExistsErrorImport) {
        return t('modal.class.import.banner.error.classroom_already_exists', {
          name: notImportedClassrooms?.name,
        });
      }

      return t('modal.class.import.banner.error');
    };

    return (
      <Modal
        isOpen={isModalOpen}
        onClose={handleOnCloseModal}
        closeOnClickOutside
        closeButton="inner"
        title={t('modal.connect.class.title')}
        buttons={[
          {
            children: t('modal.connect.class.cta.finish'),
            onClick: handleOnCloseModal,
            variant: 'accent',
          },
        ]}
        modalWidth={700}
      >
        <AnnouncementPanel text={getMessage()} canClose={false} type="error" />
      </Modal>
    );
  }

  if (isLoading) {
    return (
      <Modal
        isOpen={isModalOpen}
        onClose={handleOnCloseModal}
        title={t('modal.connect.class.title')}
        modalWidth={700}
      >
        <ModalContent>
          <Description>{t('modal.connect.class.subtitle')}</Description>
          <LoaderWrapper>
            <ModalLoader width={174} height={174} />
          </LoaderWrapper>
        </ModalContent>
      </Modal>
    );
  }

  if (isSuccess) {
    if (myClassrooms && myClassrooms.length > 0) {
      if (areAllAlreadyImported) {
        return (
          <Modal
            isOpen={isModalOpen}
            onClose={handleOnCloseModal}
            closeButton="inner"
            title={t('modal.connect.class.title')}
            buttons={[
              {
                children: t('modal.connect.class.cta.finish'),
                onClick: handleOnCloseModal,
              },
            ]}
            modalWidth={700}
          >
            <ModalContent>
              <Description>{t('modal.connect.class.subtitle')}</Description>
              <AnnouncementPanel
                text={t('modal.class.import.banner.all_imported')}
                canClose={false}
                type="info"
              />

              <br />
              <CustomTable data-testid="myClassrooms">
                <thead>
                  <tr>
                    <th className="firstCol">
                      {t('modal.connect.class.class_name')}
                    </th>
                    <th>{t('modal.connect.class.class_grade')}</th>
                  </tr>
                </thead>
                <tbody>
                  {myClassrooms.map((classroom) => (
                    <ClassroomItem
                      key={classroom.google_id}
                      checked={isClassroomSelectedToImport(classroom)}
                      disabled={
                        maxClassroomsSelected &&
                        !isClassroomSelectedToImport(classroom)
                      }
                      classroom={classroom}
                      onCheckClassroom={onCheckClassroom}
                      onSelectCourse={onSelectCourse}
                      courses={mappedCourses}
                    />
                  ))}
                </tbody>
              </CustomTable>
            </ModalContent>
          </Modal>
        );
      }

      if (isErrorImportingClassrooms) {
        return (
          <Modal
            isOpen={isModalOpen}
            onClose={handleOnCloseModal}
            closeButton="inner"
            title={t('modal.connect.class.title')}
            buttons={[
              {
                children: t('modal.connect.class.cta.finish'),
                onClick: handleOnCloseModal,
                variant: 'accent',
              },
            ]}
            modalWidth={700}
          >
            <ModalContent>
              <Description>{t('modal.connect.class.subtitle')}</Description>
              <AnnouncementPanel
                text={
                  <span>
                    <Trans
                      i18nKey="modal.class.import.error_in_some_classrooms"
                      components={{
                        strong: <strong />,
                      }}
                      values={{
                        importedClassrooms: importedClassrooms?.length,
                        myClassrooms: myClassrooms?.length,
                      }}
                    />
                  </span>
                }
                canClose={false}
                type="info"
              />

              <br />
              <CustomTable data-testid="myClassrooms">
                <thead>
                  <tr>
                    <th className="firstCol">
                      {t('modal.connect.class.class_name')}
                    </th>
                    <th>{t('modal.connect.class.class_grade')}</th>
                  </tr>
                </thead>
                <tbody>
                  {myClassrooms.map((classroom) => (
                    <ClassroomItem
                      key={classroom.google_id}
                      checked={isClassroomSelectedToImport(classroom)}
                      disabled={
                        maxClassroomsSelected &&
                        !isClassroomSelectedToImport(classroom)
                      }
                      classroom={{ ...classroom }}
                      onCheckClassroom={onCheckClassroom}
                      onSelectCourse={onSelectCourse}
                      courses={mappedCourses}
                      importingHasFailed={
                        !!importedClassrooms?.findIndex(
                          (c) => c.google_id === classroom.google_id
                        )
                      }
                    />
                  ))}
                </tbody>
              </CustomTable>
            </ModalContent>
          </Modal>
        );
      }

      return (
        <Modal
          isOpen={isModalOpen}
          onClose={handleOnCloseModal}
          closeOnClickOutside
          closeButton="inner"
          title={t('modal.connect.class.title')}
          buttons={[
            {
              children: t('modal.connect.class.cta.import'),
              onClick: () => onSubmit(importClassrooms),
              disabled: importClassrooms.length === 0,
              variant: 'accent',
            },
            {
              children: t('modal.connect.class.cta.cancel'),
              onClick: handleOnCloseModal,
              variant: 'tertiary',
            },
          ]}
          modalWidth={700}
        >
          <ModalContent>
            <Description>{t('modal.connect.class.subtitle')}</Description>
            {maxClassroomsSelected && (
              <AnnouncementPanel
                text={t(
                  'modal.connect.max-classrooms-selected',
                  'Solo puedes seleccionar 3 clases a la vez'
                )}
                canClose={false}
              />
            )}
            <CustomTable data-testid="myClassrooms">
              <thead>
                <tr>
                  <th className="firstCol">
                    {t('modal.connect.class.class_name')}
                  </th>
                  <th>{t('modal.connect.class.class_grade')}</th>
                </tr>
              </thead>
              <tbody>
                {myClassrooms.map((classroom) => (
                  <ClassroomItem
                    key={classroom.google_id}
                    disabled={
                      maxClassroomsSelected &&
                      !isClassroomSelectedToImport(classroom)
                    }
                    checked={isClassroomSelectedToImport(classroom)}
                    classroom={classroom}
                    onCheckClassroom={onCheckClassroom}
                    onSelectCourse={onSelectCourse}
                    courses={mappedCourses}
                  />
                ))}
              </tbody>
            </CustomTable>
          </ModalContent>
        </Modal>
      );
    }

    return (
      <Modal
        isOpen={isModalOpen}
        onClose={handleOnCloseModal}
        closeButton="inner"
        title={t('modal.connect.class.title')}
        buttons={[
          {
            children: t('modal.connect.class.cta.finish'),
            onClick: handleOnCloseModal,
            variant: 'accent',
          },
        ]}
        modalWidth={700}
      >
        <AnnouncementPanel
          text={t('modal.class.import.banner.no_classes')}
          canClose={false}
          type="info"
        />
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleOnCloseModal}
      closeOnClickOutside
      closeButton="inner"
      title={t('modal.connect.class.title')}
      buttons={[
        {
          children: t('modal.connect.class.cta.import'),
          onClick: () => onSubmit(importClassrooms),
          disabled: importClassrooms.length === 0,
          variant: 'accent',
        },
        {
          children: t('modal.connect.class.cta.cancel'),
          onClick: handleOnCloseModal,
          variant: 'tertiary',
        },
      ]}
      modalWidth={700}
    >
      <ModalContent>
        <Description>{t('modal.connect.class.subtitle')}</Description>
        <LoaderWrapper>
          <ModalLoader width={174} height={174} />
        </LoaderWrapper>
      </ModalContent>
    </Modal>
  );
}
