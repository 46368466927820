import { ProcessedSectionItem } from '../types';

export const NAV_TYPE = {
  Classroom: 'classroom',
  Learning: 'learning',
  Course: 'course',
  Admin: 'organization',
  AdminClassroom: 'organization-classroom',
  Student: 'digital-practice',
  Manipulative: 'manipulative',
} as const;

export type NavType = (typeof NAV_TYPE)[keyof typeof NAV_TYPE] | null;

export const openSectionInNewTab = (
  section: ProcessedSectionItem,
  pathname: string
) => {
  if (!section || typeof section === 'string') return;

  if (section.route === '/applets') {
    const [_, type, typeId] = pathname.split('/');
    const newUrl = `${window.location.origin}/${type}/${typeId}/applets`;
    window.open(newUrl, '_blank')?.focus();
  } else {
    window.open(section.route, '_blank')?.focus();
  }
};
