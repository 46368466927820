import {
  TableCellSkeleton,
  TableComponent,
  generateRowsForTableSkeleton,
  useColumnsCustomRenderConfig,
} from '@innovamat/glimmer-components';
import type { CustomColumnRenderConfig } from '@innovamat/glimmer-components';

import type { FamilyPayment } from '../types/family-payment';
import { Container } from './family-payments-table';
import { useFamilyPaymentsColumnsConfiguration } from '../hooks/use-family-payments-columns-configuration';

const FamilyPaymentsTableSkeleton = (): JSX.Element => {
  const configurationWithoutRender = useFamilyPaymentsColumnsConfiguration();

  const mockObject: FamilyPayment = {
    student: {
      name: '',
    },
    product: {
      name: '',
      courseName: '',
      language: '',
      quantity: 0,
    },
    order: {
      readableExternalId: '',
      payoutDate: '',
      financialStatus: 'paid',
    },
  };

  const rowsNumber = 4;

  const customRenderConfig: CustomColumnRenderConfig<FamilyPayment> = {
    'student.name': (_, row: any) => (
      <TableCellSkeleton isLastRow={row.rowNumber === rowsNumber} />
    ),
    'product.name': (_, row: any) => (
      <TableCellSkeleton isLastRow={row.rowNumber === rowsNumber} />
    ),
    'product.courseName': (_, row: any) => (
      <TableCellSkeleton isLastRow={row.rowNumber === rowsNumber} />
    ),
    'product.language': (_, row: any) => (
      <TableCellSkeleton isLastRow={row.rowNumber === rowsNumber} />
    ),
    'product.quantity': (_, row: any) => (
      <TableCellSkeleton isLastRow={row.rowNumber === rowsNumber} />
    ),
    'order.readableExternalId': (_, row: any) => (
      <TableCellSkeleton isLastRow={row.rowNumber === rowsNumber} />
    ),
    'order.payoutDate': (_, row: any) => (
      <TableCellSkeleton isLastRow={row.rowNumber === rowsNumber} />
    ),
    'order.financialStatus': (_, row: any) => (
      <TableCellSkeleton isLastRow={row.rowNumber === rowsNumber} />
    ),
  };

  const columns = useColumnsCustomRenderConfig<FamilyPayment>(
    customRenderConfig,
    configurationWithoutRender
  );

  const rows = generateRowsForTableSkeleton<FamilyPayment>(
    rowsNumber,
    mockObject
  );

  return (
    <Container>
      <TableComponent
        columns={columns}
        id="family-payments-table-skeleton"
        key="family-payments-table-skeleton"
        rows={rows}
      />
    </Container>
  );
};

export { FamilyPaymentsTableSkeleton };
