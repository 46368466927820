import { Classroom } from '@innovamat/glow-api-client';
import {
  CourseBlocks,
  BlockTitle,
  ClassesToAssign,
  CourseTitle,
  ClassroomsPerCourse,
  Container,
} from '../join-to-classroom.styled';
import { useJoinToClassroom } from '../join-to-classroom-context';
import { useTranslation } from 'react-i18next';
import { ClassSelectCard } from '../components/class-select-card';

export function ConfirmStep(): JSX.Element {
  const { t } = useTranslation();
  const {
    selectedClassroomsByCourse,
    coursesToRender,
    alreadyJoinedClassrooms,
    openEditModal,
  } = useJoinToClassroom();

  return (
    <Container>
      <CourseBlocks>
        <BlockTitle>
          {t('classrooms.select-classrooms.confirm.subtitle')}
        </BlockTitle>

        <ClassesToAssign>
          {coursesToRender.length > 0 ? (
            coursesToRender.map((course) => (
              <CourseBlocks key={course.id}>
                <CourseTitle>{course.name}</CourseTitle>
                <ClassroomsPerCourse>
                  {selectedClassroomsByCourse[course.id].map(
                    (classroom: Classroom) => (
                      <ClassSelectCard
                        key={`card-${classroom.id}`}
                        classroom={classroom}
                        status={'default'}
                        onClickEdit={() => openEditModal(classroom)}
                      />
                    )
                  )}
                </ClassroomsPerCourse>
              </CourseBlocks>
            ))
          ) : (
            <BlockTitle>
              {t('classrooms.select-classrooms.no-classrooms')}
            </BlockTitle>
          )}
        </ClassesToAssign>
      </CourseBlocks>
      {alreadyJoinedClassrooms?.length > 0 && (
        <CourseBlocks>
          <BlockTitle>
            {t('classrooms.select-classrooms.confirm.description')}
          </BlockTitle>
          <ClassroomsPerCourse>
            {alreadyJoinedClassrooms.map((classroom: Classroom) => (
              <ClassSelectCard
                key={`card-${classroom.id}`}
                classroom={classroom}
                onClickEdit={() => openEditModal(classroom)}
              />
            ))}
          </ClassroomsPerCourse>
        </CourseBlocks>
      )}
    </Container>
  );
}
