/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from 'react';

import {
  Body,
  Count,
  DetailsCardWrapper,
  Footer,
  Header,
  Title,
} from './DetailsCard.styles';

type HeaderProps = {
  count: string | number;
  title: string;
};

type Props = {
  body: React.ReactNode;
  header?: HeaderProps;
  footer?: React.ReactNode;
};

function DetailsCard({ header, body, footer }: Props) {
  return (
    <DetailsCardWrapper borderRadius="md" elevation="02">
      {header && (
        <Header hasCount={!!header.count}>
          {header.count && <Count>{header.count}</Count>}
          {header.title && <Title>{header.title}</Title>}
        </Header>
      )}
      {body && <Body hasFooter={!!footer}>{body}</Body>}
      {footer && <Footer>{footer}</Footer>}
    </DetailsCardWrapper>
  );
}

export default DetailsCard;
