import {
  ImportCSVProvider,
  useEnvVariables,
  useNavigation,
  useUser,
  APP_PATHS,
} from '@innovamat/ga-features';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  children: JSX.Element;
};

function ImportCSVWrapper({ children }: Props): JSX.Element {
  const { user, isRole } = useUser();
  const { goBackOrHome, navigateInSameNavType } = useNavigation();
  const queryClient = useQueryClient();

  const { ORGANIZATIONS } = useEnvVariables();

  const gotoImportCSVPage = (type: string): void => {
    navigateInSameNavType(`${APP_PATHS.UPLOAD_URL}`, `?from=${type}`);
  };

  const goToImportsPage = (): void => {
    navigateInSameNavType(APP_PATHS.IMPORTS_URL);
  };

  const handleImportUpdates = (): void => {
    queryClient.refetchQueries({ queryKey: ['Organization'], exact: false });
    queryClient.refetchQueries({ queryKey: ['Classrooms'], exact: false });
    queryClient.refetchQueries({ queryKey: ['Students'], exact: false });
    queryClient.refetchQueries({ queryKey: ['Teachers'], exact: false });
  };

  return (
    <ImportCSVProvider
      canSkipValidationStep={isRole.DistrictAdmin}
      goToImportCSVPage={gotoImportCSVPage}
      goToImportsPage={goToImportsPage}
      handleImportUpdates={handleImportUpdates}
      onGoBack={() => goBackOrHome()}
      organizationId={user?.organizationId!}
      organizationsApiUrl={ORGANIZATIONS}
      region={user?.region!}
      showImportCSV // TODO: what is this
    >
      {children}
    </ImportCSVProvider>
  );
}

export { ImportCSVWrapper };
