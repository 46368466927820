import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Stage, stages } from '@innovamat/radiance-utils';
import { Tabs, TitleHeader } from '@innovamat/glimmer-components';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Classroom } from '@innovamat/apollo-codegen';
import { Course } from '@innovamat/glow-api-client';
import { WeeklyResults } from './weekly-results';
import { GeneralProgress } from './general-progress';

export type TableType = 'WeeklyResults' | 'GeneralProgress';

type TabItem = {
  tableType: TableType;
  labelKey: string;
};

type Props = {
  setEventData: (eventType: string, eventProperties?: any) => void;
  googleClientId: string;
  classroom: Classroom;
  courses: Course[];
  standard: string;
  trainingZonePrimaryReports: boolean;
};

export function Reports({
  setEventData,
  googleClientId,
  classroom,
  courses,
  standard,
  trainingZonePrimaryReports,
}: Props): JSX.Element {
  const [tableActive, setTableActive] = useState<TableType>('WeeklyResults');
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { courseOrder, id, name } = classroom;
  const isSecundariaCourse = stages.isSecundaria(courseOrder);
  const isPrimaryCourse = stages.isPrimaria(courseOrder);
  const isEarlyYearsCourse = stages.isInfantil(courseOrder);
  const educationalStage = stages.getStageFromOrder(courseOrder || 0) as Stage;
  const isTableActive = (table: TableType): boolean => tableActive === table;

  useEffect(() => {
    const appContent = document.getElementById('app-content');
    if (appContent) {
      appContent.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    }
    return () => {
      if (appContent) {
        appContent.style.overflow = '';
        document.body.style.overflow = 'scroll';
      }
    };
  }, []);

  const changeTableActive = (table: TableType): void => {
    setTableActive(table);
    history.push({ search: '' });
    setEventData('report_change_tab', {
      classroom_id: id,
      dashbord_tab: table,
    });
  };

  const tabs: TabItem[] = [
    { tableType: 'WeeklyResults', labelKey: 'reports.weeklyResults' },
    { tableType: 'GeneralProgress', labelKey: 'reports.generalProgress' },
  ];

  const createTabObject = (tab: TabItem) => ({
    selected: isTableActive(tab.tableType),
    text: t(tab.labelKey),
    onClick: () => changeTableActive(tab.tableType),
    dataTestId: `${tab.tableType.toUpperCase()}_TAB`,
  });

  const handleNavigate = (route: string, queryParams?: string): void => {
    history.push(route, queryParams ? { search: queryParams } : undefined);
  };

  const handleNavigateToResource = (appletId: string) => {
    window.open(`/curriculum/applet/${appletId}`, '_blank');
  };
  const locationInfo = {
    search: location.search,
    pathname: location.pathname,
  };

  if (isSecundariaCourse === null) return <></>;

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <Wrapper>
        <Container>
          <TitleHeader
            title={t('reports.title')}
            hasSeparator={false}
            style={{ position: 'sticky', left: 0 }}
          />
          <TabTables id="tab-tables">
            <Tabs tabs={tabs.map(createTabObject)} />
          </TabTables>
          <TableContainer id="reports-table-container">
            {tableActive === 'WeeklyResults' ? (
              <WeeklyResults
                setEventData={setEventData}
                isSecundaria={!!isSecundariaCourse}
                isEarlyYears={!!isEarlyYearsCourse}
                educationalStage={educationalStage}
                courses={courses}
                handleNavigateToResource={handleNavigateToResource}
                handleNavigate={handleNavigate}
                locationInfo={locationInfo}
                trainingZonePrimaryReports={trainingZonePrimaryReports}
                classroom={classroom}
              />
            ) : (
              <GeneralProgress
                setEventData={setEventData}
                classroomId={id}
                isSecundaria={!!isSecundariaCourse}
                classroomName={name || ''}
                educationalStage={educationalStage}
                courses={courses}
                standard={standard}
                handleNavigate={handleNavigate}
                handleNavigateToResource={handleNavigateToResource}
                isPrimary={!!isPrimaryCourse}
              />
            )}
            <div id="portal-root" />
          </TableContainer>
        </Container>
      </Wrapper>
    </GoogleOAuthProvider>
  );
}

const Wrapper = styled.div`
  margin-right: -24px;
  margin-bottom: 32px;
`;

const Container = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    max-width: 1440px;
    margin: 0 auto;
    display: block;
  }
`;

const TableContainer = styled.div`
  position: relative;
`;

const TabTables = styled.nav`
  display: flex;
  gap: 8px;
  margin-top: 8px;
  border-bottom: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;
