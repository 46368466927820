import { Resource, resourceMapper } from '@innovamat/resource-viewer';
import { useEffect, useMemo, useRef } from 'react';
import { useResourceQuery } from '../../generated/types';
import { useCompletedResources } from '../use-completed-resources';

type Props = {
  id: string;
  type: string;
  classroomId: string | undefined;
  language: string;
  academicYearId?: string;
  isStudent: boolean;
  isLoggedIn?: boolean;
  standard?: string;
  courseOrder?: number;
};

export function useResource({
  id,
  type,
  classroomId,
  language,
  academicYearId,
  isStudent,
  isLoggedIn = true,
  standard,
  courseOrder,
}: Props) {
  const completedResources = useCompletedResources({
    classroomId,
    academicYearId,
    isStudent,
  });

  const prevCompletedResources = useRef(completedResources);

  const resourceQuery = useResourceQuery(
    {
      id,
      classroomId: classroomId || 'self',
      type,
      courseOrder,
      standard,
    },
    {
      enabled: isLoggedIn ? !!completedResources && !!id : !!id,
      retry: false,
    }
  );

  useEffect(() => {
    if (prevCompletedResources.current !== completedResources) {
      resourceQuery.refetch();
      prevCompletedResources.current = completedResources;
    }
  }, [isStudent]);

  const resource = useMemo(() => {
    if (!resourceQuery.data) return undefined;
    return resourceQuery.data.resource;
  }, [resourceQuery.data]);

  const mappedResource = useMemo(() => {
    if (!resource) return undefined;
    return resourceMapper({
      locale: language,
      response: resource as any,
      completedResources,
      isBloqued: false,
      isStudent,
    });
  }, [resource, language, completedResources, isStudent]);

  const isUnauthorized = (resourceQuery?.error as any)?.response?.errors?.some(
    (error: any) => {
      return error?.extensions?.code === 'UNAUTHENTICATED';
    }
  );

  return {
    status: resourceQuery.status,
    error: resourceQuery.error,
    isUnauthorized: isUnauthorized,
    resource: mappedResource as Resource,
  };
}
