import { Typography } from '@innovamat/glimmer-components';

import {
  FluencyMultiplicationReportQuery,
  useFluencyMultiplicationReportQuery,
} from '@innovamat/glow-api-client';
import LegendFluency from '../../components/legend-fluency';
import TableFluency from '../../components/table-fluency/table-fluency';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import ReportsLoader from '../../components/reports-loader';

type Props = {
  classroomId: string;
};

const GraphicWrapper = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
const MultiplicationTableTitle = styled(Typography.H4)`
  margin-top: 16px;
`;

export const FluencyDashboard = ({ classroomId }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { data: fluencyDashboardReport, isLoading } =
    useFluencyMultiplicationReportQuery<FluencyMultiplicationReportQuery>(
      {
        id: classroomId!,
      },
      {
        enabled: !!classroomId,
      }
    );

  const fluencyDashboardData =
    fluencyDashboardReport?.fluencyMultiplicationReport;

  const multiplicationTableTitle = fluencyDashboardData?.titleTranslationKey;
  const operator = fluencyDashboardData?.operator || '';
  const tableFluencyData = fluencyDashboardData?.multiplicationTables;

  if (isLoading) return <ReportsLoader />;

  return (
    <>
      <MultiplicationTableTitle>
        {t(`${multiplicationTableTitle}`)}
      </MultiplicationTableTitle>
      <GraphicWrapper>
        <ContentWrapper>
          <TableFluency
            operator={operator}
            tableFluencyData={tableFluencyData}
          />
          <LegendFluency />
        </ContentWrapper>
      </GraphicWrapper>
    </>
  );
};
