import { ThirdPartyConnections } from '../types/connection';
import { useQueryClient } from '@tanstack/react-query';
import {
  useDisconnectGoogleClassroomMutation,
  useGetThirdPartyConnectionsQuery,
} from '@innovamat/glow-api-client';

export function useDisconnect() {
  const queryClient = useQueryClient();
  const mutation = useDisconnectGoogleClassroomMutation();
  const onDisconnect = () => {
    mutation.mutate(
      {},
      {
        onSuccess() {
          const CONNECTIONS_KEY = useGetThirdPartyConnectionsQuery.getKey();

          const connections =
            queryClient.getQueryData<ThirdPartyConnections>(CONNECTIONS_KEY);

          if (connections) {
            const newConnections = {
              ...connections,
              google: null,
            };
            queryClient.setQueryData(CONNECTIONS_KEY, newConnections);
          }
        },
      }
    );
  };

  return { onDisconnect };
}
