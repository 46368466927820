import {
  MySettings,
  useGoogleClassroomSync,
  useNavigation,
  useUser,
} from '@innovamat/ga-features';
import { useQueryClient } from '@tanstack/react-query';

export function SettingsPage(): JSX.Element {
  const { user } = useUser();
  const { goBackOrHome } = useNavigation();
  const queryClient = useQueryClient();

  // async to avoid showing the old data before the new one is fetched
  const handleOnSuccess = async (): Promise<void> => {
    await queryClient.refetchQueries({ queryKey: ['Settings'] });
    await queryClient.refetchQueries({ queryKey: ['User'] });

    setTimeout(() => {
      queryClient.refetchQueries({
        queryKey: ['Classrooms'],
        exact: false,
      });

      queryClient.refetchQueries({
        queryKey: ['Courses'],
        exact: false,
      });

      queryClient.refetchQueries({
        predicate: (query) => {
          const queryKey = query.queryKey;
          return queryKey[0] === 'Page';
        },
      });
    }, 100);
  };

  if (!user) return <></>;

  return (
    <MySettings
      user={user}
      goBack={goBackOrHome}
      onSuccess={handleOnSuccess}
      useGoogleClassroomSync={useGoogleClassroomSync}
    />
  );
}
