/* eslint-disable */
export type Activity = {
  codename: string;
  is_motor: boolean;
  max_duration: number;
  min_duration: number;
  min_statements: number;
  pack: string;
  scene_name: string;
  variation: string;
};

export function purgeActivity(activity: Activity) {
  if (activity.pack === '-1') activity.pack = '0';

  return activity;
}
