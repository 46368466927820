import { Genially } from '../types/genially';
import { ResourceType } from '../types/resourceType';
import checkMissingLocale from '../utils/missingLocales';
import { mapPrintable } from './contentsMapper';
import { Params } from './resourceMapper';

export const geniallyMapper = ({
  response,
  isBloqued = false,
  locale,
  isStudent = false,
}: Params): Genially => {
  const isNotSession = false;
  const printables = mapPrintable(response, isStudent, isNotSession);

  return {
    id: response.uuid,
    isBloqued,
    language: locale,
    type: response.type as ResourceType,
    description: checkMissingLocale(response.description!),
    title: checkMissingLocale(response.name!),
    printables,
    src: response.file?.href!,
    tags: response.tags,
  };
};
