import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { snack } from '@innovamat/glimmer-components';
import { useCopyToClipboard } from '@innovamat/hooks';
import { Stage } from '@innovamat/radiance-utils';

import { useUser } from '../../../../user-management';
import type { ClassroomStudent } from '../types/classroom-student';

import {
  ActionsDropdown,
  type CreateItem,
} from '../../../components/common-table-components/actions-dropdown';

type Props = {
  stage: string | null;
  student: ClassroomStudent | undefined;
  isClassroomImportedFromGoogle: boolean;
  onChangePassword: (studentId: string) => void;
  onCompleteInvitation: (studentId: string) => void;
  onDeleteStudent: (student: ClassroomStudent | undefined) => void;
  onMoveStudent: (student: ClassroomStudent | undefined) => void;
  onResendInvitation: (studentId: string) => void;
  onResetTests: (student: ClassroomStudent | undefined) => void;
};

function ClassroomStudentsTableActionsCell({
  stage,
  student,
  isClassroomImportedFromGoogle,
  onChangePassword,
  onCompleteInvitation,
  onDeleteStudent,
  onMoveStudent,
  onResendInvitation,
  onResetTests,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { isRole } = useUser();
  const [_, copy] = useCopyToClipboard();

  const items: CreateItem[] = useMemo(() => {
    const baseItems: CreateItem[] = [
      {
        text: t('students.button.moveClassroom'),
        icon: 'MoveIcon',
        state: isClassroomImportedFromGoogle ? 'disabled' : 'active',
        onSelectItem: () => onMoveStudent(student),
      },
      {
        text: t('students.button.removeStudent'),
        icon: 'DeleteIcon',
        state: isClassroomImportedFromGoogle ? 'disabled' : 'active',
        onSelectItem: () => onDeleteStudent(student),
      },
    ];

    if (isRole.Advisor) {
      baseItems.push({
        text: t('students.button.resetcatchup'),
        icon: 'RestartIcon',
        state: 'active',
        onSelectItem: () => onResetTests(student),
      });

      baseItems.push({
        icon: 'CopyIcon',
        onSelectItem() {
          copy(student?.id || '');
          snack.success(t('manager.table.copiedId'));
        },
        state: 'active',
        text: t('manager.table.copyId'),
      });
    }

    if (stage === Stage.SECONDARY || stage === Stage.SECUNDARIA) {
      if (student?.isInvitation) {
        baseItems.push({
          text: t('common.resendInvitation'),
          icon: 'MailIcon',
          state: isClassroomImportedFromGoogle ? 'disabled' : 'active',
          onSelectItem: () => onResendInvitation(student?.id!),
        });

        baseItems.push({
          text: t('students.completeManually'),
          icon: 'EditIcon',
          state: isClassroomImportedFromGoogle ? 'disabled' : 'active',
          onSelectItem: () => onCompleteInvitation(student?.id!),
        });
      } else {
        baseItems.push({
          text: t('common.updatePassword'),
          icon: 'PasswordIcon',
          state: isClassroomImportedFromGoogle ? 'disabled' : 'active',
          onSelectItem: () => onChangePassword(student?.id!),
        });
      }
    }

    return baseItems;
  }, [onDeleteStudent, onMoveStudent, student, t]);

  return <ActionsDropdown dropdownId="school-students-button" items={items} />;
}

export { ClassroomStudentsTableActionsCell };
