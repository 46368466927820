import { IconType } from '@innovamat/glimmer-icons';
import { useTranslation } from 'react-i18next';
import { TimeSchedule } from '../views/weekly-results/weekly-results';
import { dates } from '@innovamat/radiance-utils';

type Props = {
  isSecundaria: boolean;
  loadingTable: boolean;
  practicePeriod: TimeSchedule;
  setPracticePeriod: (time: TimeSchedule) => void;
  handleSendEvent: (event: string, parameters?: {}) => void;
  scheduleStart: string;
  scheduleEnd: string;
};

export type PracticePeriodOptions = {
  icons: IconType[];
  onClick: () => void;
  tooltipText: string;
  disabled: boolean;
  selected: boolean;
}[];

const usePracticePeriod = ({
  isSecundaria,
  loadingTable,
  practicePeriod,
  setPracticePeriod,
  handleSendEvent,
  scheduleStart,
  scheduleEnd,
}: Props): PracticePeriodOptions => {
  const { t } = useTranslation();
  const isPracticePeriodActive = (time: TimeSchedule): boolean =>
    practicePeriod === time;

  const changePracticePeriodActive = (time: TimeSchedule): void => {
    setPracticePeriod(time);
  };

  const handleChangePracticePeriod = (time: TimeSchedule): void => {
    changePracticePeriodActive(time);
    handleSendEvent('report_practice_period_filter', {
      practice_period: time,
    });
  };

  const stage = isSecundaria ? 'secondary' : 'primary';

  const practicePeriodOptions = [
    {
      icons: ['SchoolIcon'] as IconType[],
      onClick: () => handleChangePracticePeriod('school'),
      tooltipText: t(`reports.${stage}.filter.button.schoolTime2`, {
        start: dates.removeSecondsFromCompleteHour(scheduleStart),
        end: dates.removeSecondsFromCompleteHour(scheduleEnd),
      }),
      disabled: loadingTable,
      selected: isPracticePeriodActive('school'),
    },
    {
      icons: ['SchoolIcon', 'HomeIcon'] as IconType[],
      onClick: () => handleChangePracticePeriod('all'),
      tooltipText: t(`reports.${stage}.filter.button.fullTime`),
      disabled: loadingTable,
      selected: isPracticePeriodActive('all'),
    },
    {
      icons: ['HomeIcon'] as IconType[],
      onClick: () => handleChangePracticePeriod('home'),
      tooltipText: t(`reports.${stage}.filter.button.outSchoolTime`),
      disabled: loadingTable,
      selected: isPracticePeriodActive('home'),
    },
  ];

  return practicePeriodOptions;
};

export default usePracticePeriod;
