import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Column } from '@innovamat/glimmer-components';
import { dates } from '@innovamat/radiance-utils';

import type { SchoolStudent } from '../types/student';
import { useOrganization } from '../../../hooks/use-organization';
import { useSchoolStudents } from '../providers/school-students-provider';

import { SchoolStudentsTableActionsCell } from '../components/school-students-table/school-students-table.actions-cell';

function useGetColumns(): {
  columns: Column<SchoolStudent>[];
} {
  const { t } = useTranslation();
  const { isDeletedStudentsPage } = useSchoolStudents();
  const { isOrgAnonymized } = useOrganization();

  const baseColumns: Column<SchoolStudent>[] = useMemo(() => {
    const columns = [
      {
        id: 'avatar',
        width: 50,
        minWidth: '100px',
        render: (_, row) => {
          return row.avatar ? (
            <Avatar img={row.avatar} alt={`student-avatar-${row.firstName}`} />
          ) : (
            <></>
          );
        },
      },
      {
        id: 'firstName',
        label: t('students.table.labels.name'),
        width: 100,
        minWidth: '100px',
      },
      {
        id: 'lastName',
        label: t('students.table.labels.lastName'),
        width: 100,
        minWidth: '100px',
      },
      {
        id: 'alias',
        label: t('students.table.labels.alias', 'Alias'),
        width: 100,
        minWidth: '100px',
      },
      {
        id: 'courseName',
        label: t('students.table.labels.course'),
        width: 100,
        minWidth: '100px',
      },
      {
        id: 'classroomName',
        width: 100,
        minWidth: '100px',
        label: t('students.table.labels.classroom'),
      },
    ] satisfies Column<SchoolStudent>[];

    if (isOrgAnonymized) {
      return columns.filter((c) => c.id !== 'avatar');
    } else {
      return columns.filter((c) => c.id !== 'alias');
    }
  }, [t, isOrgAnonymized]);

  const eliminationDate: Column<SchoolStudent> = useMemo(
    () =>
      ({
        id: 'deletedAt',
        width: 100,
        minWidth: '100px',
        label: t('students.table.labels.deletedAt'),
        render: (_, row) => <>{dates.formatDateFromSql(row.deletedAt)}</>,
      } satisfies Column<SchoolStudent>),
    [t]
  );

  const actionsColumn: Column<SchoolStudent> = useMemo(() => {
    return {
      id: 'actions',
      width: '60px',
      sticky: true,
      stickyPosition: 'right',

      render: (_, student) => {
        return <SchoolStudentsTableActionsCell student={student} />;
      },
    } satisfies Column<SchoolStudent>;
  }, []);

  return {
    columns: isDeletedStudentsPage
      ? [...baseColumns, eliminationDate, actionsColumn]
      : [...baseColumns, actionsColumn],
  };
}

export { useGetColumns };
