import { Dropdown } from '@innovamat/glimmer-components';
import styled from 'styled-components';
import { useAddActionDropdown } from '../../classrooms-management/hooks/use-add-action-dropdown';

type Props = {
  addNewImportButtonText: string;
};

const Actions = styled.div`
  display: flex;
  gap: 1rem;
`;

function ImportDropdown({ addNewImportButtonText }: Props): JSX.Element {
  const { getOptions } = useAddActionDropdown({
    type: 'newImport',
  });

  return (
    <Actions>
      <Dropdown openOnHover>
        <Dropdown.Toggle label={addNewImportButtonText} />
        <Dropdown.Content position={'bottomRight'}>
          {getOptions().map((option, index) => (
            <Dropdown.Item key={index} {...option} />
          ))}
        </Dropdown.Content>
      </Dropdown>
    </Actions>
  );
}

export { ImportDropdown };
