import { useContext } from 'react';
import { SpacesStateContext } from './SpacesProvider';

export default function useSpaces() {
  const context = useContext(SpacesStateContext);

  if (context === undefined) {
    throw new Error('useSpaces must be used within a SpacesProvider');
  }

  return context;
}
