import { ClassroomToImport } from '../types';
import { useSyncroGoogleClassroomMutation } from '@innovamat/glow-api-client';

export default function useSyncroClassroom() {
  const { mutate, isPending, isSuccess, isError, data, reset, error } =
    useSyncroGoogleClassroomMutation();

  const onSyncroClassroom = async (classroomToImport: ClassroomToImport) => {
    mutate({
      classroomToImport,
    });
  };

  return {
    onSyncroClassroom,
    syncroClassroomStatus: {
      isLoading: isPending,
      isError,
      isSuccess,
      data,
      error,
      reset,
    },
  };
}
