import { Action, PlayerState } from '../types/state';
import { getNumberOfSteps, getNextStep, getPrevStep } from './selectors';

export const initState: PlayerState = {
  currentStep: 0,
  currentResource: 0,
  totalSteps: 0,
  resource: undefined,
  isInFullscreen: false,
};

function playerReducer(state: PlayerState, action: Action): PlayerState {
  switch (action.type) {
    case 'SET_NEXT_RESOURCE': {
      const { step, page } = getNextStep(state);
      return {
        ...state,
        currentStep: step,
        currentResource: page,
      };
    }
    case 'SET_PREVIOUS_RESOURCE': {
      const { step, page } = getPrevStep(state);
      return {
        ...state,
        currentStep: step,
        currentResource: page,
      };
    }
    case 'RESOURCE_NAVIGATE':
      return {
        ...state,
        currentStep: action.payload.step,
        currentResource: action.payload.resource,
      };
    case 'SET_RESOURCE': {
      return {
        ...state,
        resource: action.payload,
        totalSteps:
          'resources' in action.payload
            ? getNumberOfSteps(action.payload.resources)
            : 1,
      };
    }
    case 'TOGGLE_FULLSCREEN': {
      return {
        ...state,
        isInFullscreen: !state.isInFullscreen,
      };
    }
    default:
      return state;
  }
}

export default playerReducer;
