import { useRefreshTokenMutation } from '@innovamat/glow-api-client';
import { storage } from '@innovamat/radiance-utils';

export function useRefreshToken() {
  const { mutate: refreshToken } = useRefreshTokenMutation();

  const onRefreshToken = (onTokenRefreshed: () => void): void => {
    const tokenData = storage.tokenInfo.get();
    if (!tokenData) return;
    refreshToken(
      { refreshToken: tokenData.refresh_token },
      {
        onSuccess(data) {
          const newTokens = data.refreshToken;
          if (!newTokens) return;
          storage.tokenInfo.set(newTokens);

          onTokenRefreshed();
        },
        onError() {
          console.log('Error refreshing token');
        },
      }
    );
  };

  return {
    onRefreshToken,
  };
}
