import styled from '@emotion/styled';
import { SimpleBlock } from '../SimpleBlock';
import { Tag } from '../Tag';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@innovamat/glimmer-components';

export type MPTag = {
  tag: string;
  textKey: string;
};

type Props = {
  tags: MPTag[];
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media print {
    gap: 4px;
  }
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Text = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledTooltip = styled(Tooltip)`
  overflow: hidden;
`;

export function MathPractices({ tags }: Props) {
  const { t } = useTranslation();
  if (tags.length === 0) {
    return null;
  }

  return (
    <SimpleBlock title={t('smartguides.math_practices')}>
      <Container>
        {tags.map((tag, index) => {
          return (
            <TagContainer key={index}>
              <Tag text={tag.tag} isEnabled={true} />
              <StyledTooltip content={t(tag.textKey)}>
                <Text>{t(tag.textKey)}</Text>
              </StyledTooltip>
            </TagContainer>
          );
        })}
      </Container>
    </SimpleBlock>
  );
}
