import { Row, snack } from '@innovamat/glimmer-components';
import { Course } from '@innovamat/glow-api-client';
import { dates, Stage } from '@innovamat/radiance-utils';
import { useAuthState } from '@innovamat/social-login';
import { useSaveGoogleDrive } from '@innovamat/third-party-connections-google';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { printPdfDocument } from '../utils/print-pdf';
import { ReportType } from '../utils/render-tables';
import { exportReportToXlsx } from '../utils/xlsx-exports';
import { TimeSchedule } from '../views/weekly-results/weekly-results';

type Props = {
  educationalStage: Stage;
  practicePeriod?: TimeSchedule;
  classroomName: string;
  reportDate: {
    from: string;
    to: string;
  } | null;
  studentsRows: Row[];
  averageRow: Row[];
  adaptedReportsData: any;
  handleSendEvent: (event: string, parameters?: {}) => void;
  reportType: ReportType;
  courses?: Course[]; // TODO: Change it to mandatory when course in general progress
};

type DownloadReports = {
  handleDownload: (type: DownloadReportType) => void;
  openGoogleDriveFile: () => void;
  googleDriveUrl: string | null | undefined;
  onCloseModalGoogleDriveModal: () => void;
  googleDriveLoading: boolean;
};

type ReportsArgs = {
  fileName: string;
  studentData: Row[];
  averageData: Row[];
  adaptedReportsData: any;
  t: TFunction<'translation', undefined, 'translation'>;
  educationalStage: Stage;
  practicePeriod?: TimeSchedule;
  courses?: Course[]; // TODO: Change it to mandatory when course in general progress
};

export type DownloadReportType = 'excel' | 'pdf' | 'drive';

const useDownloadReports = (props: Props): DownloadReports => {
  const {
    educationalStage,
    practicePeriod,
    classroomName,
    reportDate,
    studentsRows,
    averageRow,
    adaptedReportsData,
    handleSendEvent,
    reportType,
    courses,
  } = props;

  const { t } = useTranslation();

  //Save on google drive
  const { credentials } = useAuthState();
  const [googleDriveUrl, setGoogleDriveUrl] = useState<string | null>();
  const [reportXlsx, setReportXlsx] = useState<{
    file: Blob;
    fileName: string;
  } | null>(null);

  const handleErrorGoogleDrive = (): void => {
    snack.error(t('notification.saveGoogleDrive.error'));
  };

  const handleSuccessGoogleDrive = (url: string): void => {
    setGoogleDriveUrl(url);
  };

  const onCloseModalGoogleDriveModal = (): void => {
    setGoogleDriveUrl(null);
  };

  const openGoogleDriveFile = (): void => {
    googleDriveUrl && window.open(googleDriveUrl, '_blank');
    handleSendEvent('report_download_drive');
  };

  const { signIn, loading: googleDriveLoading } = useSaveGoogleDrive({
    file: reportXlsx?.file,
    fileName: reportXlsx?.fileName || '',
    googleClientId: credentials.googleClientId,
    googleSecret: credentials.googleSecretId,
    onError: handleErrorGoogleDrive,
    onSuccess: handleSuccessGoogleDrive,
  });

  const handleDownloadDrive = (): void => {
    const file = getReportXlsx();
    setReportXlsx(file);
  };

  //Excel download
  const getTimeSpentLabel = (): string => {
    const stage = educationalStage === 'SECUNDARIA' ? 'secondary' : 'primary';
    if (practicePeriod === 'all')
      return t(`reports.${stage}.filter.button.fullTime`);
    else if (practicePeriod === 'home')
      return t(`reports.${stage}.filter.button.outSchoolTime`);
    else return t(`reports.${stage}.filter.button.schoolTime`);
  };

  const getDateLabel = (): string => {
    if (reportType === 'weekly') {
      return dates.getFormattedDate(reportDate?.from);
    } else {
      return `${dates.getFormattedDate(
        reportDate?.from
      )}_${dates.getFormattedDate(reportDate?.to)} `;
    }
  };

  const prepareReportArgs = (reportType: ReportType): ReportsArgs => ({
    fileName: generateReportFileName(reportType),
    studentData: studentsRows,
    averageData: averageRow,
    adaptedReportsData,
    t,
    educationalStage,
    practicePeriod,
    courses,
  });

  const generateReportFileName = (reportType: ReportType): string => {
    const timeLabel = reportType === 'weekly' ? '_' + getTimeSpentLabel() : '';
    const dateLabel = getDateLabel();
    return `${t(
      'reports.button.downloadReport.nameFile'
    )}_${classroomName}_${dateLabel}${timeLabel}`;
  };

  const getReportXlsx = (): {
    file: Blob;
    fileName: string;
  } => {
    const reportFileName = `${generateReportFileName(reportType)}.xlsx`;
    const file = exportReportToXlsx(
      reportType,
      prepareReportArgs(reportType),
      false
    );
    return { file: file!, fileName: reportFileName };
  };

  const handleDownloadExcel = (): void => {
    exportReportToXlsx(reportType, prepareReportArgs(reportType));
    handleSendEvent('report_download_excel');
  };

  //PDF download
  const handlePDFDownload = (): void => {
    const printFileName = generateReportFileName(reportType);
    printPdfDocument('print-area', printFileName);
    handleSendEvent('report_download_pdf');
  };

  const handleDownload = (type: DownloadReportType): void => {
    switch (type) {
      case 'excel':
        handleDownloadExcel();
        break;
      case 'pdf':
        handlePDFDownload();
        break;
      case 'drive':
        handleDownloadDrive();
        break;
    }
  };

  useEffect(() => {
    if (reportXlsx) {
      signIn();
    }
  }, [reportXlsx]);

  return {
    handleDownload,
    openGoogleDriveFile,
    googleDriveUrl,
    onCloseModalGoogleDriveModal,
    googleDriveLoading,
  };
};

export default useDownloadReports;
