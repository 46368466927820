import { useFullScreenHandle } from 'react-full-screen';
import Resource from './components/Resource';
import Timeline from './components/Timeline';
import Controls from './components/Controls';
import PlayerProvider from './providers/PlayerProviders/PlayerProvider';
import VideoProvider from './providers/VideoProviders/VideoProvider';
import usePlayerState from './providers/PlayerProviders/usePlayerState';
import {
  Wrapper,
  NoTimeLineSeparator,
  TimelineContainer,
  StyledFullScreen,
  Container,
  ContainerParent,
  FullScreenWrapper,
} from './ResourceViewer.styles';
import { ResourceViewerProps } from './types/resourceViewer';
import SingleResource from './components/SingleResource';
import usePlayerSize from './hooks/usePlayerSize';
import MainLoader from './components/MainLoader';
import { useState } from 'react';
import { isTouchDevice } from './utils/helpers';
import { IOSFullScreen } from './components/IOSFullScreen/IOSFullScreen';

function App() {
  const {
    fullscreen,
    totalSteps,
    resource,
    isSinglePdfOrGenially,
    fullscreenIOS,
  } = usePlayerState();
  const [hideTimeline, setHideTimeline] = useState(false);
  const [observerAdded, setObserverAdded] = useState(false);
  const [objectObserved, setObjectObserved] = useState<Element>();

  const showTimeline = totalSteps > 1;

  const isInFullscreen = !!fullscreen?.active;
  const { showLoader } = usePlayerSize();
  const isTouchable = isTouchDevice();

  // When is only a PDF or Genially this component no need fullscreen function
  if (resource && isSinglePdfOrGenially) {
    return <SingleResource />;
  }

  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function () {
      const keyboard = document.getElementsByClassName('TabbedKeyBoard');
      const attributes = keyboard.length > 0 ? keyboard[0].attributes : {};
      setHideTimeline(!attributes['aria-hidden']);
    });
  });
  const config = { attributes: true, childList: true, characterData: true };

  const updateTimeline = () => {
    const keyboard = document.getElementsByClassName('TabbedKeyBoard');
    const target = keyboard.length > 0 ? keyboard[0] : undefined;
    if (isTouchable && observerAdded && target && target !== objectObserved) {
      observer.disconnect();

      const attributes = keyboard.length > 0 ? keyboard[0].attributes : {};
      setHideTimeline(!attributes['aria-hidden']);

      observer.observe(target, config);
      setObjectObserved(target);
    } else if (isTouchable && !observerAdded && keyboard.length > 0) {
      const attributes = keyboard.length > 0 ? keyboard[0].attributes : {};
      setHideTimeline(!attributes['aria-hidden']);

      if (target) {
        observer.observe(target, config);
        setObjectObserved(target);
      }

      setObserverAdded(true);
    }
  };

  return (
    <IOSFullScreen isInIOSFullscreen={fullscreenIOS}>
      <Wrapper isInFullscreen={isInFullscreen || fullscreenIOS}>
        <ContainerParent>
          <Container id="resource-wrapper" onFocus={updateTimeline}>
            {showLoader ? <MainLoader /> : <Resource />}
          </Container>
        </ContainerParent>
        <TimelineContainer isInFullscreen={isInFullscreen || fullscreenIOS}>
          {showTimeline && !hideTimeline ? (
            <Timeline />
          ) : (
            <NoTimeLineSeparator />
          )}
          {!hideTimeline && <Controls />}
        </TimelineContainer>
      </Wrapper>
    </IOSFullScreen>
  );
}

export function ResourceViewer({
  resource,
  onDispatchEvent,
  translations,
  appletInfo,
  preventArrowKeysEvents,
  fullScreen,
  apolloServer,
  axiosInstance,
  setEventData,
  videoOptions,
  school,
  uid,
  autoplay,
}: ResourceViewerProps) {
  const handleFS = useFullScreenHandle();
  const fullscreen = fullScreen || handleFS;

  return (
    <FullScreenWrapper data-testid="fullscreenWrapper">
      <StyledFullScreen handle={fullscreen}>
        <PlayerProvider
          resource={resource}
          onDispatchEvent={onDispatchEvent}
          translations={translations}
          appletInfo={appletInfo}
          fullscreen={fullscreen}
          preventArrowKeysEvents={preventArrowKeysEvents}
          apolloServer={apolloServer}
          axiosInstance={axiosInstance}
          setEventData={setEventData}
          videoOptions={videoOptions}
          school={school}
          uid={uid}
          autoplay={autoplay}
        >
          <VideoProvider>
            <App />
          </VideoProvider>
        </PlayerProvider>
      </StyledFullScreen>
    </FullScreenWrapper>
  );
}
