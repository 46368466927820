import {
  Teacher,
  useResendInvitationMutation,
  useResendSchoolInvitationMutation,
} from '@innovamat/glow-api-client';

type Props = {
  onSuccessAddTeachers: () => void;
};

type UseResendInvitation = {
  onResendInvitation: (teacher: Teacher) => void;
  isResendingInvitation: boolean;
};

function useResendInvitation({
  onSuccessAddTeachers,
}: Props): UseResendInvitation {
  const {
    mutate: onResendSchoolInvitationMutation,
    isPending: isResendingSchoolInvitation,
  } = useResendSchoolInvitationMutation();
  const {
    mutate: onResendInvitationMutation,
    isPending: isResendingInvitation,
  } = useResendInvitationMutation();

  const onResendInvitation = (teacher: Teacher): void => {
    if (!teacher.token) return;

    const { id, token } = teacher;

    if (teacher.isSchoolInvitation) {
      onResendSchoolInvitationMutation(
        { body: { id, token } },
        {
          onSuccess: () => {
            onSuccessAddTeachers();
          },
        }
      );
    } else {
      onResendInvitationMutation(
        { body: { id, token } },
        {
          onSuccess: () => {
            onSuccessAddTeachers();
          },
        }
      );
    }
  };

  return {
    onResendInvitation,
    isResendingInvitation: isResendingSchoolInvitation || isResendingInvitation,
  };
}

export { useResendInvitation };
