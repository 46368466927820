import {
  ClassroomToImport,
  useGoogleClassroomSync,
} from '@innovamat/ga-features';
import { snack } from '@innovamat/glimmer-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-domv6';

import {
  Classroom,
  useDeleteClassroomMutation,
} from '@innovamat/glow-api-client';
import { useQueryClient } from '@tanstack/react-query';

type useSyncroClassroomsModalProps = {
  classroomToSyncro: Classroom | null;
};

export const useSyncroClassroomsModal = ({
  classroomToSyncro,
}: useSyncroClassroomsModalProps) => {
  const { t } = useTranslation();

  const { mutate: deleteClassroomMutation } = useDeleteClassroomMutation();

  const navigate = useNavigate();
  const [showSyncroModal, setShowSyncroModal] = useState(false);
  const [isErrorInSyncro, setIsErrorInSyncro] = useState(false);
  const [isSyncronizing, setIsSyncronizing] = useState(false);
  const [isJustRemovingConnection, setIsJustRemovingConnection] =
    useState(true);
  const client = useQueryClient();

  const {
    handleSyncro,
    syncroClassroomStatus,
    removeClassroomConnectionStatus,
    handleRemoveClassroomConnection,
  } = useGoogleClassroomSync();

  const onOpenSyncroModal = () => {
    setShowSyncroModal(true);
  };

  const onCloseSyncroModal = () => {
    syncroClassroomStatus.reset();
    setShowSyncroModal(false);
    setIsErrorInSyncro(false);
  };

  const handleSyncroClassroom = () => {
    if (!classroomToSyncro) return;

    const googleClassroom: ClassroomToImport = {
      google_id: classroomToSyncro.providerId!,
      course_order: classroomToSyncro.courseOrder!,
    };
    handleSyncro(googleClassroom);
  };

  const resetRemoveClassroomConnectionStatus = (redirect?: boolean) => {
    removeClassroomConnectionStatus.reset();
    onCloseSyncroModal();
    if (redirect) {
      navigate(-1);
    }
  };

  const handleConvertClassroomToInnovamat = () => {
    if (!classroomToSyncro) return;
    setIsJustRemovingConnection(true);
    handleRemoveClassroomConnection(classroomToSyncro.id!);
  };

  const handleDeleteGoogleClassroom = () => {
    if (!classroomToSyncro) return;
    setIsJustRemovingConnection(false);

    deleteClassroomMutation(
      {
        classroomId: classroomToSyncro.id!,
      },
      {
        onError() {
          resetRemoveClassroomConnectionStatus();
        },
        onSuccess() {
          client.invalidateQueries({ queryKey: ['Classrooms'] });
        },
      }
    );
  };

  useEffect(() => {
    if (isJustRemovingConnection && removeClassroomConnectionStatus.isSuccess) {
      snack.success(t('toast.mantain_class.true'));

      client.invalidateQueries({ queryKey: ['Students'] });
      client.invalidateQueries({ queryKey: ['Classroom'] });

      resetRemoveClassroomConnectionStatus();
    }
    if (removeClassroomConnectionStatus.isError) {
      snack.error(t('toast.mantain_class.false'));
      resetRemoveClassroomConnectionStatus();
    }
  }, [
    removeClassroomConnectionStatus.isSuccess,
    removeClassroomConnectionStatus.isError,
  ]);

  useEffect(() => {
    if (syncroClassroomStatus.isLoading) {
      setShowSyncroModal(false);
      setIsSyncronizing(true);
      return;
    }
    if (syncroClassroomStatus.isError) {
      setShowSyncroModal(true);
      setIsSyncronizing(false);
      setIsErrorInSyncro(true);
      return;
    }
    if (syncroClassroomStatus.isSuccess) {
      setTimeout(() => {
        setIsSyncronizing(false);
        client.invalidateQueries({ queryKey: ['Students'] });
        client.invalidateQueries({ queryKey: ['Classroom'] });
      }, 5000);
    }
  }, [
    syncroClassroomStatus.isLoading,
    syncroClassroomStatus.isSuccess,
    syncroClassroomStatus.isError,
  ]);

  return {
    isSuccessInSyncro: syncroClassroomStatus.isSuccess,
    isErrorInSyncro,
    isStudentAlreadyExistsErrorSyncro:
      syncroClassroomStatus.error?.response.errors.some((error) =>
        error.extensions.code.includes(
          'STUDENTS_ALREADY_EXISTS_AS_A_TEACHER_ERROR'
        )
      ),
    isStudentAlreadyExistsAsATeacherErrorSyncro:
      syncroClassroomStatus.error?.response.errors.some((error) =>
        error.extensions.code.includes(
          'STUDENTS_ALREADY_EXISTS_AS_A_TEACHER_ERROR'
        )
      ),
    isGoogleClassroomDeletedInGoogleError:
      syncroClassroomStatus.error?.response.errors.some((error) =>
        error.extensions.code.includes('GOOGLE_CLASSROOM_NOT_FOUND')
      ),
    showSyncroModal,
    onOpenSyncroModal,
    onCloseSyncroModal,
    handleSyncroClassroom,
    showLoadingAnimation: isSyncronizing,
    handleConvertClassroomToInnovamat,
    handleDeleteGoogleClassroom,
  };
};
