import { UseMutationResult, useMutation } from '@tanstack/react-query';

const WEBHOOK_URL =
  'https://hook.eu1.make.celonis.com/mo28rnd90mo8bstdm8h4qkoeqej6iwcs';

type MutationBody = {
  username: string;
  positions: string[];
  first_year: boolean;
  current_courses: number[];
  previous_courses: number[];
};

export function useOnboardingFormWebhook(): UseMutationResult<
  any,
  Error,
  MutationBody,
  unknown
> {
  const mutation = useMutation<any, Error, MutationBody, unknown>({
    mutationFn: async (params) => {
      const body = {
        ...params,
      };

      const response = await fetch(WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Failed to send onboarding webhook');
      }

      return response.json();
    },
  });

  return mutation;
}
