import { createContext, useContext, useState } from 'react';

export type sectionType = 'Guide' | 'Session' | 'Related' | 'Assessment';
type SessionSectionProviderValue = {
  sectionActive: sectionType;
  isSectionActive: (section: sectionType) => boolean;
  changeSectionActive: (
    section: sectionType,
    onChangeSection?: (section: sectionType) => void
  ) => void;
  isGuideInResource?: boolean;
  hasLinkedResources?: boolean;
  hasAssessableResources?: boolean;
  hasResources?: boolean;
};

type SessionSectionProviderProps = {
  children: React.ReactNode;
  isGuideInResource?: boolean;
  hasLinkedResources?: boolean;
  hasAssessableResources?: boolean;
  hasResources?: boolean;
};

const SessionSectionContext = createContext<
  SessionSectionProviderValue | undefined
>(undefined);

export const SessionSectionProvider = ({
  children,
  isGuideInResource,
  hasLinkedResources,
  hasAssessableResources,
  hasResources,
}: SessionSectionProviderProps) => {
  const [sectionActive, setSectionActive] = useState<sectionType>('Guide');
  const isSectionActive = (section: sectionType) => sectionActive === section;
  const changeSectionActive = (
    section: sectionType,
    onChangeSection?: (section: sectionType) => void
  ) => {
    setSectionActive(section);
    onChangeSection?.(section);
  };

  const value = {
    sectionActive,
    isSectionActive,
    changeSectionActive,
    isGuideInResource,
    hasLinkedResources,
    hasAssessableResources,
    hasResources,
  };

  return (
    <SessionSectionContext.Provider value={value}>
      {children}
    </SessionSectionContext.Provider>
  );
};

export const useHorizontalMenuTabs = () => {
  const context = useContext(SessionSectionContext);

  if (context === undefined) {
    throw new Error(
      'useHorizontalMenuTabs must be used within a SessionSectionContext'
    );
  }

  return context;
};
