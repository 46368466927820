import { ResourceResponse } from '../types/resourceResponse';
import { Option } from '../types/base';
import { IconType } from '@innovamat/glimmer-icons';

const printableValue = 'printables';

export type VisibilityType = 'BLANK' | 'ACTIVITY' | 'SOLUTION';

export const getFileExtension = (filename: string | undefined) => {
  const splitFileName = filename?.split('.');
  return splitFileName?.[splitFileName.length - 1];
};

export const getIconByType = (type: string) => {
  switch (type) {
    case 'pdf':
      return 'FilePdfIcon' as IconType;
    case 'xls':
      return 'FileXlsIcon' as IconType;
    case 'xlsx':
      return 'FileXlsIcon' as IconType;
    case 'zip':
      return 'FileZipIcon' as IconType;
    default:
      return 'DownloadFilesIcon' as IconType;
  }
};

export const stripHTMLTags = (string: string) =>
  string.replace('\n', '').replace(/(<([^>]+)>)/gi, '');

export const mapPrintable = (
  resource: ResourceResponse,
  isStudent: boolean,
  isSession: boolean
): Option[] => {
  const dataPrintable = resource.resource_blocks?.find(
    (block) => block.name === printableValue
  )?.resources;

  const printablesResponse = isSession ? dataPrintable : resource?.printables;

  if (!printablesResponse) return [];

  const options: Option[] = printablesResponse.map((printableResource: any) => {
    const originalFileName = printableResource.file?.file_name;
    const extensionFile = getFileExtension(originalFileName);
    if (!isSession) {
      return {
        key: printableResource.display_name,
        id: printableResource.uuid,
        label: printableResource.display_name,
        value: printableResource.file.id,
        href: printableResource.file.href,
        icon: getIconByType(extensionFile || ''),
        subtitle: stripHTMLTags(
          `${printableResource.display_name}.${extensionFile}`
        ),
        isDisabled: isStudent
          ? printableResource.visibility !== 'visible'
          : false,
      } as Option;
    }
    const originalFile =
      printableResource.resource_view.file?.original_file_name;
    const extension = getFileExtension(originalFile);
    return {
      key: printableResource.resource_view.name,
      label: printableResource.resource_view.name,
      value: printableResource.resource_view.file?.uuid,
      href: printableResource.resource_view.file?.href,
      icon: getIconByType(extension || ''),
      subtitle: stripHTMLTags(
        `${printableResource.resource_view.name}.${extension}`
      ),
      isDisabled: isStudent
        ? printableResource.resource_view.visibility !== 'visible'
        : false,
      id: printableResource.resource_view.uuid,
    } as Option;
  });
  return options;
};
