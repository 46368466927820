import {
  QueryClientProvider as Provider,
  QueryClient,
} from '@tanstack/react-query';
import React, { createContext, useContext, useMemo } from 'react';
import { CustomGraphQLClient, RequestError } from './graphql-client';

type ContextValues = {
  graphqlClient: CustomGraphQLClient;
  queryClient: QueryClient;
};

type Props = {
  children: React.ReactNode;
  apiUrl: string;
  client: QueryClient;
  getAuthToken: () => string | undefined;
  getUserAcceptLanguage: () => string | undefined;
  onRefreshToken: () => Promise<any>;
  onRequestError: (error: RequestError) => void;
};

const ApiClientContext = createContext<ContextValues | undefined>(undefined);

export function GlowApiClientProvider({
  children,
  apiUrl,
  client,
  getAuthToken,
  getUserAcceptLanguage,
  onRefreshToken,
  onRequestError,
}: Props): JSX.Element {
  const graphqlClient = useMemo(
    () =>
      new CustomGraphQLClient(
        apiUrl,
        getAuthToken,
        getUserAcceptLanguage,
        onRefreshToken,
        onRequestError
      ),
    [apiUrl, getAuthToken, getUserAcceptLanguage, onRefreshToken]
  );

  return (
    <ApiClientContext.Provider value={{ graphqlClient, queryClient: client }}>
      {React.cloneElement(
        <Provider client={client} />,
        {},
        ...React.Children.toArray(children)
      )}
    </ApiClientContext.Provider>
  );
}

export const useGlowApiClient = (): ContextValues => {
  const context = useContext(ApiClientContext);
  if (!context) {
    throw new Error('useGlowApiClient should be inside GlowApiClientProvider');
  }
  return context;
};
