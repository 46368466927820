import React, { useEffect, useState } from 'react';

import ButtonCircle from '../ButtonCircle';
import {
  Container,
  ContentWrapper,
  CounterWrapper,
} from './MaterialCardControls.styles';

function MaterialCardControls({
  counterValue,
  isDecrementDisabled,
  isOpened,
  onDecrement,
  onIncrement,
}: any) {
  const [render, setRender] = useState(isOpened);

  useEffect(() => {
    if (isOpened) setRender(true);
  }, [isOpened]);

  const onAnimationEnd = () => {
    if (!isOpened) setRender(false);
  };

  return (
    render && (
      <Container
        isOpened={isOpened}
        borderRadius="sm"
        elevation="01"
        height="44px"
        width="136px"
        onAnimationEnd={onAnimationEnd}
      >
        <ContentWrapper>
          <ButtonCircle
            disabled={isDecrementDisabled}
            icon="Minus"
            onClick={onDecrement}
          />
          <CounterWrapper>{counterValue}</CounterWrapper>
          <ButtonCircle icon="Plus" onClick={onIncrement} />
        </ContentWrapper>
      </Container>
    )
  );
}

export default MaterialCardControls;
