import { GridCol, GridRow, useDevice } from '@innovamat/glimmer-components';
import { ModalV2 as Modal } from '@innovamat/innova-components';
import { Session } from '@innovamat/resource-viewer';
import {
  Stage,
  getMaterialsByStage,
  useDigitalGuides,
} from '@innovamat/smart-guides';
import { TFunction } from 'i18next';
import ContentBlock from '../../components/ContentBlock';
import DimensionsBlock from '../../components/DimensionsBlock';
import ImagesModal, {
  ImageModal,
} from '../../components/ImagesModal/ImagesModal';
import MaterialsBlock from '../../components/MaterialsBlock';
import MomentsBlock from '../../components/MomentsBlock';
import { PlayerBlock } from '../../components/PlayerBlock';
import SimpleBlock from '../../components/SimpleBlock';
import useMoments from '../../hooks/useMoments';
import { ScrollPosition } from '../../types/prepr';
import {
  IntroWidthPlayerContainer,
  MaterialsWrapper,
  StickyContainer,
  StyledCol,
  StyledContent,
  StyledGridContainer,
  StyledWrapper,
} from '../_templates.styled';
import {
  SECONDARY_CONTENT_BLOCKS,
  SECONDARY_CONTENT_BLOCKS_ICONS,
  SECONDARY_MOMENT_TYPES_ICONS,
} from './Secondary.mapper';
import { SecondaryDigitalGuide } from './types/secondaryDigitalGuide';

type Props = {
  guide: SecondaryDigitalGuide;
  images: ImageModal[];
  resource: Session;
  scrollPosition: ScrollPosition;
  onEvent: (key: string, data: any) => void;
  regionParameters: any;
  t: TFunction<'translation', undefined, 'translation'>;
};

export default function SecondaryDigitalGuideWrapper({
  guide,
  images,
  resource,
  scrollPosition,
  onEvent,
  t,
}: Props) {
  const {
    defaultImage,
    onClickImage,
    getIsOpen,
    onScrollMoment,
    onSelectMoment,
  } = useMoments({
    guide,
    onEvent,
    resource,
  });
  const { isMobile } = useDevice();
  const { relatedItemsComponent, unblockedAppletsComponent } =
    useDigitalGuides();

  if (!guide) return null;

  return (
    <>
      <StyledWrapper>
        <IntroWidthPlayerContainer>
          <StyledGridContainer>
            <GridRow>
              <StyledCol md={6} sm={6} xs={12}>
                <SimpleBlock
                  id="summary"
                  text={guide.summary}
                  title={t('digitalguides.se.summary')}
                />
                <SimpleBlock
                  text={guide.sessionObjectives}
                  title={t(
                    'digitalguides.se.objectives',
                    'Objectius especifÃ­cs de la sessiÃ³'
                  )}
                />
                <SimpleBlock
                  text={guide.prerequisites}
                  title={t(
                    'digitalguides.se.prerequisits',
                    'Prerequisits per a fer la sessiÃ³'
                  )}
                />
              </StyledCol>
              <StyledCol md={3} sm={6} xs={12}>
                <ContentBlock
                  contentIcons={SECONDARY_CONTENT_BLOCKS_ICONS}
                  contentBlocks={guide.contentBlocks}
                  items={SECONDARY_CONTENT_BLOCKS}
                  standardContent={resource.standardContent}
                />
                <DimensionsBlock
                  dimensions={guide.dimensions}
                  title={t('digitalguides.processes')}
                />
              </StyledCol>
              <GridCol md={3} sm={12}>
                <MaterialsWrapper>
                  <MaterialsBlock
                    material={guide.material}
                    items={getMaterialsByStage(Stage.Secondary)}
                  />
                </MaterialsWrapper>
              </GridCol>
            </GridRow>
          </StyledGridContainer>
        </IntroWidthPlayerContainer>
        {isMobile && (
          <StyledContent>
            <PlayerBlock />
          </StyledContent>
        )}
        <StyledContent>
          <StyledGridContainer>
            <GridRow>
              <StyledCol sm={7}>
                <MomentsBlock
                  momentIcons={SECONDARY_MOMENT_TYPES_ICONS}
                  onClickImage={onClickImage}
                  moments={guide.moments}
                  getIsOpen={getIsOpen}
                  scrollPosition={scrollPosition}
                  onScrollMoment={onScrollMoment}
                  onSelectMoment={onSelectMoment}
                  materialList={getMaterialsByStage(Stage.Secondary)}
                  momentSummaryKey={t('digitalguides.se.challenge.summary')}
                />
              </StyledCol>
              {!isMobile && (
                <StyledCol sm={5}>
                  <StickyContainer>
                    <PlayerBlock />
                  </StickyContainer>
                </StyledCol>
              )}
            </GridRow>
          </StyledGridContainer>
        </StyledContent>
        {unblockedAppletsComponent}
        {relatedItemsComponent}
      </StyledWrapper>
      <Modal
        open={!!defaultImage}
        showSubmitBtn={false}
        showCancelBtn={false}
        backgroundColor="transparent"
        overlayBgOpacity={0.8}
        clickOutsideClose
        onClose={() => {
          onClickImage(null);
        }}
        padding="4px"
      >
        <ImagesModal
          listOfImages={images}
          defaultImage={defaultImage}
          onClickImage={onClickImage}
        />
      </Modal>
    </>
  );
}
