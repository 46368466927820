import styled from '@emotion/styled';
import { Tooltip, Typography } from '@innovamat/glimmer-components';
import { useTranslation } from 'react-i18next';
import { StandardTag } from '../../types/SmartGuide';
import { SimpleBlock } from '../SimpleBlock';
import { Tag } from '../Tag';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media print {
    gap: 4px;
  }
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Text = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledTooltip = styled(Tooltip)`
  overflow: hidden;
`;

type Props = {
  tags?: StandardTag[];
};

export function StandardTags({ tags }: Props) {
  const { t } = useTranslation();

  if (!tags || !tags.length) {
    return null;
  }

  const tagsComesFromPrepr = tags.some((tag) => tag.isFromPrepr);

  const title = !tagsComesFromPrepr
    ? `DYNAMIC: ${t('digitalguides.standards')}`
    : t('digitalguides.standards');

  return (
    <SimpleBlock title={title}>
      <Container>
        {tags.map((tag) => {
          return (
            <TagContainer>
              <Tag text={tag.text} isEnabled={true} />
              <StyledTooltip content={t(tag.tooltipKey)}>
                <Text>{t(tag.tooltipKey)}</Text>
              </StyledTooltip>
            </TagContainer>
          );
        })}
      </Container>
    </SimpleBlock>
  );
}
