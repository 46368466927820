import { useEffect, useState } from 'react';
import { useMedia } from '@innovamat/hooks';
import Star from '../../star';
import StarsCounter from './stars-counter';
import { ImageComponent, theme } from '@innovamat/glimmer-components';
import {
  getCharacter,
  getCharacter3rdOr4thSE,
} from '../../../utils/getImagesSecundaria';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

type Props = {
  starsCount: { [key: number]: number };
  starsValue: { [key: number]: number };
  starsTotal: number;
  firstName: string;
  courseOrder: number;
};

const EndingDialog = ({
  starsCount,
  starsValue,
  starsTotal,
  firstName,
  courseOrder,
}: Props) => {
  const [characterImg] = useState(Math.floor(Math.random() * 5) + 1);
  const [character3rdOr4thSEImg] = useState(Math.floor(Math.random() * 5) + 1);
  const [currentStarCount, setCurrentStarCount] = useState(-1);
  const [totalStarsCount, setTotalStarsCount] = useState(0);
  const [acumulativeStarsCount, setAcumulativeStarsCount] = useState(0);
  const [triggerCharacterAnimation, setTriggerCharacterAnimation] =
    useState(false);
  const [triggerContainerAnimation, setTriggerContainerAnimation] =
    useState(false);
  const [triggerStarAnimation, setTriggerStarAnimation] = useState(false);

  const { t } = useTranslation();

  const is3rdOr4thSE = courseOrder === 14 || courseOrder === 15;

  useEffect(() => {
    setTimeout(() => {
      setTriggerCharacterAnimation(true);
    }, 1000);

    setTimeout(() => {
      setTriggerContainerAnimation(true);
    }, 2000);

    setTimeout(() => {
      setCurrentStarCount(0);
    }, 3000);
  }, []);

  useEffect(() => {
    if (currentStarCount >= 0) {
      if (starsValue[currentStarCount])
        setTotalStarsCount((prevCount) => prevCount + 1);
      starsValue[currentStarCount] &&
        setAcumulativeStarsCount(
          (prevCount) => prevCount + starsValue[currentStarCount]
        );
      setTriggerStarAnimation(true);
    }
  }, [currentStarCount]);

  useEffect(() => {
    if (totalStarsCount < acumulativeStarsCount) {
      const interval = setInterval(
        () => setTotalStarsCount((prevCount) => prevCount + 1),
        100
      );
      return () => {
        clearInterval(interval);
      };
    }
    if (totalStarsCount === acumulativeStarsCount) {
      setTriggerStarAnimation(false);
    }
    return () => undefined;
  }, [totalStarsCount]);

  const handleCountFinished = () => {
    setCurrentStarCount((prevCount) => prevCount + 1);
  };

  const getNumberWithoutPx = (value: string) => Number(value.replace('px', ''));

  const triggerBreakpointM = useMedia(getNumberWithoutPx(theme.breakpoints.md));

  const triggerBreakpointL = useMedia(getNumberWithoutPx(theme.breakpoints.lg));

  const triggerBreakpointXL = useMedia(
    getNumberWithoutPx(theme.breakpoints.xl)
  );

  const finalStarSize = () => {
    if (!triggerBreakpointXL) return 54;
    if (!triggerBreakpointL) return 46;
    if (!triggerBreakpointM) return 36;
    return 32;
  };

  const getCountStarSize = () => {
    if (!triggerBreakpointXL) return 24;
    if (!triggerBreakpointL) return 18;
    return 12;
  };

  const getCharacterImg = () => {
    if (is3rdOr4thSE) return getCharacter3rdOr4thSE(character3rdOr4thSEImg);
    return getCharacter(characterImg);
  };

  return (
    <Relative>
      <Character
        src={getCharacterImg().src}
        alt="character"
        triggerCharacterAnimation={triggerCharacterAnimation}
        is3rdOr4thSE={is3rdOr4thSE}
      />
      <Container triggerContainerAnimation={triggerContainerAnimation}>
        <Dialog>
          <Content>
            <Text>{t('weeklyPractice.endingSam', { name: firstName })}</Text>
            <Scoring>
              <StarsCount>
                {Object.keys(starsCount).map((key) => (
                  <StarsCounter
                    key={key}
                    numStars={Number(key)}
                    totalCount={starsCount[key]}
                    startCount={currentStarCount === Number(key)}
                    handleCountFinished={handleCountFinished}
                    starSize={getCountStarSize()}
                  />
                ))}
              </StarsCount>
              <FinalScoring>
                <FinalStar triggerStarAnimation={triggerStarAnimation}>
                  <Star size={finalStarSize()} success />
                </FinalStar>
                <PracticeStars>{totalStarsCount}</PracticeStars>
                <TotalStars>{`/ ${starsTotal}`}</TotalStars>
              </FinalScoring>
            </Scoring>
          </Content>
        </Dialog>
      </Container>
    </Relative>
  );
};

export default EndingDialog;

const Relative = styled.div`
  position: relative;
`;

const Container = styled.div<{ triggerContainerAnimation: boolean }>`
  margin-left: 80px;
  position: relative;
  left: 1000px;
  ${(props) =>
    props.triggerContainerAnimation &&
    css`
      left: 0px;
    `}
  transition: left 1s ease-in;
`;

const Character = styled(ImageComponent)<{
  triggerCharacterAnimation: boolean;
  is3rdOr4thSE: boolean;
}>`
  display: flex;
  position: absolute;
  overflow: hidden;
  bottom: -500px;
  width: 222px;
  height: 252px;
  left: 316px;
  ${(props) =>
    props.triggerCharacterAnimation &&
    css`
      bottom: -65%;
    `}
  transition: bottom 1s ease-in;
  z-index: 1;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 296px;
    height: 336px;
    ${(props) =>
      props.is3rdOr4thSE
        ? css`
            left: 496px;
          `
        : css`
            left: 440px;
          `}
    ${(props) =>
      props.triggerCharacterAnimation &&
      css`
        bottom: -45%;
      `}
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 296px;
    height: 336px;
    ${(props) =>
      props.is3rdOr4thSE
        ? css`
            left: 558px;
          `
        : css`
            left: 500px;
          `}
    ${(props) =>
      props.triggerCharacterAnimation && !props.is3rdOr4thSE
        ? css`
            bottom: -25%;
          `
        : css`
            bottom: -35%;
          `}
  }
`;

const Dialog = styled.div`
  display: flex;
  border-radius: 12px;
  background: ${(props) => props.theme.colors.white};
  transform: skewX(-10deg);
  backdrop-filter: blur(4px);
  min-width: 332px;
  min-height: 158px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    min-width: 412px;
    min-height: 198px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    min-width: 432px;
    min-height: 208px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    min-width: 566px;
    min-height: 270px;
  }
`;

const Content = styled.div`
  transform: skewX(10deg) !important;
  margin: 12px 48px 24px 24px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 12px 36px 18px 64px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    margin: 18px 36px 18px 64px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    margin: 16px 116px 32px 64px;
  }
`;

const Text = styled.div`
  text-align: center;
  white-space: pre;
  font-size: 12px;
  font-weight: 700;
  font-family: ${(props) => props.theme.fonts.robotoMedium};
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 16px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    font-size: 20px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: 24px;
  }
`;

const Scoring = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  gap: 24px;
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    margin-top: 20px;
    gap: 36px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    margin-top: 32px;
    gap: 48px;
  }
`;

const StarsCount = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 900;
  line-height: 36px;
  gap: 4px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 18px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: 24px;
  }
`;

const FinalScoring = styled.div`
  display: flex;
  font-weight: 900;
  align-items: center;
`;

const PracticeStars = styled.div`
  font-size: 28px;
  margin: 0 8px 0 16px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: 36px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    font-size: 48px;
  }
`;

const TotalStars = styled.div`
  font-size: 24px;
  color: #949494;
  margin-top: 8px;
  white-space: nowrap;
  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    margin-top: 16px;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const FinalStar = styled.div<{ triggerStarAnimation: boolean }>`
  margin-right: -8px;
  ${(props) =>
    props.triggerStarAnimation &&
    css`
      animation: ${pulse} 0.8s ease-in infinite;
    `}
`;
