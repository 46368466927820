export const ONBOARDING_STEP = {
  Welcome: 0,
  SelectPositions: 1,
  FirstYear: 2,
  SelectBeforeCourse: 3,
  SelectCourseNow: 4,
  AppDisclaimer: 5,
  Finish: 6,
  LoadingManager: 7,
} as const;

export type OnboardingStep =
  (typeof ONBOARDING_STEP)[keyof typeof ONBOARDING_STEP];

export type Position =
  | 'teacher'
  | 'coordinator'
  | 'assistant'
  | 'head_pedagogic'
  | 'secretary'
  | 'it_manager';

export const Group1 = ['teacher', 'assistant'] as Position[];
export const Group2 = [
  'coordinator',
  'head_pedagogic',
  'secretary',
] as Position[];
export const Group3 = ['it_manager'] as Position[];

export const FLOW_GROUP = {
  Group1: 1,
  Group2: 2,
  Group3: 3,
} as const;

export type FlowGroup = (typeof FLOW_GROUP)[keyof typeof FLOW_GROUP];
