import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MaterialsTable,
  TableCell,
  TableSubRow,
  TextRow,
} from '@innovamat/innova-components';

import { Loader } from '@innovamat/glimmer-components';

import {
  CoursesQuery,
  OrderDetailsQuery,
  useOrderDetailsQuery,
} from '@innovamat/glow-api-client';

import { locales } from '@innovamat/localization';
import styles from './sub-row.module.scss';

import type {
  HistoryData,
  HistoryRow,
  Material,
} from '../../orders-returns-history.types';

type Props = {
  row: HistoryRow;
  setTableData: (data: HistoryData[]) => void;
  tableData: HistoryData[];
  coursesData: CoursesQuery | undefined;
};

const SubRow = ({ row, tableData, setTableData, coursesData }: Props) => {
  const { t } = useTranslation();

  const onDataFetchCompleted = (
    responseData: OrderDetailsQuery
  ): void | undefined => {
    if (!responseData?.orderDetails) return;

    const newTableData = tableData.map((currentRow) => {
      if (currentRow.id !== row.original.id) {
        return currentRow;
      }

      const newMaterials: Material[] = responseData.orderDetails!.materials.map(
        (material) => {
          const typedMaterial = material as Material;
          return {
            ...typedMaterial,
            language: locales.getInnovamat3(typedMaterial.language)
              ? locales.getInnovamat3(typedMaterial.language).toUpperCase()
              : '',
            units: t('common.unitsAbbreviation'),
            course:
              coursesData?.courses?.find(
                (currentCourse) =>
                  currentCourse?.order === typedMaterial.courseOrder
              )?.name ?? '-',
          } as Material;
        }
      );

      return {
        ...currentRow,
        subRows: {
          ...responseData.orderDetails,
          materials: newMaterials,
        },
      };
    });

    setTableData(newTableData);
  };

  const {
    data,
    isLoading: isLoadingDetails,
    isSuccess,
  } = useOrderDetailsQuery({
    orderId: row.original.id,
  });

  const { subRows } = row.original;

  const renderShippingAddressTitle = () => {
    return (
      <p className={styles.subRowTitle}>
        {t(`ordersReturnsHistory.${row.original.orderType}ShippingAddress`)}
      </p>
    );
  };

  const renderShippingAddress = () => {
    if (subRows.shippingAddress.length === 0) {
      if (row.original.transactionType === 'prescription') {
        return (
          <p>
            {t(`ordersReturnsHistory.shippingAddress.noAddressPrescription`)}
          </p>
        );
      }

      return (
        <>
          {renderShippingAddressTitle()}
          <TextRow isOpaque>
            {t(`ordersReturnsHistory.shippingAddress.noAddress`)}
          </TextRow>
        </>
      );
    }

    return (
      <>
        {renderShippingAddressTitle()}
        {subRows.shippingAddress.map((currentRow: string, i: number) => (
          <TextRow isOpaque elipsisOnRows={2} key={i}>
            {currentRow}
          </TextRow>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (isSuccess && data) {
      onDataFetchCompleted(data);
    }
  }, [isSuccess, data]);

  if (!subRows || isLoadingDetails) {
    return (
      <TableSubRow key={`${row.original.id}_subrow`} isExpanded>
        <TableCell colSpan={7}>
          <div className={styles.loader}>
            <Loader />
            <span className={styles.loaderText}>
              {t('common.loadingContent')}
            </span>
          </div>
        </TableCell>
      </TableSubRow>
    );
  }

  return (
    <TableSubRow key={`${row.original.id}_subrow`} isExpanded>
      <TableCell>{renderShippingAddress()}</TableCell>

      <TableCell colSpan={4}>
        <p className={styles.subRowTitle}>
          {t(`ordersReturnsHistory.${row.original.orderType}Materials`)}
        </p>
        <MaterialsTable materials={subRows.materials as Material[]} />
      </TableCell>

      <TableCell colSpan={2}>
        <p className={styles.subRowTitle}>
          {t('ordersReturnsHistory.billingAddress')}
        </p>
        {subRows.billingAddress.map((subRow: string, i: number) => (
          <TextRow isOpaque elipsisOnRows={2} key={i}>
            {subRow}
          </TextRow>
        ))}
      </TableCell>
    </TableSubRow>
  );
};

export { SubRow };
