import { DEFAULT_REGION } from '@innovamat/localization';

const SPAIN = 'ES';

const SOLVER_PHONE_NUMBERS_FOR_SPAIN = {
  es_ES: '+34 919 03 46 86',
  ca_ES: '+34 932 20 94 23',
} as const;

const SOLVER_PHONE_NUMBERS: Record<string, string> = {
  CL: '+56 44 204 5053',
  CO: '+57 601 5898576',
  EC: '+593 2 342 9335',
  ES: SOLVER_PHONE_NUMBERS_FOR_SPAIN.es_ES,
  IN2: '+34 919 03 46 86',
  IT: '+39 02 8295 7418',
  MX: '+52 55 4741 1320',
  PE: '+51 1 7071698',
  US: '+1 415 993 6035',
} as const;

const getSolverPhoneNumberByRegion = (
  region?: string,
  language?: string
): string => {
  const currentRegion = region ?? DEFAULT_REGION;

  if (currentRegion !== SPAIN) {
    return SOLVER_PHONE_NUMBERS[currentRegion];
  }

  const phoneKey =
    `${language}_${currentRegion}` as keyof typeof SOLVER_PHONE_NUMBERS_FOR_SPAIN;

  return phoneKey in SOLVER_PHONE_NUMBERS_FOR_SPAIN
    ? SOLVER_PHONE_NUMBERS_FOR_SPAIN[phoneKey]
    : SOLVER_PHONE_NUMBERS[DEFAULT_REGION];
};

export { getSolverPhoneNumberByRegion };
