// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IVyfAEZVg0h1q4HH{width:16px;height:16px}.IVyfAEZVg0h1q4HH svg{transform:translate(-28%, -28%) scale(0.5)}.KhcfY1JjrNgJozoe{width:20px;height:20px}.KhcfY1JjrNgJozoe svg{transform:translate(-20%, -20%) scale(0.6)}", "",{"version":3,"sources":["webpack://./../../libs/innova-components/src/lib/components/ChallengeCheckbox/ChallengeCheckbox.module.scss"],"names":[],"mappings":"AAAA,kBACE,UAAA,CACA,WAAA,CACA,sBACE,0CAAA,CAIJ,kBACE,UAAA,CACA,WAAA,CACA,sBACE,0CAAA","sourcesContent":[".checkMarkStyleSm{\n  width: 16px;\n  height: 16px;\n  svg {\n    transform: translate(-28%, -28%) scale(0.5);\n  }\n}\n\n.checkMarkStyleMd{\n  width: 20px;\n  height: 20px;\n  svg {\n    transform: translate(-20%, -20%) scale(0.6);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkMarkStyleSm": "IVyfAEZVg0h1q4HH",
	"checkMarkStyleMd": "KhcfY1JjrNgJozoe"
};
export default ___CSS_LOADER_EXPORT___;
