import MuxPlayer from '@mux/mux-player-react';

export type PreprPlayerProps = {
  playbackId: string;
  metadata: Metadata;
  thumbnailTime?: number;
  onLoadedMetadata?: (event: any) => void;
  myRef?: any;
};

export type Metadata = {
  videoId: string;
  videoTitle: string;
};

export function PreprPlayer({
  playbackId,
  metadata,
  thumbnailTime = 0,
  onLoadedMetadata,
  myRef,
}: PreprPlayerProps) {
  return (
    <MuxPlayer
      ref={myRef}
      streamType="on-demand"
      playbackId={playbackId}
      metadata={{
        video_id: metadata.videoId,
        video_title: metadata.videoTitle,
      }}
      thumbnailTime={thumbnailTime}
      onLoadedMetadata={(event) => onLoadedMetadata?.(event)}
    />
  );
}
