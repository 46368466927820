import React, { useState } from 'react';
import {
  AccordionTab,
  ArrowWrapper,
  AccordionHeader,
  AccordionContentWrapper,
  AccordionContent,
} from './Accordion.styles';
import Icon from '../Icon';

type AccordionProps = {
  title: JSX.Element;
  isBordered?: boolean;
  isOpen?: boolean;
  children?: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Accordion = ({
  title,
  isBordered,
  isOpen,
  children,
  onClick,
}: AccordionProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleAccordionOpen = () => {
    setIsAccordionOpen((prev) => !prev);
  };

  return (
    <AccordionTab isBordered={isBordered} data-testid="accordion">
      <AccordionHeader onClick={onClick || handleAccordionOpen}>
        <>
          <div>{title}</div>
          {children && (
            <ArrowWrapper isExpanded={isAccordionOpen || !!isOpen}>
              <Icon icon={'ArrowUp'} size="sm" />
            </ArrowWrapper>
          )}
        </>
      </AccordionHeader>
      {children && (
        <AccordionContentWrapper
          isVisible={isAccordionOpen || isOpen}
          hasAnimation
        >
          <AccordionContent>{children}</AccordionContent>
        </AccordionContentWrapper>
      )}
    </AccordionTab>
  );
};

export default Accordion;
