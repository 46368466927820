import { ACTION_TYPES } from '../material-card.types';
import type { ActionType } from '../material-card.types';

import { CounterBadgeWrapper } from './counter-badge.styles';

type CounterBadgeProps = {
  content: number;
  position?: 'left' | 'right';
  variant: ActionType;
};

function CounterBadge({
  content,
  variant,
  position = 'right',
}: CounterBadgeProps) {
  const getContent = () => {
    if (variant === ACTION_TYPES.INCREMENT) {
      return `+ ${content}`;
    }

    if (variant === ACTION_TYPES.DECREMENT) {
      return `- ${content}`;
    }

    return content;
  };

  return (
    <CounterBadgeWrapper variant={variant} position={position}>
      {getContent()}
    </CounterBadgeWrapper>
  );
}

export { CounterBadge };
