import React, { SyntheticEvent, useState } from 'react';
import Icon from '../Icon';
import styles from './SalesOrderItem.module.scss';

import imageNotFound from './imagenotfound.png';

type props = {
  item: any;
};

const itemImagesUrl = '/product-images';

function SalesOrderItem(props: props) {
  const { item } = props;

  const addDefaultSrc = (event: SyntheticEvent<HTMLImageElement>) => {
    const img = event.target as HTMLImageElement;
    img.src = (imageNotFound as unknown as { src: string }).src;
  };

  const [isOpen, setBoxOpen] = useState(false);
  const isBox = item.name.includes('(CM)');

  return (
    <div className={styles.item}>
      <div className={styles.info}>
        <div className={styles.thumb}>
          <img
            alt={item.sku}
            src={`${itemImagesUrl}/${item.sku}.jpg`}
            className={styles.thumb}
            onError={addDefaultSrc}
          />
        </div>
        <div className={styles.data}>
          <div className={styles.title}>{item.name}</div>
          <div>{item.sku}</div>
          <div>{item.quantity}</div>
          <div>
            {isBox && (
              <button
                className={styles.dropdown}
                onClick={() => setBoxOpen(!isOpen)}
                type="button"
              >
                <Icon size="sm" icon={isOpen ? 'ArrowUp' : 'ArrowDown'} />
              </button>
            )}
          </div>
        </div>
      </div>
      {isBox && (
        <div className={`${styles.boxWrapper} ${isOpen ? styles.open : ''}`}>
          <img
            alt={item.sku}
            src={`${itemImagesUrl}/${item.sku}-box.jpg`}
            onError={addDefaultSrc}
          />
        </div>
      )}
    </div>
  );
}

export default SalesOrderItem;
