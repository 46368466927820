import { createRoot } from 'react-dom/client';

import Latex from 'react-latex-next';
import { decodeStringWithAscii } from '@innovamat/radiance-utils';

/**
 *  Latex con prepr, problemas y como se ha solucionado
 *
 *  El componente <Latex> solo permite añadir un string como children <Latex>hola $$90º$$</Latex>
 *  A nosotros desde prepr nos llega un html que hay que inyectar en un div con setDangerously..
 *  Envolver con <Latex> <InnerHtml /> </Latex> no funciona.
 *
 *  Solucion:
 *  Al componente InnerHtml le pasamos el texto que viene de prepr, usando el metodo mapTextForLatex
 *  con este metodo buscamos todos los elementos latex $$loquesea$$ y lo envolvemos por un span
 *  con un Id unico para tener identificado la expresion.
 *  Una vez hecho eso con todas las exepresiones del texto que nos llega de prepr, hacemos un return
 *  del valor al componente InnerHtml para que aparezca en el DOM, dejamos pasar
 *  500ms y los buscamos todos en el dom y con ReactDom creamos un elemento <Latex> y le pasmos como
 *  string el contenido del span que habiamos creado anteriormente
 *
 */

const INITIAL_MACROS = { '\\f': '#1f(#2)' };

export type LatexElement = {
  id: string;
  body: string;
};

export const mapTextForLatex = (item: LatexElement | undefined) => {
  if (!item) return undefined;

  const regex = new RegExp(/##(.*?)##/, 'gm');
  let content = item.body;
  const expressions = content.match(regex);

  if (!expressions) return content;

  const latexIds: Record<string, string> = {};
  for (let index = 0; index < expressions.length; index++) {
    const expression = expressions[index];

    const cleanedExpression = expression.split('##').join('');

    if (!cleanedExpression) return undefined;

    const latexId = `latex-${item.id}-${index}`;
    latexIds[latexId] = cleanedExpression;

    const span = `<span id="${latexId}"></span>`;
    content = content.replace(expression, span);
  }

  setTimeout(() => {
    Object.entries(latexIds).forEach(([id, value]) => {
      const latexElement = document.getElementById(id);
      if (latexElement) {
        const isFormatted = latexElement?.getAttribute('done');
        if (!isFormatted) {
          latexElement.setAttribute('done', 'true');

          const wrapper = createRoot(latexElement);

          wrapper.render(
            <Latex macros={INITIAL_MACROS}>
              {decodeStringWithAscii(value)}
            </Latex>
          );
        }
      }
    });
  }, 500);

  return content;
};
