import React from 'react';

import { Title, TitleContainer } from './TitleHeader.styles';

type Props = {
  actions?: JSX.Element;
  hasSeparator?: boolean;
  title: string;
};

function TitleHeader({ actions, hasSeparator = true, title }: Props) {
  return (
    <TitleContainer hasSeparator={hasSeparator}>
      <Title>{title}</Title>
      {actions}
    </TitleContainer>
  );
}

export default TitleHeader;
