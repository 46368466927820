import { ContentBlock } from '../types/SmartGuide';

const ContentBlockData = {
  '1': {
    icon: 'CbNumbersOperationsIcon',
    text: 'digitalguides.blocks.numbers_and_operations',
  },
  '2': {
    icon: 'CbSpaceFormIcon',
    text: 'digitalguides.blocks.space_and_shape',
  },
  '3': {
    icon: 'CbMeasureIcon',
    text: 'digitalguides.blocks.measurement',
  },
  '4': {
    icon: 'CbRelationshipsChangeIcon',
    text: 'digitalguides.blocks.change_and_relationships',
  },
  '5': {
    icon: 'CbStatisticProbabilityIcon',
    text: 'digitalguides.blocks.statistics_and_probability',
  },
};

export function mapContentBlock(data: any): ContentBlock[] {
  return data.bloques_de_contenido_especficos?.map((item: any) => {
    const content =
      ContentBlockData[
        item.bloque_de_contenido as keyof typeof ContentBlockData
      ];

    return {
      text: item.contenido_especfico,
      title: content.text,
      icon: content.icon,
    } as ContentBlock;
  });
}
