import { Icon } from '../../Icon';
import { Tooltip } from '../../Tooltip';
import { useGlimmerTheme } from '../../../theme';

type Props = {
  tooltipText: string;
};

export function InfoColumnIcon({ tooltipText }: Props): JSX.Element {
  const theme = useGlimmerTheme();

  return (
    <Tooltip content={tooltipText} popperOptions={{ strategy: 'fixed' }}>
      <Icon
        icon="InfoIcon"
        iconColor="white"
        bgColor={theme.tokens.color.alias.cm.icon['icon-info'].value}
        size="S"
        hasStateLayer
      />
    </Tooltip>
  );
}
