import {
  GlowApiError,
  Student,
  useRecoverStudentMutation,
} from '@innovamat/glow-api-client';

type RecoverStudent = {
  student: Student;
  classroomId?: string | undefined;
};

type Props = {
  onSuccess: () => void;
  onError: (error: GlowApiError) => void;
};

type UseRecoverStudent = {
  onRecoverStudent: ({ student, classroomId }: RecoverStudent) => void;
  isPending: boolean;
  isError: boolean;
};

function useRecoverStudent({ onSuccess, onError }: Props): UseRecoverStudent {
  const { mutate, isPending, isError } =
    useRecoverStudentMutation<GlowApiError>();

  const onRecoverStudent = ({ student, classroomId }: RecoverStudent): void => {
    mutate(
      {
        body: {
          studentId: student.id!,
          classroomId,
        },
      },
      {
        onError(error) {
          onError(error);
        },
        onSuccess(data) {
          onSuccess();
        },
      }
    );
  };

  return {
    onRecoverStudent,
    isPending,
    isError,
  };
}

export { useRecoverStudent };
