import styled from '@emotion/styled';
import MobileLogoSVG from '../assets/mobile-logo.svg?url';
import DesktopLogoStatic from '../assets/heli-desktop-logo.png';
import { ImageComponent } from '@innovamat/glimmer-components';
import { Link } from 'react-router-domv6';
import LogoGif from '../assets/heli-desktop-logo.gif';
import { useState } from 'react';

const DesktopLogo = styled(ImageComponent)`
  display: block;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const MobileLogo = styled(ImageComponent)`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

export function Logo({
  onClickGoHome = true,
}: {
  onClickGoHome?: boolean;
}): JSX.Element {
  const [playAnimation, setPlayAnimation] = useState(false);

  const triggerAnimation = (): void => {
    if (playAnimation) return;

    setPlayAnimation(true);
    const timeout = setTimeout(() => {
      setPlayAnimation(false);
      clearTimeout(timeout);
    }, 2700);
  };

  return (
    <Link to={onClickGoHome ? '/' : ''} onMouseEnter={triggerAnimation}>
      <DesktopLogo
        src={playAnimation ? LogoGif.src : DesktopLogoStatic.src}
        width={136}
        height={24}
        alt="Logo de innovamat"
      />
      <MobileLogo
        src={MobileLogoSVG}
        width={24}
        height={24}
        alt="Logo de innovamat"
      />
    </Link>
  );
}
