/* eslint-disable */
import { Activity, purgeActivity } from './activityPurger';

export function purgedSession(
  sessionEntity: any,
  sceneName: string,
  packOrVariation: string,
): any {
  const createdSession = JSON.parse(JSON.stringify(sessionEntity)); // Create a deep clone for session entity
  if (
    createdSession.activities != null
    && createdSession.activities.length > 0
  ) {
    removeNonCurrentActivitiesFromSession(
      createdSession,
      sceneName,
      packOrVariation,
    );
  }

  if (
    createdSession.activities == null
    || createdSession.activities.length === 0
    || createdSession.activities[0] == null
  ) {
    createdSession.activities = [
      generateActivityObject(sceneName, packOrVariation),
    ];
  }
  createdSession.activities[0] = purgeActivity(createdSession.activities[0]);

  return createdSession;
}

function removeNonCurrentActivitiesFromSession(
  sessionEntity: any,
  sceneName: string,
  packOrVariation: string,
) {
  const targetSceneName = sceneName;
  const toBeRemoved: Array<any> = [];
  sessionEntity.activities.forEach((activity: any) => {
    if (
      activity.scene_name !== targetSceneName
      || (activity.pack.toString() !== packOrVariation.toString()
        && activity.variation.toString() !== packOrVariation.toString())
    ) {
      toBeRemoved.push(activity);
    }
  });
  toBeRemoved.forEach((removeActivity: any) => {
    const removeIndex = sessionEntity.activities.indexOf(removeActivity, 0);
    if (removeIndex > -1) {
      sessionEntity.activities.splice(removeIndex, 1);
    }
  });
}

function generateActivityObject(sceneName: string, pack: string): Activity {
  return {
    scene_name: sceneName,
    pack,
    is_motor: sceneName.startsWith('M'),
    codename: '',
    max_duration: 1800,
    min_duration: 600,
    min_statements: 12,
    variation: pack,
  };
}
