import { FlexEndContainer, Container } from '../join-to-classroom.styled';
import { Button, Typography } from '@innovamat/glimmer-components';
import { useJoinToClassroom } from '../join-to-classroom-context';
import { useTranslation } from 'react-i18next';
import { HappyStudentsWithTeacher } from '@innovamat/glimmer-assets';

export function IntroductionStep(): JSX.Element {
  const { t } = useTranslation();
  const { goToNextStep } = useJoinToClassroom();

  return (
    <Container>
      <Typography.Body1>
        {t('classrooms.steps.firstStep.subtitle2')}
      </Typography.Body1>

      <HappyStudentsWithTeacher style={{ margin: '0 auto' }} />
      <FlexEndContainer>
        <Button onClick={goToNextStep}>{t('common.start')}</Button>
      </FlexEndContainer>
    </Container>
  );
}
