import { useEffect } from 'react';
import { isElementInViewPort } from '../../../utils/common';

export const useScrollToCurrentWeek = (
  currentWeekColumnId: string,
  scrollContainerId: string
): void => {
  useEffect(() => {
    const scrollToCurrentWeek = (): void => {
      const currentWeekColumn = document.getElementById(
        currentWeekColumnId
      ) as HTMLElement | null;
      const scrollContainer = document.getElementById(
        scrollContainerId
      ) as HTMLElement | null;

      if (!currentWeekColumn || !scrollContainer) return;

      if (!isElementInViewPort(currentWeekColumn)) {
        const columnRect = currentWeekColumn.getBoundingClientRect();
        const containerRect = scrollContainer.getBoundingClientRect();
        const scrollPosition =
          columnRect.left +
          columnRect.width / 2 -
          containerRect.left -
          containerRect.width / 2 +
          scrollContainer.scrollLeft;
        scrollContainer.scrollTo({ left: scrollPosition, behavior: 'smooth' });
      }
    };

    const timer = setTimeout(scrollToCurrentWeek, 2000);
    const handleResize = (): void => scrollToCurrentWeek();

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, [currentWeekColumnId, scrollContainerId]);
};
