import { Region } from '@innovamat/localization';
import { getData, mapSubtitle } from '../digital-guides';
import { getItemsFromLang } from '../digital-guides/utils/common';
import {
  SpaceChallenge,
  Space,
  SpaceSession,
  SpacesData,
} from './Spaces.types';

const mapChallenges = async (
  space_session: any,
  locale: string,
  region: Region
): Promise<SpaceChallenge[]> => {
  const spaceData = await getData(
    space_session.data.prepr_content_id,
    'items{image{cdn_files}}'
  );

  const localedItems = getItemsFromLang(spaceData.items, locale, region);

  const challengesInfo = localedItems.challenges.items[0].items.challenge.items;

  const mappedChallenges = await Promise.all(
    challengesInfo.map((c: any) => getData(c.id, 'items{image{cdn_files}}'))
  );

  return mappedChallenges.map((c: any, index: number) => {
    const challenge = getItemsFromLang(c.items, locale, region);
    return {
      id: c.id,
      number: index + 1,
      challengeTitle: '',
      image: challenge.image?.items?.[0].cdn_files.items[0].url,
      title: challenge.title.body,
      motto: challenge.motto.body,
      label: '',
      onChange: () => {},
      checked: false,
    };
  });
};

export const mapResponseSpace = async (
  response: any,
  locale: string,
  region: Region
) => {
  const items = getItemsFromLang(response.items, locale, region);

  const getSpace = async (s: any) => {
    const spaceData = await getData(s.data.prepr_content_id, 'items');
    const items = getItemsFromLang(spaceData.items, locale, region);

    const titleInfo = items.title.body;
    const subtitleInfo = items.subtitle;

    const subtitle = mapSubtitle(subtitleInfo, 'earlyYear');
    const challenges = await mapChallenges(s, locale, region);

    return {
      id: s.id,
      title: titleInfo,
      description: subtitle.body,
      defaultImage: s.image_url,
      challenges,
      pinned: false,
      preprId: s.data.prepr_content_id,
      challengeId: challenges[0]?.id,
    } as Space;
  };

  const mappedSpaces: Space[] = await Promise.all(
    items.space_session.items.map((s: any) => getSpace(s))
  );

  const digitalGuide: SpaceSession = {
    id: response.id,
    spaces: mappedSpaces,
    title: items.title.body,
    type: 'space',
  };

  return digitalGuide;
};

export const formatPlanningSpaces = (
  spacesFromPrepr: Space[],
  spacesData: SpacesData
) => {
  if (!spacesData) return spacesFromPrepr;
  return spacesFromPrepr.map((space) => {
    const spaceFromApi = spacesData[space.preprId];
    if (!spaceFromApi) return space;

    const { is_pinned, challenge_id, challenges } = spaceFromApi;

    return {
      ...space,
      pinned: is_pinned,
      challengeId: challenge_id || space.challengeId,
      challenges: space.challenges.map((challenge: any) => ({
        ...challenge,
        checked:
          challenges?.[challenge.id] &&
          challenges?.[challenge.id] !== 'pending',
      })),
    };
  });
};
