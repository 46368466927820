import {
  RemoveClassroomConnectionMutation,
  useGetThirdPartyConnectionsQuery,
} from '@innovamat/glow-api-client';
import { QueryClient } from '@tanstack/react-query';
import { createContext } from 'react';
import { useConnect, useDisconnect, useGetGoogleClassrooms } from '../hooks';
import useRemoveClassroomConnection from '../hooks/use-remove-classroom-connection';
import useSyncroClassroom, {
  SyncroMutation,
} from '../hooks/use-syncro-classroom';
import useImportClassrooms from '../hooks/useI-import-classrooms';
import {
  ClassroomToImport,
  GoogleClassroom,
  GoogleClassroomResponse,
  Mutation,
  Query,
  ThirdPartyConnections,
} from '../types';
import { GoogleImportProvider } from './google-import-provider';
import { useUser } from '../../user-management';

type GoogleClassroomSyncProviderProps = {
  children: React.ReactNode;
  client: QueryClient;
  organizationsApiUrl: string;
};

type GoogleClassroomSyncStateValue =
  | {
      classroomsQuery: Query<GoogleClassroomResponse[]>;
      isGoogleConnected: boolean;
      importingClassroomsStatus: Mutation<ClassroomToImport[]>;
      syncroClassroomStatus: SyncroMutation['syncroClassroomStatus'];
      removeClassroomConnectionStatus: Mutation<
        RemoveClassroomConnectionMutation | undefined
      >;
      connections: ThirdPartyConnections | undefined;
      handleDisconnect: () => void;
      handleConnect: (callback?: Function) => void;
      handleSyncro: (classroomToSyncro: ClassroomToImport) => void;
      handleImportClassrooms: (importClassrooms: GoogleClassroom[]) => void;
      handleRemoveClassroomConnection: (classroomId: string) => void;
    }
  | undefined;

export const GoogleClassroomSyncStateContext =
  createContext<GoogleClassroomSyncStateValue>(undefined);

export function GoogleClassroomSyncProvider({
  children,
  client,
  organizationsApiUrl,
}: GoogleClassroomSyncProviderProps) {
  const { user } = useUser();
  const { data: connectionsQuery, refetch: refetchConnections } =
    useGetThirdPartyConnectionsQuery(
      {},
      {
        enabled: Boolean(user),
      }
    );

  const connections =
    connectionsQuery?.getThirdPartyConnections as ThirdPartyConnections;

  const { onDisconnect } = useDisconnect();
  const { onImportClassrooms, importingClassroomsStatus } = useImportClassrooms(
    {
      client,
    }
  );
  const { onSyncroClassroom, syncroClassroomStatus } = useSyncroClassroom();

  const { onRemoveClassroomConnection, removeClassroomConnectionStatus } =
    useRemoveClassroomConnection();

  const isGoogleConnected = Boolean(connections?.google);

  const { onConnect } = useConnect({
    organizationsApiUrl,
  });
  const classroomsQuery = useGetGoogleClassrooms({
    enabled: false,
  });

  const handleConnect = (callback?: Function) => {
    if (isGoogleConnected) {
      callback?.();
      return;
    }
    onConnect({
      onSuccess: () => {
        refetchConnections();
        callback?.();
      },
    });
  };

  const handleDisconnect = () => {
    onDisconnect();
  };

  const handleSyncro = (classroomToSyncro: ClassroomToImport) => {
    handleConnect(() => onSyncroClassroom(classroomToSyncro));
  };

  const handleImportClassrooms = (importClassrooms: GoogleClassroom[]) => {
    handleConnect(() => {
      const classroomsToImport = importClassrooms.map((classroom) => ({
        google_id: classroom.google_id,
        course_order: classroom.course!.order!,
      }));
      onImportClassrooms(classroomsToImport);
    });
  };

  const handleRemoveClassroomConnection = (classroomId: string) => {
    handleConnect(() => {
      onRemoveClassroomConnection(classroomId);
    });
  };

  const value: GoogleClassroomSyncStateValue = {
    classroomsQuery,
    importingClassroomsStatus,
    syncroClassroomStatus,
    removeClassroomConnectionStatus,
    isGoogleConnected,
    connections,
    handleDisconnect,
    handleSyncro,
    handleImportClassrooms,
    handleConnect,
    handleRemoveClassroomConnection,
  };

  return (
    <GoogleClassroomSyncStateContext.Provider value={value}>
      <GoogleImportProvider>{children}</GoogleImportProvider>
    </GoogleClassroomSyncStateContext.Provider>
  );
}
