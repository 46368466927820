import { Resource } from '../types/resource';
import { SessionResource, Session } from '../types/session';
import { PlayerState } from '../types/state';

export const getNumberOfSteps = (resources: SessionResource[]) =>
  resources
    .map((resource) => ('pages' in resource ? resource.pages.length : 1))
    .reduce((prev, curr) => prev + curr, 0);

export const getNextStep = (state: PlayerState) => {
  const { currentStep, resource, currentResource, totalSteps } = state;

  if (!resource || !('resources' in resource)) {
    return {
      step: 0,
      page: 0,
    };
  }

  const currentStepValue = getRealStepValue(
    currentResource,
    currentStep,
    resource as Session
  );

  if (!resource || currentStepValue === totalSteps) {
    return {
      step: currentStep,
      page: currentResource,
    };
  }

  const subResource = resource.resources[currentResource];
  const numOfSteps =
    subResource && 'pages' in subResource ? subResource.pages.length : 1;
  const nextStep = subResource ? currentStep + 1 : currentStep;

  const step = numOfSteps === nextStep ? 0 : nextStep;
  const page = step === 0 ? currentResource + 1 : currentResource;

  return {
    step,
    page,
  };
};

export const getPrevStep = (state: PlayerState) => {
  const { currentStep, resource, currentResource } = state;

  if (!resource || !('resources' in resource)) {
    return {
      step: 0,
      page: 0,
    };
  }

  const currentStepValue = getRealStepValue(
    currentResource,
    currentStep,
    resource
  );

  if (!resource || currentStepValue === 1) {
    return {
      step: currentStep,
      page: currentResource,
    };
  }

  if (currentResource === 0) {
    return {
      step: currentStep - 1,
      page: currentResource,
    };
  }
  const prevResource = resource.resources[currentResource - 1];
  const numOfSteps = 'pages' in prevResource ? prevResource.pages.length : 1;
  const prevStep = currentStep - 1;

  const step = prevStep === -1 ? numOfSteps - 1 : prevStep;
  const page = prevStep === -1 ? currentResource - 1 : currentResource;

  return {
    step,
    page,
  };
};

export const getRealStepValue = (
  currentResource: number,
  currentStep: number,
  resource: Resource | undefined
) => {
  if (!resource || !('resources' in resource)) return 1;
  if (currentResource === 0) return currentStep + 1;

  const numOfSteps = resource.resources
    .slice(0, currentResource)
    .map((resource) => ('pages' in resource ? resource.pages.length : 1))
    .reduce((prev, curr) => prev + curr, 0);

  return numOfSteps + currentStep + 1;
};

export const getResource = (
  resource: Resource | undefined,
  currentResource: number
) => {
  if (!resource) return undefined;

  return 'resources' in resource
    ? resource?.resources[currentResource]
    : resource;
};
