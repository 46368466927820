import { useEffect, useRef } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import usePlayerDispatch from '../providers/PlayerProviders/usePlayerDispatch';

export default function useResourceTimer() {
  const startTime = useRef<number>(0);
  const dispatch = usePlayerDispatch();

  useEffect(() => {
    startTime.current = Date.now();
    return () => {
      handleDispatch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBeforeunload(() => {
    handleDispatch();
  });

  const handleDispatch = () => {
    const endTime = Date.now();
    const timeDiff = endTime - startTime.current;
    dispatch({ type: 'RESOURCE_LEFT', payload: timeDiff });
  };
}
