import ResourceNotification from '../ResourceNotification';
import usePlayerState from '../../providers/PlayerProviders/usePlayerState';
import SingleGeniallyViewer from '../../resources/SingleGeniallyViewer';
import SinglePdfViewer from '../../resources/SinglePdfViewer';
import { Genially } from '../../types/genially';
import { Pdf } from '../../types/pdf';
import { useWebviewBridge } from '@innovamat/webview-bridge';

export default function SingleResource() {
  const { resource, translations } = usePlayerState();
  const { isInWebview } = useWebviewBridge();

  if (!resource) return null;

  if (resource.isBloqued) {
    return (
      <ResourceNotification
        text={translations['session.bloquedResource.message']}
        dataTestId="warningResourceNotEnabled"
      />
    );
  }
  if (resource.type === 'pdf') {
    const pdf = resource as Pdf;
    return (
      <>
        {isInWebview ? (
          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: '12px',
                right: '12px',
                width: '40px',
                height: '40px',
                backgroundColor: '#D1D1D1',
              }}
            />
            <iframe
              title="pdf"
              frameBorder="0"
              scrolling="yes"
              height="100%"
              width="100%"
              style={{ height: '70vh' }}
              src={`https://docs.google.com/viewer?url=${pdf.path}&embedded=true`}
            />
          </div>
        ) : (
          <SinglePdfViewer
            translations={translations}
            path={pdf.path}
            key={pdf.id}
            isPageBlocked={(page) => pdf.pages[page - 1]?.isBloqued}
            isInWebview={isInWebview}
          />
        )}
      </>
    );
  }

  const genially = resource as Genially;
  return <SingleGeniallyViewer src={genially.src} key={genially.id} />;
}
