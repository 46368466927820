import { useFlag } from '@innovamat/flags';
import { DropdownItemProps } from '@innovamat/glimmer-components';
import { useOrganizationQuery } from '@innovamat/glow-api-client';
import { useCheckIsUpdatePeriod } from '../../components/update-period-announcement-panel';
import { useTranslation } from 'react-i18next';
import { ImportType, useImportCSV } from '../../csv-import';

type Props = {
  type: ImportType;
  onAddDefault?: () => void;
  onImportFromGoogle?: () => void;
  isClassroomImportedFromGoogle?: boolean;
  addTeacherButtonText?: string;
};

type TypeToOptionDict = {
  [key: string]: {
    importDefaultOption?: DropdownItemProps;
    importGoogleOption?: DropdownItemProps;
    importCSVOption?: DropdownItemProps;
    importClassroomCSVOption?: DropdownItemProps;
    importTeacherCSVOption?: DropdownItemProps;
    importStudentCSVOption?: DropdownItemProps;
  };
};

function useAddActionDropdown({
  type,
  onAddDefault,
  onImportFromGoogle,
  isClassroomImportedFromGoogle,
  addTeacherButtonText,
}: Props): {
  getOptions: () => DropdownItemProps[];
} {
  const { t } = useTranslation();
  const { goToImportCSVPage, showImportCSV, organizationId } = useImportCSV();

  const { value: showGoogleClassroomImport } = useFlag(
    'showgoogleclassroomimport',
    false,
    {
      identifier: '',
      custom: {
        schoolId: organizationId || '',
      },
    }
  );

  const { data: { organization } = {} } = useOrganizationQuery(
    { id: organizationId },
    { staleTime: Infinity, enabled: !!organizationId }
  );

  const isUpdatePeriod = useCheckIsUpdatePeriod();

  const isOrgAnonymized = organization?.anonymized ?? false;

  const typeToOption: TypeToOptionDict = {
    newImport: {
      importClassroomCSVOption: {
        children: t('imports.new_class'),
        icon: 'ClassIcon',
        onSelectItem: () => goToImportCSVPage('classroom'),
        state: isUpdatePeriod ? 'disabled' : 'active',
      },
      importTeacherCSVOption: {
        children: t('imports.new_teacher'),
        icon: 'TeacherIcon',
        onSelectItem: () => goToImportCSVPage('teacher'),
        state: 'active',
      },
      importStudentCSVOption: {
        children: t('imports.new_student'),
        icon: 'StudentsIcon',
        onSelectItem: () => goToImportCSVPage('student'),
        state: isUpdatePeriod ? 'disabled' : 'active',
      },
    },
    schoolStudent: {
      importCSVOption: {
        children: t('school.button.importCSV'),
        onSelectItem: () => goToImportCSVPage('student'),
        icon: 'FileCsvIcon',
        state: 'active',
      },
    },
    student: {
      importDefaultOption: {
        children: t('students.button.addStudent'),
        onSelectItem: onAddDefault,
        icon: 'AddIcon',
        state: isClassroomImportedFromGoogle ? 'disabled' : 'active',
      },
      importGoogleOption: {
        children: t('common.syncGoogleClassroom'),
        onSelectItem: onImportFromGoogle,
        icon: 'GoogleClassroomIcon',
        state:
          !isClassroomImportedFromGoogle || !showGoogleClassroomImport
            ? 'disabled'
            : 'active',
      },
      importCSVOption: {
        children: t('school.button.importCSV'),
        onSelectItem: () => goToImportCSVPage(type),
        icon: 'FileCsvIcon',
        state: isClassroomImportedFromGoogle ? 'disabled' : 'active',
      },
    },
    classroom: {
      importDefaultOption: {
        children: t('classrooms.addAClass'),
        onSelectItem: onAddDefault,
        icon: 'AddIcon',
        state: 'active',
      },
      importGoogleOption: {
        children: t('classrooms.importGoogle'),
        onSelectItem: onImportFromGoogle,
        icon: 'GoogleClassroomIcon',
        state: showGoogleClassroomImport ? 'active' : 'disabled',
      },
      importCSVOption: {
        children: t('school.button.importCSV'),
        onSelectItem: () => goToImportCSVPage(type),
        icon: 'FileCsvIcon',
        state: 'active',
      },
    },
    teacher: {
      importDefaultOption: {
        children: addTeacherButtonText || '',
        onSelectItem: onAddDefault,
        icon: 'AddIcon',
        state: 'active',
      },
      importCSVOption: {
        children: t('school.button.importCSV'),
        onSelectItem: () => goToImportCSVPage(type),
        icon: 'FileCsvIcon',
        state: 'active',
      },
    },
  };

  const getOptions = (): DropdownItemProps[] => {
    if (type === 'schoolStudent') {
      return [{ ...typeToOption[type].importCSVOption }] as DropdownItemProps[];
    }

    if (type === 'newImport') {
      return [
        { ...typeToOption[type].importClassroomCSVOption! },
        { ...typeToOption[type].importTeacherCSVOption! },
        { ...typeToOption[type].importStudentCSVOption! },
      ];
    }

    let options: DropdownItemProps[] = [
      { ...typeToOption[type].importDefaultOption! },
    ];

    if (
      (type === 'classroom' && !isOrgAnonymized) ||
      (type === 'student' && isClassroomImportedFromGoogle && !isOrgAnonymized)
    ) {
      options = [...options, { ...typeToOption[type].importGoogleOption! }];
    }
    if (showImportCSV && !isOrgAnonymized) {
      options = [...options, { ...typeToOption[type].importCSVOption! }];
    }

    return options;
  };

  const filterDisabledOptions = (): DropdownItemProps[] =>
    getOptions().filter((option) => option.state !== 'disabled');

  return { getOptions: filterDisabledOptions };
}

export { useAddActionDropdown };
