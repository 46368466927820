import { Container } from './materials-list-header.styles';

type MaterialsListHeaderProps = {
  children: React.ReactNode;
};

function MaterialsListHeader({ children }: MaterialsListHeaderProps) {
  return <Container>{children}</Container>;
}

export { MaterialsListHeader };
