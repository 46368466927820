import { useEventLogging } from '@innovamat/event-logging';
import { useMarkChallengeMutation } from '@innovamat/glow-api-client';
import { Session } from '@innovamat/resource-viewer';
import { useQueryClient } from '@tanstack/react-query';
import { updateChallengeInCache } from '../cache-actions';
import { PreprResponse } from './use-get-prepr-resource';

type HandleChallengeProps = {
  preprResource: PreprResponse;
  session: Session;
  classroomId: string;
  sessionId: string;
  academicYearId: string;
  enabled?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
};

function useHandleChallenge({
  classroomId,
  sessionId,
  academicYearId,
  enabled = true,
  preprResource,
  session,
  onSuccess,
  onError,
}: HandleChallengeProps): {
  handleMarkChallenge: (challengeId: string, done: boolean) => void;
} {
  const queryClient = useQueryClient();
  const markChallengeMutation = useMarkChallengeMutation();
  const { setEventData } = useEventLogging();
  const handleMarkChallenge = (challengeId: string, done: boolean) => {
    if (!classroomId || !sessionId || !enabled) return;

    const challengesBody = {
      body: {
        classroomId: classroomId!,
        sessionId: sessionId!,
        academicYearId: academicYearId!,
      },
    };

    updateChallengeInCache(queryClient, challengesBody, challengeId, done);

    markChallengeMutation.mutate(
      {
        body: {
          academicYearId: academicYearId!,
          challengeId,
          classroomId,
          done,
        },
      },
      {
        onSuccess(data) {
          if (!data.markChallenge?.errors) {
            onSuccess?.();
            return;
          }
          onError?.();
          updateChallengeInCache(
            queryClient,
            challengesBody,
            challengeId,
            done
          );
        },
        onError() {
          onError?.();
          updateChallengeInCache(
            queryClient,
            challengesBody,
            challengeId,
            done
          );
        },
      }
    );
    if (
      preprResource?.type === 'digitalGuide' &&
      'challenges' in preprResource
    ) {
      const challengesGuides = preprResource.challenges.items;
      const challenge = challengesGuides.find((i: any) => i.id === challengeId);
      const index = challengesGuides.findIndex(function getIndex(el: any) {
        return el.id === challengeId;
      });
      const spaceId = session.guide?.id || '';
      const key = done ? 'challenge_done' : 'challenge_undone';
      setEventData(key, {
        session_id: sessionId,
        challenge_id: challengeId,
        challenge_name: challenge?.title.body,
        challenge_order: index + 1,
        challenges_total: challengesGuides.length,
        space_id: spaceId,
        space_name: preprResource.title.body,
        session_name: session.title,
      });
    }
  };

  return {
    handleMarkChallenge,
  };
}

export { useHandleChallenge };
