import { StudentViewOverlay, useUser } from '@innovamat/ga-features';
import { useEffect } from 'react';
import { useLocation } from 'react-router-domv6';

const STUDENT_VIEW_URLS = ['curriculum', 'general'];

function StudentViewManagement(): JSX.Element {
  const { isStudentViewActive, onDisableStudentView } = useUser();
  const location = useLocation();

  useEffect(() => {
    if (
      isStudentViewActive &&
      !STUDENT_VIEW_URLS.find((url) => location.pathname.includes(url))
    ) {
      onDisableStudentView();
    }
  }, [location.pathname, onDisableStudentView, isStudentViewActive]);

  return <StudentViewOverlay />;
}

export { StudentViewManagement };
