import { useCallback, useEffect, useMemo } from 'react';

import {
  type StudentsByParentQuery,
  useCourses,
  useStudentsByParentQuery,
} from '@innovamat/glow-api-client';
import { useUser } from '@innovamat/ga-features';

import type { DigitalPracticeAccess } from '../../../../hooks/use-put-classroom';

import {
  type ParentLauncher,
  usePreprParentLauncher,
} from './use-prepr-parent-launcher';

type StudentInfo = {
  classroomId: string;
  classroomName: string;
  courseName: string;
  digitalPracticeAccess: DigitalPracticeAccess;
  fullName: string;
  studentId: string;
  teachers: string;
};

type UseParentLauncherValues = {
  data: ParentLauncher & { students: StudentInfo[] };
  isPreprLoading: boolean;
  isStudentError: boolean;
  isStudentsLoading: boolean;
  refetchStudentsInfo: () => void;
};

type StudentByParent = NonNullable<
  StudentsByParentQuery['studentsByParent'][number]
>;

function useParentLauncher(): UseParentLauncherValues {
  const { data: preprData, isLoading: isPreprLoading } =
    usePreprParentLauncher();

  const {
    data: studentsData,
    isLoading: isStudentsLoading,
    refetch: refetchStudentsInfo,
    isError: isStudentError,
  } = useStudentsByParentQuery({}, { staleTime: Infinity });

  const { user } = useUser();
  const { getCourseById } = useCourses({ regionCode: user?.region! });

  const getTeachers = (teachers?: string[] | null): string => {
    if (!teachers) {
      return '-';
    }

    const showTeachersNames = teachers?.length <= 2 && teachers?.length > 0;
    return showTeachersNames
      ? teachers.join(', ')
      : teachers?.length.toString();
  };

  const processStudent = useCallback(
    (student: StudentByParent) => {
      const classroomId = student?.classroom_id || '';
      const classroomName = student?.classroom_name || '-';
      const courseName = getCourseById(student.course_id)?.name || '-';
      const digitalPracticeAccess = student?.digital_practice_access || '';
      const fullName = `${student?.first_name} ${student?.last_name}`;
      const studentId = student?.student_id || '';
      const teachers = getTeachers(student.teachers);

      return {
        classroomId,
        classroomName,
        courseName,
        digitalPracticeAccess,
        fullName,
        studentId,
        teachers,
      };
    },
    [getCourseById]
  );

  const students = useMemo(
    () => studentsData?.studentsByParent,
    [studentsData]
  );

  useEffect(() => {}, [studentsData]);

  const newData = useMemo(() => {
    return {
      ...preprData,
      students:
        students?.map((student) =>
          processStudent(student as StudentByParent)
        ) || [],
    };
  }, [preprData, students, processStudent]);

  return {
    data: newData,
    isPreprLoading,
    isStudentError,
    isStudentsLoading,
    refetchStudentsInfo,
  };
}

export { useParentLauncher };
export type { StudentInfo };
