import {
  InvitationBody,
  InvitedEmailBody,
  useInviteUsersToOrganizationMutation,
  useOrganizationQuery,
} from '@innovamat/glow-api-client';
import { newId } from '@innovamat/radiance-utils';
import { useUser } from '../../user-management';

type Props = {
  onSuccess: () => void;
  onError: (error: any) => void;
};

type InviteTeachersToOrganization = {
  onInviteTeachers: (emails: string[]) => void;
  isPending: boolean;
};

function useInviteTeachersToOrganization({
  onSuccess,
  onError,
}: Props): InviteTeachersToOrganization {
  const { mutate, isPending } = useInviteUsersToOrganizationMutation();
  const { user } = useUser();
  const { data } = useOrganizationQuery(
    { id: user?.organizationId ?? '' },
    { staleTime: Infinity, enabled: !!user?.organizationId }
  );

  const handleInviteTeachers = (emails: string[]) => {
    const invitedEmails: InvitedEmailBody[] = emails.map((email) => ({
      email,
      id: newId(),
      invitedId: newId(),
    }));

    if (!user || !data?.organization) return;

    const invitations: InvitationBody = {
      schoolId: user.organizationId,
      inviterId: user.id,
      invitedEmails,
      language: data.organization.language,
      type: 'teacher',
      operativeRegion: user.region,
    };

    mutate(
      {
        invitations,
      },
      {
        onSuccess: onSuccess,
        onError: onError,
      }
    );
  };

  return {
    onInviteTeachers: handleInviteTeachers,
    isPending,
  };
}

export { useInviteTeachersToOrganization };
