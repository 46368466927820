import React from 'react';

import { ChallengeTitleProps } from './ChallengeTitle.types';

import {
  ChallengeNumber as ChallengeBox,
  Title,
  Heading,
  TitleWrapper,
  Wrapper,
} from './ChallengeTitle.styles';
import { theme } from '../../theme/theme';

function ChallengeTitle({
  challengeBoxChildren,
  children,
  title,
  size,
  challengeTitle,
  bgColor = theme.colors.brand['03'],
  color = theme.colors.brand.dark['02'],
  ...props
}: ChallengeTitleProps) {
  return (
    <Wrapper {...props}>
      {challengeBoxChildren && (
        <ChallengeBox color={color} bgColor={bgColor} size={size}>
          {challengeBoxChildren}
        </ChallengeBox>
      )}

      <TitleWrapper>
        <Heading color={color} size={size}>
          {challengeTitle}
        </Heading>
        {title ? <Title size={size}>{title}</Title> : children}
      </TitleWrapper>
    </Wrapper>
  );
}

export default ChallengeTitle;
