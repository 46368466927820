import {
  GlowApiError,
  useSyncroGoogleClassroomMutation,
} from '@innovamat/glow-api-client';
import { ClassroomToImport } from '../types';

export type SyncroMutation = {
  onSyncroClassroom: (classroomToImport: ClassroomToImport) => Promise<void>;
  syncroClassroomStatus: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    data: any;
    error: GlowApiError | null;
    reset: () => void;
  };
};

export default function useSyncroClassroom(): SyncroMutation {
  const { mutate, isPending, isSuccess, isError, data, reset, error } =
    useSyncroGoogleClassroomMutation<GlowApiError>();

  const onSyncroClassroom = async (classroomToImport: ClassroomToImport) => {
    mutate({
      classroomToImport,
    });
  };

  return {
    onSyncroClassroom,
    syncroClassroomStatus: {
      isLoading: isPending,
      isError,
      isSuccess,
      data,
      error,
      reset,
    },
  };
}
