import { useEventLogging } from '@innovamat/event-logging';
import { useFlag } from '@innovamat/flags';
import {
  Option,
  PreprResource,
  SessionResource,
  SessionSectionProvider,
  SingleResource,
  useCurrentNavigationValues,
  useNavigation,
  useOrganization,
  useRegionParameters,
  useUser,
} from '@innovamat/ga-features';
import { useResource } from '@innovamat/glow-api-client';
import { useQueryParams } from '@innovamat/hooks';
import { isEmpty, saveFile, stripHTMLTags } from '@innovamat/radiance-utils';
import { ScrollPosition, SmartGuideSkeleton } from '@innovamat/smart-guides';
import { detectIsInWebview } from '@innovamat/webview-bridge';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';
import { ResourceSkeleton } from './resource-skeleton';
import { SessionDoneProviderWrapper } from './session-done-provider';

type I18NTranslations = {
  translations: Record<string, string>;
};

export function ResourceRouter(): JSX.Element {
  const { classroomId, type, id } = useParams();
  const { user, isRole } = useUser();
  const { i18n } = useTranslation();
  const { goBack } = useNavigation();
  const { setEventData } = useEventLogging();
  const { courseOrder } = useCurrentNavigationValues();

  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({
    scrollHeight: 0,
    scrollTop: 0,
    clientHeight: 0,
  });

  const { regionParameters } = useRegionParameters();
  const queryParams = useQueryParams();
  const { organization } = useOrganization();
  const isStudent = isRole.Student;

  const { value: showDynamicStandards } = useFlag('dynamic-standards', false, {
    identifier: '',
    email: user?.email ?? '',
    custom: {},
  });

  const { resource, status } = useResource({
    id: id!,
    type: type!,
    classroomId,
    language: user?.locale!,
    academicYearId: user?.organization?.academicYearId,
    isStudent: isStudent,
    isLoggedIn: true,
    courseOrder: courseOrder ?? undefined,
    standard:
      showDynamicStandards && organization?.standard
        ? organization?.standard
        : undefined,
  });

  useEffect(() => {
    if (resource?.id) {
      const isSession = resource && 'resources' in resource;
      const hasFileId = 'fileId' in resource ? resource.fileId : undefined;
      setEventData('resource_accessed', {
        resource_id: resource.id,
        resource_name: resource.title,
        resource_type: resource.type,
        file_id: isSession ? '' : hasFileId,
      });
    }
  }, [resource?.id]);

  const handleChangeScrollPosition = (position: ScrollPosition) => {
    setScrollPosition(position);
  };

  const i18nTranslations =
    i18n.getDataByLanguage(i18n.language) || i18n.getDataByLanguage('es');

  const { translations } = i18nTranslations as I18NTranslations;

  const handleDownloadOption = (option?: Option | undefined) => {
    if (!resource) return;
    if (!option || !('resources' in resource)) return;
    const uuids = String(option.value).split('-');
    uuids.forEach((uuid) => {
      const printableToSave = resource.printables.find((o) => uuid === o.value);
      if (printableToSave) {
        const splitOriginalFile = printableToSave?.subtitle?.split('.');
        const extension = splitOriginalFile?.slice(-1).pop();
        saveFile(
          printableToSave.href,
          stripHTMLTags(printableToSave.subtitle || ''),
          detectIsInWebview
        );
        setEventData('content_printable_download', {
          resource_name: stripHTMLTags(printableToSave.subtitle || ''),
          resource_id: printableToSave.id,
          file_id: printableToSave.value,
          resource_type: extension,
          session_id: resource.id,
          session_name: resource.title,
        });
      }
    });
  };

  const [isFakeLoading, setIsFakeLoading] = useState(true);

  useEffect(() => {
    setIsFakeLoading(true);
    const timer = setTimeout(() => {
      setIsFakeLoading(false);
    }, 2800); // 2800ms is the time it takes for the loading gif to complete half cycle

    return () => {
      setIsFakeLoading(true);
      clearTimeout(timer);
    };
  }, [id]);

  if (isFakeLoading || status === 'pending' || !resource || !regionParameters) {
    if (queryParams.has('isDigitalGuide')) {
      return <SmartGuideSkeleton />;
    } else {
      return <ResourceSkeleton />;
    }
  }

  const isSession = resource && 'resources' in resource;

  const getProviderProps = () => {
    return {
      isGuideInResource: isSession ? !isEmpty(resource.guide) : false,
      hasLinkedResources:
        isSession &&
        (!!(resource.linkedLists?.length > 0) ||
          !!((resource.relatedLists?.length || 0) > 0)),
      hasAssessableResources:
        isSession && !!((resource.assessableLists?.length || 0) > 0),
      hasResources: resource && 'resources' in resource,
    };
  };

  const renderResource = () => {
    if (!isSession) {
      return (
        <SingleResource
          translations={translations}
          setScrollPosition={handleChangeScrollPosition}
          resource={resource}
          onGoBack={goBack}
        />
      );
    }

    if (resource.guide?.type === 'prepr') {
      return (
        <PreprResource
          setScrollPosition={handleChangeScrollPosition}
          scrollPosition={scrollPosition}
          session={resource}
          onDownloadOption={handleDownloadOption}
          translations={translations}
          onGoBack={goBack}
        />
      );
    }

    return (
      <SessionResource
        session={resource}
        onDownloadOption={handleDownloadOption}
        translations={translations}
        setScrollPosition={handleChangeScrollPosition}
        onGoBack={goBack}
      />
    );
  };

  return (
    <SessionDoneProviderWrapper>
      <SessionSectionProvider {...getProviderProps()}>
        {renderResource()}
      </SessionSectionProvider>
    </SessionDoneProviderWrapper>
  );
}
