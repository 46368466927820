import { useTranslation } from 'react-i18next';

import {
  Skeleton,
  TableComponent,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';

import { tableStyles } from '../../../../components/common-table-components/table-styles';

function generateRows(count: number): any[] {
  const result: any[] = [];

  for (let i = 0; i < count; i++) {
    result.push({
      id: i + 1,
      firstName: '',
      lastName: '',
      email: '',
      type: '',
      roles: '',
      lastLogin: '',
      reportsEnabled: '',
      isInvitation: '',
      isSchoolInvitation: '',
      token: '',
      expiresAt: '',
      classrooms: [],
    });
  }

  return result;
}

function SchoolTeachersTableSkeleton(): JSX.Element {
  const theme = useGlimmerTheme();
  const { t } = useTranslation();
  const rowsNumber = 4;
  const rows = generateRows(rowsNumber);

  const columns = [
    {
      id: 'firstName',
      label: t('common.firstName'),
      width: 100,
      minWidth: '140px',
      render: () => {
        return <Skeleton width="100%" height="24px" radius="4" />;
      },
    },
    {
      id: 'lastName',
      label: t('common.lastName'),
      width: 100,
      minWidth: '140px',
      render: () => {
        return <Skeleton width="100%" height="24px" radius="4" />;
      },
    },
    {
      id: 'email',
      label: t('common.email'),
      width: 100,
      minWidth: '300px',
      render: () => {
        return <Skeleton width="100%" height="24px" radius="4" />;
      },
    },
    {
      id: 'roles',
      label: t('common.permissions'),
      width: 100,
      minWidth: '140px',
      render: () => {
        return <Skeleton width="100%" height="24px" radius="4" />;
      },
    },
    {
      id: 'clasrooms',
      label: t('common.classrooms'),
      width: 100,
      minWidth: '120px',
      render: () => {
        return <Skeleton width="100%" height="24px" radius="4" />;
      },
    },
    {
      id: 'lastLogin',
      label: t('common.status'),
      width: 100,
      minWidth: '140px',
      render: () => {
        return <Skeleton width="100%" height="24px" radius="4" />;
      },
    },
    {
      id: 'actions',
      width: 100,
      minWidth: '100px',
      render: () => {
        return <Skeleton width="100%" height="24px" radius="4" />;
      },
    },
  ];

  return (
    <TableComponent
      columns={columns as any}
      id="school-teachers-table"
      key="teachers-table-skeleton"
      rows={rows}
      sx={tableStyles(theme)}
    />
  );
}

export { SchoolTeachersTableSkeleton };
