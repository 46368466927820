import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import useNoOverflow from '../../hooks/useNoOverflow';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import styles from './Drawer.module.scss';

type Props = {
  open: boolean;
  clickOutsideClose?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  position?: 'left' | 'right';
};

function Drawer({
  open,
  children,
  clickOutsideClose = false,
  position = 'right',
  className = '',
  onClose,
}: Props) {
  const ref = useRef(null);
  useNoOverflow(open);

  useOnClickOutside(ref, () => {
    // TODO: dont close when is confirmation modal displayed
    if (clickOutsideClose) {
      onClose!();
    }
  });

  const drawerClass = cx(styles.drawer, {
    [styles[position]]: true,
    [className]: !!className,
  });

  if (!open) return null;

  const drawerRoot = document.getElementById('drawer') || document.body;
  return createPortal(
    <>
      <div className={styles.overlay} />
      <div ref={ref} className={drawerClass}>
        {children}
      </div>
    </>,
    drawerRoot
  );
}

export default Drawer;
