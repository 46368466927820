import * as authService from './lib/auth';
import { compressQuery } from './lib/compressQuery';
import * as cookies from './lib/cookies';
import * as crypto from './lib/crypto';
import * as dates from './lib/dates';
import type { InlineManualUser } from './lib/inlineManual';
import * as inlineManual from './lib/inlineManual';
import * as stages from './lib/stages';
import * as storage from './lib/storage';
import * as unity from './lib/unity';
import * as validators from './lib/validators';
export * from './lib/aux-functions';
export * from './lib/datadog';
export * from './lib/downloadHelpers';
export * from './lib/from-page';
export * from './lib/html';
export * from './lib/resources';
export * from './lib/root-element';

// los exporto asi de momento porque esta importado asi desde core utilities
import * as roles from './lib/types/roles';

export {
  authService,
  compressQuery,
  cookies,
  crypto,
  dates,
  inlineManual,
  InlineManualUser,
  roles,
  stages,
  storage,
  unity,
  validators,
};

export * from './lib/types/classroom';
export * from './lib/types/course';
export * from './lib/types/preferences';
export * from './lib/types/roles';
export * from './lib/types/stage';
export * from './lib/types/token';
export * from './lib/types/user';
