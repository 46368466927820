import React, { createContext, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

type VideoProviderProps = {
  children: React.ReactNode;
};

type VideoContextValues = {
  videoRef: React.RefObject<ReactPlayer>;
  duration: number;
  progressList: any;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
  setProgressList: React.Dispatch<React.SetStateAction<any>>;
  getProgress: () => number;
  handleResetVideoPlayer: () => void;
};

export const VideoContext = createContext<VideoContextValues | undefined>(
  undefined
);

export default function VideoProvider({ children }: VideoProviderProps) {
  const ref = useRef<any>(null);
  const [keyValue, setKeyValue] = useState(1);
  const [progressList, setProgressList] = useState([]);
  const [duration, setDuration] = useState<number>(
    ref.current?.getDuration() || 0
  );

  const getProgress = () => ref.current?.getCurrentTime() || 0;

  const handleResetVideoPlayer = () => {
    ref.current = null;
    setDuration(0);
    setKeyValue((t) => t + 1);
  };

  const value: VideoContextValues = useMemo(
    () => ({
      videoRef: ref,
      duration,
      progressList,
      setProgressList,
      setDuration,
      getProgress,
      handleResetVideoPlayer,
    }),
    [duration, progressList]
  );

  return (
    <VideoContext.Provider key={keyValue} value={value}>
      {children}
    </VideoContext.Provider>
  );
}
