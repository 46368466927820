import React, { useState } from 'react';

import { ChallengeTitle } from '@innovamat/innova-components';

import {
  CardWrapper,
  HeadingCard,
  ChallengeCheckboxStyles,
  MottoWrapper,
  Image,
  Wrapper,
  ConfettiWrapper,
} from './SpacesItemCard.styles';
import { Challenge } from '../SpacesCard.types';
import { ConfettiAnimation as Confetti } from '../../ConfettiAnimation';

function SpacesItemCard({
  title,
  number,
  challengeTitle,
  checked,
  onChange,
  motto,
  label,
  image,
  showCheckbox,
  enableConfettiAnimation,
}: Challenge) {
  const [isConfettiAnimation, setConfettiAnimation] = useState(false);

  const handleOnChange = (event: any) => {
    event.stopPropagation();
    onChange();
    if (enableConfettiAnimation && !checked) {
      setConfettiAnimation(true);
      setTimeout(() => {
        setConfettiAnimation(false);
      }, 1500);
    }
  };
  return (
    <Wrapper>
      <Image alt={title} src={image} />
      <CardWrapper borderRadius="sm">
        <HeadingCard>
          <ChallengeTitle
            title={title}
            challengeBoxChildren={<>{number}</>}
            size="sm"
            challengeTitle={challengeTitle}
          />
          {isConfettiAnimation && (
            <ConfettiWrapper>
              <Confetti width={200} />
            </ConfettiWrapper>
          )}
          {showCheckbox && (
            <ChallengeCheckboxStyles
              label={label}
              size="sm"
              onChange={handleOnChange}
              checked={checked}
            />
          )}
        </HeadingCard>
        <MottoWrapper>
          <span>{motto}</span>
        </MottoWrapper>
      </CardWrapper>
    </Wrapper>
  );
}

export default SpacesItemCard;
