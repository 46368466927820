import { useQueryParams } from '@innovamat/hooks';
import { useEffect } from 'react';
import { ReportsOptions } from '../views/weekly-results/weekly-results.header';
import { dates } from '@innovamat/radiance-utils';

type Props = {
  reportsOptions: ReportsOptions[];
  setCurrentReport: (value: string) => void;
  handleSelectReportDate: (reportDate: { from: string; to: string }) => void;
};

type SelectWeek = {
  onChangeReportDate: (dateFrom: string) => void;
  options: {
    value: string;
    label: string;
    __typename?: 'WeeklyReportDate' | undefined;
    date_from?: string | undefined;
    date_to?: string | undefined;
    title?: string | null | undefined;
  }[];
};

const useSelectWeek = ({
  reportsOptions,
  setCurrentReport,
  handleSelectReportDate,
}: Props): SelectWeek => {
  const query = useQueryParams();

  const queryFrom = query.get('from')?.replace(' ', '+');
  const queryTo = query.get('to')?.replace(' ', '+');

  useEffect(() => {
    if (queryFrom && queryTo && reportsOptions) {
      const queryReport = reportsOptions.find(
        (r) => r?.date_from === queryFrom && r?.date_to === queryTo
      );
      if (!queryReport) return;
      onChangeReportDate(queryReport.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFrom, queryTo, reportsOptions]);

  useEffect(() => {
    if (reportsOptions[0] && !(queryFrom && queryTo)) {
      onChangeReportDate(reportsOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsOptions]);

  const removeQueryParams = (): void => {
    const url = window.location.origin + window.location.pathname;
    window.history.replaceState({}, '', url);
  }

  const onChangeReportDate = (dateFrom: string): void => {
    const report = reportsOptions.find((r) => r.date_from === dateFrom);
    if (!report) return;
    handleSelectReportDate({ from: report.date_from!, to: report.date_to! });
    setCurrentReport(`
    ${dates.getFormattedDate(report?.date_from)} - ${dates.getFormattedDate(
      report?.date_to
    )}`);
    if (queryFrom || queryTo) removeQueryParams();
  };

  const options = reportsOptions.map((r: ReportsOptions) => ({
    ...r,
    value: r.value.toString(),
  }));

  return {
    onChangeReportDate,
    options,
  };
};

export default useSelectWeek;
