import type { Organization } from '@innovamat/glow-api-client';
import { authService, storage } from '@innovamat/radiance-utils';

export const updateCurrentUserData = async (organization: Organization) => {
  await authService.refreshToken();

  const tokenInfo = storage.tokenInfo.get();

  if (!tokenInfo) throw new Error('No token info');

  const { locale } = authService.decodeToken(tokenInfo);

  let language = locale;

  if (organization.region !== organization.operativeRegion) {
    language = organization.language;
  }

  storage.language.set(language);
  storage.region.set(organization.region);
  storage.organizationName.set(organization.name!);
};
