import { useState } from 'react';

export function useIOSFullScreen() {
  const [fullscreenIOS, setFullscreenIOS] = useState(false);

  const handleFullScreenInIOS = () => {
    setFullscreenIOS(!fullscreenIOS);
  };

  return { fullscreenIOS, handleFullScreenInIOS };
}
