import { useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from '@innovamat/hooks';
import usePlayerState from '../providers/PlayerProviders/usePlayerState';

export default function usePlayerSize() {
  const previousHeight = useRef(() => {
    const wrapper = document.getElementById('resource-wrapper');
    const height = wrapper?.clientHeight || 0;
    return height;
  });
  const [showLoader, setShowLoader] = useState(true);
  const { fullscreen, resource, totalSteps } = usePlayerState();

  const hasTimeLine = totalSteps > 1;

  // TODO when is a resoruce outside a session, the timeline is not shown
  const TIMELINE = hasTimeLine ? 110 : 40;

  useUpdateEffect(() => {
    const wrapper = document.getElementById('resource-wrapper');

    /* when the fullscreen is activated, the height of the wrapper is set to the window height
     but is not full screen after few seconds, so we need to wait a bit to set the height */
    if (fullscreen.active) {
      setTimeout(() => {
        const fullscreenHeight = window.innerHeight - TIMELINE;
        wrapper?.setAttribute('style', `height: ${fullscreenHeight}px`);
      }, 500);
    } else {
      wrapper?.setAttribute('style', `height: ${previousHeight.current}px`);
    }
  }, [fullscreen.active]);

  useEffect(() => {
    const wrapper = document.getElementById('resource-wrapper');
    const height = wrapper?.clientHeight || 0;

    if (showLoader && height !== 0 && resource) {
      setShowLoader(false);
      wrapper?.setAttribute('style', `height: ${height}px;`);
      previousHeight.current = () => height;
    }
  }, [resource, showLoader]);

  return {
    showLoader,
  };
}
