import ContentLoader from 'react-content-loader';

const SchoolSectionSkeleton = (): JSX.Element => {
  return (
    <div style={{ width: '100%', height: '168px', overflow: 'hidden' }}>
      <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" width="20%" height="32" rx="4" />
        <rect x="0" y="48" width="100%" height="120" rx="16" />
      </ContentLoader>
    </div>
  );
};

export { SchoolSectionSkeleton };
