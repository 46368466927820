import { QueryClient } from '@tanstack/react-query';

import { TeachersQuery, useTeachersQuery } from '@innovamat/glow-api-client';

type Props = {
  classroomId: string;
  isPlatformEnabled: boolean;
  organizationId: string;
  queryClient: QueryClient;
  teacherId: string;
};

function updateTeachersInCache({
  classroomId,
  isPlatformEnabled,
  organizationId,
  queryClient,
  teacherId,
}: Props): void {
  const key = useTeachersQuery.getKey({
    classroomId,
    isPlatformEnabled,
    organizationId,
  });
  const teachersQuery = queryClient.getQueryData<TeachersQuery>(key);

  if (teachersQuery) {
    queryClient.setQueryData(key, {
      teachers: teachersQuery.teachers?.map((teacher) =>
        teacher?.id === teacherId
          ? { ...teacher, reportsEnabled: !teacher?.reportsEnabled }
          : teacher
      ),
    });
  }
}

export { updateTeachersInCache };
