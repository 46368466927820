const data = {
  ca: {
    nativeName: 'Català',
    region: 'Spain',
    iso639_1: 'ca',
    iso639_2T: 'cat',
    innovamat_2: 'ca',
    innovamat_3: 'cat',
    zendesk: 'ca',
    zendesk_official: 'ca',
  },
  en: {
    nativeName: 'English',
    region: 'England',
    iso639_1: 'en',
    iso639_2T: 'eng',
    innovamat_2: 'en',
    innovamat_3: 'eng',
    zendesk: 'en-gb',
    zendesk_official: 'en-gb',
  },
  en_US: {
    nativeName: 'English',
    region: 'EEUU',
    iso639_1: 'en',
    iso639_2T: 'eng',
    innovamat_2: 'en',
    innovamat_3: 'eng',
    zendesk: 'en-gb',
    zendesk_official: 'en-us',
  },
  es: {
    nativeName: 'Español',
    region: 'Spain',
    iso639_1: 'es',
    iso639_2T: 'spa',
    innovamat_2: 'es',
    innovamat_3: 'esp',
    zendesk: 'es',
    zendesk_official: 'es',
  },
  es_419: {
    nativeName: 'Español',
    region: 'Latam',
    iso639_1: 'es',
    iso639_2T: 'spa',
    innovamat_2: 'es',
    innovamat_3: 'esp',
    zendesk: 'es-419',
    zendesk_official: 'es-419',
  },
  es_CO: {
    nativeName: 'Español',
    region: 'Colombia',
    iso639_1: 'es',
    iso639_2T: 'spa',
    innovamat_2: 'es',
    innovamat_3: 'esp',
    zendesk: 'es-419',
    zendesk_official: 'es-419',
  },
  es_EC: {
    nativeName: 'Español',
    region: 'Ecuador',
    iso639_1: 'es',
    iso639_2T: 'spa',
    innovamat_2: 'es',
    innovamat_3: 'esp',
    zendesk: 'es-419',
    zendesk_official: 'es-419',
  },
  es_ES: {
    nativeName: 'Español',
    region: 'Spain',
    iso639_1: 'es',
    iso639_2T: 'spa',
    innovamat_2: 'es',
    innovamat_3: 'esp',
    zendesk: 'es',
    zendesk_official: 'es',
  },
  es_MX: {
    nativeName: 'Español',
    region: 'México',
    iso639_1: 'es',
    iso639_2T: 'spa',
    innovamat_2: 'es',
    innovamat_3: 'esp',
    zendesk: 'es-419',
    zendesk_official: 'es-419',
  },
  eu: {
    nativeName: 'Euskara',
    region: 'Spain',
    iso639_1: 'eu',
    iso639_2T: 'eus',
    innovamat_2: 'eu',
    innovamat_3: 'eus',
    zendesk: 'es',
    zendesk_official: 'eu',
  },
  fr_FR: {
    nativeName: 'Français',
    region: 'France',
    iso639_1: 'fr',
    iso639_2T: 'fra',
    innovamat_2: 'fr',
    innovamat_3: 'fra',
    zendesk: 'en-gb',
    zendesk_official: 'fr',
  },
  it: {
    nativeName: 'Italiano',
    region: 'Italy',
    iso639_1: 'it',
    iso639_2T: 'ita',
    innovamat_2: 'it',
    innovamat_3: 'ita',
    zendesk: 'en-gb',
    zendesk_official: 'it',
  },
  it_IT: {
    nativeName: 'Italiano',
    region: 'Italy',
    iso639_1: 'it',
    iso639_2T: 'ita',
    innovamat_2: 'it',
    innovamat_3: 'ita',
    zendesk: 'en-gb',
    zendesk_official: 'it',
  },
  pt: {
    nativeName: 'Português',
    region: 'Portugal',
    iso639_1: 'pt',
    iso639_2T: 'por',
    innovamat_2: 'pt',
    innovamat_3: 'por',
    zendesk: 'en-gb',
    zendesk_official: 'pt',
  },
  pt_BR: {
    nativeName: 'Português',
    region: 'Brazil',
    iso639_1: 'pt',
    iso639_2T: 'por',
    innovamat_2: 'pt',
    innovamat_3: 'por',
    zendesk: 'en-gb',
    zendesk_official: 'pt-br',
  },
  va: {
    nativeName: 'Valenciano',
    region: 'Valencia',
    iso639_1: 'ca',
    iso639_2T: 'cat',
    innovamat_2: 'va',
    innovamat_3: 'val',
    zendesk: 'ca',
    zendesk_official: 'ca',
  },
  vl: {
    nativeName: 'Valenciano',
    region: 'Valencia',
    iso639_1: 'ca',
    iso639_2T: 'cat',
    innovamat_2: 'va',
    innovamat_3: 'val',
    zendesk: 'ca',
    zendesk_official: 'ca',
  },
};

type IsoLanguageCodes = {
  innovamat_2: string;
  innovamat_3: string;
  iso639_1: string;
  iso639_2T: string;
  nativeName: string;
  region: string;
  zendesk: string;
};

type IsoLanguageCodesByLocale = Record<string, IsoLanguageCodes>;

const currentData = data as IsoLanguageCodesByLocale;

const getInnovamat2 = (locale: string): string => {
  return currentData[locale]?.innovamat_2;
};

const getInnovamat3 = (locale: string): string => {
  return currentData[locale]?.innovamat_3;
};

const getISO6391 = (locale: string): string => {
  return currentData[locale]?.iso639_1;
};

const getISO6392T = (locale: string): string => {
  return currentData[locale]?.iso639_2T;
};

const getZendesk = (locale: string): string => {
  return currentData[locale]?.zendesk;
};

const locales = {
  getInnovamat2,
  getInnovamat3,
  getISO6391,
  getISO6392T,
  getZendesk,
};

export { locales };
