import { useReducer, useState } from 'react';
import type { Course, GoogleClassroom } from '../types';

const MAX_CLASSROOMS_SELECTED = 3;

type ImportClassroomsAction =
  | { type: 'RESET_STATE'; payload: undefined }
  | { type: 'REMOVE_CLASSROOM'; payload: GoogleClassroom }
  | {
      type: 'ADD_CLASSROOM';
      payload: GoogleClassroom;
    }
  | {
      type: 'CHANGE_COURSE';
      payload: { classroom: GoogleClassroom; course: Course };
    };

interface ImportClassroomsState {
  importClassrooms: GoogleClassroom[];
}

function importClassroomsReducer(
  state: ImportClassroomsState,
  action: ImportClassroomsAction
): ImportClassroomsState {
  const { type, payload } = action;
  switch (type) {
    case 'RESET_STATE':
      return {
        ...state,
        importClassrooms: [],
      };
    case 'REMOVE_CLASSROOM':
      return {
        ...state,
        importClassrooms: state.importClassrooms.filter(
          (item) => item.google_id !== payload.google_id
        ),
      };
    case 'ADD_CLASSROOM':
      return {
        ...state,
        importClassrooms: [...state.importClassrooms, payload],
      };
    case 'CHANGE_COURSE':
      return {
        ...state,
        importClassrooms: state.importClassrooms.map((c) => {
          if (c.google_id === payload.classroom.google_id) {
            return { ...c, course: payload.course };
          } else {
            return c;
          }
        }),
      };
    default:
      throw new Error();
  }
}

export const useImportClassroomsModal = (courses: Course[]) => {
  const [classroomsChecked, setClassroomsChecked] = useState<number>(0);

  const [state, dispatch] = useReducer(importClassroomsReducer, {
    importClassrooms: [],
  });

  const resetState = () => {
    setClassroomsChecked(0);
    dispatch({ type: 'RESET_STATE', payload: undefined });
  };

  const isClassroomSelectedToImport = (classroom: GoogleClassroom) =>
    state.importClassrooms.findIndex(
      (c) => c.google_id === classroom.google_id
    ) >= 0;

  const onCheckClassroom = (
    classroom: GoogleClassroom,
    course: string | undefined
  ) => {
    const _course = courses.find((c) => c.id === course) as Course;
    isClassroomSelectedToImport(classroom)
      ? setClassroomsChecked(classroomsChecked - 1)
      : setClassroomsChecked(classroomsChecked + 1);
    dispatch({
      type: isClassroomSelectedToImport(classroom)
        ? 'REMOVE_CLASSROOM'
        : 'ADD_CLASSROOM',
      payload: { ...classroom, course: _course ?? courses[0] },
    });
  };

  const onSelectCourse = (option: any, classroom: GoogleClassroom) => {
    const course = courses.find((c) => c.id === option.value) as Course;
    dispatch({
      type: 'CHANGE_COURSE',
      payload: { classroom, course },
    });
  };

  return {
    resetState,
    onCheckClassroom,
    onSelectCourse,
    isClassroomSelectedToImport,
    maxClassroomsSelected: classroomsChecked === MAX_CLASSROOMS_SELECTED,
    importClassrooms: state.importClassrooms,
    mappedCourses: courses.map((c) => ({
      label: c.name as string,
      value: c.id as string,
    })),
  };
};
