import { useEffect, useState } from 'react';
import { EMAIL_FIELD, SIS_ID_FIELD, HEADER_INDEX } from '../utils/constants';

type DuplicateType = {
  sis_id: string[];
  email: string[];
};

function useCheckStudentsForEmailAndSisDuplicates(csvData: string[][]): {
  isChecking: boolean;
  duplicates: DuplicateType;
} {
  const [duplicates, setDuplicates] = useState<DuplicateType>({
    sis_id: [],
    email: [],
  });
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    if (csvData.length > 1) {
      const SIS_ID_COLUMN_INDEX = csvData[HEADER_INDEX]?.indexOf(SIS_ID_FIELD);
      const EMAIL_COLUMN_INDEX = csvData[HEADER_INDEX]?.indexOf(EMAIL_FIELD);

      if (SIS_ID_COLUMN_INDEX === -1 || EMAIL_COLUMN_INDEX === -1) {
        setDuplicates({ sis_id: [], email: [] });
        setIsChecking(false);
        return;
      }

      const dataWithoutHeader = csvData.slice(1);

      setTimeout(() => {
        const duplicatesFound = checkForDuplicates(
          dataWithoutHeader,
          SIS_ID_COLUMN_INDEX,
          EMAIL_COLUMN_INDEX
        );
        setDuplicates(duplicatesFound);
        setIsChecking(false);
      }, 0);
    } else {
      setDuplicates({ sis_id: [], email: [] });
      setIsChecking(false);
    }
  }, [csvData]);

  return { isChecking, duplicates };
}

function checkForDuplicates(
  data: string[][],
  sisIdIndex: number,
  emailIndex: number
): DuplicateType {
  const sisIdCounts: Record<string, number> = {};
  const emailCounts: Record<string, number> = {};

  for (const row of data) {
    const sisId = row[sisIdIndex];
    const email = row[emailIndex];

    if (sisId) {
      sisIdCounts[sisId] = (sisIdCounts[sisId] || 0) + 1;
    }

    if (email) {
      emailCounts[email] = (emailCounts[email] || 0) + 1;
    }
  }

  const duplicates: DuplicateType = {
    sis_id: Object.keys(sisIdCounts).filter((key) => sisIdCounts[key] > 1),
    email: Object.keys(emailCounts).filter((key) => emailCounts[key] > 1),
  };

  return duplicates;
}

export { useCheckStudentsForEmailAndSisDuplicates };
export type { DuplicateType };
