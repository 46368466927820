export const hideRootElement = () => {
  const element = document.getElementById('root');
  if (element) {
    element.style.height = '0';
    element.style.overflowY = 'hidden';
  }
};

export const showRootElement = () => {
  const element = document.getElementById('root');
  if (element) {
    element.style.height = 'auto';
    element.style.overflowY = 'auto';
  }
};
