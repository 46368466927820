import { IconButton, Tooltip } from '@innovamat/glimmer-components';
import { useUser } from '../providers';
import { useTranslation } from 'react-i18next';

type Props = {
  size?: 'S' | 'M' | 'L';
};

function StudentViewButton({ size }: Props): JSX.Element {
  const { isStudentViewActive, onToggleStudentView } = useUser();
  const { t } = useTranslation();
  const state = isStudentViewActive ? 'inUse' : undefined;

  return (
    <Tooltip
      content={
        isStudentViewActive
          ? t('student_view.button.tooltip.teacher')
          : t('student_view.button.tooltip.student')
      }
    >
      <IconButton
        size={size}
        data-inlinemanual="student_view"
        icon={'StudentViewIcon'}
        onClick={() => onToggleStudentView('icon')}
        state={state}
      />
    </Tooltip>
  );
}

export { StudentViewButton };
