import React from 'react';

import { CounterBadgeWrapper } from './CounterBadge.styles';

type CounterBadgeProps = {
  content: number;
  position?: 'left' | 'right';
  variant: 'increment' | 'decrement';
};

function CounterBadge({
  content,
  variant,
  position = 'right',
}: CounterBadgeProps) {
  return (
    <CounterBadgeWrapper variant={variant} position={position}>
      {variant === 'increment' ? '+' : '-'} {content}
    </CounterBadgeWrapper>
  );
}

export default CounterBadge;
