import { Grid, Grounder } from '@innovamat/glimmer-components';

import { SchoolSectionSkeleton } from '../../../components/my-school';
import { Container, ColWithMargin } from '../styles';

function LandingPageDistrictAdminSkeleton(): JSX.Element {
  return (
    <Container>
      <Grid.Row justifyContent="center">
        <ColWithMargin md={10} lg={8}>
          <SchoolSectionSkeleton />
        </ColWithMargin>
      </Grid.Row>
      <Grounder />
    </Container>
  );
}

export { LandingPageDistrictAdminSkeleton };
