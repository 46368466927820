import useEventListener from './useEventListener';

type Options = {
  [x: string]: Function;
};

export default function useKeyPress(options: Options) {
  useEventListener('keydown', (event: any) => {
    const { key } = event as KeyboardEvent;
    options[key]?.();
  });
}
