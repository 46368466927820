import styled from '@emotion/styled';
import { getTypography } from '@innovamat/glimmer-components';
import { useWebviewBridge } from '@innovamat/webview-bridge';
import { useRef } from 'react';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import {
  PrintableBody1,
  PrintableBody2,
} from '../../printable-styles/printable-typography';
import { AllMaterials, Printable } from '../../types/Materials';
import { downloadFile } from '../../utils/downloadFile';
import { PreprInnerHtml } from '../PreprInnerHtml';
import { Resource } from './Resource';

const Container = styled.div<{ hideOnPrint?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media print {
    ${({ hideOnPrint }) =>
      hideOnPrint &&
      `
      display: none;
    `}
  }
`;

const Wrapper = styled.div<{ hideOnPrint?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-secondary'].value};
  border-radius: 4px;
  height: fit-content;

  @media print {
    padding: 12px;
    gap: 8px;

    * {
      ${({ theme }) => getTypography(theme, 'Body 3')}
    }

    ${({ hideOnPrint }) =>
      hideOnPrint &&
      `
      display: none;
    `}
  }
`;

const Title = styled(PrintableBody1)`
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};

  @media print {
    font-weight: 500;
  }
`;

const NoMaterial = styled(PrintableBody2)``;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media print {
    gap: 2px;
  }
`;

export function Materials({
  applets,
  classroomMaterials,
  materialsList,
  printables,
  projectables,
  videos,
}: AllMaterials): JSX.Element {
  const { t } = useSmartGuides();

  const { isInWebview } = useWebviewBridge();

  const handleDownloadFile = (printable: Printable): void => {
    if (printable.isDisabled) return;
    downloadFile(printable.url, printable.value);
  };

  const handleOpen = (printable: Printable): void => {
    const isPDF = printable.url.endsWith('.pdf');

    if (!isPDF) {
      handleDownloadFile(printable);
      return;
    }

    if (printable.isDisabled) return;
    const url = `/api/preview-pdf?file=${printable.url}&name=${printable.value}`;
    window.open(url, '_blank');
  };

  const hasResources = Boolean(
    printables?.length ||
      applets?.length ||
      videos?.length ||
      projectables?.length
  );
  const hasMaterials = Boolean(
    classroomMaterials?.length || materialsList?.length
  );

  const materialText = (
    materialKey: string,
    additionalText: string | undefined,
    isOptional: boolean
  ): string => {
    return `${t(materialKey)} ${additionalText ? ` (${additionalText})` : ''} ${
      isOptional ? `(${t('digitalguides.material.optional')})` : ''
    }`;
  };

  const copyMaterialLink = (
    materialKey: string,
    materialId: number,
    event: React.MouseEvent<HTMLDivElement>
  ): void => {
    if (event?.metaKey || event?.ctrlKey) {
      const materialLink = `[[Material=${t(materialKey)}|${materialId}]]`;

      const tempInput = document.createElement('input');
      tempInput.value = materialLink;
      document.body.appendChild(tempInput);

      tempInput.select();
      document.execCommand('copy');

      document.body.removeChild(tempInput);
    }
  };

  const materiaBoxRef = useRef<HTMLDivElement>(null);

  const hasOnlyPrintables =
    printables?.length &&
    applets?.length === 0 &&
    videos?.length === 0 &&
    projectables?.length === 0;

  return (
    <Wrapper
      ref={materiaBoxRef}
      hideOnPrint={!!hasOnlyPrintables && !hasMaterials}
    >
      {!hasResources && !hasMaterials && (
        <Container>
          <Title>{t('digitalguides.material')}</Title>
          <NoMaterial>{t('digitalguides.material.no_material')}</NoMaterial>
        </Container>
      )}

      {hasMaterials && (
        <Container>
          <Title>{t('digitalguides.material')}</Title>
          <List>
            {classroomMaterials?.map((material) =>
              material.split('\r\n').map((element) => (
                <Resource
                  key={element}
                  icon="ClassMaterialIcon"
                  tooltip={{
                    text: element,
                  }}
                >
                  <PreprInnerHtml text={element} />
                </Resource>
              ))
            )}

            {materialsList?.map((material) => (
              <Resource
                onClick={(e) =>
                  copyMaterialLink(material.value, material.id, e)
                }
                id={`${material.id}`}
                icon="InnovamatLogoIcon"
                key={material.value}
                tooltip={{
                  img: material.image,
                  text: materialText(
                    material.value,
                    material.additionalText,
                    material.isOptional
                  ),
                }}
              >
                <PreprInnerHtml
                  text={materialText(
                    material.value,
                    material.additionalText,
                    material.isOptional
                  )}
                />
              </Resource>
            ))}
          </List>
        </Container>
      )}

      {hasResources && (
        <Container hideOnPrint={!!hasOnlyPrintables}>
          <Title>{t('digitalguides.resources')}</Title>
          <List>
            {printables?.map((printable) => (
              <Resource
                key={printable.value}
                icon="FileStandardIcon"
                isDownloadable={!!printable.url}
                isDisabled={printable.isDisabled}
                onOpen={
                  !isInWebview
                    ? () => handleOpen(printable)
                    : () => handleDownloadFile(printable)
                }
                onDownload={() => handleDownloadFile(printable)}
              >
                <PreprInnerHtml text={printable.value} />
              </Resource>
            ))}
            {applets?.map((applet) => (
              <Resource icon="AppletIcon" key={applet}>
                <PreprInnerHtml text={applet} />
              </Resource>
            ))}
            {videos?.map((video) => (
              <Resource icon="MediaIcon" key={video}>
                <PreprInnerHtml text={video} />
              </Resource>
            ))}
            {projectables?.map((projectable) => (
              <Resource icon="ProjectableIcon" key={projectable}>
                <PreprInnerHtml text={projectable} />
              </Resource>
            ))}
          </List>
        </Container>
      )}
    </Wrapper>
  );
}
