import { useLocation } from 'react-router-domv6';
import { useRegionParametersQuery } from '@innovamat/glow-api-client';
import { useUser } from '../../user-management';

export function useRegionParameters() {
  const location = useLocation();
  const { user } = useUser();

  // Obtener el parámetro 'region' de la URL para el caso de recursos públicos
  const urlSearchParams = new URLSearchParams(location.search);
  const urlRegion = urlSearchParams.get('region');
  if (urlRegion) {
    localStorage.setItem('region', urlRegion);
  }

  const regionCode = urlRegion || user?.region?.replace('-LIT', '');

  const educationLevel = 'SECUNDARIA';

  const stage = educationLevel === 'SECUNDARIA' ? 'secondary' : 'primary';

  const query = useRegionParametersQuery(
    { regionCode: regionCode!, stage: stage! },
    { enabled: Boolean(regionCode && stage), staleTime: Infinity }
  );

  return {
    ...query,
    regionParameters: {
      ...query.data?.regionParameters,
      dataOrigin: 'front-web-ga',
    },
  };
}
