const isVideo = (resource: any) => resource.type === 'vimeo';

const isADobleSession = (resource: any) => {
  return (
    resource.type === 'session' &&
    resource.is_completable &&
    !resource.status.includes('not_available')
  );
};

export const shiftArrayBy = (array: any[], value: number) => {
  const stepsToShift = value % array.length;
  return array.slice(stepsToShift).concat(array.slice(0, stepsToShift));
};

const findLastDoableResource = (resources: any[], index: number) => {
  for (let i = index + 1; i < resources.length; i += 1) {
    if (
      isADobleSession(resources[i]) ||
      (isVideo(resources[i]) && resources[i].is_done)
    ) {
      return i;
    }
  }
  return index;
};

export const findLastSessionDoneIndex = (resources: any[]) => {
  if (resources.length === 0) return -1;
  for (let i = resources.length - 1; i >= 0; i -= 1) {
    if (
      (isADobleSession(resources[i]) && resources[i].is_done) ||
      (isVideo(resources[i]) && resources[i].is_done)
    ) {
      return i;
    }
  }
  return 0;
};

export const findFirstSessionNotDoneAfterLastSessionDoneIndex = (
  resources: any[]
) => {
  const lastDoneIndex = findLastSessionDoneIndex(resources);
  if (!lastDoneIndex) return 0;
  return findLastDoableResource(resources, lastDoneIndex);
};

const getFirstIndexWhenSlidesToShow = (
  value: number,
  slidesToShow?: number
) => {
  if (!slidesToShow) return value;
  if (value < slidesToShow) return 0;
  return value - slidesToShow + 1;
};

// this function is not used anymore but the functions inside it are used in the new sortResources function "getInitialSlide"
// part of the logic of "getInitialSlide" function is covered in sortResources.test.ts by testing sortResources function, so that's why i leave it here
export const sortResources = (
  notSortedResources: any[],
  isStudent: boolean,
  slidesToShow?: number
) => {
  if (!notSortedResources || notSortedResources.length === 0) return [];

  if (isStudent) {
    const value =
      findFirstSessionNotDoneAfterLastSessionDoneIndex(notSortedResources);
    const realValue = getFirstIndexWhenSlidesToShow(value, slidesToShow);
    const sortedResources = shiftArrayBy(notSortedResources, realValue);
    return sortedResources;
  }

  const lastDoneIndex = findLastSessionDoneIndex(notSortedResources);

  if (
    findLastDoableResource(notSortedResources, lastDoneIndex) === lastDoneIndex
  )
    return notSortedResources;
  const sortedResources = shiftArrayBy(notSortedResources, lastDoneIndex);
  return sortedResources;
};

export const getInitialSlide = (
  notSortedResources?: any[] | null,
  slidesToShow?: number
): number | undefined => {
  if (!notSortedResources || notSortedResources.length === 0 || !slidesToShow)
    return;
  const lastDoneIndex = findLastSessionDoneIndex(notSortedResources);

  return lastDoneIndex;
};
