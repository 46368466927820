import { useMemo } from 'react';
import { usePageQuery } from '../../generated/types';
import { useCompletedResources } from '../use-completed-resources';
import { mapPageResponse } from './mapper';
import { Page, PageData } from './types';

type Props = {
  pageId: string;
  academicYearId: string;
  classroomId?: string;
  isStudentView?: boolean;
};

type Return = {
  isLoading: boolean;
  isError: boolean;
  data: Page | undefined;
};

export function usePage({
  pageId,
  academicYearId,
  classroomId,
  isStudentView,
}: Props): Return {
  const completedResources = useCompletedResources({
    classroomId,
    academicYearId,
  });

  const variables = {
    id: pageId!,
    academicYearId: academicYearId!,
    classroomId: classroomId || null,
    isStudentView,
  };
  const { data, isLoading, isError } = usePageQuery(variables, {
    enabled: !!pageId,
  });

  const pageData = data?.page as PageData;

  const page = useMemo(() => {
    if (!pageData) return undefined;
    return mapPageResponse(
      pageData,
      completedResources,
      Boolean(isStudentView)
    );
  }, [completedResources, isStudentView, pageData]);

  return {
    isLoading,
    isError,
    data: page,
  };
}
