import ContentLoader from 'react-content-loader';

import { useGlimmerTheme } from '@innovamat/glimmer-components';

import { Container } from './types-of-tests-announcement-panel';

const TypesOfTestsAnnouncementPanelSkeleton = (): JSX.Element => {
  const theme = useGlimmerTheme();
  const backgroundColor = theme.tokens.color.global.neutral.neutral100.value;

  return (
    <Container>
      <ContentLoader
        speed={2}
        width="100%"
        height="45px"
        backgroundColor={backgroundColor}
        foregroundColor={backgroundColor}
      >
        <rect x="0" y="0" width="100%" height="45px" rx="8" />
      </ContentLoader>
    </Container>
  );
};

export { TypesOfTestsAnnouncementPanelSkeleton };
