import { useEffect, useState } from 'react';

// deprecated: now we use useDevice from '@innovamat/glimmer-components';
export function useDevice() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  const isTablet = width <= 1280;

  return { isTablet, isMobile };
}
