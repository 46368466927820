'use client';

import {
  JoinToClassroomProvider,
  useJoinToClassroom,
} from './join-to-classroom-context';

import {
  ConfirmStep,
  FirstTimeSelectClassroomsStep,
  IntroductionStep,
  SelectClassroomsStep,
  SelectCoursesStep,
  SuccessStep,
} from './steps';
import { useState } from 'react';
import { Button, Wizard, WizardStep } from '@innovamat/glimmer-components';
import { AddClassroomModal } from '../../components/add-classroom-modal';
import { useTranslation } from 'react-i18next';
import { AddClassroomDropdown } from '../../components/add-classroom-dropdown';
import { useEventListener } from '@innovamat/hooks';

export type JoinToClassroomProps = {
  orgId: string;
  region: string;
  email: string;
  onGoBack: () => void;
  onGoHome: () => void;
  isFirstTime?: boolean;
};

export const JoinToClassroom = (props: JoinToClassroomProps): JSX.Element => {
  return (
    <JoinToClassroomProvider {...props}>
      <JoinToClassroomBase isFirstTime={props.isFirstTime} />
    </JoinToClassroomProvider>
  );
};

function JoinToClassroomBase({
  isFirstTime = false,
}: {
  isFirstTime?: boolean;
}): JSX.Element {
  const {
    step,
    editModalData,
    goToNextStep,
    goToPreviousStep,
    orgId,
    region,
    handleSuccessNewClassroom,
    selectedCourses,
    handleConfirm,
    assignMutationIsPending,
    selectedClassrooms,
    onGoHome,
    orgHasClassrooms,
  } = useJoinToClassroom();
  const SCROLL_THRESHOLD = 50;
  const { t } = useTranslation();
  const [showAddClassroomDropdown, setShowAddClassroomDropdown] =
    useState(false);
  const checkScroll = (): void => {
    setShowAddClassroomDropdown(window.scrollY > SCROLL_THRESHOLD);
  };

  useEventListener('scroll', checkScroll);

  const getFirstTimeSteps = () => {
    const steps: WizardStep[] = [
      {
        title: t('classrooms.steps.firstStep.title'),
        description: t('classrooms.steps.firstStep.subtitle'),
        content: <IntroductionStep />,
      },
    ];

    if (orgHasClassrooms) {
      steps.push({
        title: t('classrooms.select-classrooms.select-course'),
        description: t(
          'classrooms.select-classrooms.select-course.description'
        ),
        content: <SelectCoursesStep />,
        isNextStepDisabled: selectedCourses.length === 0,
        rightFooterActions: (
          <Button
            onClick={goToNextStep}
            disabled={selectedCourses.length === 0}
          >
            {t('common.continue')}
          </Button>
        ),
      });
    }

    steps.push({
      title: t('classrooms.select-classrooms.select-classroom'),
      description: orgHasClassrooms
        ? t('classrooms.select-classrooms.select-classroom.description')
        : undefined,
      titleRightActions: (
        <AddClassroomDropdown
          options={['add', 'import']}
          organizationId={orgId}
          region={region}
          size="M"
          onSuccess={handleSuccessNewClassroom}
        />
      ),
      content: <FirstTimeSelectClassroomsStep />,
      leftFooterActions: showAddClassroomDropdown && (
        <AddClassroomDropdown
          options={['add', 'import']}
          organizationId={orgId}
          region={region}
          position="topLeft"
          size="M"
          onSuccess={handleSuccessNewClassroom}
        />
      ),
      isNextStepDisabled: selectedClassrooms.length === 0,
      rightFooterActions: (
        <Button
          onClick={goToNextStep}
          disabled={selectedClassrooms.length === 0}
        >
          {t('common.continue')}
        </Button>
      ),
    });

    steps.push({
      title: t('classrooms.select-classrooms.confirm.title'),
      content: <ConfirmStep />,
      rightFooterActions: (
        <>
          <Button onClick={goToPreviousStep} variant="secondary">
            {t('join-classroom.footer.edit')}
          </Button>

          <Button onClick={handleConfirm} loading={assignMutationIsPending}>
            {t('join-classroom.footer.confirm')}
          </Button>
        </>
      ),
    });

    steps.push({
      title: t('classrooms.steps.success.title'),
      content: <SuccessStep />,
      hideBackButton: true,
      rightFooterActions: (
        <Button onClick={onGoHome}>{t('join-classroom.footer.go-home')}</Button>
      ),
    });

    return steps;
  };

  const getJoinToClassroomSteps = () => [
    {
      title: t('classrooms.select-classrooms.join-classroom'),
      description: t('classrooms.select-classrooms.join-classroom.description'),
      onGoBack: onGoHome,
      content: <SelectClassroomsStep />,
      isNextStepDisabled: selectedClassrooms.length === 0,
      rightFooterActions: (
        <Button
          onClick={goToNextStep}
          disabled={selectedClassrooms.length === 0}
        >
          {t('common.continue')}
        </Button>
      ),
    },
    {
      title: t('classrooms.select-classrooms.confirm.title'),
      content: <ConfirmStep />,
      rightFooterActions: (
        <>
          <Button onClick={goToPreviousStep} variant="secondary">
            {t('join-classroom.footer.edit')}
          </Button>

          <Button onClick={handleConfirm} loading={assignMutationIsPending}>
            {t('join-classroom.footer.confirm')}
          </Button>
        </>
      ),
    },
    {
      title: t('classrooms.steps.success.title'),
      content: <SuccessStep />,
      hideBackButton: true,
      rightFooterActions: (
        <Button onClick={onGoHome}>{t('join-classroom.footer.go-home')}</Button>
      ),
    },
  ];

  return (
    <>
      <Wizard
        steps={isFirstTime ? getFirstTimeSteps() : getJoinToClassroomSteps()}
        currentStep={step}
        handleNextStep={goToNextStep}
        handlePrevStep={goToPreviousStep}
      />
      <AddClassroomModal {...editModalData} isCourseSelectorAutofilled />
    </>
  );
}
