import { useEventLogging } from '@innovamat/event-logging';
import { Roles } from '@innovamat/radiance-utils';
import { useState } from 'react';
import { useNavigationEventData } from '../../navigation';

type StudentViewLocation = 'overlay' | 'icon';

type StudentView = {
  isStudentViewActive: boolean;
  onToggleStudentView: (location?: StudentViewLocation) => void;
  onDisableStudentView: () => void;
};

type Props = {
  roles: Roles[];
  onUpdateRoles: (roles: Roles[]) => void;
};

function useStudentView({ roles, onUpdateRoles }: Props): StudentView {
  const { setEventData } = useEventLogging();
  const { getResourceData, resourceId } = useNavigationEventData();

  const [previousRoles, setPreviousRoles] = useState<Roles[] | undefined>(
    undefined
  );

  const isStudentViewActive = Boolean(previousRoles);

  const onToggleStudentView = (location?: StudentViewLocation): void => {
    const key = isStudentViewActive
      ? 'back_to_teacher_view'
      : 'switch_to_student_view';

    const buttonLocation = isStudentViewActive ? location : undefined;

    const resourceData = getResourceData();

    const isResource = resourceData?.type !== 'session';

    setEventData(key, {
      resource_id: isResource ? resourceId : null,
      resource_name: isResource ? resourceData?.name : null,
      resource_type: isResource ? resourceData?.type : null,
      session_id: !isResource ? resourceId : null,
      session_name: !isResource ? resourceData?.name : null,
      button_location: buttonLocation,
    });

    if (!previousRoles?.length) {
      setPreviousRoles(roles);
      onUpdateRoles([Roles.STUDENT]);
    } else {
      setPreviousRoles(undefined);
      onUpdateRoles(previousRoles);
    }
  };

  const onDisableStudentView = (): void => {
    setPreviousRoles(undefined);
    onUpdateRoles(previousRoles ?? roles);
  };

  return {
    isStudentViewActive,
    onToggleStudentView,
    onDisableStudentView,
  };
}

export { useStudentView };
export type { StudentViewLocation };
