import { Button, Dropdown } from '@innovamat/glimmer-components';
import { publicAvailableLanguages } from '@innovamat/localization';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type RegionLanguageSelector = {
  lang: string;
};

export function RegionLanguageSelector({
  lang,
}: RegionLanguageSelector): JSX.Element {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(lang);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  return (
    <Dropdown closeOnSelectItem>
      <Dropdown.Toggle>
        <Button leftIcon="LanguageIcon" variant="tertiary">
          {publicAvailableLanguages.find((l) => l.value === language)?.label}
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Content>
        {publicAvailableLanguages.map((language) => (
          <Dropdown.Item
            key={language.value}
            onSelectItem={() => changeLanguage(language.value)}
            state="active"
          >
            {language.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
}
