import { roles, stages } from '@innovamat/radiance-utils';
import { useMemo } from 'react';
import { useUser } from '../providers';

type Params = {
  courseOrder: number | null | undefined;
  menu?: string;
};

type ShowStudentView = {
  showStudentViewButton: boolean;
};

function useShowStudentView({ courseOrder, menu }: Params): ShowStudentView {
  const { user, isStudentViewActive } = useUser();

  const showStudentViewButton = useMemo(() => {
    if (!roles.hasTeacherRole(user?.roles) && !isStudentViewActive)
      return false;
    if (!stages.isSecundaria(courseOrder)) return false;
    if (menu !== 'curriculum' && menu !== 'general') return false;

    return true;
  }, [courseOrder, isStudentViewActive, menu, user?.roles]);

  return {
    showStudentViewButton,
  };
}

export { useShowStudentView };
