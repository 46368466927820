type UploadIconProps = {
  stroke?: string;
};

function UploadIcon(props: UploadIconProps) {
  const { stroke } = props;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 15C9 16.0609 9.42143 17.0783 10.1716 17.8284C10.9217 18.5786 11.9391 19 13 19C14.0609 19 15.0783 18.5786 15.8284 17.8284C16.5786 17.0783 17 16.0609 17 15C17 13.9391 16.5786 12.9217 15.8284 12.1716C15.0783 11.4214 14.0609 11 13 11C11.9391 11 10.9217 11.4214 10.1716 12.1716C9.42143 12.9217 9 13.9391 9 15V15Z"
        stroke={stroke || '#108478'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.088 22.3402L23.848 20.3562C23.7581 20.2124 23.6331 20.0938 23.4848 20.0116C23.3364 19.9294 23.1696 19.8862 23 19.8862C22.8304 19.8862 22.6636 19.9294 22.5152 20.0116C22.3669 20.0938 22.2419 20.2124 22.152 20.3562L16.874 28.8002L14.724 25.3562C14.6341 25.2124 14.5091 25.0938 14.3608 25.0116C14.2124 24.9294 14.0456 24.8862 13.876 24.8862C13.7064 24.8862 13.5396 24.9294 13.3912 25.0116C13.2429 25.0938 13.1179 25.2124 13.028 25.3562L7 35.0002H17"
        stroke={stroke || '#108478'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 47H3C2.46957 47 1.96086 46.7893 1.58579 46.4142C1.21071 46.0391 1 45.5304 1 45V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H29.586C30.1164 1.00011 30.625 1.2109 31 1.586L38.414 9C38.7891 9.37499 38.9999 9.88361 39 10.414V19"
        stroke={stroke || '#108478'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 35C23 38.1826 24.2643 41.2348 26.5147 43.4853C28.7652 45.7357 31.8174 47 35 47C38.1826 47 41.2348 45.7357 43.4853 43.4853C45.7357 41.2348 47 38.1826 47 35C47 31.8174 45.7357 28.7652 43.4853 26.5147C41.2348 24.2643 38.1826 23 35 23C31.8174 23 28.7652 24.2643 26.5147 26.5147C24.2643 28.7652 23 31.8174 23 35Z"
        stroke={stroke || '#108478'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 29V41"
        stroke={stroke || '#108478'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 35H29"
        stroke="#108478"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UploadIcon;
