import { useState } from 'react';

type Props = {
  defaultValue?: number[];
  multiExpandable?: boolean;
  length: number;
};

type Return = {
  isExpanded: (id: number) => boolean;
  onExpand: (id: number, value: boolean) => void;
  onCollapse: (id: number) => void;
  onCollapseAll: () => void;
  onExpandAll: () => void;
  areAllExpanded: boolean;
  areAllCollapsed: boolean;
};

export function useAccordion({
  defaultValue = [],
  multiExpandable = false,
  length,
}: Props): Return {
  const [expanded, setExpanded] = useState(defaultValue);

  const isExpanded = (id: number): boolean => expanded.includes(id);

  const handleExpand = (id: number, value: boolean): void => {
    if (!value) {
      handleClose(id);
      return;
    }

    if (!multiExpandable) {
      setExpanded([id]);
      return;
    }

    setExpanded((prev) => [...prev, id]);
  };

  const handleClose = (id: number): void => {
    setExpanded((prev) => prev.filter((item) => item !== id));
  };

  const handleCollapseAll = (): void => {
    handleInnerAccordions('collapse');
    setExpanded([]);
  };

  const handleExpandAll = (): void => {
    handleInnerAccordions('expand');
    setExpanded(Array.from(Array(length).keys()));
  };

  const areAllExpanded = expanded.length > 0 && expanded.length === length;
  const areAllCollapsed = expanded.length === 0;

  function handleInnerAccordions(action: 'expand' | 'collapse'): void {
    const innerAccordions = document.querySelectorAll(
      "[data-testid='accordion'] [data-testid='accordion']"
    );

    innerAccordions.forEach((accordion) => {
      const shouldClick =
        (action === 'expand' && accordion.ariaExpanded === 'false') ||
        (action === 'collapse' && accordion.ariaExpanded === 'true');

      if (shouldClick) {
        (accordion as HTMLElement).click();
      }
    });
  }

  return {
    isExpanded,
    onExpand: handleExpand,
    onCollapse: handleClose,
    onCollapseAll: handleCollapseAll,
    onExpandAll: handleExpandAll,
    areAllExpanded,
    areAllCollapsed,
  };
}
